<template>
    <div id="container">
<div id="content">
            <h1>Probably Graduated Students</h1>
            <h2>Students with expected graduation dates, program enrollment dates, and registrations all in the past.</h2>
            <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align" @selection-change="handleSelectionChange">
                <el-table-column v-if="showExpiration" :key="'select'" type="selection"></el-table-column>
                <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="preferred_first_name" label="Preferred" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="70"></el-table-column>
                <el-table-column prop="status_display" label="Student Status" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="expected_graduation" label="Expected Graduation" sortable :sort-orders="['ascending', 'descending']" width="175">
                    <template slot-scope="scope">
                        {{ scope.row.expected_graduation | formatDateMDY }}
                    </template>
                </el-table-column>
                <el-table-column prop="program_graduation" label="Most recent program graduation date" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        {{ scope.row.program_graduation | formatDateMDY }}
                    </template>
                </el-table-column>
                <el-table-column prop="registration_end_date" label="End of most recent enrolled semester" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        {{ scope.row.registration_end_date | formatDateMDY }}
                    </template>
                </el-table-column>
            </el-table>
            ( {{ data.length }} records)<br><br>
            <el-button v-if="showExpiration" size="mini" type="primary" @click="expireSelectedStudents">Expire Selected Students</el-button>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Probably Graduated Students"
            }
        },
        components: {
            },
        data () {
            return {
                data: [],
                loading: false,
                selectedStudents: [],
                showExpiration: false
            }
        },
        methods: {
            load: function() {
                this.getData();
                
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/probably-graduated-students/')
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                            this.showExpiration = response.data.show_expiration;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSelectionChange: function(val) {
                this.selectedStudents = val;
            },
            expireSelectedStudents: function() {
                this.$message.closeAll();
                if(this.selectedStudents.length){
                    var finished = [];
                    for(var i = 0; i < this.selectedStudents.length; i++){
                        axios.put('/api/student/change-status/', {id: this.selectedStudents[i].id, new_status: 'expired', expired_reason: "Other", expired_comment: "Student appeared in Probably Graduated report"})
                        .then(response => {
                            finished.push(this.selectedStudents[i]);
                            if(finished.length == this.selectedStudents.length){
                                this.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'success',
                                    message: "The students were updated."
                                });
                                this.getData();
                            }
                        });
                    }
                } else {
                    this.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: "No students were selected."
                    });
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    table td, table th {
        font-size:11.7px;
    }
</style>
