<template>
    <div id="container">
<div id="content">
            <student-nav selected="academic" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <student-current-semester :updatedFromSisAt="updatedFromSisAt" :currentTerm="currentTerm" :standing="standing" :school="school" :affiliation="affiliation" :enrollment="enrollment" :expectedGraduation="expectedGraduation" :dean="dean" :advisors="advisors" :veteranStatus="veteranStatus"></student-current-semester>
            <student-program-of-study :programs="programs"></student-program-of-study>
            <student-activities :activities="activities"></student-activities>
            <student-registration-history :registrations="registrations"></student-registration-history>
            <student-withdrawal-history :withdrawals="withdrawals"></student-withdrawal-history>
            <student-current-term-summary :id="id" :currentTerm="currentTerm" :currentTermCourses="currentTermCourses"></student-current-term-summary>
            <student-next-term-summary :nextTermCourses="nextTermCourses" :id="id"></student-next-term-summary>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
     import axios from 'axios'
     import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                activities: [],
                advisors: [],
                affiliation: "",
                currentTerm: "",
                currentTermCourses: [],
                currentTermExams: [],
                dean: "",
                enrollment: "",
                expectedGraduation: "",
                nextTermCourses: [],
                nextTermExams: [],
                programs: [],
                registrations: [],
                school: "",
                standing: "",
                updatedFromSisAt: "",
                veteranStatus: "",
                withdrawals: []
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/academic/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                axios.get('/api/student/academic-information/', {params:{id: this.id}})
                    .then(response => {
                        this.activities = response.data.activities;
                        this.advisors = response.data.advisors;
                        this.affiliation = response.data.affiliation;
                        this.currentTerm = response.data.current_term;
                        this.currentTermCourses = response.data.current_term_courses;
                        this.currentTermExams = response.data.current_term_exams;
                        this.dean = response.data.dean;
                        this.enrollment = response.data.enrollment;
                        if(response.data.expected_graduation){
                            this.expectedGraduation = moment(response.data.expected_graduation).format("M/D/YYYY");
                        }
                        this.nextTermCourses = response.data.next_term_courses;
                        this.nextTermExams = response.data.next_term_exams;
                        this.programs = response.data.programs;
                        this.registrations = response.data.registrations;
                        this.school = response.data.school;
                        this.standing = response.data.standing;
                        this.updatedFromSisAt = moment(response.data.updated_from_sis_at).format("M/D/YYYY");
                        this.veteranStatus = response.data.veteran_status;
                        this.withdrawals = response.data.withdrawals;

                        this.activities.forEach(function(activity){
                            if(activity.begin_date){
                                activity.begin_date = moment(activity.begin_date).format("MMMM YYYY");
                            }

                            if(activity.end_date){
                                activity.end_date = moment(activity.end_date).format("MMMM YYYY");
                            }
                        });

                        this.withdrawals.forEach(function(withdrawal){
                            if(withdrawal.wd_date){
                                withdrawal.wd_date = moment(withdrawal.wd_date).format("M/D/YYYY");
                            }
                        });

                        if(this.currentTermCourses){
                            this.currentTermCourses.sort(function(a,b) {
                                return a.course_identifier.toUpperCase() > b.course_identifier.toUpperCase();
                            });
                        }

                        this.currentTermCourses.forEach(function(course){
                            if (course.times.length > 1) {
                                var arr1 = [];
                                var arr2 = [];
                                for(var j = 0; j < course.days.length; j++){
                                    if(j % 2 == 0){
                                        arr1.push(course.days[j]);
                                    } else {
                                        arr2.push(course.days[j]);
                                    }
                                }
                                course.days = [arr1.join(" "), arr2.join(" ")];
                            }
                        });

                        this.nextTermCourses.forEach(function(course){
                            if (course.times.length > 1) {
                                var arr1 = [];
                                var arr2 = [];
                                for(var j = 0; j < course.days.length; j++){
                                    if(j % 2 == 0){
                                        arr1.push(course.days[j]);
                                    } else {
                                        arr2.push(course.days[j]);
                                    }
                                }
                                course.days = [arr1.join(" "), arr2.join(" ")];
                            }
                        });
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    p {
        font-style:italic
    }
</style>
