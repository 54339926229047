<template>
    <div id="container">
<div id="content">
            <el-form class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="onSemesterChange">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div style="float:right; margin-right:10px;">
                <el-date-picker type="date" size="mini" v-model="startDate" value-format="yyyy-MM-dd" style="width:135px"></el-date-picker> -
                <el-date-picker type="date" size="mini" v-model="endDate" value-format="yyyy-MM-dd" style="width:135px"></el-date-picker>
                <el-button size="mini" type="primary" @click="submitDates">Submit</el-button>
            </div>
            <h1>Case Management Notes</h1>
            <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="uni" label="Uni" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.uni ? scope.row.uni : '(none)' }}</a></template>
                </el-table-column>
                <el-table-column prop="first_name" label="First Name" sortable :sort-orders="['ascending', 'descending']" width="120">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.first_name }}</a></template>
                </el-table-column>
                <el-table-column prop="last_name" label="Last Name" sortable :sort-orders="['ascending', 'descending']" width="110">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.last_name }}</a></template>
                </el-table-column>
                <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.preferred_name }}</a></template>
                </el-table-column>
                <el-table-column prop="status_display" label="DORIS Status" sortable :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="coordinator_name" label="Coordinator" sortable :sort-orders="['ascending', 'descending']" width="120"></el-table-column>
                <el-table-column prop="created_at" label="Date of Note" sortable :sort-orders="['ascending', 'descending']" width="130">
                    <template slot-scope="scope">{{ scope.row.created_at | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column prop="author_name" label="Author of Note" sortable :sort-orders="['ascending', 'descending']" width="105"></el-table-column>
                <el-table-column prop="content" label="CM Note" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope"><span class="formatted-text">{{ scope.row.content }}</span></template>
                </el-table-column>
            </el-table>
            <p><a :href="'/api/report/case-management-notes.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate + '&semester_id=' + this.semesterId" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Case Management Notes"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                semesterId: null,
                semesters: [],
                startDate: null,
                endDate: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                if(this.urlParams.semester_id){
                    this.semesterId = parseInt(this.urlParams.semester_id);
                }
                if(this.urlParams.start_date){
                    this.startDate = this.urlParams.start_date;
                }
                if(this.urlParams.end_date){
                    this.endDate = this.urlParams.end_date;
                }
                this.getData();

                axios.get('/api/simple/get-current-and-previous-six-semesters/')
                    .then(response => {
                        if(response.data.success) {
                            this.semesters = response.data.rows;
                            var currentSemesterObj = this.semesters.find(o => o.id === this.semesterId);
                            this.startDate = currentSemesterObj.start_date;
                            this.endDate = currentSemesterObj.end_date;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getData: function() {
                this.data = [];
                this.loading = true;
                axios.get('/api/report/case-management-notes/', {params: {start_date: this.startDate, end_date: this.endDate, semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            submitDates: function(){
                this.semesterId = null;
                history.pushState(null, null, '/report/case-management-notes?start_date=' + this.startDate + '&end_date=' + this.endDate);
                this.getData();
            },
            onSemesterChange: function(val){
                if(val){
                    var currentSemesterObj = this.semesters.find(o => o.id === this.semesterId);
                    this.startDate = currentSemesterObj.start_date;
                    this.endDate = currentSemesterObj.end_date;
                    history.pushState(null, null, '/report/case-management-notes?semester_id=' + this.semesterId);
                    this.getData();
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        float:right;
        width: 250px;
    }
</style>
