<template>
  <div id="container">
    <div id="content">
      <h1>Edit Graduate Proctoring Session Requestor</h1>
      <el-form :model="requestor" :rules="rules" ref="requestorForm" label-width="50px" label-position="left">
        <el-form-item label="Name" prop="name">
          <el-input size="mini" v-model="requestor.name" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input size="mini" v-model="requestor.email" style="width:250px;"></el-input>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
      <br><br>
      <student-emails :emails="groupEmails" :title="'Group Emails'" :showCCField="false"></student-emails>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "Edit Graduate Proctoring Session Requestor"
      }
    },
    components: {
    },
    data () {
      return {
        requestor: {
          id: null,
          name: null,
          email: null
        },
        groupEmails: [],
        rules: {
          name: [{ required: true, whitespace: true, message: 'Name is required' }],
        }
      }
    },
    methods: {
      load: function(){
        this.requestor.id = location.pathname.split('/proctoring-session-requestor/edit/')[1];
        if(this.requestor.id){
          this.requestor.id = parseInt(this.requestor.id);
          this.getRequestor();
        }
      },
      getRequestor: function(){
        axios.get("/api/proctoring-session-requestor/", {params: {id: this.requestor.id}})
          .then(response => {
            this.requestor = response.data.proctoring_session_requestor;
          });

        axios.get("/api/group-email/group-emails-for-generic/", {params: {id: this.requestor.id, type: "ProctoringSessionRequestor"}})
          .then(response => {
            this.groupEmails = response.data.rows;
          });
      },
      onSubmit: function(){
        this.$refs["requestorForm"].validate((valid) => {
          if(valid){
            if(this.requestor.id){
              axios.put('/api/proctoring-session-requestor/', this.requestor)
                .then(response => {
                  this.$router.push({ path: '/proctoring-session-requestor'})
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              axios.post('/api/proctoring-session-requestor/', this.requestor)
                .then(response => {
                  this.$router.push({ path: '/proctoring-session-requestor'})
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          }
        });
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .el-form {
    width: 725px;
  }
</style>
