<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getWeekOptions">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Note taking Pickups and Dropoffs for {{ formattedWeekName }}</h1>

            <div style="float:right">
                <p>
                    Filter:
                    <el-select size="mini" v-model="filter" @change="onFilterChange">
                        <el-option v-for="option in filterOptions" :key="option" :label="option" :value="option"></el-option>
                    </el-select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    Week:
                    <el-select size="mini" v-model="weekName" @change="onWeekChange">
                        <el-option v-for="option in weekOptions" :key="option.name" :value="option.name" :label="option.name"></el-option>
                    </el-select>
                </p>
                <p v-if="filter == 'Outstanding dropoffs' && weekNum"><a :href="'/group-email/compose?missing_dropoffs=true&week_num='+weekNum+'&semester_id='+semesterId">Email Outstanding Dropoffs Note Takers</a></p>
            </div>

            <p>One row per student and course, check box for recording drop-offs and pickups by week<br><a @click="toggleEditMode">{{ editMode ? 'Finished editing' : 'Click to edit' }}</a></p>

            <el-table :default-sort="{prop: 'student_name', order: 'ascending'}" :data="filter == 'Show all' ? students : filteredStudents" :key="refreshTable" @sort-change="onSortChange" ref="dataTable">
                <el-table-column>
                    <el-table-column label="Student name" prop="student_name" sortable :sort-orders="['ascending', 'descending']">
                        <template slot-scope="scope">
                            <a :href="'/student/services/' + scope.row['student_' + 'id']">{{ scope.row.student_name }} - {{ scope.row.uni }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column label="Course name" prop="class_name" sortable :sort-orders="['ascending', 'descending']">
                        <template slot-scope="scope">
                            <a :href="'/note-taking-service/show/' + scope.row.service_id">{{ scope.row.class_name }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column label="Provider" prop="note_taker_name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                </el-table-column>
                <template v-if="weekName == 'Show All'">
                    <template v-for="(weekObj, weekObjIndex) in weeks">
                        <el-table-column :label="weekObj.name" :key="weekObjIndex">
                            <template v-for="(week, index) in weekObj.weeks">
                                <el-table-column label="D" width="27" :key="index + 'D'">
                                    <template slot-scope="scope">
                                        <template v-if="editMode">
                                            <el-checkbox v-model="scope.row['dropoff-' + week]" @change="toggleDroppedOff($event, week, scope.row)"></el-checkbox>
                                        </template>
                                        <template v-else>
                                            <template v-if="scope.row['dropoff-' + week]">&#10004;</template>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column label="P" width="27" :key="index + 'P'">
                                    <template slot-scope="scope">
                                        <template v-if="editMode">
                                            <el-checkbox v-model="scope.row['pickup-' + week]" @change="togglePickedUp($event, week, scope.row)"></el-checkbox>
                                        </template>
                                        <template v-else>
                                            <template v-if="scope.row['pickup-' + week]">&#10004;</template>
                                        </template>
                                    </template>
                                </el-table-column>
                            </template>
                        </el-table-column>
                    </template>
                </template>
                <template v-else>
                    <template v-for="(week, index) in weeks">
                        <el-table-column :label="getWeekName(week)" :key="index">
                            <el-table-column label="Dropped off?">
                                <template slot-scope="scope">
                                    <template v-if="editMode">
                                        <el-checkbox v-model="scope.row['dropoff-' + week]" @change="toggleDroppedOff($event, week, scope.row)"></el-checkbox>
                                    </template>
                                    <template v-else>
                                        <template v-if="scope.row['dropoff-' + week]">&#10004;</template>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column label="Picked up?">
                                <template slot-scope="scope">
                                    <template v-if="editMode">
                                        <el-checkbox v-model="scope.row['pickup-' + week]" @change="togglePickedUp($event, week, scope.row)"></el-checkbox>
                                    </template>
                                    <template v-else>
                                        <template v-if="scope.row['pickup-' + week]">&#10004;</template>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </template>
                </template>
            </el-table>
            <p v-if="filter == 'Show all'">&nbsp;({{ students.length }} records)</p>
            <p v-else>&nbsp;({{ filteredStudents.length }} records)</p>
</div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Note taking Pickups and Dropoffs for " + this.formattedWeekName
            }
        },
        components: {
            },
        data () {
            return {
                students: [],
                filteredStudents: [],
                semesters: [],
                weekOptions: [],
                filterOptions: ['Show all', 'Outstanding dropoffs', 'Outstanding pickups'],
                filter: 'Show all',
                startWeek: null,
                endWeek: null,
                weekName: null,
                semesterId: null,
                total: null,
                editMode: false,
                currentWeekNum: null,
                weekNum: null,
                refreshTable: false,
                sorters: null
            }
        },
        computed: {
            formattedWeekName: function() {
                if(this.weekName){
                    if(this.weekName == 'Show All'){
                        return 'All weeks';
                    } else {
                        return this.weekName.split(':')[0]
                    }
                }
                return '';
            },
            weeks: function(){
                var i = null;
                var weeks = [];
                if(this.weekName == 'Show All'){
                    for(i = 0; i < this.weekOptions.length; i++){
                        var week = this.weekOptions[i];
                        if(week.name !== 'Show All' && week.start_week !== week.end_week){
                            var weekObj = {name: week.name};
                            weekObj.weeks = [];
                            for(var j = week.start_week; j <= week.end_week; j++){
                                weekObj.weeks.push(j);
                            }
                            weeks.push(weekObj);
                        }
                    }
                    return weeks;
                } else {
                    if(this.startWeek){
                        for(i = this.startWeek; i <= this.endWeek; i++){
                            weeks.push(i);
                        }
                        return weeks;
                    }
                }
                return [];
            }
        },
        methods: {
            onSortChange: function(sorter){
                this.sorters = sorter;
            },
            toggleEditMode: function() {
                this.editMode = !this.editMode;

                this.onFilterChange(this.filter);
            },
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getWeekOptions();
                    });
            },
            getStudents: function() {
                axios.get('/api/notetaking-service/pickups-and-dropoffs/', {params: {semester_id: this.semesterId, start_week: this.startWeek, end_week: this.endWeek}})
                    .then(response => {
                        if(response.data.success) {
                            this.students = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getWeekOptions: function() {
                axios.get('/api/notetaking-service/semester-week-options/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.weekOptions = response.data.rows;
                            for(var i = 0; i < this.weekOptions.length; i++){
                                if(this.weekOptions[i].is_current_week){
                                    this.currentWeekNum = this.weekOptions[i].start_week;
                                    this.startWeek = this.weekOptions[i].start_week;
                                    this.endWeek = this.weekOptions[i].end_week;
                                    this.weekName = this.weekOptions[i].name;
                                    this.weekNum = this.currentWeekNum;
                                }
                            }
                            this.getStudents();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onFilterChange: function(val){
                this.filteredStudents = [];
                if(val != 'Show all') {
                    for(var i = 0; i < this.students.length; i++){
                        var filterArray = this.students[i].note_pickup_dates;
                        if(val == 'Outstanding dropoffs'){
                            filterArray = this.students[i].note_dropoff_dates;
                        }

                        // console.log(filterArray);

                        var slicedArray = filterArray.slice(this.startWeek - 1, this.endWeek);

                        // console.log(slicedArray);

                        if(!slicedArray.length || slicedArray.indexOf(false) !== -1){
                            // console.log("Here");
                            this.filteredStudents.push(this.students[i]);
                        }
                    }

                    this.refreshTable = !this.refreshTable;

                }

                if(this.sorters){
                    this.$refs.dataTable.sort(this.sorters.prop, this.sorters.order);
                    this.refreshTable = !this.refreshTable;
                }
            },
            onWeekChange: function(){
                for(var i = 0; i < this.weekOptions.length; i++){
                    if(this.weekName == this.weekOptions[i].name){
                        this.weekNum = this.weekOptions[i].week_number;
                        this.startWeek = this.weekOptions[i].start_week;
                        this.endWeek = this.weekOptions[i].end_week;
                        this.getStudents();
                    }
                }
            },
            getWeekName: function(weekNum){
                for(var i = 0; i < this.weekOptions.length; i++){
                    if(this.weekOptions[i].start_week == weekNum && this.weekOptions[i].end_week == weekNum){
                        return this.weekOptions[i].name;
                    }
                }
            },
            toggleDroppedOff: function(val, weekNum, row){
                var message = "Are you sure you want to uncheck this checkbox? You should only do this if you checked it off by mistake.";
                if(val){
                    message = "Are you sure you want to check this checkbox?";
                }

                this.$confirm(message)
                    .then(() => {
                        this.students.forEach((function(student){
                            if(row.service_id == student.service_id){
                                // // eslint-disable-next-line
                                // console.log(student);

                                student["dropoff-"+weekNum] = val;
                                // // eslint-disable-next-line
                                // console.log(student["dropoff-"+weekNum]);
                                axios.put('/api/notetaking-service/update-dropoff/', {service_id: student.service_id, week_number: weekNum, value: val})
                                    .then(response => {
                                    })
                                    .catch(error => {
                                        // eslint-disable-next-line
                                        console.log(error);
                                    });
                            }
                        }));
                    }).catch(() => {
                        row['dropoff-' + weekNum] = !row['dropoff-' + weekNum];
                    });
            },
            togglePickedUp: function(val, weekNum, row){
                var message = "Are you sure you want to uncheck this checkbox? You should only do this if you checked it off by mistake.";
                if(val){
                    message = "Are you sure you want to check this checkbox?";
                }

                this.$confirm(message)
                    .then(() => {
                        axios.put('/api/notetaking-service/update-pickup/', {service_enrollment_id: row.service_enrollment_id, week_number: weekNum, value: val})
                            .then(response => {
                              //  this.getStudents();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                              console.log(error);
                            });
                    }).catch(() => {
                        row['pickup-' + weekNum] = !row['pickup-' + weekNum];
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
