<template>
    <div id="container">
<div id="content">
            <h1>Exam Index By {{ indexByCourse ? "Course" : "Student" }}</h1>
            <div style="margin-bottom:2px;">
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="startDate" value-format="yyyy-MM-dd"></el-date-picker> -
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="endDate" value-format="yyyy-MM-dd"></el-date-picker><br>
            </div>
            Use drop-down menu to select entire semester:
            <el-select size="mini" v-model="semesterId">
                <el-option label="Select a Semester" :value="null"></el-option>
                <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
            </el-select> <el-button size="mini" type="primary" @click="getExams">Submit</el-button>

            <p><a @click="toggleExamIndexType">Show exams by {{ indexByCourse ? "student" : "course" }}</a></p>

    <!-- NOTE: Width restricted per DT-87 -->
            <el-table style="width:1350px" :default-sort="{prop: 'start_time', order: 'ascending'}" :data="exams" v-loading="loading" element-loading-text="Loading...">
                <el-table-column prop="start_time" label="Date" sortable :sort-orders="['ascending', 'descending']" width="95">
                    <template slot-scope="scope">{{ scope.row.start_time | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column v-if="indexByCourse" :key="'start_time'" prop="start_time" label="Time" sortable :sort-orders="['ascending', 'descending']" width="85">
                    <template slot-scope="scope">{{ scope.row.start_time | formatTimehma }}</template>
                </el-table-column>
                <el-table-column v-if="!indexByCourse" :key="'student_name'" prop="student_name" label="Student" width="175" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="class_name" label="Course Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="course_number" label="Course Number" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="section_code" label="Section" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="instructor_name" label="Professor" sortable :sort-orders="['ascending', 'descending']" width="175">
                    <template slot-scope="scope">
                        {{ scope.row.instructor_name }}
                        <template v-for="ta in scope.row.teaching_assistants">
                            <br :key="ta.id">TA: {{ ta.first_name }} {{ ta.last_name }}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column v-if="!indexByCourse" :key="'proctoring_session_start_time'" prop="proctoring_session_start_time" label="Proctoring Session" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-if="scope.row.proctoring_session_start_time">
                            {{ scope.row.proctoring_session_start_time | formatDateMDY }} {{ scope.row.proctoring_session_start_time | formatTimehma }}-{{ scope.row.proctoring_session_end_time | formatTimehma }} in {{ scope.row.location }}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column v-if="indexByCourse" :key="'eligible_students'" prop="eligible_students" label="Eligible students" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column v-if="indexByCourse" :key="'scheduled_students'" prop="scheduled_students" label="Scheduled students" sortable :sort-orders="['ascending', 'descending']" width="110"></el-table-column>
                <el-table-column v-if="indexByCourse" :key="'placeholder_students'" prop="placeholder_students" label="Placeholder students" sortable :sort-orders="['ascending', 'descending']" width="110"></el-table-column>
                <el-table-column v-if="indexByCourse" :key="'exam_type'" prop="exam_type" label="Exam Type" sortable :sort-orders="['ascending', 'descending']" width="110">
                  <template slot-scope="scope">
                    <template v-if="scope.row.exam_type === 'Virtual'">Virtual: {{ scope.row.virtual_exam_type }}</template>
                    <template v-else>{{ scope.row.exam_type }}</template>
                  </template>
                </el-table-column>
                <el-table-column v-if="indexByCourse" :key="'is_missing_exam_specs'" prop="is_missing_exam_specs" label="Missing Exam Specs" sortable :sort-orders="['ascending', 'descending']" width="110">
                    <template slot-scope="scope">
                        <template v-if="scope.row.is_missing_exam_specs"><div class="aqua">Yes</div></template>
                        <template v-else>No</template>
                    </template>
                </el-table-column>
                <el-table-column width="135"><template slot-scope="scope"><a :href="'/testing/show/' + scope.row.id">Edit course exam</a></template></el-table-column>
            </el-table>
            <p v-if="total">({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: "Exam Index By Course"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                loading: false,
                indexByCourse: true,
                startDate: moment(new Date()).format('YYYY-MM-DD'),
                endDate: moment(new Date()).format('YYYY-MM-DD'),
                semesters: [],
                semesterId: null,
                total: null,
                exams: []
            }
        },
        computed: {
            title: function(){
                var title = "Exam Index by ";
                if(this.indexByCourse){
                    title += "Course";
                } else {
                    title += "Student";
                }
                return title += " - Columbia University ODS";
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-service-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                    });

                this.urlParams = this.$route.query;
                if(this.urlParams.start_date) this.startDate = this.urlParams.start_date;
                if(this.urlParams.end_date) this.endDate = this.urlParams.end_date;

                this.getExams();
            },
            getExams: function() {
                var url = '/api/testing-service/exam-index-by-';
                if(this.indexByCourse){
                    url += 'course/';
                } else {
                    url += 'student/';
                }

                this.exams = [];
                this.loading = true;
                axios.get(url, {params: {start_date: this.startDate, end_date: this.endDate, semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.exams = response.data.rows;
                            this.total = response.data.total;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            toggleExamIndexType: function(){
                this.indexByCourse = !this.indexByCourse;
                this.getExams();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-table th > .cell {
        height: 100% !important;
        vertical-align: top !important;
    }
</style>
