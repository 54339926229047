<template>
    <div id="container">
<div id="content">
            <h1>Edit Service Provider</h1>
            <el-form :model="serviceProvider" :rules="rules" ref="serviceProviderForm" label-width="103px" label-position="left" style="width:100%;">
                <div style="float: left; width: 20em">
                    <el-form-item label="First Name" prop="first_name">
                        <el-input size="mini" v-model="serviceProvider.first_name" style="width:135px"></el-input>
                    </el-form-item>
                </div>
                <div style="float: left; width: 30em">
                    <el-form-item label="Last Name" prop="last_name">
                        <el-input size="mini" v-model="serviceProvider.last_name" style="width:260px"></el-input>
                    </el-form-item>
                </div>
                <div style="clear: left; float: left; width: 20em">
                    <el-form-item label="Phone">
                        <el-input size="mini" v-model="serviceProvider.phone" style="width:135px"></el-input>
                    </el-form-item>
                </div>
                <div style="float: left; width: 30em">
                    <el-form-item label="Email" prop="email">
                        <el-input size="mini" v-model="serviceProvider.email" style="width:260px"></el-input>
                    </el-form-item>
                </div>
                <div style="float: left; width: 30em">
                    <el-form-item label="UNI">
                        <el-input size="mini" v-model="serviceProvider.uni" style="width:260px"></el-input>
                    </el-form-item>
                </div>
                <div style="clear: left; width: 40em">
                    <el-form-item label="Contact Information">
                        <el-input type="textarea" :rows="4" v-model="serviceProvider.contact_information"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Edit Service Provider"
            }
        },
        components: {
            },
        data () {
            return {
                serviceProvider: {
                },
                rules: {
                    first_name: [{ required: true, whitespace: true, message: 'First Name is required' }],
                    last_name: [{ required: true, whitespace: true, message: 'Last Name is required' }],
                    email: [{ required: true, whitespace: true, message: 'Email is required' }]
                }
            }
        },
        methods: {
            load: function(){
                this.serviceProvider.id = location.pathname.split('/service-provider/edit/')[1];
                if(this.serviceProvider.id){
                    this.serviceProvider.id = parseInt(this.serviceProvider.id);
                    this.getServiceProvider();
                }
            },
            getServiceProvider: function(){
                axios.get("/api/service/service-provider/", {params: {id: parseInt(this.serviceProvider.id)}})
                    .then(response => {
                        this.serviceProvider = response.data.service_provider;
                    });
            },
            onSubmit: function(){
                this.$refs["serviceProviderForm"].validate((valid) => {
                    if(valid){
                        axios.put('/api/service/service-provider/', this.serviceProvider)
                            .then(response => {
                              this.$router.push({ path: '/service-provider/show/' + this.serviceProvider.id });
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }
                });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 725px;
    }
</style>
