<template>
  <div id="container">
    <div id="content">
      <h1>{{ pageTitle }}</h1>
      <div v-if="!createMode && user.isAdmin" class="admin-section">
        <h3>(Administrators only)</h3>
        <p>Document Type: <el-select size="mini" v-model="changeToDocumentType">
          <el-option v-for="(type, typeIndex) in documentTypes" :key="typeIndex" :label="type.name" :value="type.value"></el-option>
        </el-select>
          <el-button size="mini" type="primary" @click="changeDocumentType">Change document type</el-button></p>
      </div>

      <el-form :label-width="labelWidth" label-position="left" style="width:100%;">
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Course">
          <span class="form-text">{{ documentObj.courseName }}</span>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Semester">
          <span class="form-text">{{ documentObj.semesterName }}</span>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Instructor">
          <span class="form-text">{{ documentObj.instructorName }}</span>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Document Name">
          <el-input size="mini" v-model="documentObj.name"></el-input>
        </el-form-item>
        <el-form-item v-if="documentObj.type != 'AccommodationLetter'" label="Document Category">
          <el-select v-model="documentObj.documentCategoryId" size="mini">
            <el-option v-for="category in documentCategories" :key="category.id" :value="category.id" :label="category.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="documentObj.type == 'AccommodationLetter' ? 'Date Letter was Requested or Created' : 'Date of ' + documentDateFieldTitle">
          <el-date-picker type="date" size="mini" v-model="documentObj.documentDate" value-format="yyyy-MM-dd" format="MM/dd/yyyy" style="width:140px;"></el-date-picker>
          <span v-if="documentObj.type && documentObj.type.indexOf('DisabilityDocument') !== -1" class="form-text"><br>If date is unknown, just choose today's date.</span>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Status">
          <el-select size="mini" v-model="documentObj.status">
            <el-option :value="currentStatus" :label="documentObj.statusDisplay"></el-option>
            <el-option v-for="status in documentObj.nextStatuses" :key="status.value" :value="status.value" :label="status.display"></el-option>
            <el-option value="undo" label="Undo previous action"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Should be sent to advisor?">
          <el-checkbox v-model="documentObj.sendToAdvisor"></el-checkbox> <span v-if="documentObj.advisors && documentObj.advisors.length" class="form-text" style="padding-left: 8em">Advisor: {{ documentObj.advisors[0].first_name }} {{ documentObj.advisors[0].last_name }} &lt;<a :href="'mainto:' + documentObj.advisors[0].email">{{ documentObj.advisors[0].email }}</a>&gt;</span>
        </el-form-item>
        <el-form-item v-if="documentObj.type == 'AccommodationLetter'" label="Was sent to advisor?">
          <el-checkbox v-model="documentObj.sentToAdvisor"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="!createMode && showStatusField" :label="documentStatusFieldTitle + ' Status'">
          <span class="form-text text-italic">{{ statusDisplay }}</span>
        </el-form-item>
        <el-form-item v-if="showDocumentNameField" label="Name/Category">
          <el-select v-model="documentObj.name" size="mini" @change="onDocumentNameChange">
            <el-option v-for="name in documentNames" :key="name" :value="name" :label="name"></el-option>
          </el-select>
          <el-input v-if="documentObj.name == 'Other'" class="other-option" placeholder="Enter alternate category here" v-model="documentObj.otherName" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="Select file for upload">
          <el-upload drag :action="createMode ? '/api/document/create-student-document/' : '/api/document/update-student-document/'" ref="upload" :auto-upload="false" :data="documentObj" :file-list="fileList" :on-change="handleChange">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          </el-upload>
          <span v-if="existingFileName" class="existing-file-text">Current document on file is named <a :href="'/api/document/download/' + documentObj.id" target="_blank">{{ existingFileName }}</a>.<br>If you specify another file to upload here, the current document will be overwritten.</span>
        </el-form-item>
        <div v-if="showApplicationReview" class="panel">
          <h2>Application Review (staff only)</h2>
          <el-form-item label="The application is">
            <el-radio-group v-model="documentObj.newStatus">
              <el-radio label="complete">Complete</el-radio>
              <el-radio label="incomplete">Incomplete</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item v-if="showDocumentStatusField" label="Document Status">
          <span v-if="createMode" class="text-italic form-text">will initially be set to "Not Reviewed"</span>
          <span v-else class="form-text">{{ statusDisplay }}</span>
        </el-form-item>
      </el-form>

      <div v-if="documentObj.type == 'DisabilityDocument' && (documentObj.name == 'Learning Disability Evaluation' || documentObj.name == 'Neuropsychological Report')" class="document-contact-form">
        <h4>This section for Learning Disability Evaluation or Neuropsychological Report documents only:</h4>
        <el-form label-width="125px" label-position="left" style="width:100%;">
          <el-form-item label="Source">
            <el-radio-group v-model="documentObj.documentSource" size="mini">
              <el-radio :label="'private_evaluators_clinic'">Private Evaluator's Clinic</el-radio><br>
              <el-radio :label="'university_testing_center'">University Testing Center</el-radio><br>
              <el-radio :label="'N/A'">N/A</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Contact First Name">
            <el-input v-model="documentObj.contactFirstName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="Contact Last Name">
            <el-input v-model="documentObj.contactLastName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="Center">
            <el-select v-model="documentObj.contactCenterName" size="mini" class="contact-center-select">
              <el-option v-for="name in contactCenterNames" :key="name.name" :value="name.name" :label="name.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="documentObj.contactCenterName == '-- Other --'" label="Center (if &quot;Other&quot;)">
            <el-input v-model="documentObj.otherContactCenterName" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="Contact Phone">
            <el-input v-model="documentObj.contactPhone" size="mini"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="isReviewable && showDisabilityReview" class="panel">
        <h2>Documentation Review (staff only)</h2>
        <el-form label-width="100px" label-position="left" style="width:100%;">
          <el-form-item label="This document">
            <el-radio-group v-model="documentObj.newStatus">
              <el-radio label="sufficient">Meets ODS Standards</el-radio>
              <el-radio label="insufficient">Does Not Meet ODS Standards</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Reason(s)">
            <span class="form-text">It's very important to track specific reasons that documentation falls short of our standards; please check all of the statements below that apply and provide details if there are additional reasons:</span>
            <el-checkbox-group v-model="documentObj.rejectionReasons" :disabled="documentObj.newStatus !== 'insufficient'">
                <template v-for="(reason, rIndex) in rejectionReasons">
                  <el-checkbox :key="rIndex" :label="reason.name" ></el-checkbox><br :key="rIndex +'br'"/>
                </template>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="Comment">
            <el-input size="mini" v-model="documentObj.rejectionComment" style="width:600px;" :disabled="documentObj.newStatus !== 'insufficient'"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <el-button type="primary" size="mini" @click="onSubmit">Save Changes</el-button> or <router-link :to="'/student/documents/' + this.documentObj.studentId" >Cancel and return to student record</router-link><br>
      <p v-if="!createMode">Created {{ createdAt | formatDateMDY }}<br>Last updated {{ lastUpdatedAt | formatDateMDY }} by {{ lastUpdatedBy }}</p>

      <div v-if="!createMode" class="panel">
        <template v-if="documentObj.newStatus == 'deleted'">
          <h2>This document has been deleted</h2>
        </template>
        <template v-else-if="showDeleteDocument">
          <h2>Other options</h2>
          <ul><li><a @click="deleteDocument">Delete this document</a></li></ul>
        </template>
      </div>

      <utils-history v-if="!createMode && statusHistoryType" :id="documentObj.id" :type="statusHistoryType" :show-title="true"></utils-history>
      <br>
      <document-file-naming :id="documentObj.studentId" :uni="studentUni"></document-file-naming>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'



  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },

    components: {
    },
    data () {
      return {
        urlParams: {},
        fileList: [],
        createMode: true,
        user: {},
        documentTypes: [],
        changeToDocumentType: null,
        studentName: '',
        studentUni: '',
        studentStatus: '',
        pageTitle: '',
        documentDateFieldTitle: 'Documentation',
        documentCategories: [],
        documentNames: [],
        contactCenterNames: [],
        showDocumentNameField: false,
        showDocumentStatusField: false,
        showStatusField: false,
        labelWidth: '164px',
        existingFileName: null,
        isReviewable: false,
        showApplicationReview: false,
        showDisabilityReview: false,
        showDeleteDocument: false,
        statusDisplay: null,
        rejectionReasons: [],
        createdAt: null,
        lastUpdatedAt: null,
        lastUpdatedBy: null,
        statusHistoryType: null,
        documentObj: {
          id: null,
          studentId: null,
          type: null,
          documentCategoryId: null,
          documentDate: null,
          name: null,
          otherName: null,
          documentSource: null,
          contactFirstName: null,
          contactLastName: null,
          contactCenterName: null,
          otherContactCenterName: null,
          contactPhone: null,
          rejectionReasons: [],
          rejectionComment: null,
          newStatus: null,
          //Accommodation Letter specific fields
          courseName: null,
          instructorName: null,
          status: null,
          statusDisplay: null,
          nextStatuses: [],
          advisors: [],
          sendToAdvisor: null,
          sentToAdvisor: null,
          semesterId: null,
          semesterName: null
        },
        currentStatus: null
      }
    },
    methods: {
      load: function () {
        this.urlParams = this.$route.query;
        this.documentObj.studentId = this.urlParams.student_id;

        if(this.urlParams.type == 'AccommodationLetter'){
          this.createMode = false;
          this.documentObj.id = location.pathname.split('/document/edit/')[1].split('?')[0];
          this.documentObj.type = 'AccommodationLetter';

          axios.get("/api/document/get-accommodation-letter/", {params:{id: this.documentObj.id}})
            .then(response => {
              this.documentObj.courseName = response.data.course_name;
              this.documentObj.instructorName = response.data.instructor_name;
              this.documentObj.name = response.data.name;
              this.documentObj.documentDate = response.data.doc_date;
              this.documentObj.status = response.data.status;
              this.currentStatus = response.data.status;
              this.documentObj.statusDisplay = response.data.status_display;
              this.documentObj.nextStatuses = response.data.next_statuses;
              this.documentObj.advisors = response.data.advisors;
              this.documentObj.sendToAdvisor = response.data.send_to_advisor;
              this.documentObj.sentToAdvisor = response.data.sent_to_advisor;
              this.documentObj.existingFileName = response.data.asset_file_name;
              this.documentObj.semesterId = response.data.semester_id;
              this.documentObj.semesterName = response.data.semester_name;
              this.createdAt = response.data.created_at;
              this.lastUpdatedAt = response.data.updated_at;
              this.lastUpdatedBy = response.data.latest_change_by_identifier;
            });
        } else if (location.pathname.split('/document/edit/')[1]){
          this.createMode = false;
          this.documentObj.id = location.pathname.split('/document/edit/')[1].split('?')[0];

          axios.get("/api/document/" + this.documentObj.id)
            .then(response => {
              this.documentObj.type = response.data.type;
              this.changeToDocumentType = response.data.type;
              this.documentObj.documentCategoryId = response.data.document_category_id;
              this.documentObj.documentDate = response.data.doc_date;
              this.documentObj.name = response.data.name;
              this.documentObj.otherName = response.data.other_name;
              this.documentObj.documentSource = response.data.document_source;
              this.documentObj.contactFirstName = response.data.contact_first_name;
              this.documentObj.contactLastName = response.data.contact_last_name;
              this.documentObj.contactCenterName = response.data.contact_center_name;
              //this.documentObj.otherContactCenterName = response.data.otherContactCenterName;
              this.documentObj.contactPhone = response.data.contact_phone;
              this.documentObj.rejectionReasons = response.data.rejection_reasons != null ? response.data.rejection_reasons : [];
              this.documentObj.rejectionComment = response.data.rejection_comment;
              this.documentObj.newStatus = response.data.status;
              this.statusDisplay = response.data.status_display;
              this.existingFileName = response.data.asset_file_name;
              this.isReviewable = response.data.is_reviewable;
              this.showApplicationReview = response.data.show_application_review;
              this.showDisabilityReview = response.data.show_disability_review;
              this.showDeleteDocument = response.data.show_delete_document;
              this.createdAt = response.data.created_at;
              this.lastUpdatedAt = response.data.updated_at;
              this.lastUpdatedBy = response.data.latest_change_by_identifier;
              this.statusHistoryType = response.data.status_history_type;
              this.getStudent();
            });

          axios.get("/api/user")
            .then(response => {
              if(response.data.success) {
                this.user = response.data.user;
              } else {
                // eslint-disable-next-line
                console.log("failed to get user");
              }
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });

          this.documentTypes = [];
          axios.get("/api/document/document-types/")
            .then(response => {
              for(var i = 0; i < response.data.rows.length; i++){
                var item = response.data.rows[i];

                if(item.name != "Accommodation Letter"){
                  this.documentTypes.push(item);
                }
              }
            });

          axios.get("/api/document/rejection-reasons/")
            .then(response => {
              this.rejectionReasons = response.data.rows;
            });
        } else {
          this.documentObj.type = this.urlParams.type;
          this.getStudent();
        }

        axios.get("/api/document/document-categories/")
          .then(response => {
            this.documentCategories = response.data.rows;

            if(this.documentObj.type == 'ApplicationDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Registration'}).id;
              this.documentDateFieldTitle = 'Application';
              this.documentStatusFieldTitle = 'App';
              this.showStatusField = true;
              this.labelWidth = '146px';
            } else if(this.documentObj.type == 'DisabilityDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Registration'}).id;
              this.showDocumentNameField = true;
              this.showDocumentStatusField = true;
              axios.get("/api/document/document-names-for-document-type/", {params: {document_type: this.documentObj.type}})
                .then(response => {
                  this.documentNames = response.data.rows;
                });
              axios.get("/api/document/contact-center-names/")
                .then(response => {
                  this.contactCenterNames = response.data.rows;
                });
            } else if(this.documentObj.type == 'RequestForSupplementalAccommodation'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Accommodation Requests'}).id;
            } else if(this.documentObj.type == 'AppealDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Registration'}).id;
              this.documentDateFieldTitle = 'Appeal';
              this.documentStatusFieldTitle = 'Appeal';
              this.showStatusField = true;
              this.labelWidth = '146px';
            } else if(this.documentObj.type == 'OtherDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Other'}).id;
              this.showDocumentNameField = true;
              axios.get("/api/document/document-names-for-document-type/", {params: {document_type: this.documentObj.type}})
                .then(response => {
                  this.documentNames = response.data.rows;
                });
            } else if(this.documentObj.type == 'HaApplicationDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.documentDateFieldTitle = 'Application';
              this.documentStatusFieldTitle = 'App';
              this.showStatusField = true;
              this.labelWidth = '146px';
            } else if(this.documentObj.type == 'HaDisabilityDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.showDocumentNameField = true;
              this.showDocumentStatusField = true;
              axios.get("/api/document/document-names-for-document-type/", {params: {document_type: this.documentObj.type}})
                .then(response => {
                  this.documentNames = response.data.rows;
                });
            } else if(this.documentObj.type == 'HaDecisionLetter'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.documentDateFieldTitle = 'HA Notification Letter';
              this.documentStatusFieldTitle = 'Appeal';
              this.showStatusField = true;
              this.labelWidth = '204px';
            } else if(this.documentObj.type == 'HaAppealDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.documentDateFieldTitle = 'Appeal';
              this.documentStatusFieldTitle = 'Appeal';
              this.showStatusField = true;
              this.labelWidth = '146px';
            } else if(this.documentObj.type == 'HaAppealDisabilityDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.showDocumentNameField = true;
              this.showDocumentStatusField = true;
              axios.get("/api/document/document-names-for-document-type/", {params: {document_type: this.documentObj.type}})
                .then(response => {
                  this.documentNames = response.data.rows;
                });
            } else if(this.documentObj.type == 'HaAppealDecisionLetter'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.documentDateFieldTitle = 'Appeal Decision';
              this.documentStatusFieldTitle = 'Appeal';
              this.showStatusField = true;
              this.labelWidth = '169px';
            } else if(this.documentObj.type == 'HaOtherDocument'){
              if(this.createMode) this.documentObj.documentCategoryId = this.documentCategories.find(function(category){return category.name == 'Housing'}).id;
              this.showDocumentNameField = true;
              axios.get("/api/document/document-names-for-document-type/", {params: {document_type: this.documentObj.type}})
                .then(response => {
                  this.documentNames = response.data.rows;
                });
            } else if(this.documentObj.type == 'AccommodationLetter'){
              this.labelWidth = '285px';
            }
          });
      },
      getStudent: function(){
        axios.get("/api/student/" + this.documentObj.studentId)
          .then(response => {
            this.studentName = response.data.title;
            this.studentUni = response.data.uni;
            this.studentStatus = response.data.statusDisplay;

            if(this.documentObj.type == 'ApplicationDocument'){
              this.pageTitle = "Edit application details for ";
            } else if(this.documentObj.type == 'DisabilityDocument'){
              this.pageTitle = this.createMode ? "Enter a new disability document for " : "Edit one disability document for ";
            } else if(this.documentObj.type == 'RequestForSupplementalAccommodation'){
              this.pageTitle = "Edit Request for Supplemental Accommodation details for ";
            } else if(this.documentObj.type == 'AppealDocument'){
              this.pageTitle = "Edit Appeal details for ";
            } else if(this.documentObj.type == 'OtherDocument'){
              this.pageTitle = this.createMode ? "Enter a new document for " : "Edit one document for ";
            } else if(this.documentObj.type == 'HaApplicationDocument'){
              this.pageTitle = "Edit application details for ";
            } else if(this.documentObj.type == 'HaDisabilityDocument'){
              this.pageTitle = this.createMode ? "Enter a new HA Disability Document for " : "Edit one HA Disability Document for ";
            } else if(this.documentObj.type == 'HaDecisionLetter'){
              this.pageTitle = "Edit HA Notification Letter details for ";
            } else if(this.documentObj.type == 'HaAppealDocument'){
              this.pageTitle = "Edit Appeal details for ";
            } else if(this.documentObj.type == 'HaAppealDisabilityDocument'){
              this.pageTitle = this.createMode ? "Enter a new Housing Accommodation Appeal Disability Documentation for " : "Edit one Housing Accommodation Appeal Disability Documentation for ";
            } else if(this.documentObj.type == 'HaAppealDecisionLetter'){
              this.pageTitle = "Edit Appeal Decision details for ";
            } else if(this.documentObj.type == 'HaOtherDocument'){
              this.pageTitle = this.createMode ? "Enter a new document for " : "Edit one document for ";
            }

            this.pageTitle += this.studentName;
            if(this.studentUni){
              this.pageTitle += " - " + this.studentUni;
            }
          });
      },
      onDocumentNameChange: function(value){
        if(value == 'Learning Disability Evaluation' || value == 'Neuropsychological Report'){
          if(!this.documentObj.documentSource){
            this.documentObj.documentSource = 'N/A'
          }
          if(!this.documentObj.contactCenterName){
            this.documentObj.contactCenterName = "";
          }
        } else {
          this.documentObj.documentSource = null;
          this.documentObj.contactFirstName = null;
          this.documentObj.contactLastName = null;
          this.documentObj.contactCenterName = null;
          this.documentObj.otherContactCenterName = null;
          this.documentObj.contactPhone = null;
        }
      },
      changeDocumentType: function(){
        axios.put('/api/document/change-type/', {id: parseInt(this.documentObj.id), type: this.changeToDocumentType})
          .then(response => {
            if(response.data.success){
              this.load();
            }
          });
      },
      deleteDocument: function(){
        this.$confirm("Are you sure you want to move this document to 'deleted' status?  It will no longer be displayed on the student record, but it will still be available on the Change History report if you choose \"Deleted Documents\".")
          .then(() => {
            axios.put('/api/document/delete-document/', {id: parseInt(this.documentObj.id)})
              .then(response => {
                if(response.data.success){
                  this.$router.push("/student/documents/"+this.documentObj.studentId);
                }
              });
          }).catch(() => {});
      },
      onSubmit: function(){
        var data = new FormData();
        data.append('id', parseInt(this.documentObj.id));

        for(var key in this.documentObj){
          if(key != 'id' && this.documentObj[key]){
            data.append(key, this.documentObj[key]);
          }
        }

        if(this.fileList.length && this.fileList[0].raw){
          data.append('file', this.fileList[0].raw)
        }

        if(this.documentObj.type == 'AccommodationLetter'){
          axios.put('/api/document/update-accommodation-letter/', data)
            .then(response =>{
              if(response.data.success){
                this.$router.push({ path: '/student/accommodation-letters/' + this.documentObj.studentId});
              } else {
                this.$alert("Error: "+response.data.message);
              }
            });
        } else if (!this.createMode){
          axios.put('/api/document/update-student-document/', data)
            .then(response =>{
              if(response.data.success){
                this.$router.push({ path: '/student/documents/' + this.documentObj.studentId});
              } else {
                this.$alert("Error: "+response.data.message);
              }
            });
        } else if(this.fileList.length){
          axios.post('/api/document/create-student-document/', data)
            .then(response =>{
              if(response.data.success){
                this.$router.push({ path: '/student/documents/' + this.documentObj.studentId});
              } else {
                this.$alert("Error: "+response.data.message);
              }
            });
        }
      },
      handleChange: function(file, fileList) {
        //only allow one file to be uploaded, and replace previous file with new upload
        this.fileList = fileList.slice(-1);
      },
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .el-form-item__label {
    line-height:28px;
  }
  .el-form-item__content {
    line-height:inherit;
  }
  .el-date-editor.el-input {
    width:130px;
  }
  .el-input {
    width:265px;
  }
  .other-option {
    padding-left:30px;
    width:265px;
  }
  .document-contact-form {
    border: 1px solid gray;
    width:680px;
    padding-left:7px;
    margin-bottom:5px;
  }
  .el-radio__label {
    font-size: small;
  }
  .el-radio-group {
    line-height: 28px;
    font-size: inherit;
  }
  .contact-center-select .el-input {
    width:545px;
  }
  .admin-section {
    border: 2px solid #633C7D;
    padding: 0 1em 0 1em;
    text-align: center;
    margin-bottom: 5px;
  }
  .existing-file-text {
    font-size:13px;
  }
</style>
