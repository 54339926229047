<template>
    <div id="container">
<div id="content">
            <h1>Group Email</h1>
            <el-steps :active="active" finish-status="success" process-status="success">
                <el-step title="Step 1" description="Select recipients"></el-step>
                <el-step title="Step 2" description="Compose email"></el-step>
                <el-step title="Step 3" description="Preview and send"></el-step>
                <el-step title="Step 4" description="Finished"></el-step>
            </el-steps>
            <h2 v-if="!sent">Email is being sent. This page will update when the email sending is complete. (last checked: {{ checkDate | formatDateTimeMDYhhmmssA }}  )</h2>
            <h2 v-if="sent">Email has been sent. <template v-if="groupEmailId">Details <router-link :to="'/group-email/show/' + groupEmailId">here</router-link></template></h2>
        </div>
        </div>
</template>

<script>

    import axios from "axios";

    export default {
        metaInfo () {
            return {
                title: "Group Email has been sent"
            }
        },

        components: {
            },
        data () {
            return {
                checkDate: new Date(),
                sent: false,
                active: 3,
                groupEmailId: '',
                draftEmailId: ''
            }
        },
        methods: {
            displayError: function(msg) {
                this.$message({
                    showClose: true,
                    duration: 10000,
                    type: 'error',
                    message: msg,
                    customClass: 'error-msg'
                });
            },
            load: function(){

              axios.get("/api/group-email/draft-is-sent?id=" + this.groupEmailId)
                  .then(response => {
                    if(response.data.success) {
                      this.sent = true
                      this.groupEmailId = response.data.email.id;
                    } else {
                      this.checkDate = new Date()
                    }
                  });
            }
        },
        mounted() {
          this.params = this.$route.query;

          this.groupEmailId = this.params.id;

          const me = this;
          this.load();
          setInterval(function() {
            if (!this.sent) {
              me.load()
            }
          }, 15000)
        }
    }
</script>

<style lang="postcss" scoped>
    .error-msg p {
        color: red;
    }
</style>
