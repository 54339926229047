<template>
  <div id="container">
    <div id="content">
      <h1>Create<template v-if="intakeNoteType == 'Housing'"> Housing</template> Intake Note</h1>
      <el-form label-width="200px" label-position="left">
        <el-form-item label="Intake Note">
          <el-input size="mini" type="textarea" :rows="15" v-model="content"></el-input>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" @click="onSubmit">Save</el-button> or <a :href="'/student/profile/' + studentId">Cancel and return to student record</a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'



  export default {
    metaInfo () {
      return {
        title: this.urlParams.housing ? "Create Housing Intake Note" : "Create Intake Note"
      }
    },

    components: {
    },
    data () {
      return {
        urlParams: {},
        studentId: null,
        intakeNoteType: 'Academic',
        content: null
      }
    },
    methods: {
      load: function() {
        this.urlParams = this.$route.query;
        this.studentId = this.urlParams.student_id;
        this.intakeNoteType = this.urlParams.housing ? 'Housing' : 'Academic';
      },
      onSubmit: function(){
        this.$message.closeAll();

        if(this.content && this.content.trim() != ''){
          axios.post('/api/intake-note/', {student_id: parseInt(this.studentId), intake_note_type: this.intakeNoteType, content: this.content})
            .then(response => {
              if(response.data.success){
                this.$router.push({ path: '/student/profile/' + this.studentId });
              }
            });
        } else {
          this.$message({
            showClose: true,
            duration: 20000,
            type: 'error',
            message: "One error prohibited this intake note from being saved: Content can't be blank."
          });
        }
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  #content {
    width:600px;
  }
</style>
