<template>
    <div id="container">
<div id="content">
            <h1>Custom Email Lists - {{editForm.name}}</h1>
            <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm"  label-position="top">
                <el-form-item label="List Name" prop="name"  style="max-width:600px">
                    <el-input v-model="editForm.name" size="small" placeholder="List name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onSave('editForm')" type="primary" size="small" icon="el-icon-document">Save</el-button>
                </el-form-item>
            </el-form><br/>
            <el-form id="recipient-form" label-width="120px" label-position="top" style="max-width:600px">
            <el-form-item label="Add a student">
                    <el-autocomplete class="inline-input" v-model="member" :fetch-suggestions="querySearch"
                                     placeholder="Please Input" :trigger-on-focus="false" @select="handleSelect"
                                     size="small" style="width: 100%"></el-autocomplete>

            </el-form-item>
            </el-form>
            <el-table :data="sortedData" style="max-width: 600px" @selection-change="handleMemberSelect">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="125px"></el-table-column>
            <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.id">{{ scope.row.first_name }}</a></template></el-table-column>
            <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.id">{{ scope.row.last_name }}</a></template></el-table-column>

  </el-table>
            <div style="margin-top: 20px">
                <el-button @click="handleRemoveSelect" size="mini">Remove selected</el-button>
            </div>
            <div style="margin-top: 20px">
                <router-link to="/group-email/email-lists">Back to custom email lists</router-link>
            </div>
            <div style="margin-top: 10px">
                <router-link to="/group-email">Back to group email page</router-link>
            </div>
        </div>
        </div>
</template>

<script>

    import axios from 'axios'
    import showdown from 'showdown'
    const converter = new showdown.Converter()
    converter.setOption('simpleLineBreaks',true)

    export default {
        metaInfo () {
            return {
                title: "Edit Email List"
            }
        },

        components: {
            },
        data() {
            return {
                id: '',
                name: '',
                member: '',
                sortedData: [],
                multipleSelection: [],
                selectedData: [],
                editForm: {
                    name: ''
                },
                rules: {
                    name: [{ required: true, message: 'Please enter a name', trigger: 'blur' }],
                }
            }
        },
        //computed: {
        //   html () {
        //       return converter.makeHtml(this.content)
        //   }
        //},
        methods: {
            displayError: function (msg) {
                this.$message({
                    showClose: true,
                    duration: 10000,
                    type: 'error',
                    message: msg,
                    customClass: 'error-msg'
                });
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
                // call callback function to return suggestions
            },
            handleRemoveSelect: function() {
                var memberIds = [];
                this.selectedData.forEach(member => {
                   memberIds.push(member.id);
                });
                axios
                    .put("/api/group-email/remove-members-from-custom-email-list", {
                        id: parseInt(this.id),
                        memberIds: memberIds
                    })
                    .then(response => {
                        this.selectedData = [];
                        this.load();
                    });
              // this.saveRecipients();
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelect: function(item) {
                // eslint-disable-next-line
                console.log("id is : " + this.id);
                axios
                    .put('/api/group-email/member-to-custom-email-list', {
                       id: parseInt(this.id),
                       memberId: item.id
                    })
                    .then(response => {
                        this.load();
                        this.member = '';
                    });
            },
            handleMemberSelect: function(val) {
                for(var i=0; i<val.length; i++) {
                    this.selectedData.push(val[i]);
                }

            },
            onSave: function(formName) {
                this.$refs[formName].validate(valid => {
                    if(valid) {
                        axios
                            .put('/api/group-email/custom-email-list', {
                                id: parseInt(this.id),
                                name: this.editForm.name
                            })
                            .then(response => {
                                this.$alert("List name has been updated");
                            });
                    } else {
                        this.$alert("List name cannot be blank");
                    }
                });
            },
            load: function () {
                this.params = this.$route.query;
                this.id = this.params.id;
                axios
                    .get('/api/group-email/custom-email-list-members/' + this.id)
                    .then(response => {
                        if(response.data.success) {
                            this.sortedData = response.data.rows;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios
                     .get('/api/group-email/custom-email-list/' + this.id)
                     .then(response => {
                        this.editForm.name = response.data.name;
                      })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

            }

        },
        mounted() {
            this.load()
        }
    }
</script>

<style lang="postcss" scoped>
    .error-msg p {
        color: red;
    }
    .editor-container {
        float: left;
    }
    .editor-container .CodeMirror {
        height: 100%!important;
    }
    .content {
        position: relative;
    }
    #html-preview h1, #html-preview h2, #html-preview h3 {
        color: black;
        border-bottom: none;
        padding: 0;
    }
    .el-form--label-top .el-form-item__label {
        padding: 0;
    }
    .el-form-item__content {
        line-height: 14px;
    }
    #html-preview {
        margin-left: 20px;
        padding-left: 10px;
        width: 80ch;
        float: left;
        margin-top: 30px;
    }

</style>
