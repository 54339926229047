<template>
    <div id="container">
<div id="content">
            <h1>NYS Report - Enrollment of College Students with Disabilities - {{ semesterName }}</h1>
            <div style="float: right;">
                <el-select size="mini" v-model="semesterId" @change="onSemesterChange">
                    <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                </el-select>
            </div>
            <p>This report provides counts of disabilities belonging to undergraduate students enrolled during the indicated term.</p>
            <table width="100%"><tbody>
                <tr>
                    <th width="33%">Disability Category</th>
                    <th width="7%">Line No.</th>
                    <th width="20%">Enrolled in occupationally specific programs</th>
                    <th width="20%">Enrolled in all other programs</th>
                    <th width="20%">Total</th>
                </tr>
                <tr>
                    <td style="font-weight:bold">Learning Disabilities</td>
                    <td style="text-align:center">01</td>
                    <td style="text-align:right;padding-right:4em">{{ data.learning_disabilities.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.learning_disabilities.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.learning_disabilities.occupationally_specified + data.learning_disabilities.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">Mobility Impairments</td>
                    <td colspan="4"></td>
                </tr>
                <tr>
                    <td style="text-align:right">Wheelchair</td>
                    <td style="text-align:center">02</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.occupationally_specified + data.mobility_wheelchair.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Other assistive device</td>
                    <td style="text-align:center">03</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_other.occupationally_specified + data.mobility_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">No assistive device</td>
                    <td style="text-align:center">04</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_no_device.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_no_device.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_no_device.occupationally_specified + data.mobility_no_device.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Subtotal</td>
                    <td style="text-align:center">05</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.occupationally_specified + data.mobility_other.occupationally_specified + data.mobility_no_device.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.non_occupationally_specific + data.mobility_other.non_occupationally_specific + data.mobility_no_device.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.mobility_wheelchair.occupationally_specified + data.mobility_other.occupationally_specified + data.mobility_no_device.occupationally_specified + data.mobility_wheelchair.non_occupationally_specific + data.mobility_other.non_occupationally_specific + data.mobility_no_device.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">Visual Impairments</td>
                    <td colspan="4"></td>
                </tr>
                <tr>
                    <td style="text-align:right">Legally blind</td>
                    <td style="text-align:center">06</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.occupationally_specified + data.visual_legally_blind.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Other visually impaired</td>
                    <td style="text-align:center">07</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_other.occupationally_specified + data.visual_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Subtotal</td>
                    <td style="text-align:center">08</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.occupationally_specified + data.visual_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.non_occupationally_specific + data.visual_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.visual_legally_blind.occupationally_specified + data.visual_other.occupationally_specified + data.visual_legally_blind.non_occupationally_specific + data.visual_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">Acoustical Impairments</td>
                    <td colspan="4"></td>
                </tr>
                <tr>
                    <td style="text-align:right">Legally deaf</td>
                    <td style="text-align:center">09</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.occupationally_specified + data.acoustical_deaf.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Other acoustically impaired</td>
                    <td style="text-align:center">10</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_other.occupationally_specified + data.acoustical_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Subtotal</td>
                    <td style="text-align:center">11</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.occupationally_specified + data.acoustical_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.non_occupationally_specific + data.acoustical_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.acoustical_deaf.occupationally_specified + data.acoustical_other.occupationally_specified + data.acoustical_deaf.non_occupationally_specific + data.acoustical_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">Multiple Disabilities</td>
                    <td style="text-align:center">12</td>
                    <td style="text-align:right;padding-right:4em">{{ data.multiple_disabilities.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.multiple_disabilities.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.multiple_disabilities.occupationally_specified + data.multiple_disabilities.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">Other Health Impairments</td>
                    <td colspan="4"></td>
                </tr>
                <tr>
                    <td style="text-align:right">Mental health impairment</td>
                    <td style="text-align:center">13</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.occupationally_specified + data.other_mental_health.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Speech impairment</td>
                    <td style="text-align:center">14</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_speech_impediment.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_speech_impediment.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_speech_impediment.occupationally_specified + data.other_speech_impediment.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Traumatic brain injury</td>
                    <td style="text-align:center">15</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_tbi.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_tbi.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_tbi.occupationally_specified + data.other_tbi.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Orthopedic impairment</td>
                    <td style="text-align:center">16</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_orthopedic.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_orthopedic.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_orthopedic.occupationally_specified + data.other_orthopedic.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Alcohol/substance abuse & recovery</td>
                    <td style="text-align:center">17</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_alchohol_substance.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_alchohol_substance.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_alchohol_substance.occupationally_specified + data.other_alchohol_substance.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Other</td>
                    <td style="text-align:center">18</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_other.occupationally_specified + data.other_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="text-align:right">Subtotal</td>
                    <td style="text-align:center">19</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.occupationally_specified + data.other_speech_impediment.occupationally_specified + data.other_tbi.occupationally_specified + data.other_orthopedic.occupationally_specified + data.other_alchohol_substance.occupationally_specified + data.other_other.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.non_occupationally_specific + data.other_speech_impediment.non_occupationally_specific + data.other_tbi.non_occupationally_specific + data.other_orthopedic.non_occupationally_specific + data.other_alchohol_substance.non_occupationally_specific + data.other_other.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.other_mental_health.occupationally_specified + data.other_speech_impediment.occupationally_specified + data.other_tbi.occupationally_specified + data.other_orthopedic.occupationally_specified + data.other_alchohol_substance.occupationally_specified + data.other_other.occupationally_specified + data.other_mental_health.non_occupationally_specific + data.other_speech_impediment.non_occupationally_specific + data.other_tbi.non_occupationally_specific + data.other_orthopedic.non_occupationally_specific + data.other_alchohol_substance.non_occupationally_specific + data.other_other.non_occupationally_specific }}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold">UNDUPLICATED TOTAL</td>
                    <td style="text-align:center">20</td>
                    <td style="text-align:right;padding-right:4em">{{ data.non_duplicate.occupationally_specified }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.non_duplicate.non_occupationally_specific }}</td>
                    <td style="text-align:right;padding-right:4em">{{ data.non_duplicate.occupationally_specified + data.non_duplicate.non_occupationally_specific }}</td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "NYS Report - Enrollment of College Students with Disabilities - " + this.semesterName
            }
        },
        components: {
            },
        data () {
            return {
                data: {},
                loading: null,
                semesters: [],
                semesterId: null,
                semesterName: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-last-eight-years-of-semesters/')
                    .then(response => {
                        if(response.data.success) {
                            this.semesters = response.data.rows;
                            var currentSemesterObj = this.semesters.find(o => o.is_current === true);
                            this.semesterId = currentSemesterObj.id;
                            this.semesterName = currentSemesterObj.name;
                            this.getData();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/nys-enrollment-report/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSemesterChange: function(id){
                this.semesterName = this.semesters.find(o => o.id === id).name;
                this.getData();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>

data.other_mental_health.occupationally_specified + data.other_speech_impediment.occupationally_specified + data.other_tbi.occupationally_specified + data.other_orthopedic.occupationally_specified + data.other_alchohol_substance.occupationally_specified + data.other_other.occupationally_specified
