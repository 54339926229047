<template>
  <div id="container">
    <div id="content">
      <h1>Logging In</h1>
    </div>
  </div>
</template>

<script>
  //require('es6-promise').polyfill();

  import { mapActions } from 'vuex'

  export default {
    metaInfo () {
      return {
        title: 'Log In'
      }
    },
    data () {
      return {
        // valid: false//,
        // login: {
        // email: '',
        // password: ''
        // },
        // rules: {
        //   email: [
        //     {required: true, message: 'Please enter an email', trigger: 'blur'}
        //   ],
        //   password: [
        //     {required: true, message: 'Please enter a password', trigger: 'blur'}
        //   ]
        // },
        authError: ''
      }
    },
    methods: {
      ...mapActions('auth', ['getUser']),
      // checkForm: function() {
      //   this.$refs.login.validate((valid) => {
      //       this.valid = valid
      //   });
      // },
      // Try to log the user in with the username
      // and password they provided.
      getLoggedInUser: function() {
        //this.tryingToLogIn = true
        // Reset the authError if it existed.
        //this.authError = null
        return this.getUser({
        }).then(token => {

// eslint-disable-next-line
//           console.log("token:");
// eslint-disable-next-line
//           console.log(token);


          //this.tryingToLogIn = false
          // Redirect to the originally requested page, or to the home page
          var returnURL = token && token.returnUrl ? token.returnUrl : JSON.parse(localStorage.getItem("returnURL"));
          localStorage.removeItem("returnURL");
// eslint-disable-next-line
          console.log("RETURN URL: ");// eslint-disable-next-line
          console.log(returnURL);
         // console.log(this.loggedIn())

          // setTimeout(function(app){app.$router.push(returnURL || {name: 'home'})}, 5000, this)

          this.$router.push(returnURL || {name: 'home'})

          // }).catch(error => {
          //   this.displayError(error);
          //   this.tryingToLogIn = false
          //   this.authError = error
        });
      },
      displayError: function(message) {
        this.$message({
          showClose: true,
          duration: 6000,
          type: 'error',
          message: message,
          customClass: 'error-msg'
        });
      }
    },

    mounted() {
      this.getLoggedInUser();
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  /*.el-form {*/
  /*  width:400px;*/
  /*}*/
  .el-form-item.is-required .el-form-item__label::before {
    content: '';
    margin-right: 0;
  }
  .el-form-item {
    padding-bottom: 15px;
  }
  .el-form-item__error {
    font-size: 11px;
    padding-top: 3px;
  }
  #content .el-form-item .el-form-item__label {
    line-height: 32px;
  }
</style>
