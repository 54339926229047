<template>
    <div id="container">
<div id="content">
            <h1>Database change history</h1>
            <table><tbody>
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Student</th>
                    <th v-if="typeOfChange == 'accommodations'">Accommodation</th>
                    <th v-if="typeOfChange == 'deleted_documents'">Document type</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
                <tr v-for="(row, index) in data" :key="index">
                    <td>{{ row.created_at | formatDateMDY }}</td>
                    <td>{{ row.created_at | formatTimehma }}</td>
                    <td>{{ row.user_name }}</td>
                    <td><a :href="'/student/profile/' + row.student_id">{{ row.student_name }}</a></td>
                    <td v-if="typeOfChange == 'accommodations'">{{ row.accommodation_name }}</td>
                    <td v-if="typeOfChange == 'deleted_documents'"><a :href="'/document/edit/' + row.document_id + '?student_id=' + row.student_id">{{ row.document_type }}</a></td>
                    <td>{{ row.from }}</td>
                    <td>{{ row.to }}</td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Database change history"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                typeOfChange: null,
                limit: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.typeOfChange = this.urlParams.type_of_change;
                this.limit = this.urlParams.limit;
                this.getData();
            },
            getData: function() {
                //TODO: uncomment this when page is ready
                //this.loading = this.$loading({lock: true, text: 'Loading...'});
                //TODO: Replace URL and params
                axios.get('/api/report/change-history/', {params: {type_of_change: this.typeOfChange, limit: this.limit}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
