<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getRequests">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Current Note taking Services By Course for {{this.semesterName}}</h1>
            <note-taking-service-nav selected="active-services"></note-taking-service-nav>
            <p>The following {{requests.length}} courses have hired note takers.</p>
            <el-table :default-sort="{prop: 'class_name', order: 'ascending'}" :data="requests">
                <el-table-column label="Course" prop="class_name" sortable :sort-orders="['ascending', 'descending']" :min-width="18.5">
                    <template slot-scope="scope">
                        <a :href="'/note-taking-service/show/' + scope.row.id">{{ scope.row.class_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Course ID" prop="course_identifier" sortable :sort-orders="['ascending', 'descending']" :min-width="11"></el-table-column>
                <el-table-column label="Section" prop="section_code" sortable :sort-orders="['ascending', 'descending']" :min-width="6.5"></el-table-column>
                <el-table-column label="Student" prop="student_name" sortable :sort-orders="['ascending', 'descending']" :min-width="9.5">
                    <template slot-scope="scope">
                        <a :href="'/student/services/' + scope.row.student_id">{{ scope.row.student_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Date Requested" prop="requested_date" sortable :sort-orders="['ascending', 'descending']" :min-width="11.0">
                    <template slot-scope="scope">{{ scope.row.requested_date | formatDateMD }}</template>
                </el-table-column>
                <el-table-column label="Note Taker Name" prop="note_taker_name" sortable :sort-orders="['ascending', 'descending']" :min-width="9.5">
                    <template slot-scope="scope">
                        <a :href="'/service-provider/show/' + scope.row.note_taker_id">{{ scope.row.note_taker_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Hired" prop="hired_date" sortable :sort-orders="['ascending', 'descending']" :min-width="5.5"><template slot-scope="scope">{{ scope.row.hired_date | formatDateMD }}</template></el-table-column>
                <el-table-column label="Comment" prop="comments" sortable :sort-orders="['ascending', 'descending']" :min-width="75.5"></el-table-column>
            </el-table>
            <p v-if="total"><a :href="'/api/notetaking-service/current-by-course.xlsx?semester_id=' + this.semesterId" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Current Note taking Services By Course for " + this.semesterName
            }
        },
        components: {
        },
        data () {
            return {
                requests: [],
                semesters: [],
                semesterId: null,
                total: null,
                semesterName : ""
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                                this.semesterName = this.semesters[i].name
                            }
                        }
                        this.getRequests();
                    });
            },
            getRequests: function() {
                var semesterObj = this.semesters.find(o => o.id === this.semesterId);

                this.semesterName = semesterObj.name;


                axios.get('/api/notetaking-service/current-by-course/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.requests = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
