<template>
    <div id="container">
<div id="content">
            <template v-if="total > 0">
                <h1>Search Results ({{ pageSize * (currentPage-1) + 1 }}-{{ pageSize * (currentPage-1) + intakeNotes.length }} of {{ total }})</h1>
            </template>
            <template v-else-if="total === 0">
                <h1>No record matched your search terms.</h1>
            </template>
            <h2>Search Intake & Housing Intake Notes</h2>
            <el-form style="width:100%">
                <el-form-item>
                    <span class="label">Search item 1 </span><el-select  style="width: 100px" v-model="searchField1" placeholder="Please Select" size="mini">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm1" style="width:350px;" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <span class="label">Search item 2 </span><el-select  style="width: 100px" v-model="searchField2" placeholder="Please Select" size="mini">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm2" style="width:350px;" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <span class="label">Search item 3 </span><el-select  style="width: 100px" v-model="searchField3" placeholder="Please Select" size="mini">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm3" style="width:350px;" size="mini"></el-input>
                    <el-button type="primary"  size="mini" @click="getIntakeNotes">Search</el-button>
                </el-form-item>
            </el-form>
            <template v-if="total > 0">
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
                <el-table :default-sort="{prop: 'created_at', order: 'descending'}" :data="intakeNotes" @sort-change="handleSort">
                    <el-table-column prop="student_name" label="Student Name" sortable="custom" :sort-orders="['ascending', 'descending']" width="135"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
                    <el-table-column prop="content" label="Intake Note" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column><!-- TODO: Might need to format this text -->
                    <el-table-column prop="is_housing" label="Is Housing?" sortable="custom" :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                    <el-table-column prop="status" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="90"><template slot-scope="scope">{{ scope.row.status_display }}</template></el-table-column>
                    <el-table-column prop="author_name" label="Author" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                    <el-table-column prop="created_at" label="Date of contact" sortable="custom" :sort-orders="['ascending', 'descending']" width="140"><template slot-scope="scope">{{ scope.row.created_at | formatDateMDY }}</template></el-table-column>
                </el-table>
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Search Results (" + (this.pageSize * (this.currentPage-1) + 1) + "-" + (this.pageSize * (this.currentPage-1) + this.contactNotes.length) + " of " + this.total + ")"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                pageSize: 200,
                currentPage: 1,
                total: null,
                prop: "created_at",
                order: "descending",
                intakeNotes: [],
                searchField1: 'content',
                searchTerm1: null,
                searchField2: 'content',
                searchTerm2: null,
                searchField3: 'content',
                searchTerm3: null,
                options: [{
                    'value': 'content',
                    'label': 'Content'
                }, {
                    'value': 'student',
                    'label': 'Student'
                }, {
                    'value': 'author',
                    'label': 'Author'
                }]
            }
        },
        computed: {
            csvUrl: function(){
                var url = '/api/intake-note/search.xlsx?';
                if(this.searchTerm1){
                    url += 'search_field_1=' + this.searchField1 + '&' + 'search_term_1=' + this.searchTerm1;
                }
                if(this.searchTerm2){
                    if(this.searchTerm1) url += '&';
                    url += 'search_field_2=' + this.searchField2 + '&' + 'search_term_2=' + this.searchTerm2;
                }
                if(this.searchTerm3){
                    if(this.searchTerm1 || this.searchTerm2) url += '&';
                    url += 'search_field_3=' + this.searchField3 + '&' + 'search_term_3=' + this.searchTerm3;
                }

                return url;
            }
        },
        methods: {
            handleChangePage: function(val) {
                this.currentPage = val;
                this.getIntakeNotes();
            },
            load: function() {
                this.urlParams = this.$route.query;

                this.searchField1 = this.urlParams.search_field_1 ? this.urlParams.search_field_1 : 'content';
                this.searchTerm1 = this.urlParams.search_term_1;
                this.searchField2 = this.urlParams.search_field_2 ? this.urlParams.search_field_2 : 'content';
                this.searchTerm2 = this.urlParams.search_term_2;
                this.searchField3 = this.urlParams.search_field_3 ? this.urlParams.search_field_3 : 'content';
                this.searchTerm3 = this.urlParams.search_term_3;

                this.getIntakeNotes();
            },
            getIntakeNotes: function() {
                axios.get('/api/intake-note/search/', {params: {search_field_1: this.searchField1, search_term_1: this.searchTerm1, search_field_2: this.searchField2, search_term_2: this.searchTerm2, search_field_3: this.searchField3, search_term_3: this.searchTerm3, start: this.pageSize * (this.currentPage-1), limit: this.pageSize, prop: this.prop, order: this.order}})
                    .then(response => {
                        if(response.data.success) {
                            this.intakeNotes = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSort: function(sortObj){
                this.prop = sortObj.prop;
                this.order = sortObj.order;
                this.getIntakeNotes();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .label {
        font-size: small;
    }
</style>
