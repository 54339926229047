<template>
    <div id="container">
<div id="content" v-loading="loading" element-loading-text="Loading...">
            <template v-if="total > 0">
                <h1>{{ title }} ({{ pageSize * (currentPage-1) + 1 }}-{{ pageSize * (currentPage-1) + tasks.length }} of {{ total }})</h1>
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
                <a @click="toggleShowDetails">{{ showDetails ? "hide details" : "show all details" }}</a>
                <tasks-tasks :tasks="tasks" :showDetails="showDetails" @sortdata="val => handleSort(val)"></tasks-tasks>
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
            </template>
            <template v-else-if="total === 0">
                <h1>No record matched your search terms.</h1>
            </template>
            <p><a :href="'/tasks/edit'">Create a new task</a><br>(Use this link to assign tasks to others or to send a message)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title:this.title + " (" + (this.pageSize * (this.currentPage-1) + 1) + "-" + (this.pageSize * (this.currentPage-1) + this.tasks.length) + " of " + this.total + ")"
            }
        },
        components: {
        },
        data () {
            return {
                pageSize: 200,
                currentPage: 1,
                total: null,
                prop: "last_updated_at",
                order: "descending",
                status: '',
                title: '',
                showDetails: false,
                tasks: [],
                loading: false
            }
        },
        methods: {
            handleChangePage: function(val) {
                this.currentPage = val;
                this.getTasks();
            },
            load: function() {
                this.status = location.pathname.split('/tasks/waiting/')[1];
                this.getTasks();
            },
            toggleShowDetails: function(){
                this.showDetails = !this.showDetails;
                this.getTasks();
            },
            getTasks: function() {
                this.loading=true;
                axios.get('/api/task/tasks-by-waiting-status/', {params: {status: this.status, start: this.pageSize * (this.currentPage-1), limit: this.pageSize, prop: this.prop, order: this.order}})
                    .then(response => {
                        if(response.data.success) {
                            this.tasks = response.data.rows;
                            this.total = response.data.total;
                            this.title = response.data.title;
                        }
                        this.loading=false;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSort: function(sortObj){
                this.prop = sortObj.prop;
                this.order = sortObj.order;
                this.getTasks();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
