<template>
    <div id="container">
        <div id="content">
            <h1>Your Account</h1>
            <table class="user-table">
                <tbody><tr>
                    <th>Name:</th>
                    <td>{{ firstNames }} {{ lastName }}</td>
                </tr>
                <tr>
                    <th>Address:</th>
                    <td>{{ address1 }}</td>
                </tr>
                <tr>
                    <th>Email:</th>
                    <td>{{ email }}</td>
                </tr>
                <tr>
                    <th>Cell phone:</th>
                    <td>{{ cellPhone }}</td>
                </tr>
                <tr>
                    <th>Home phone:</th>
                    <td>{{ homePhone }}</td>
                </tr>
                <tr>
                    <th>Work phone:</th>
                    <td>{{ workPhone }}</td>
                </tr>
                <tr>
                    <th>Role(s):</th>
                    <td>{{ userRoles }}</td>
                </tr>
                <tr>
                    <th>Contact Note Supervisor:</th>
                    <td>{{ contactNoteSupervisor }}</td>
                </tr>
                <tr>
                    <th>Accommodation Approval Supervisor:</th>
                    <td>{{ accommodationApprovalSupervisor }}</td>
                </tr>
                <tr>
                    <th>Last password change:</th>
                    <td>{{ passwordUpdatedOn }}</td>
                </tr>
            </tbody></table>
            <p><a :href="'/user/edit'">Edit name and contact information</a></p>
            <p><a :href="'/home'">Return to my home page</a></p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    export default {
        metaInfo () {
            return {
                title: 'Your Account'
            }
        },
        components: {
            },
        data () {
            return {
                prefix: "",
                firstNames: "",
                lastName: "",
                suffix: "",
                address1: "",
                address2: "",
                address3: "",
                email: "",
                cellPhone: "",
                homePhone: "",
                workPhone: "",
                roles: [],
                contactNoteSupervisorId: "",
                contactNoteSupervisor: "",
                accommodationApprovalSupervisorId: "",
                accommodationApprovalSupervisor: "",
                passwordUpdatedOn: "",
                allRoles: [],
                userRoles: ""
            }
        },
        methods: {
            getUser: function () {
                axios.get("/api/admin/user/")
                    .then(response => {
                        this.firstNames = response.data.first_names;
                        this.lastName = response.data.last_name;
                        this.address1 = response.data.address_1;
                        this.address2 = response.data.address_2;
                        this.address3 = response.data.address_3;
                        this.email = response.data.email;
                        this.cellPhone = response.data.cell_phone;
                        this.homePhone = response.data.home_phone;
                        this.workPhone = response.data.work_phone;
                        this.getRoles();
                        this.roles = response.data.roles;
                        this.passwordUpdatedOn = response.data.password_updated_on;
                        this.accommodationApprovalSupervisorId = response.data.accommodation_approval_supervisor_id;
                        if(this.accommodationApprovalSupervisorId) {
                            this.getAccommodationApprovalSupervisorName();
                        }
                        this.contactNoteSupervisorId = response.data.contact_note_supervisor_id;
                        if(this.contactNoteSupervisorId) {
                            this.getContactNoteSupervisorName();
                        }
                    });
            },
            getAccommodationApprovalSupervisorName: function() {
                axios.get("/api/admin/user/?id=" + this.accommodationApprovalSupervisorId)
                    .then(response => {
                        this.accommodationApprovalSupervisor = response.data.first_names + " " + response.data.last_name;
                    });
            },
            getContactNoteSupervisorName: function() {
                axios.get("/api/admin/user/?id=" + this.contactNoteSupervisorId)
                    .then(response => {
                        this.contactNoteSupervisor = response.data.first_names + " " + response.data.last_name;
                    });
            },
            getRoles: function() {
                axios.get("/api/admin/roles")
                    .then(response => {
                        this.allRoles = response.data.rows;
                        for(var x in this.roles) {
                            for(var y in this.allRoles) {
                                if (this.allRoles[y].id == this.roles[x]) {
                                    this.userRoles += this.allRoles[y].name + ", ";
                                }
                            }
                        }
                        this.userRoles = this.userRoles.slice(0, -2);

                });
            }
        },
        created () {
            this.getUser();
        }
    }
</script>

<style scoped>
    .user-table td, .user-table th {
        border: none;
        font-size: 1em;
    }

    .user-table th {
        padding-left: 0px;
    }
</style>
