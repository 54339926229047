<template>
    <div id="container">
<div id="content">
            <ha-committee-status :urlParams="urlParams"></ha-committee-status>
        </div>
        </div>
</template>

<script>



    export default {

        components: {
        },
        data () {
            return {

                urlParams: {}
            }
        },
        methods: {
            load: function () {
               // this.urlParams = this.getStatusDataParams('/ha-committee/status/');
              this.urlParams = this.$route.query;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
