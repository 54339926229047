<template>
    <div id="container">
      <div id="content">
            <template v-if="total > 0">
                <h1>Search Results ({{ pageSize * (currentPage-1) + 1 }}-{{ pageSize * (currentPage-1) + contactNotes.length }} of {{ total }})</h1>
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
                <a @click="toggleShowDetails">{{ showDetails ? "hide details" : "show all details" }}</a> / <a :href="csvUrl" target="_blank">short form csv</a>
                <contact-notes-contact-notes :contactNotes="contactNotes" :showDetails="showDetails" @sortdata="val => handleSort(val)"></contact-notes-contact-notes>
                <el-pagination v-if="total > 200" layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
            </template>
            <template v-else>
                <h1>No record matched your search terms.</h1>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'




    export default {
        metaInfo () {
            return {
                title: "Search Results (" + (this.pageSize * (this.currentPage-1) + 1) + "-" + (this.pageSize * (this.currentPage-1) + this.contactNotes.length) + " of " + this.total + ")"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {},
                pageSize: 200,
                currentPage: 1,
                total: null,
                prop: "created_at",
                order: "descending",
                showDetails: false,
                contactNotes: [],
                searchField1: null,
                searchTerm1: null,
                searchField2: null,
                searchTerm2: null,
                searchField3: null,
                searchTerm3: null
            }
        },
        computed: {
            csvUrl: function(){
                var url = '/api/contact-note/search.xlsx?';
                if(this.searchTerm1){
                    url += 'search_field_1=' + this.searchField1 + '&' + 'search_term_1=' + this.searchTerm1;
                }
                if(this.searchTerm2){
                    if(this.searchTerm1) url += '&';
                    url += 'search_field_2=' + this.searchField2 + '&' + 'search_term_2=' + this.searchTerm2;
                }
                if(this.searchTerm3){
                    if(this.searchTerm1 || this.searchTerm2) url += '&';
                    url += 'search_field_3=' + this.searchField3 + '&' + 'search_term_3=' + this.searchTerm3;
                }

                return url;
            }
        },
        methods: {
            handleChangePage: function(val) {
                this.currentPage = val;
                this.getContactNotes();
            },
            load: function() {

              this.searchField1 = this.$route.query.search_field_1;
              this.searchTerm1 = this.$route.query.search_term_1;
              this.searchField2 = this.$route.query.search_field_2;
              this.searchTerm2 = this.$route.query.search_term_2;
              this.searchField3 = this.$route.query.search_field_3;
              this.searchTerm3 = this.$route.query.search_term_3;

              this.getContactNotes();
            },
            toggleShowDetails: function(){
                this.showDetails = !this.showDetails;
                this.getContactNotes();
            },
            getContactNotes: function() {

              axios.get('/api/contact-note/search/', {params: {search_field_1: this.searchField1, search_term_1: this.searchTerm1, search_field_2: this.searchField2, search_term_2: this.searchTerm2, search_field_3: this.searchField3, search_term_3: this.searchTerm3, show_details: this.showDetails, start: this.pageSize * (this.currentPage-1), limit: this.pageSize, prop: this.prop, order: this.order}})
                    .then(response => {
                        if(response.data.success) {
                            this.contactNotes = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSort: function(sortObj){
                this.prop = sortObj.prop;
                this.order = sortObj.order;
                this.getContactNotes();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
