<template>
    <div id="container">
<div id="content">
            <student-nav selected="disabilities" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div>
                <student-disabilities :id="id" :isAcademicDisability="true"></student-disabilities>
                <a :href="'/student/disabilities-edit/' + id + '?is_academic=true'">Enter an academic disability</a>
                <template v-if="this.hasHousingProfile">
                    <br/><br/>
                    <student-disabilities :id="id" :isAcademicDisability="false"></student-disabilities>
                    <a :href="'/student/disabilities-edit/' + id + '?is_academic=false'">Enter a housing disability</a>
                </template>
            </div>
            <div>
              <h2>Other options</h2>
              <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&amp;student=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>

              </ul>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                hasHousingProfile: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/disabilities/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                        this.hasHousingProfile = response.data.hasHousingProfile;
                    });
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
