<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="onSemesterChange">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Disabilities by semester, school, and eligibility</h1>
            <div style="float:right;">(<a :href="'/api/report/disabilities-semester-school-eligibility.xlsx?semester_id=' + semesterId" target="_blank">CSV</a>)</div>
            <table style="clear:right;"><tbody>
                <tr>
                    <th>School</th>
                    <th>Disability</th>
                    <th>No eligibility and registered</th>
                    <th>Permanent and registered</th>
                    <th>Temporary term and registered</th>
                    <th>Short term and registered</th>
                    <th>Prospective</th>
                    <th>Coordinator review</th>
                    <th>Psychologist review</th>
                    <th>Director review</th>
                    <th>One doc submitted</th>
                    <th>Ha only</th>
                </tr>
                <template v-for="row in data">
                    <template v-for="(disability, index) in row.disabilities">
                        <template v-if="index === 0">
                            <tr style="border-top: medium solid black;" :key="index">
                                <th :rowspan="row.disabilities.length">{{ row.school }}</th>
                                <td>{{ disability.name }}</td>
                                <td>{{ disability["No eligibility and registered"] ? disability["No eligibility and registered"] : 0 }}</td>
                                <td>{{ disability["Permanent and registered"] ? disability["Permanent and registered"] : 0 }}</td>
                                <td>{{ disability["Temporary term and registered"] ? disability["Temporary term and registered"] : 0 }}</td>
                                <td>{{ disability["Short term and registered"] ? disability["Short term and registered"] : 0 }}</td>
                                <td>{{ disability["Prospective"] ? disability["Prospective"] : 0 }}</td>
                                <td>{{ disability["Coordinator review"] ? disability["Coordinator review"] : 0 }}</td>
                                <td>{{ disability["Psychologist review"] ? disability["Psychologist review"] : 0 }}</td>
                                <td>{{ disability["Director review"] ? disability["Director review"] : 0 }}</td>
                                <td>{{ disability["One doc submitted"] ? disability["One doc submitted"] : 0 }}</td>
                                <td>{{ disability["Ha only"] ? disability["Ha only"] : 0 }}</td>
                            </tr>
                        </template>
                        <template v-else-if="index === row.disabilities.length - 1">
                            <tr :key="index">
                                <th>{{ disability.name }}</th>
                                <th>{{ disability["No eligibility and registered"] ? disability["No eligibility and registered"] : 0 }}</th>
                                <th>{{ disability["Permanent and registered"] ? disability["Permanent and registered"] : 0 }}</th>
                                <th>{{ disability["Temporary term and registered"] ? disability["Temporary term and registered"] : 0 }}</th>
                                <th>{{ disability["Short term and registered"] ? disability["Short term and registered"] : 0 }}</th>
                                <th>{{ disability["Prospective"] ? disability["Prospective"] : 0 }}</th>
                                <th>{{ disability["Coordinator review"] ? disability["Coordinator review"] : 0 }}</th>
                                <th>{{ disability["Psychologist review"] ? disability["Psychologist review"] : 0 }}</th>
                                <th>{{ disability["Director review"] ? disability["Director review"] : 0 }}</th>
                                <th>{{ disability["One doc submitted"] ? disability["One doc submitted"] : 0 }}</th>
                                <th>{{ disability["Ha only"] ? disability["Ha only"] : 0 }}</th>
                            </tr>
                        </template>
                        <template v-else>
                            <tr :key="index">
                                <td>{{ disability.name }}</td>
                                <td>{{ disability["No eligibility and registered"] ? disability["No eligibility and registered"] : 0 }}</td>
                                <td>{{ disability["Permanent and registered"] ? disability["Permanent and registered"] : 0 }}</td>
                                <td>{{ disability["Temporary term and registered"] ? disability["Temporary term and registered"] : 0 }}</td>
                                <td>{{ disability["Short term and registered"] ? disability["Short term and registered"] : 0 }}</td>
                                <td>{{ disability["Prospective"] ? disability["Prospective"] : 0 }}</td>
                                <td>{{ disability["Coordinator review"] ? disability["Coordinator review"] : 0 }}</td>
                                <td>{{ disability["Psychologist review"] ? disability["Psychologist review"] : 0 }}</td>
                                <td>{{ disability["Director review"] ? disability["Director review"] : 0 }}</td>
                                <td>{{ disability["One doc submitted"] ? disability["One doc submitted"] : 0 }}</td>
                                <td>{{ disability["Ha only"] ? disability["Ha only"] : 0 }}</td>
                            </tr>
                        </template>
                    </template>
                </template>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Disabilities by semester, school, and eligibility"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                semesters: [],
                semesterId: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                if(this.urlParams.semester_id){
                    this.semesterId = parseInt(this.urlParams.semester_id);
                }
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        if(!this.semesterId){
                            this.semesterId = this.semesters.find(o => o.is_current === true).id;
                        }
                        this.getData();
                    });
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/disabilities-semester-school-eligibility/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSemesterChange: function(){
                history.pushState(null, null, '/report/students-semester-eligibility-disability?semester_id=' + this.semesterId);
                this.getData();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
</style>
