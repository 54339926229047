<template>
  <div id="container">
    <div id="content">
      <h1>Edit Proctor</h1>
      <el-form :model="proctor" :rules="rules" ref="proctorForm" label-width="205px" label-position="left">
        <el-form-item label="First Name" prop="first_name">
          <el-input size="mini" v-model="proctor.first_name" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Last Name" prop="last_name">
          <el-input size="mini" v-model="proctor.last_name" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Phone">
          <el-input size="mini" v-model="proctor.phone" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input size="mini" v-model="proctor.email" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Active?">
          <el-checkbox style="margin-top:6px;" v-model="proctor.active"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="!proctor.active" label="Deactivation Date">
          <el-date-picker type="date" size="mini" v-model="proctor.inactive_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="CUMC Proctor?">
          <el-checkbox style="margin-top:6px;" v-model="proctor.cumc_proctor"></el-checkbox>
        </el-form-item>
        <el-form-item label="Trainee?">
          <el-checkbox style="margin-top:6px;" v-model="proctor.proctor_is_trainee"></el-checkbox>
        </el-form-item>
        <el-form-item label="Title">
          <el-select size="mini" v-model="proctor.proctor_title">
            <el-option :key="''" :value="null" :label="''"></el-option>
            <el-option v-for="title in titles" :key="title" :value="title" :label="title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="proctor.proctor_title == 'Special Assignment'" label="Special Assignment Note">
          <el-input type="textarea" :rows="3" v-model="proctor.proctor_special_assignment_note"></el-input>
        </el-form-item>
        <el-form-item label="Payroll Status">
          <el-select size="mini" v-model="proctor.proctor_university_status" style="width:225px">
            <el-option :key="''" :value="null" :label="''"></el-option>
            <el-option v-for="payrollStatus in payrollStatuses" :key="payrollStatus" :value="payrollStatus" :label="payrollStatus"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="proctor.proctor_university_status == 'Other'" label="Other Payroll Status">
          <el-input size="mini" v-model="proctor.proctor_university_status_other"></el-input>
        </el-form-item>
        <el-form-item v-if="proctor.proctor_university_status == 'Student Add-Comp'" label="Max hours allowed to work at DS each week">
          <el-input size="mini" v-model="proctor.proctor_payroll_status_max_hours"></el-input>
        </el-form-item>
        <el-form-item label="Columbia Affiliation">
          <el-select size="mini" v-model="proctor.proctor_columbia_affiliation" style="width:100%;">
            <el-option :key="''" :value="null" :label="''"></el-option>
            <el-option v-for="affiliation in affiliations" :key="affiliation" :value="affiliation" :label="affiliation"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
      <template v-if="proctorHistory && proctorHistory.length">
        <br><br><a @click="showProctorHistory = !showProctorHistory">{{ showProctorHistory ? "Hide" : "Show" }} Status and Affiliation History</a>
        <template v-if="showProctorHistory">
          <h2>University Status and Columbia Affiliation History</h2>
          <table><tbody>
          <tr>
            <th>Date</th>
            <th>University Status Changed To</th>
            <th>Columbia Affiliation Changed To</th>
            <th>Changed By</th>
          </tr>
          <tr v-for="(item, index) in proctorHistory" :key="index">
            <td>{{ item.created_at | formatDateTimeMDYhma }}</td>
            <td>{{ item.university_status }}</td>
            <td>{{ item.columbia_affiliation }}</td>
            <td>{{ item.changed_by_name }}</td>
          </tr>
          </tbody></table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "Edit Proctor"
      }
    },
    components: {
    },
    data () {
      return {
        proctor: {
          id: null,
          first_name: null,
          phone: null,
          email: null,
          active: false,
          inactive_date: null,
          cumc_proctor: false,
          proctor_title: null,
          proctor_special_assignment_note: null,
          proctor_university_status: null,
          proctor_university_status_other: null,
          proctor_payroll_status_max_hours: null,
          proctor_columbia_affiliation: null,
          proctor_is_trainee: null
        },
        proctorWasActive: false,
        proctorHistory: [],
        showProctorHistory: false,
        rules: {
          first_name: [{ required: true, whitespace: true, message: 'First Name is required' }],
          last_name: [{ required: true, whitespace: true, message: 'Last Name is required' }],
          email: [{ required: true, whitespace: true, message: 'Email is required' }]
        },
        affiliations: [],
        titles: [],
        payrollStatuses: []
      }
    },
    methods: {
      load: function(){
        this.proctor.id = location.pathname.split('/proctor/edit/')[1];
        if(this.proctor.id){
          this.proctor.id = parseInt(this.proctor.id);
          this.getProctor();
        }

        axios.get("/api/proctor/form-items/")
          .then(response => {
            this.affiliations = response.data.affiliation_options;
            this.titles = response.data.title_options;
            this.payrollStatuses = response.data.payroll_statuses;
          });
      },
      getProctor: function(){
        axios.get("/api/proctor/", {params: {id: this.proctor.id}})
          .then(response => {
            this.proctor = response.data.proctor;
            this.proctorWasActive = this.proctor.active;
          });

        axios.get("/api/proctor/proctor-history/", {params: {id: this.proctor.id}})
          .then(response => {
            this.proctorHistory = response.data.rows;
          });
      },
      onSubmit: function(){
        this.$refs["proctorForm"].validate((valid) => {
          if(valid){
            if(this.proctor.id){
              axios.put('/api/proctor/', this.proctor)
                .then(response => {
                  this.$router.push({ path: '/proctor/show/' + this.proctor.id});
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              axios.post('/api/proctor/', this.proctor)
                .then(response => {
                  this.$router.push({ path: '/proctor/show/' + response.data.id});
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          }
        });
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .el-form {
    width: 725px;
  }
</style>
