<template>
    <div id="container">
<div id="content">
            <ha-committee-expired-in-last-6-months :urlParams="urlParams"></ha-committee-expired-in-last-6-months>
        </div>
        </div>
</template>

<script>



    export default {
        metaInfo () {
            return {
                title: "Expired in last 6 months"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {}
            }
        },
        methods: {
            load: function () {
              //  this.urlParams = this.getStatusDataParams('/ha-committee/');
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
