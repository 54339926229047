<template>
  <div id="container">
    <div id="content">
      <h1>Reports</h1>
      <h2>Student Database and Statistics</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsBySchoolOptions = !showStudentsBySchoolOptions">Students by School</a>
            <div v-if="showStudentsBySchoolOptions">
              <table><tbody>
              <tr>
                <th>Choose a school:</th>
                <td id="students-by-school-container">
                  <el-checkbox label="All" v-model="studentsBySchoolOptions.allSchools" @change="onStudentsBySchoolAllChange"></el-checkbox>
                  <el-checkbox-group v-model="studentsBySchoolOptions.schools" @change="onStudentsBySchoolCheckboxGroupChange">
                    <div>

                      <template v-for="(school, schoolIndex) in schools">
                        <div class="students-by-school-checkbox" :key="school" v-if="schoolIndex%2==0"><el-checkbox :label="school"></el-checkbox></div>
                      </template>
                    </div>
                    <div>

                      <template v-for="(school, schoolIndex) in schools">
                        <div class="students-by-school-checkbox" :key="school" v-if="schoolIndex%2==1"><el-checkbox :label="school"></el-checkbox></div>
                      </template>
                    </div>
                  </el-checkbox-group>
                </td>
              </tr>
              <tr>
                <th>Choose statuses:</th>
                <td>
                  <el-checkbox label="All" v-model="studentsBySchoolOptions.allStatuses" @change="onStudentsBySchoolAllStatusChange"></el-checkbox>
                  <el-checkbox-group v-model="studentsBySchoolOptions.statuses" @change="onStudentsBySchoolCheckboxGroupStatusChange">
                    <template v-for="status in statuses">
                      <div class="students-by-school-checkbox" :key="status"><el-checkbox :label="status"></el-checkbox></div>
                    </template>
                  </el-checkbox-group>
                </td>
              </tr>
              <tr>
                <th>Secondary sort order:</th>
                <td><el-select size="mini" v-model="studentsBySchoolOptions.secondary_sort"><el-option v-for="option in studentsBySchoolSortOptions" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Start Semester</th>
                <td><el-select size="mini" v-model="studentsBySchoolOptions.start_semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr>
                <th>End Semester</th>
                <td><el-select size="mini" v-model="studentsBySchoolOptions.end_semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runStudentsBySchoolReport">Run Report</el-button> <el-checkbox v-model="studentsBySchoolOptions.csvFormat">CSV format?</el-checkbox></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Selects all students for the chosen school (or all schools) for the semester(s) chosen, where the students status for the period chosen is NOT 'inactive' or 'expired'.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsByCourseInstructorOptions = !showStudentsByCourseInstructorOptions">Students by Course Instructor</a>
            <div v-if="showStudentsByCourseInstructorOptions">
              <table><tbody>
              <tr>
                <th>Semester:</th>
                <td><el-select size="mini" v-model="studentsByCourseInstructorOptions.semester_id"><el-option v-for="semester in semestersWithNext" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsByCourseInstructorOptions)">Run Report</el-button> <el-checkbox v-model="studentsByCourseInstructorOptions.csvFormat">CSV format?</el-checkbox></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For the chosen semester, choosens students with one of the following statuses: ('registered', 'one_doc_submitted', 'coordinator_review', 'psychologist_review', 'director_review', 'pending_orientation', 'guest_registered_temporary')<br>Then groups students by course and instructor.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsWithLdAdhdDisabilitiesOptions = !showStudentsWithLdAdhdDisabilitiesOptions">Students with LD or AD/HD disabilities</a>
            <div v-if="showStudentsWithLdAdhdDisabilitiesOptions">
              <table><tbody>
              <tr>
                <th>Primary Sort order:</th>
                <td><el-select size="mini" v-model="studentsWithLdAdhdDisabilitiesOptions.primary_order"><el-option v-for="option in sortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Secondary Sort order:</th>
                <td><el-select size="mini" v-model="studentsWithLdAdhdDisabilitiesOptions.secondary_order"><el-option v-for="option in sortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsWithLdAdhdDisabilitiesOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Chooses students who are enrolled this term with LD or AD/HD. Orders the report per chosen options.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsWithVisualDisabilitiesOptions = !showStudentsWithVisualDisabilitiesOptions">Students with Visual disabilities</a>
            <div v-if="showStudentsWithVisualDisabilitiesOptions">
              <table><tbody>
              <tr>
                <th>Primary Sort order:</th>
                <td><el-select size="mini" v-model="studentsWithVisualDisabilitiesOptions.primary_order"><el-option v-for="option in sortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Secondary Sort order:</th>
                <td><el-select size="mini" v-model="studentsWithVisualDisabilitiesOptions.secondary_order"><el-option v-for="option in sortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Start Semester</th>
                <td><el-select size="mini" v-model="studentsWithVisualDisabilitiesOptions.start_semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr>
                <th>End Semester</th>
                <td><el-select size="mini" v-model="studentsWithVisualDisabilitiesOptions.end_semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsWithVisualDisabilitiesOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Chooses students who are enrolled in one of the chosen semesters with Visual disabilities. Orders the report per chosen options.
          <br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/report/time-in-temporary-status'">Students by Time in Review Status</a></div></td>
        <td width="50%">For students who are currently in the following statuses: (coordinator_review psychologist_review director_review pending_orientation).<br>Groups students by status and sorts into sub-groups who have been in the status for '3-5 days', '6-10 days' & '10+ days'</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><div class="link_with_csv"><a :href="'/report/athletes'">Student Athletes</a><span class="csv">(<a :href="'api/report/student-athletes.xlsx'" target="_blank">CSV</a>)</span></div></div></td>
        <td width="50%">A list of student athletes<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><div class="link_with_csv"><a :href="'/student/groups/?id=102'">Registered - Temporary and enrolled for current or future terms</a><span class="csv">(<a :href="'/api/student/groups-download.xlsx?id=102'" target="_blank">CSV</a>)</span></div></div></td>
        <td width="50%">All students in status 'registered' with eligibility 'temporary' and enrolled in current or future term<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><div class="link_with_csv"><a :href="'/student/groups/?id=158'">Undergraduate Students (Excludes Nursing)</a><span class="csv">(<a :href="'/api/student/groups-download.xlsx?id=158'" target="_blank">CSV</a>)</span></div></div></td>
        <td width="50%">All active and enrolled students reported as undergraduates based on registration and standing, except those who school is reported by SIS as 'SCHOOL OF NURSING: UNDERGRADUATE'<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><div class="link_with_csv"><a :href="'/student/groups/?id=159'">Expired students who may need to be re-activated</a><span class="csv">(<a :href="'/api/student/groups-download.xlsx?id=159'" target="_blank">CSV</a>)</span></div></div></td>
        <td width="50%">All students who were once registered for tenure, then were expired, and are enrolled in classes for the current semester.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/report/probably-graduated-students'">Students who have probably graduated and may need to be expired</a></div></td>
        <td width="50%">Students with expected graduation dates, program enrollment dates, and registrations all in the past.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/report/students-by-registration-date'">Students who first registered with Columbia as undergraduates in a particular term</a></div></td>
        <td width="50%">Students who enrolled as undergraduates.<br>They may be graduate students now, and it's possible that students without graduation dates here have actually graduated, but the information hasn't been updated from SIS.<br><a @click="showStudentsByRegistrationDateMore = !showStudentsByRegistrationDateMore">{{ showStudentsByRegistrationDateMore ? 'Less' : 'More' }}</a><div v-if="showStudentsByRegistrationDateMore"><br>Also, students who have graduation dates may still be enrolled at Columbia as grad students or in a different program.<br><br>A csv version is available.</div><br></td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/report/nys-enrollment-report'">NYS Enrollment Report</a></div></td>
        <td width="50%">Provides counts of disabilities belonging to undergraduate students enrolled during the indicated term</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><div class="link_with_csv"><a :href="'/report/students-semester-eligibility-disability'">Disabilities by semester, school, and eligibility</a><span class="csv">(<a :href="'/api/report/disabilities-semester-school-eligibility.xlsx'" target="_blank">CSV</a>)</span></div></div></td>
        <td width="50%">Finds all students for the selected semester. Then groups and counts students by school, status and primary disability<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsByOdsRegistrationDateOptions = !showStudentsByOdsRegistrationDateOptions">Student Counts by ODS Registration Date and Current Status</a>
            <div v-if="showStudentsByOdsRegistrationDateOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="studentsByOdsRegistrationDateOptions.from_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="studentsByOdsRegistrationDateOptions.to_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsByOdsRegistrationDateOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Students registered with ODS between start and end date, grouped and totaled by status value.<br>Individual students are listed for each group, and are clickable leading to the student record.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsWithoutContactNotesOptions = !showStudentsWithoutContactNotesOptions">Students without Contact Notes or Individual Emails</a>
            <div v-if="showStudentsWithoutContactNotesOptions" class="toggled">
              <div class="link_with_csv">
                <a :href="'/report/students-without-contact-notes?semester=current'">Current Term</a>
                <span class="csv">(<a :href="'/api/report/students-without-contact-notes.xlsx?semester=current'" target="_blank">CSV</a>)</span> |
                <div class="link_with_csv">
                  <a :href="'/report/students-without-contact-notes?semester=previous'">Current and Previous Term</a>
                  <span class="csv">(<a :href="'/api/report/students-without-contact-notes.xlsx?semester=previous'" target="_blank">CSV</a>)</span>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td width="50%">Students who've had no individual contact notes or individual emails added to their file since the start of the term chosen<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsByGraduationYearOptions = !showStudentsByGraduationYearOptions">Students by School and Graduation Year</a>
            <div v-if="showStudentsByGraduationYearOptions">
              These students are currently registered or were registered in the past with ODS.
              <table width="100%"><tbody>
              <tr>
                <th>Choose a school:</th>
                <td><el-select size="mini" v-model="studentsByGraduationYearOptions.school" style="width:100%;"><el-option v-for="school in schools" :key="school" :value="school" :label="school"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsByGraduationYearOptions)">Run Report</el-button> <el-checkbox v-model="studentsByGraduationYearOptions.csvFormat">CSV format?</el-checkbox></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">A report containing all students from the chosen school who were ever registered with ODS since 2007.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showCaseloadByDateOptions = !showCaseloadByDateOptions">Caseload by Date</a>
            <div v-if="showCaseloadByDateOptions">
              <table><tbody>
              <tr>
                <th>Choose a date:</th>
                <td><el-date-picker type="date" size="mini" v-model="caseloadByDateOptions.date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(caseloadByDateOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For the chosen date, display a count of the students in the following statuses: ('new_student', 'inactive', 'alumnus_or_guest', 'applicant', 'prospective', 'ha_only', 'one_doc_submitted', 'pending_assignment', 'coordinator_review', 'psychologist_review', 'director_review', 'denied', 'pending_orientation', 'registered', 'expired')</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showExamStudentsBySchoolOptions = !showExamStudentsBySchoolOptions">Exam Students by School</a>
            <div v-if="showExamStudentsBySchoolOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="examStudentsBySchoolOptions.from_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="examStudentsBySchoolOptions.to_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(examStudentsBySchoolOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For each student who has one or more exams between the chosen dates, count the total exams for that student.<br><br>CSV only</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStatusDataByDateOptions = !showStatusDataByDateOptions">Status Data by Date</a>
            <div v-if="showStatusDataByDateOptions">
              <table><tbody>
              <tr>
                <th>Date:</th>
                <td><el-select size="mini" v-model="statusDataByDateOptions.date"><el-option v-for="option in  statusDataByDateDropdownOptions" :key="option" :label="formatDate(option)" :value="option"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(statusDataByDateOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Status data page by date.</td>
      </tr>
      </tbody></table>

      <h2>Accommodations</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showAccommodationLetterTrackingOptions = !showAccommodationLetterTrackingOptions">Accommodation Letter Tracking</a>
            <div v-if="showAccommodationLetterTrackingOptions">
              <table><tbody>
              <tr>
                <th>Coordinator:</th>
                <td><el-select size="mini" v-model="accommodationLetterTrackingOptions.coordinator_id"><el-option v-for="coordinator in coordinators" :key="coordinator.id" :value="coordinator.id == 'All' ? 'all' : coordinator.id" :label="coordinator.name"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Semester:</th>
                <td><el-select size="mini" v-model="accommodationLetterTrackingOptions.semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Secondary sort order:</th>
                <td><el-select size="mini" v-model="accommodationLetterTrackingOptions.secondary_order"><el-option v-for="option in accommodationLetterSortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(accommodationLetterTrackingOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%"></td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showStudentsByAccommodationsOptions = !showStudentsByAccommodationsOptions">Students by Accommodation</a>
            <div v-if="showStudentsByAccommodationsOptions">
              <table><tbody>
              <tr>
                <th>Choose an accommodation type or category:</th>
                <td><el-select popper-class="accommodations-dropdown" size="mini" v-model="studentsByAccommodationsOptions.option" style="max-width:600px">
                  <el-option value="All" label="All"></el-option>
                  <el-option v-for="accommodation in accommodationTypeOptions" :key="accommodation" :label="accommodation" :value="accommodation"></el-option>
                  </el-select>
                </td>
              </tr>
              <tr>
                <th>Secondary sort order:</th>
                <td><el-select size="mini" v-model="studentsByAccommodationsOptions.secondary_order"><el-option v-for="option in studentsByAccommodationSortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(studentsByAccommodationsOptions)">Run Report</el-button>  <el-checkbox v-model="studentsByAccommodationsOptions.csvFormat">CSV format?</el-checkbox></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For all accommodation types, select students who are enrolled this term who have that accommodation<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/api/report/testing-accommodations.xlsx'" target="_blank">Testing accommodations CSV</a></div></td>
        <td width="50%">For each student enrolled this term, display a summary of their testing accommodations.<br><br>CSV only.</td>
      </tr>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/api/report/testing-courses.xlsx'" target="_blank">Testing accommodations: courses and forms CSV</a> (takes ~1min)</div></td>
        <td width="50%">An overview of courses taken by currently registered and enrolled students with testing accommodations<br><br>CSV only.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showNoteTakingSupportOptions = !showNoteTakingSupportOptions">Note Taking Support CSV</a>
            <div v-if="showNoteTakingSupportOptions">
              <table><tbody>
              <tr>
                <th>Semester:</th>
                <td><el-select size="mini" v-model="noteTakingSupportOptions.semester_id"><el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(noteTakingSupportOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Every active note taking service in selected semester, including columns for each week of dropoff and pickup.<br><br>CSV only.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showExtendedAccommodationOptions = !showExtendedAccommodationOptions">Extended Accommodation Analysis CSV</a>
            <div v-if="showExtendedAccommodationOptions">
              <table><tbody>
              <tr>
                <th>Date:</th>
                <td><el-date-picker type="date" size="mini" v-model="extendedAccommodationOptions.date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(extendedAccommodationOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%"></td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showMasterListOptions = !showMasterListOptions">Master List - No Identifiers</a>
            <div v-if="showMasterListOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="masterListOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="masterListOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(masterListOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">A summary of exams between the chosen dates without student identifiers.<br><br>A csv version is available.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showExamsPerTimePeriodOptions = !showExamsPerTimePeriodOptions">Exams per time period</a>
            <div v-if="showExamsPerTimePeriodOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="examsPerTimePeriodOptions.startDate" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="examsPerTimePeriodOptions.endDate" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>Starting between</th>
                <td><el-select size="mini" v-model="examsPerTimePeriodOptions.startTime">
                  <el-option v-for="time in times" :key="time.value" :value="time.value" :label="time.display"></el-option>

                </el-select></td>
              </tr>
              <tr>
                <th>and</th>
                <td><el-select size="mini" v-model="examsPerTimePeriodOptions.endTime">
                  <el-option v-for="time in times" :key="time.value" :value="time.value" :label="time.display"></el-option>
                </el-select></td>
              </tr>
              <tr>
                <td colspan="2">
                  <el-button size="mini" type="primary" @click="runExamsPerTimePeriodReport">Run Report</el-button>
                </td>
              </tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Displays a count of the number of exams between the chosen dates and time period</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showExamsPerDayOptions = !showExamsPerDayOptions">Exams per day (CSV)</a>
            <div v-if="showExamsPerDayOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="examsPerDayOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="examsPerDayOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(examsPerDayOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">A count of the day exams, evenings exams and total exams per day between the chosen dates.<br><br>CSV only.</td>
      </tr>
      </tbody></table>

      <h2>Coordinator overviews</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%"><div class="report-div"><a :href="'/report/caseload'">Current Caseload by Coordinator</a></div></td>
        <td width="50%">For all coordinators, list the number of cases in the following tables:<br><br><a @click="showCurrentCaseloadByCoordinatorMore = !showCurrentCaseloadByCoordinatorMore">{{ showCurrentCaseloadByCoordinatorMore ? 'Less' : 'More' }}</a><div v-if="showCurrentCaseloadByCoordinatorMore">1) Total cases<br>2) Total cases per expected graduation year<br>3) Total cases per ODS status<br>4) Total cases per Disability<br>5) Total cases per School</div></td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showContactNoteStatisticsOptions = !showContactNoteStatisticsOptions">Contact Note Statistics</a>
            <div v-if="showContactNoteStatisticsOptions" class="toggled">
              <a :href="'/report/contact-notes?period=current_month'">Current Month</a> |
              <a :href="'/report/contact-notes?period=current_term'">Current Term</a> |
              <a :href="'/report/contact-notes?period=current_academic_year'">Current Academic Year</a> |
              <a :href="'/report/contact-notes?period=all'">All</a>
            </div>
          </div>
        </td>
        <td width="50%">For the chosen time period, display total contact notes, total contact notes per contact note status and total contact notes per coordinator.</td>
      </tr>
      </tbody></table>

      <h2>Contact Notes</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showContactNotesByReasonForContact = !showContactNotesByReasonForContact">Contact Notes by Reason for Contact</a>
            <div v-if="showContactNotesByReasonForContact">
              <table><tbody>
              <tr>
                <th>Reason for Contact:</th>
                <td><el-select size="mini" v-model="contactNotesByReasonForContactOptions.contact_reason" @change="onReasonForContactChange"><el-option label="All" value="All"></el-option><el-option v-for="reason in reasonsForContact" :key="reason" :label="reason" :value="reason"></el-option></el-select></td>
              </tr>
              <tr v-if="contactNotesByReasonForContactOptions.contact_reason == 'Accommodations'">
                <th>Accommodations:</th>
                <td><el-select size="mini" v-model="contactNotesByReasonForContactOptions.accommodation"><el-option label="All" value="All"></el-option><el-option v-for="accommodation in contactNoteAccommodationTypes" :key="accommodation" :label="accommodation" :value="accommodation"></el-option></el-select></td>
              </tr>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="contactNotesByReasonForContactOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="contactNotesByReasonForContactOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(contactNotesByReasonForContactOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For the chosen time period, display a table of contact notes grouped by reason for contact</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showContactNotesByMonthOptions = !showContactNotesByMonthOptions">Contact Notes by Month and User</a>
            <div v-if="showContactNotesByMonthOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="contactNotesByMonthOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="contactNotesByMonthOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(contactNotesByMonthOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For the chosen time period, display a table of contact notes by month, user and status. Minimum Start = July 2017</td>
      </tr>
      </tbody></table>

      <h2>Intake Notes</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/intake-notes?semester_id=' + currentSemesterId">Intake Notes</a>
          </div>
        </td>
        <td width="50%">Show all academic-side intake notes</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/housing-intake-notes?semester_id=' + currentSemesterId">Housing Intake Notes</a>
          </div>
        </td>
        <td width="50%">Show all housing-side intake notes</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/case-management-notes?semester_id=' + currentSemesterId">Case Management Notes</a>
          </div>
        </td>
        <td width="50%">Show all case management notes</td>
      </tr>
      </tbody></table>



      <h2>Housing Reports</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/housing-accommodations'">Housing Accommodations</a>
          </div>
        </td>
        <td width="50%">Housing accommodations report</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/document/ha-decision-letter/'">HA Decision Letter Email</a>
          </div>
        </td>
        <td width="50%">Housing Accommodation decision letters by date</td>
      </tr>
      </tbody></table>

      <h2>Doris internal reports</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showFolderReviewOptions = !showFolderReviewOptions">Folder review verification process</a>
            <div v-if="showFolderReviewOptions">
              <table><tbody>
              <tr>
                <th>Primary Sort order:</th>
                <td><el-select size="mini" v-model="folderReviewOptions.primary_order"><el-option v-for="option in folderReviewSortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr>
                <th>Secondary Sort order:</th>
                <td><el-select size="mini" v-model="folderReviewOptions.secondary_order"><el-option v-for="option in folderReviewSortOrders" :key="option" :value="option" :label="option"></el-option></el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(folderReviewOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">This is a list of all active and expired students in Doris. It was used to verify the first major historical data import</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/sis-integration'">SIS integration audit</a>
          </div>
        </td>
        <td width="50%">A summary of students for which no SIS data has been received</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showDatabaseChangeHistoryOptions = !showDatabaseChangeHistoryOptions">Database change history</a>
            <div v-if="showDatabaseChangeHistoryOptions">
              <table><tbody>
              <tr>
                <th>Type of change to display:</th>
                <td>
                  <el-select size="mini" v-model="databaseChangeHistoryOptions.type_of_change" style="width:250px;">
                    <el-option v-for="option in databaseChangeOptions" :key="option.value" :value="option.value" :label="option.display"></el-option>
                  </el-select>
                </td>
              </tr>
              <tr>
                <th>Number of results to display:</th>
                <td><el-select size="mini" v-model="databaseChangeHistoryOptions.limit">
                  <el-option :value="100" label="100"></el-option>
                  <el-option :value="500" label="500"></el-option>
                  <el-option :value="1000" label="1000"></el-option>
                </el-select></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(databaseChangeHistoryOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Displays a list of changes in the doris database per the chosen filter</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/report/notifications-catalog'">Catalog of automatic change notifications</a>
          </div>
        </td>
        <td width="50%">A list of the changes to students that Doris can notify other users of.</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showProctorHoursOptions = !showProctorHoursOptions">Proctor Hours Report</a>
            <div v-if="showProctorHoursOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="proctorHoursOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="proctorHoursOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(proctorHoursOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">For each day in the chosen time period, display the hours that a proctor is on duty.<br><br>Csv option available</td>
      </tr>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a @click="showProctorSessionsAnalysisOptions = !showProctorSessionsAnalysisOptions">Proctor Sessions Analysis</a>
            <div v-if="showProctorSessionsAnalysisOptions">
              <table><tbody>
              <tr>
                <th>From:</th>
                <td><el-date-picker type="date" size="mini" v-model="proctorSessionsAnalysisOptions.start_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr>
                <th>To:</th>
                <td><el-date-picker type="date" size="mini" v-model="proctorSessionsAnalysisOptions.end_date" value-format="yyyy-MM-dd"></el-date-picker></td>
              </tr>
              <tr><td colspan="2"><el-button size="mini" type="primary" @click="runReport(proctorSessionsAnalysisOptions)">Run Report</el-button></td></tr>
              </tbody></table>
            </div>
          </div>
        </td>
        <td width="50%">Proctor Sessions Analysis<br><br>Csv option available</td>
      </tr>
      <tr v-if="canViewAdminOnlyReports">
        <td width="50%">
          <div class="report-div">
            <div class="link_with_csv">
              <a :href="'/report/part-and-half-time-students'">Part and Half Time Students</a>
              <span class="csv">(<a :href="'api/report/part-and-half-time-students.xlsx'" target="_blank">CSV</a>)</span>
            </div>
          </div>
        </td>

        <td width="50%">Part and Half Time Students Report<br><br>Csv option available</td>
      </tr>
      <tr v-if="canViewAdminOnlyReports">
        <td width="50%">
          <div class="report-div">
            <div class="link_with_csv">
              <a :href="'/report/quizzes-and-reschedules'">Quizzes and Rescheduled Exams</a>
              <span class="csv">(<a :href="'api/report/quizzes-and-reschedules.xlsx'" target="_blank">CSV</a>)</span>
            </div>
          </div>
        </td>

        <td width="50%">Quizzes and Rescheduled Exams Report<br><br>Csv option available</td>
      </tr>
      <tr v-if="canViewAdminOnlyReports">
        <td width="50%">
          <div class="report-div">
            <div class="link_with_csv">
              <a :href="'/report/contact-notes-with-ls-and-as'">Contact Notes With LS and AS</a>
              <span class="csv">(<a :href="'api/report/contact-notes-with-ls-and-as.xlsx'" target="_blank">CSV</a>)</span>
            </div>
          </div>
        </td>

        <td width="50%">Contact Notes With LS and AS Report<br><br>Csv option available</td>
      </tr>
      <tr v-if="canViewAdminOnlyReports">
        <td width="50%">
          <div class="report-div">
            <div class="link_with_csv">
              <a :href="'/report/tech-exams-breakdown'">Tech Exams Breakdown</a>
              <span class="csv">(<a :href="'api/report/tech-exams-breakdown.xlsx'" target="_blank">CSV</a>)</span>
            </div>
          </div>
        </td>

        <td width="50%">Tech Exams Breakdown Report<br><br>Csv option available</td>
      </tr>
      </tbody></table>

      <h2>Group Emails</h2>
      <table width="90%" class="report-table"><tbody>
      <tr>
        <td width="50%">
          <div class="report-div">
            <a :href="'/group-email/sent'">Sent Emails</a>
          </div>
        </td>
        <td width="50%">Takes you to page one of the sent group emails</td>
      </tr>
      </tbody></table>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    metaInfo () {
      return {
        title: "Reports"
      }
    },
    components: {
    },
    data () {
      return {
        canViewAdminOnlyReports: false,
        currentSemesterId: 0,
        coordinators: [],
        statuses: [],
        schools: [],
        semesters: [],
        semestersWithNext: [],
        academicYears: [],
        accommodationLetterSortOrders: [],
        accommodationTypeOptions: [],
        databaseChangeOptions: [],
        folderReviewSortOrders: [],
        reasonsForContact: [],
        sortOrders: [],
        statusDataByDateDropdownOptions: [],
        studentsByAccommodationSortOrders: [],
        studentsBySchoolSortOptions: [],
        contactNoteAccommodationTypes: [],
        times: [{display: '6:00 am', value: '6:00'}, {display: '7:00 am', value: '7:00'}, {display: '8:00 am', value: '8:00'}, {display: '9:00 am', value: '9:00'}, {display: '10:00 am', value: '10:00'}, {display: '11:00 am', value: '11:00'}, {display: '12:00 pm', value: '12:00'}, {display: '1:00 pm', value: '13:00'}, {display: '2:00 pm', value: '14:00'}, {display: '3:00 pm', value: '15:00'}, {display: '4:00 pm', value: '16:00'}, {display: '5:00 pm', value: '17:00'}, {display: '6:00 pm', value: '18:00'}, {display: '7:00 pm', value: '19:00'}, {display: '8:00 pm', value: '20:00'}, {display: '9:00 pm', value: '21:00'}, {display: '10:00 pm', value: '22:00'}, {display: '11:59 pm', value: '23:59'}],
        //Student Reports
        showStudentsBySchoolOptions: false,
        studentsBySchoolOptions: {
          url: 'students-by-school',
          schools: [],
          allSchools: true,
          statuses: [],
          allStatuses: true,
          secondary_sort: 'Name',
          start_semester_id: null,
          end_semester_id: null,
          csvFormat: false
        },
        showStudentsByCourseInstructorOptions: false,
        studentsByCourseInstructorOptions: {
          url: 'students-by-course-instructor',
          semester_id: null,
          csvFormat: false
        },
        showStudentsWithLdAdhdDisabilitiesOptions: false,
        studentsWithLdAdhdDisabilitiesOptions: {
          url: 'students-with-ldhd-or-add',
          primary_order: 'Name',
          secondary_order: 'Name'
        },
        showStudentsWithVisualDisabilitiesOptions: false,
        studentsWithVisualDisabilitiesOptions: {
          url: 'students-with-visual',
          primary_order: 'Name',
          secondary_order: 'Name',
          start_semester_id: null,
          end_semester_id: null
        },
        showStudentsByRegistrationDateMore: false,
        showStudentsByOdsRegistrationDateOptions: false,
        studentsByOdsRegistrationDateOptions: {
          url: 'status-student-counts',
          from_date: moment(new Date()).format('YYYY-MM-DD'),
          to_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showStudentsWithoutContactNotesOptions: false,
        showStudentsByGraduationYearOptions: false,
        studentsByGraduationYearOptions: {
          url: 'students-by-graduation-year-and-school',
          school: 'None Specified',
          csvFormat: false
        },
        showCaseloadByDateOptions: false,
        caseloadByDateOptions: {
          url: 'caseload-by-date',
          date: moment(new Date()).format('YYYY-MM-DD')
        },
        showExamStudentsBySchoolOptions: false,
        examStudentsBySchoolOptions: {
          url: 'exam-students-by-school',
          from_date: moment(new Date()).format('YYYY-MM-DD'),
          to_date: moment(new Date()).format('YYYY-MM-DD'),
          csvFormat: true
        },
        showStatusDataByDateOptions: false,
        statusDataByDateOptions: {
          url: 'status-data-by-date',
          date: null
        },
        //Accommodation Reports
        showAccommodationLetterTrackingOptions: false,
        accommodationLetterTrackingOptions: {
          url: 'letter-tracking',
          coordinator_id: 'all',
          semester_id: null,
          secondary_order: 'Student Name'
        },
        showStudentsByAccommodationsOptions: false,
        studentsByAccommodationsOptions: {
          url: 'students-by-accommodation',
          option: 'All',
          secondary_order: 'Name',
          csvFormat: false
        },
        showNoteTakingSupportOptions: false,
        noteTakingSupportOptions: {
          url: 'note-taking-support',
          semester_id: null,
          csvFormat: true
        },
        showExtendedAccommodationOptions: false,
        extendedAccommodationOptions: {
          url: 'extended-accommodation-analysis',
          date: moment(new Date()).format('YYYY-MM-DD'),
          csvFormat: true
        },
        showMasterListOptions: false,
        masterListOptions: {
          url: 'master-list-no-identifiers',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showExamsPerTimePeriodOptions: false,
        examsPerTimePeriodOptions: {
          startDate: moment(new Date()).format('YYYY-MM-DD'),
          endDate: moment(new Date()).format('YYYY-MM-DD'),
          startTime: '6:00 am',
          endTime: '11:59 pm'
        },
        showExamsPerDayOptions: false,
        examsPerDayOptions: {
          url: 'exams-per-day',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD'),
          csvFormat: true
        },
        showCurrentCaseloadByCoordinatorMore: false,
        showContactNoteStatisticsOptions: false,
        showContactNotesByReasonForContact: false,
        contactNotesByReasonForContactOptions: {
          url: 'contact-note-by-reason',
          contact_reason: 'All',
          accommodation: null,
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showContactNotesByMonthOptions: false,
        contactNotesByMonthOptions: {
          url: 'contact-note-by-month-and-user',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showFolderReviewOptions: false,
        folderReviewOptions: {
          url: 'verification',
          primary_order: 'Name',
          secondary_order: 'Name'
        },
        showDatabaseChangeHistoryOptions: false,
        databaseChangeHistoryOptions: {
          url: 'change-history',
          type_of_change: 'expire',
          limit: 100
        },
        showProctorHoursOptions: false,
        proctorHoursOptions: {
          url: 'proctor-hours-report',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showProctorSessionsAnalysisOptions: false,
        proctorSessionsAnalysisOptions: {
          url: 'proctor-sessions-analysis',
          start_date: moment(new Date()).format('YYYY-MM-DD'),
          end_date: moment(new Date()).format('YYYY-MM-DD')
        },
        showPartAndHalfTimeStudentsOptions: false,
        partAndHalfTimeStudentsOptions: {
          url: 'part-and-half-time-students',
          academic_year: null
        },
        totalExams: null,
        totalExamLocations: null,
        totalExamDays: null
      }
    },
    methods: {
      load: function(){
        this.getCurrentSemester();
        this.getReportOptions();
      },
      getCurrentSemester: function(){
        axios.get('/api/simple/get-current-semester')
          .then(response => {
            if(response.data.success){
              this.currentSemesterId = response.data.semester.id;
              this.studentsBySchoolOptions.start_semester_id = this.currentSemesterId;
              this.studentsBySchoolOptions.end_semester_id = this.currentSemesterId;
              this.studentsWithVisualDisabilitiesOptions.start_semester_id = this.currentSemesterId;
              this.studentsWithVisualDisabilitiesOptions.end_semester_id = this.currentSemesterId;
              this.studentsByCourseInstructorOptions.semester_id = this.currentSemesterId;
              this.accommodationLetterTrackingOptions.semester_id = this.currentSemesterId;
              this.noteTakingSupportOptions.semester_id = this.currentSemesterId;
            }
          });
      },
      getReportOptions: function() {
        axios.get('/api/report/report-options/')
          .then(response => {
            if(response.data.success){
              this.statuses = response.data.statuses;
              this.schools = response.data.schools;
              this.semesters = response.data.semesters;
              this.semestersWithNext = response.data.semestersWithNext;
              this.academicYears = response.data.academicYears;
              this.studentsBySchoolSortOptions = response.data.students_by_school_options;
              this.folderReviewSortOrders = response.data.folder_review_options;
              this.accommodationLetterSortOrders = response.data.letter_tracking_options;
              this.studentsByAccommodationSortOrders = response.data.students_by_accommodation_options;
              this.sortOrders = response.data.ld_adhd_options;
              this.statusDataByDateDropdownOptions = response.data.status_date_snapshot_dates;
              this.statusDataByDateOptions.date = this.statusDataByDateDropdownOptions[0];
              this.coordinators = response.data.coordinators;
              this.accommodationTypeOptions = response.data.accommodation_options;
              this.reasonsForContact = response.data.contact_note_reasons;
              this.contactNoteAccommodationTypes = response.data.contact_note_accommodation_types;
              this.databaseChangeOptions = response.data.database_change_options;
              this.canViewAdminOnlyReports = response.data.can_view_admin_only_reports;
            }
          });
      },
      onStudentsBySchoolAllChange: function(val){
        if(val){
          this.studentsBySchoolOptions.schools = [];
        }
      },
      onStudentsBySchoolCheckboxGroupChange: function(){
        if(this.studentsBySchoolOptions.allSchools && this.studentsBySchoolOptions.schools.length){
          this.studentsBySchoolOptions.allSchools = false;
        }
      },
      onStudentsBySchoolAllStatusChange: function(val){
        if(val){
          this.studentsBySchoolOptions.statuses = [];
        }
      },
      onStudentsBySchoolCheckboxGroupStatusChange: function(){
        if(this.studentsBySchoolOptions.allStatuses && this.studentsBySchoolOptions.statuses.length){
          this.studentsBySchoolOptions.allStatuses = false;
        }
      },
      formatDate: function(val){
        if(val){
          return moment(val).format('MM/DD/YYYY')
        }
      },
      runReport: function(reportOptions){
        var url = '/report/' + reportOptions.url;

        if(reportOptions.csvFormat){
          url = '/api/report/' + reportOptions.url + '.xlsx';
        }

        var first = true;
        for(var key in reportOptions){
          if(!reportOptions[key] || key == 'url' || key == 'csvFormat'){
            continue;
          }
          if(first){
            url += '?';
            first = false;
          } else {
            url += '&';
          }
          url += key + '=' + reportOptions[key];
        }

        if(reportOptions.csvFormat){
          window.open(url, '_blank');
        } else {
          this.$router.push({ path: url });
        }
      },
      runStudentsBySchoolReport: function(){
        var url = '/report/' + this.studentsBySchoolOptions.url;

        if(this.studentsBySchoolOptions.csvFormat){
          url = '/api/report/' + this.studentsBySchoolOptions.url + '.xlsx';
        }

        var first = true;
        for(var key in this.studentsBySchoolOptions){
          if(!this.studentsBySchoolOptions[key] || key == 'url' || key == 'csvFormat'  || key == 'allSchools' || key == 'allStatuses'){
            continue;
          }
          if(first){
            url += '?';
            first = false;
          } else {
            url += '&';
          }

          if(key === 'schools' || key === 'statuses'){
            if(this.studentsBySchoolOptions[key].length){
              for(var i = 0; i < this.studentsBySchoolOptions[key].length; i++){
                if(i > 0){
                  url += '&';
                }
                url += key + '=' + encodeURIComponent(this.studentsBySchoolOptions[key][i]);
              }
            } else {
              if(key == 'schools'){
                url += 'schools=All';
              } else {
                url += 'statuses=All';
              }
            }
          } else {
            url += key + '=' + this.studentsBySchoolOptions[key];
          }
        }

        if(this.studentsBySchoolOptions.csvFormat){
          window.open(url, '_blank');
        } else {
          this.$router.push({ path: url });
        }
      },
      runExamsPerTimePeriodReport: function(){
        const reportUrl = "/api/report/exams-per-time-period.xlsx?start_date="+this.examsPerTimePeriodOptions.startDate+
        "&end_date="+this.examsPerTimePeriodOptions.endDate+
            "&start_time="+this.examsPerTimePeriodOptions.startTime+
            "&end_time="+this.examsPerTimePeriodOptions.endTime;
        window.open(reportUrl, '_blank');
      },
      onReasonForContactChange: function(val){
        if(val == 'Accommodations'){
          this.contactNotesByReasonForContactOptions.accommodation = 'All';
        } else {
          this.contactNotesByReasonForContactOptions.accommodation = null;
        }
      }
    },
    created () {
      this.load();
    }
  }
</script>

<style>

  .el-select-dropdown.el-popper.accommodations-dropdown {
    max-width:100% !important;
  }

</style>

<style scoped>
  li > .link_with_csv { width: 40em; position: relative; }
  li > .link_with_csv > .csv { display: block; position: absolute; right: 0; top: 0;}
  div > .link_with_csv { display: inline; }
  span.csv {float: right; margin-right: 140px;}

  .report-div { position: relative; }
  .report-description-link { position: absolute; right: 0px; top: 0px;}

  ul.report-list {
    width: 750px;
  }

  .report-table tr:nth-child(2n) {background: #e2d9e8}
  .report-table tr td {padding: 10px;}


  /*.el-select-dropdown.el-popper.accommodations-dropdown{*/
  /*  left: 0px !important;*/
  /*}*/
</style>
