<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getCourses">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>E-Text Courses by Department</h1>
            <etext-service-nav></etext-service-nav>
            <br clear="all">
            <el-table :default-sort="{prop: 'department', order: 'ascending'}" :data="courses">
                <el-table-column label="Department Code" prop="department" sortable :sort-orders="['ascending', 'descending']" width="275"></el-table-column>
                <el-table-column label="Course Number" prop="course_number" sortable :sort-orders="['ascending', 'descending']" width="275"></el-table-column>
                <el-table-column label="Course Section" prop="section_code" sortable :sort-orders="['ascending', 'descending']" width="275"></el-table-column>
                <el-table-column label="Course Title" prop="class_name" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <a :href="'/etext-service/show/' + scope.row.id">{{ scope.row.class_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="# Students" prop="num_students" sortable :sort-orders="['ascending', 'descending']" width="275"></el-table-column>
            </el-table>
            <p v-if="total">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "E-Text Courses by Department"
            }
        },
        components: {
        },
        data () {
            return {
                courses: [],
                semesters: [],
                semesterId: null,
                total: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getCourses();
                    });
            },
            getCourses: function() {
                axios.get('/api/etext-service/courses-by-department/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.courses = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
