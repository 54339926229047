<template>
    <div id="container">
<div id="content">
            <h1>Students by Time in Review Status</h1>
            <p>(<b>NOTE:</b> Figures in the "Days" columns do not include Saturdays and Sundays, but may include school holidays.)</p>

            <table style="border: medium solid black">
                <colgroup></colgroup>
                <colgroup span="3" style="border-width: 0 medium; border-style: solid; border-color: black;"></colgroup>
                <colgroup span="3" style="border-width: 0 medium; border-style: solid; border-color: black;"></colgroup>
                <colgroup span="3" style="border-width: 0 medium; border-style: solid; border-color: black;"></colgroup>
                <thead>
                    <tr>
                        <th></th>
                        <th colspan="3">3-5 days</th>
                        <th colspan="3">6-10 days</th>
                        <th colspan="3">10+ days</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Student</td>
                        <td>Coordinator</td>
                        <td>Days</td>
                        <td>Student</td>
                        <td>Coordinator</td>
                        <td>Days</td>
                        <td>Student</td>
                        <td>Coordinator</td>
                        <td>Days</td>
                    </tr>
                </thead>
                <template v-for="(statusObj, statusObjIndex) in data">
                    <tbody style="border: medium solid black" :key="statusObjIndex">
                        <tr>
                            <th :rowspan="getMaxArray(statusObj).length + 1">{{ statusObj.status_display }} ({{ statusObj.students ? statusObj.students.length : 0 }} students)</th>
                            <th colspan="3">{{ statusObj.threeToFiveDays.length }} students</th>
                            <th colspan="3">{{ statusObj.sixToTenDays.length }} students</th>
                            <th colspan="3">{{ statusObj.tenPlusDays.length }} students</th>
                        </tr>
                        <tr v-for="(student, index) in getMaxArray(statusObj)" :key="index">
                            <template v-if="statusObj.threeToFiveDays[index]">
                                <td><a :href="'/student/profile/' + statusObj.threeToFiveDays[index].id">{{ statusObj.threeToFiveDays[index].student_name }}</a></td>
                                <td>{{ statusObj.threeToFiveDays[index].coordinator_name }}</td>
                                <td>{{ statusObj.threeToFiveDays[index].days_in_review }}</td>
                            </template>
                            <template v-else><td colspan="3"></td></template>
                            <template v-if="statusObj.sixToTenDays[index]">
                                <td><a :href="'/student/profile/' + statusObj.sixToTenDays[index].id">{{ statusObj.sixToTenDays[index].student_name }}</a></td>
                                <td>{{ statusObj.sixToTenDays[index].coordinator_name }}</td>
                                <td>{{ statusObj.sixToTenDays[index].days_in_review }}</td>
                            </template>
                            <template v-else><td colspan="3"></td></template>
                            <template v-if="statusObj.tenPlusDays[index]">
                                <td><a :href="'/student/profile/' + statusObj.tenPlusDays[index].id">{{ statusObj.tenPlusDays[index].student_name }}</a></td>
                                <td>{{ statusObj.tenPlusDays[index].coordinator_name }}</td>
                                <td>{{ statusObj.tenPlusDays[index].days_in_review }}</td>
                            </template>
                            <template v-else><td colspan="3"></td></template>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Students by Time in Review Status"
            }
        },
        components: {
            },
        data () {
            return {
                data: [],
                loading: null
            }
        },
        methods: {
            load: function() {
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/students-by-time-in-review-status/')
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;

                            for(var i = 0; i < this.data.length; i++){
                                var statusObj = this.data[i];
                                statusObj.threeToFiveDays = [];
                                statusObj.sixToTenDays = [];
                                statusObj.tenPlusDays = [];

                                if(statusObj.students){
                                    for(var j = 0; j < statusObj.students.length; j++){
                                        if(statusObj.students[j].days_in_review <= 5){
                                            statusObj.threeToFiveDays.push(statusObj.students[j]);
                                        } else if(statusObj.students[j].days_in_review <= 10){
                                            statusObj.sixToTenDays.push(statusObj.students[j]);
                                        } else {
                                            statusObj.tenPlusDays.push(statusObj.students[j]);
                                        }
                                    }
                                }
                            }

                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getMaxArray: function(statusObj){
                var maxArray = statusObj.threeToFiveDays;

                if(statusObj.sixToTenDays.length > maxArray.length){
                    maxArray = statusObj.sixToTenDays;
                }

                if(statusObj.tenPlusDays.length > maxArray.length){
                    maxArray = statusObj.tenPlusDays;
                }

                return maxArray;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
