<template>
    <div id="container">
<div id="content">
            <h1>Services</h1>
            <div style="float:left; margin-right:3em">
                <b>Note-taking services</b>
                <ul>
                    <li><a :href="'/note-taking-service/setup'">Add note-taking services</a></li>
                    <li><a :href="'/note-taking-service/unfilled-requests'">Note-taking service requests pending hiring of note-taker</a></li>
                    <li><a :href="'/note-taking-service/recipients'">Current note-taking services by student</a></li>
                    <li><a :href="'/note-taking-service/active-services'">Current note-taking services by course</a></li>
                    <li><a :href="'/note-taking-service/weekly-data'">Dropoffs and pickups</a></li>
                    <li><a :href="'/note-taking-service/note-taker-payroll-status'">Note-taker Payroll Status</a></li>
                    <li><a :href="'/note-taking-service/courses-by-school'">Current Note-taking Courses by Department</a></li>
                    <li><a :href="'/note-taking-service/students-by-school'">Current Note-taking Students by School</a></li>
                    <li><a :href="'/group-email/compose?email_note_taking_related_instructors=true'">Email note-taking related instructors</a></li>
                </ul>
            </div>
            <div style="float:left; margin-right:3em; width:25em;">
                <b>E-text services</b>
                <ul>
                    <li><a :href="'/etext-service/setup'">Add E-text services for eligible students</a></li>
                    <li><a :href="'/etext-service/unfilled-requests'">Pending E-Text requests - Need reading list</a></li>
                    <li><a :href="'/etext-service/active-services'">Current E-Text services - Reading lists received</a></li>
                    <li><a :href="'/etext-service/recipients'">Current E-text services by student</a></li>
                    <li><a :href="'/etext-service/books-by-status'">Media by status</a></li>
                    <li><a :href="'/etext-service/courses-by-school'">Current E-Text Courses by Department</a></li>
                    <li><a :href="'/etext-service/students-by-school'">Current E-Text Students by School</a></li>
                </ul>
            </div>
            <div style="float:left; margin-right:3em; width: 35em;">
                <b>Testing accommodations</b>
                <ul>
                    <li>
                        <a @click="showMasterListDateField = !showMasterListDateField">Master List</a>
                        <div v-if="showMasterListDateField">
                            <table><tbody>
                                <tr>
                                    <th>From:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="masterListStartDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions" ></el-date-picker></td>
                                </tr>
                                <tr>
                                    <th>To:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="masterListEndDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker></td>
                                </tr>
                                <tr>
                                    <th>
                                        First Sort
                                    </th>
                                    <td>
                                        <el-select size="mini" v-model="masterListFirstSort" style="width: 50%">
                                            <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                                        </el-select>
                                        <el-select size="mini" v-model="masterListFirstSortDir" style="width: 50%">
                                            <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                                            <el-option key="DESC" value="DESC" label="Descending"></el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Second Sort
                                    </th>
                                    <td>
                                        <el-select size="mini" v-model="masterListSecondSort" style="width: 50%" :disabled="!masterListFirstSort">
                                            <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                                        </el-select>
                                        <el-select size="mini" v-model="masterListSecondSortDir" style="width: 50%" :disabled="!masterListFirstSort">
                                            <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                                            <el-option key="DESC" value="DESC" label="Descending"></el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Third Sort
                                    </th>
                                    <td>
                                        <el-select size="mini" v-model="masterListThirdSort" style="width: 50%" :disabled="!masterListFirstSort || !masterListSecondSort">
                                            <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                                        </el-select>
                                        <el-select size="mini" v-model="masterListThirdSortDir" style="width: 50%" :disabled="!masterListFirstSort || !masterListSecondSort">
                                            <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                                            <el-option key="DESC" value="DESC" label="Descending"></el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Fourth Sort
                                    </th>
                                    <td>
                                        <el-select size="mini" v-model="masterListFourthSort" style="width: 50%" :disabled="!masterListFirstSort || !masterListSecondSort || !masterListThirdSort">
                                            <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                                        </el-select>
                                        <el-select size="mini" v-model="masterListFourthSortDir" style="width: 50%" :disabled="!masterListFirstSort || !masterListSecondSort || !masterListThirdSort">
                                            <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                                            <el-option key="DESC" value="DESC" label="Descending"></el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"><el-button size="mini" type="primary" @click="showMasterListPage">Submit</el-button></td>
                                </tr>
                            </tbody></table>
                        </div>
                    </li>
                    <li>
                        <a @click="showExamIndexDateField = !showExamIndexDateField">Exam index by course</a>
                        <div v-if="showExamIndexDateField">
                            <table><tbody>
                                <tr>
                                    <th>From:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="examIndexStartDate" value-format="yyyy-MM-dd"></el-date-picker></td>
                                </tr>
                                <tr>
                                    <th>To:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="examIndexEndDate" value-format="yyyy-MM-dd"></el-date-picker></td>
                                </tr>
                                <tr>
                                    <td colspan="2"><el-button size="mini" type="primary" @click="showExamIndexPage">Submit</el-button></td>
                                </tr>
                            </tbody></table>
                        </div>
                    </li>
                    <li>
                    <a @click="showProctoringSessionsDateField = !showProctoringSessionsDateField">Proctoring sessions</a>
                        <div v-if="showProctoringSessionsDateField">
                            <table><tbody>
                                <tr>
                                    <th>From:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="proctoringSessionsStartDate" value-format="yyyy-MM-dd"></el-date-picker></td>
                                </tr>
                                <tr>
                                    <th>To:</th>
                                    <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="proctoringSessionsEndDate" value-format="yyyy-MM-dd"></el-date-picker></td>
                                </tr>
                                <tr>
                                    <td colspan="2"><el-button size="mini" type="primary" @click="showProctoringSessionsPage">Submit</el-button></td>
                                </tr>
                            </tbody></table>
                        </div>
                    </li>
                    <li><a :href="'/proctoring-location'">Proctoring locations</a></li>
                    <li><a :href="'/proctor'">Proctors</a></li>
                    <li><a :href="'/group-email/compose/?email_exam_related_instructors=true'">Email Test-related Instructors</a></li>
                    <li><a :href="'/group-email/compose/?proctor_scheduled_sessions=true'">Email Proctors Scheduled Sessions</a></li>
                    <li v-if="showTodaysEnrollments"><a :href="'/group-email/compose?email_locations_for_today=true'">Email Todays Students exam locations</a></li>
                    <li v-if="showTomorrowsEnrollments"><a :href="'/group-email/compose?email_locations_for_tomorrow=true'">Email Tomorrows Students exam locations</a></li>
                    <li v-if="showMondaysEnrollments"><a :href="'/group-email/compose?email_locations_for_monday=true'">Email Mondays Students exam locations</a></li>
                    <li v-if="showOnDutyProtors"><a :href="'/group-email/compose/?proctors_on_duty_now=true'">Email All Exam Proctors on Duty Now</a></li>

                    <li>
                        <a @click="showExamProctorsDateField = !showExamProctorsDateField">Email Exam Proctors for Date Range</a>
                        <div v-if="showExamProctorsDateField">
                            <table><tbody>
                            <tr>
                                <th>From:</th>
                                <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="examProctorsForDatesStart" value-format="yyyy-MM-dd"></el-date-picker></td>
                            </tr>
                            <tr>
                                <th>To:</th>
                                <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="examProctorsForDatesEnd" value-format="yyyy-MM-dd"></el-date-picker></td>
                            </tr>
                            <tr>
                                <td colspan="2"><el-button size="mini" type="primary" @click="emailExamProctorsForDateRange">Submit</el-button></td>
                            </tr>
                            </tbody></table>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: "Services"
            }
        },
        components: {
            },
        data () {
            return {
                showExamIndexDateField: false,
                examIndexStartDate: moment(new Date()).format('YYYY-MM-DD'),
                examIndexEndDate: moment(new Date()).format('YYYY-MM-DD'),
                showProctoringSessionsDateField: false,
                proctoringSessionsStartDate: moment(new Date()).format('YYYY-MM-DD'),
                proctoringSessionsEndDate: moment(new Date()).format('YYYY-MM-DD'),
                showMasterListDateField: false,
                masterListStartDate: moment(new Date()).format('YYYY-MM-DD'),
                masterListEndDate: moment(new Date()).format('YYYY-MM-DD'),
                showTodaysEnrollments: false,
                showTomorrowsEnrollments: false,
                showMondaysEnrollments: false,
                showOnDutyProtors: false,
                masterListSortOptions: [],
                masterListFirstSort: "",
                masterListFirstSortDir: "ASC",
                masterListSecondSort: "",
                masterListSecondSortDir: "ASC",
                masterListThirdSort: "",
                masterListThirdSortDir: "ASC",
                masterListFourthSort: "",
                masterListFourthSortDir: "ASC",
                showExamProctorsDateField: false,
                examProctorsForDatesStart: moment(new Date()).format('YYYY-MM-DD'),
                examProctorsForDatesEnd: moment(new Date()).format('YYYY-MM-DD'),
                pickerOptions: {
                    // disabledDate(time) {
                    //   return time.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                        // }, {
                        //   text: 'Yesterday',
                        //   onClick(picker) {
                        //     const date = new Date();
                        //     date.setTime(date.getTime() - 3600 * 1000 * 24);
                        //     picker.$emit('pick', date);
                        //   }
                    }]
                }
            }
        },
        methods: {
            showExamIndexPage: function(){
              this.$router.push({ path: 'testing', query: { start_date:  this.examIndexStartDate, end_date: this.examIndexEndDate }});
            },
            showProctoringSessionsPage: function(){
              this.$router.push({ path: 'proctoring-session', query: { start_date: this.proctoringSessionsStartDate, end_date: this.proctoringSessionsEndDate}});
            },
            showMasterListPage: function(){
                //var masterListUrl = 'master-list?start_date=' + this.masterListStartDate + '&end_date=' + this.masterListEndDate;
                let query = {
                  start_date: this.masterListStartDate,
                  end_date: this.masterListEndDate
                };

                if(this.masterListFirstSort){
                  query.sort_1 = this.masterListFirstSort;
                  query.sort_1_dir =this.masterListFirstSortDir
                    //masterListUrl += '&sort_1=' + this.masterListFirstSort + '&sort_1_dir=' + this.masterListFirstSortDir;

                    if(this.masterListSecondSort){
                      query.sort_2 = this.masterListSecondSort;
                      query.sort_2_dir =this.masterListSecondSortDir

                      //masterListUrl += '&sort_2=' + this.masterListSecondSort + '&sort_2_dir=' + this.masterListSecondSortDir;

                        if(this.masterListThirdSort){
                          query.sort_3 = this.masterListThirdSort;
                          query.sort_3_dir =this.masterListThirdSortDir

                          //masterListUrl += '&sort_2=' + this.masterListThirdSort + '&sort_2_dir=' + this.masterListThirdSortDir;

                            if(this.masterListFourthSort){
                              query.sort_4 = this.masterListFourthSort;
                              query.sort_4_dir =this.masterListFourthSortDir

                              //masterListUrl += '&sort_2=' + this.masterListFourthSort + '&sort_2_dir=' + this.masterListFourthSortDir;
                            }
                        }
                    }
                }
                this.$router.push({ path: 'master-list', query: query });
                //window.location = masterListUrl;
            },
            emailExamProctorsForDateRange: function(){
                this.$router.push({
                  path: '/group-email/compose',
                  query: {
                    exam_proctors_for_date_range: true,
                    start_date: this.examProctorsForDatesStart,
                    end_date: this.examProctorsForDatesEnd
                  }});
            },
            load: function(){
                axios.get("/api/service/service-links/", {})
                    .then(response => {
                        this.showTodaysEnrollments = response.data.show_todays_enrollments;
                        this.showTomorrowsEnrollments = response.data.show_tomorrows_enrollments;
                        this.showMondaysEnrollments = response.data.show_mondays_enrollments;
                        this.showOnDutyProtors = response.data.show_on_duty_proctors;
                    });

                axios.get("/api/testing-service/master-list-sort-options/", {})
                    .then(response => {
                        this.masterListSortOptions = response.data.master_list_sort_options;
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>

<style scoped>
    li {
        margin: .6em 2em;
    }
</style>
