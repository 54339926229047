<template>
    <div id="container">
<div id="content">
            <h1>Current Caseload by Coordinator</h1>
            <h2>Total Current Cases</h2>
            <table width="100%"><tbody>
                <tr>
                    <th v-for="coordinator in coordinators" width="14%" :key="coordinator.id">{{ coordinator.coordinator_name }}</th>
                </tr>
                <tr>
                    <td v-for="coordinator in coordinators" :key="coordinator.id">{{ coordinator.count }}</td>
                </tr>
            </tbody></table>
            <report-caseload-grid :title="'Expected Graduation'" :data="expectedGraduation" :coordinators="coordinators"></report-caseload-grid>
            <report-caseload-grid :title="'ODS Status'" :data="odsStatus" :coordinators="coordinators"></report-caseload-grid>
            <report-caseload-grid :title="'Disability'" :data="disability" :coordinators="coordinators"></report-caseload-grid>
            <report-caseload-grid :title="'School'" :data="school" :coordinators="coordinators"></report-caseload-grid>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Current Caseload by Coordinator"
            }
        },
        components: {
        },
        data () {
            return {
                coordinators: [],
                totalCurrentCases: [],
                expectedGraduation: [],
                odsStatus: [],
                disability: [],
                school: [],
                loading: null
            }
        },
        methods: {
            load: function() {
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/caseload/')
                    .then(response => {
                        if(response.data.success) {
                            this.coordinators = response.data.coordinator_totals;
                            this.expectedGraduation = response.data.expected_graduations;
                            this.odsStatus = response.data.statuses;
                            this.disability = response.data.disabilities;
                            this.school = response.data.schools;

                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
