<template>
    <div id="container">
        <div id="content">
            <template v-if="editMode">
                <h1>Editing semester</h1>
                <el-form label-width="90px" class="semester-form" :model="semester" :rules="rules" ref="semesterForm"  label-position="right" >
                    <h2>{{semester.name}}</h2>
                    <el-form-item label="Start Date">
                        <el-date-picker size="mini" type="date" placeholder="Start Date" v-model="semester.start_date" value-format="yyyy-MM-dd" style="width:175px;"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="End Date">
                        <el-date-picker size="mini" type="date" placeholder="End Date" v-model="semester.end_date" value-format="yyyy-MM-dd" style="width:175px;"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="onSave('semesterForm')">Save</el-button>
                    </el-form-item>
                </el-form>
                <router-link :to="'/semester/show/'+this.semester.id" v-on:click.native="toggleEditMode">Show</router-link> |
                <router-link :to="'/semester/list/'">Back</router-link>
            </template>
            <template v-else>
                <h1>View Semester</h1>
                <p><b>Name: </b>{{semester.name}}</p>
                <p><b>Start date: </b>{{semester.start_date}}</p>
                <p><b>End date: </b>{{semester.end_date}}</p>

                <router-link :to="'/semester/edit/'+this.semester.id"  v-on:click.native="toggleEditMode">Edit</router-link> |
                <router-link :to="'/semester/list/'">Back</router-link>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Edit Semester"
            }
        },
        components: {
        },
        data () {
            return {
                semester: {
                    id: null,
                    name: null,
                    start_date: null,
                    end_date: null
                },
                editMode: true,
                rules: {
                    start_date: [{ required: true, message: 'Start date is required', trigger: 'blur' }],
                    end_date: [{ required: true, message: 'End date is required', trigger: 'blur' }]
                }
            }
        },
        methods: {
            toggleEditMode: function(){
              this.editMode = !this.editMode;
            },
            load: function() {
                if(location.pathname.indexOf("show") != -1){
                    this.semester.id = location.pathname.split('/semester/show/')[1];
                    this.editMode = false;
                } else {
                    this.semester.id = location.pathname.split('/semester/edit/')[1];
                }

                axios.get("/api/simple/get-semester/", {params: {id: this.semester.id}})
                    .then(response => {
                        this.semester = response.data;

                    });
            },
            onSave: function (formName) {
                // eslint-disable-next-line
                console.log("FORM NAME: "+formName);
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        axios.put('/api/admin/semester/', this.semester)
                            .then(response => {
                                if(response.data.success) {
                                    //console.log("success");
                                    this.$router.push({ path: '/semester/show/'+this.semester.id});
                                    this.toggleEditMode();
                                } else {
                                    // eslint-disable-next-line
                                    console.log("failed:" + response.data.message);
                                    this.displayError(response.data.message);
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    } else {
                        //return message
                        // eslint-disable-next-line
                        console.log("not valid");
                    }
                });
            }
        },
        created () {
                    // eslint-disable-next-line
                    console.log("here valid");
            this.load();
        }
    }
</script>

<style>
    .semester-form .el-form-item label {
        text-align: left !important;
    }
</style>

<style scoped>
    #content .el-form {
        width:550px;
    }

    /*    .el-form-item__content {
            font-size: small;
        }*/
    /*    #content .el-form-item__content {
            line-height: inherit;
        }*/
</style>
