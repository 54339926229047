<template>
    <div id="container">
<div id="content">
            <h1>Athletes<template v-if="data.length"> - {{ data.length }} Total</template></h1>
            <a :href="'/api/report/student-athletes.xlsx'" target="_blank">Download as CSV</a><br><br>
            <report-student-report-grid :data="data" :loading="loading" :sortProp="'uni'" :fullView="true" :showActivities="true"></report-student-report-grid>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Athletes"
            }
        },
        components: {
        },
        data () {
            return {
                data: [],
                loading: false
            }
        },
        methods: {
            load: function() {
                this.getData();
                
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/student-athletes/')
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
