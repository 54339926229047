<template>
    <div id="container">
<div id="content">
            <h1>Proctoring Location: {{ proctoringLocation.building }} {{proctoringLocation.room}}</h1>
            <el-form label-width="160px" label-position="left">
                <el-form-item label="Building" prop="building">
                    <span class="form-text">{{ proctoringLocation.building }}</span>
                </el-form-item>
                <el-form-item label="Room">
                    <span class="form-text">{{ proctoringLocation.room }}</span>
                </el-form-item>
                <el-form-item label="Wheelchair accessible?">
                    <span class="form-text">{{ proctoringLocation.wheelchair_accessible ? "Yes" : "No" }}</span>
                </el-form-item>
                <el-form-item label="Is Graduate Location?">
                    <span class="form-text">{{ proctoringLocation.is_graduate_location ? "Yes" : "No" }}</span>
                </el-form-item>
                <el-form-item label="Is Active?">
                    <span class="form-text">{{ proctoringLocation.is_active ? "Yes" : "No" }}</span>
                </el-form-item>
                <el-form-item label="Room Capacity">
                    <span class="form-text">{{ proctoringLocation.room_capacity }}</span>
                </el-form-item>
                <el-form-item label="ROOM LOCKED" v-if="proctoringLocation.is_locked"  style="color: red !important">
                    <span class="form-text">by {{ proctoringLocation.locker_name }} on {{ proctoringLocation.lock_date_time | formatDateTimeMDYhma }}</span>
                </el-form-item>
                <el-form-item label="Seating Style">
                    <span class="form-text">{{ proctoringLocation.seating_style }}</span>
                </el-form-item>
                <el-form-item v-if="proctoringLocation.seating_style == 'Other'" label="Other Seating Style">
                    <span class="form-text">{{ proctoringLocation.other_seating_style }}</span>
                </el-form-item>
            </el-form>
            <el-form v-if="proctoringLocation.room_notes && proctoringLocation.room_notes.length" label-width="160px" label-position="left">
                <template v-for="note in proctoringLocation.room_notes">
                    <el-form-item label="Room Note" :key="note.id">
                        <span class="form-text">{{ note.note }}</span>
                    </el-form-item>
                    <el-form-item v-if="note.note == 'Other'" label="Other" :key="note.id">
                        <span class="form-text formatted-text">Other: {{ proctoringLocation.room }}</span>
                    </el-form-item>
                </template>
            </el-form>

            <template v-if="proctoringLocation.room_diagrams && proctoringLocation.room_diagrams.length">
                <h4>Existing diagrams:</h4>
                <table><tbody>
                    <tr v-for="(diagram) in proctoringLocation.room_diagrams" :key="diagram.id">
                        <td><a :href="'/api/document/download/' + diagram.id" target="_blank">{{ diagram.asset_file_name }}</a></td>
                    </tr>
                </tbody></table>
            </template>

            <p><a :href="'/proctoring-location/edit/' + proctoringLocation.id">Edit</a></p>
            <p><a :href="'/proctoring-location'">Proctoring locations</a></p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Proctoring Location: " + this.proctoringLocation.building + " " + this.proctoringLocation.room
            }
        },
        components: {
            },
        data () {
            return {
                proctoringLocation: {
                    id: null,
                    building: null,
                    room: null,
                    room_capacity: null,
                    seating_style: null,
                    other_seating_style: null,
                    wheelchair_accessible: false,
                    is_graduate_location: false,
                    is_active: false,
                    room_notes: [],
                    is_locked: null,
                    lock_date_time: null,
                    locker_name: null
                },
                seatingStyles: [],
                roomNoteOptions: []
            }
        },
        methods: {
            load: function(){
                this.proctoringLocation.id = location.pathname.split('/proctoring-location/show/')[1];
                if(this.proctoringLocation.id){
                    this.proctoringLocation.id = parseInt(this.proctoringLocation.id);
                    this.getProctoringLocation();
                }
            },
            getProctoringLocation: function(){
                axios.get("/api/proctoring-location/", {params: {id: this.proctoringLocation.id}})
                    .then(response => {
                        this.proctoringLocation = response.data.proctoring_location;
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 735px;
    }
</style>
