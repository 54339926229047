<template>
  <div id="container">
    <div id="content">
      <h1>Student Counts by ODS Registration Date And Current Status</h1>
      <h2>{{ fromDate | formatDateMDY }} through {{ toDate | formatDateMDY }}</h2>
      <p>(<a :href="'/api/report/student-status-counts.xlsx?from_date=' + fromDate + '&to_date=' + toDate+'&school='+ selectedSchool" target="_blank">CSV</a>)</p>
      <table width="60%"><tbody>
      <tr>
        <th width="15%">Status</th>
        <th width="10%">Students</th>
        <th width="15%">Name</th>
        <th width="10%">UNI</th>
        <th width="10%">Registration Date</th>
        <th width="10%">Status</th>
        <th width="30%">School<br/>
          <el-select size="mini" v-model="selectedSchool" @change="onSelectSchool">
            <el-option v-for="option in schools" :key="option" :value="option" :label="option"></el-option>
          </el-select>
        </th>
      </tr>
      <template v-for="(row, index) in data">
        <tr :key="index">
          <td>{{ row.status }}</td>
          <td>{{ row.count }}</td>
          <td v-if="row.count > 0"></td>
          <td v-if="row.count > 0"></td>
          <td v-if="row.count > 0"></td>
          <td v-if="row.count > 0"></td>
          <td v-if="row.count > 0"></td>
        </tr>
        <template v-for="student in row.status_students">
          <tr :key="student.id" @click="onTableRowClick(student.id)" @mouseover="student.isHovered = true" @mouseleave="student.isHovered = false" :class="{ clickablerow: student.isHovered }">
            <td></td>
            <td></td>
            <td>{{ student.display_name }}</td>
            <td>{{ student.uni }}</td>
            <td>{{ student.ods_registration_date }}</td>
            <td>{{ row.status }}</td>
            <td>{{ student.school }}</td>
          </tr>
        </template>
      </template>
      <tr>
        <td><b>Total</b></td>
        <td><b>{{ total }}</b></td>
      </tr>
      </tbody></table>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'



  export default {
    metaInfo () {
      return {
        title: "Student Counts by ODS Registration Date And Current Status"
      }
    },

    components: {
    },
    data () {
      return {
        urlParams: {},
        data: [],
        loading: null,
        fromDate: null,
        toDate: null,
        schools: [],
        selectedSchool: "All",
        total: 0
      }
    },
    methods: {
      load: function() {
        this.urlParams = this.$route.query;
        this.fromDate = this.urlParams.from_date;
        this.toDate = this.urlParams.to_date;
        this.getData();
      },
      getData: function() {
        this.loading = this.$loading({lock: true, text: 'Loading...'});
        axios.get('/api/report/student-status-counts/', {params: {from_date: this.fromDate, to_date: this.toDate, school: this.selectedSchool}})
          .then(response => {
            if(response.data.success) {
              this.data = response.data.rows;
              this.total = response.data.total;
              for(var i = 0; i < this.data.length; i++){
                if(this.data[i].status_students){
                  for(var j = 0; j < this.data[i].status_students.length; j++){
                    this.$set(this.data[i].status_students[j], 'isHovered', false);
                  }
                }
              }
              this.schools = response.data.schools;
              this.loading.close();
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onTableRowClick: function(id){
        this.$router.push('/student/profile/' + id)
      },
      onSelectSchool: function(){
        this.getData();
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .clickablerow {
    background: #ffc !important;
    cursor: pointer;
  }
</style>
