<template>
    <div id="container">
<div id="content">
            <h1>Once-registered Students by Graduation Year and School</h1>
            <h2>{{ school }}</h2>
            <p>This report includes all students ever registered with ODS at {{ school }} reliably since 2007 (Doris DOB)</p>
            <el-table :default-sort="{prop: 'first_name', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                <el-table-column prop="preferred_first_name" label="Preferred" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="status_display" label="Student Status" sortable :sort-orders="['ascending', 'descending']" width="145"></el-table-column>
                <el-table-column prop="expected_graduation_semester" label="SIS expected graduation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="program_enrollment_graduation" label="Program enrollment graduation" sortable :sort-orders="['ascending', 'descending']" width="260">
                    <template slot-scope="scope">{{ scope.row.program_enrollment_graduation | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column prop="ods_registration_date" label="ODS registration date" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{ scope.row.ods_registration_date | formatDateMDY }}</template>
                </el-table-column>
            </el-table>
            <p><a :href="'/api/report/students-by-graduation-year-and-school.xlsx?school=' + this.school" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Once-registered Students by Graduation Year and School"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                school: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.school = decodeURIComponent(this.urlParams.school);
                this.getData();
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/students-by-graduation-year-and-school/', {params: {school: this.school}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
