<template>
    <div id="container">
<div id="content">
            <h1>Proctor: {{ proctor.first_name }} {{ proctor.last_name }}</h1>
            <a :href="'/proctor/edit/' + this.proctor.id">Edit</a>
            <table>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Active</th>
                        <th>Inactive Date</th>
                        <th>CUMC Proctor</th>
                        <th>Trainee?</th>
                        <th>Payroll Status</th>
                        <th>Columbia Affiliation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ proctor.first_name }}</td>
                        <td>{{ proctor.last_name }}</td>
                        <td>{{ proctor.phone }}</td>
                        <td><a :href="'/group-email/compose?proctor_id=' + proctor.id">{{ proctor.email }}</a></td>
                        <td>{{ proctor.active ? "Yes" : "No" }}</td>
                        <td>{{ proctor.inactive_date | formatDateMDY }}</td>
                        <td>{{ proctor.cumc_proctor ? "Yes" : "No" }}</td>
                        <td>{{ proctor.proctor_is_trainee ? "Yes" : "No" }}</td>
                        <td>{{ proctor.proctor_university_status }}</td>
                        <td>{{ proctor.proctor_columbia_affiliation }}</td>
                    </tr>
                </tbody>
            </table>
            <student-emails :emails="groupEmails" :title="'Group Emails'" :showCCField="false"></student-emails>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Proctor: " + this.proctor.first_name +" " + this.proctor.last_name
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                proctor: {},
                groupEmails: []
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/proctor/show/')[1];
                this.getProctor();
                this.getGroupEmails();
            },
            getProctor: function(){
                axios.get("/api/proctor/", {params: {id: parseInt(this.id)}})
                    .then(response => {
                        this.proctor = response.data.proctor;
                    });
            },
            getGroupEmails: function(){
                axios.get("/api/group-email/group-emails-for-generic/", {params: {id: parseInt(this.id), type: "ServiceProvider"}})
                    .then(response => {
                        this.groupEmails = response.data.rows;
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .comment-author {
        font-style: italic;
        text-align: right;
        padding-bottom: 0.25em;
        border-bottom: 1px solid #ccc;
    }
</style>
