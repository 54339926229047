<template>
  <div id="container">
    <div id="content">
      <h1>Students by Course Instructor</h1>
      <a :href="'/api/report/students-by-course-instructor.xlsx?semester_id=' + this.semesterId" target="_blank">Download as CSV</a>
      <template v-for="(value, key) in data">
        <h3 :key="key + 'a'">Instructor: {{ value[0].instructor_first_name }} {{ value[0].instructor_last_name }}, Course: {{ value[0].department }} {{ value[0].course_number }} - {{ value[0].class_name }}, Section: {{ value[0].section_code }}</h3>
        <h3 :key="key + 'b'">Meeting Times:
          <template v-if="value[0].meeting_times.days.length">
            <template v-for="(time, index) in value[0].meeting_times.times">
              <template v-if="value[0].meeting_times.times.length > 1">
                {{ value[0].meeting_times.days[index] }}, {{ value[0].meeting_times.times[index].start_time }} - {{ value[0].meeting_times.times[index].end_time }};
              </template>
              <template v-else>
                {{ renderDayArray(value[0].meeting_times.days) }}, {{ value[0].meeting_times.times[index].start_time }} - {{ value[0].meeting_times.times[index].end_time }};
              </template>
            </template>
          </template>
          <template v-else>N/A</template>
        </h3>
        <div style="float: left"  :key="key + 'c'"><a :href="'mailto:' + value[0].instructor_email">{{ value[0].instructor_email }}</a></div>
        <div style="float:right" :key="key + 'd'"><a :href="'/api/report/students-by-single-course.xlsx?course_id=' + key">Download CSV for this course</a><a :href="'/group-email/compose/?email_course_students=' + key" style="margin-left: 20px">Email All Students for this course</a></div><br :key="key+'x'"><br :key="key+'y'">

        <table class="listing" width="100%" :key="key + 'e'">
          <thead>
          <tr><th width="18%">Student Name</th>
            <th width="8%">ODS Status</th>
            <th width="8%">Enrollment</th>
            <th width="7%">Coord.</th>
            <th width="20%">Disabilities</th>
            <th width="22%">Accommodations</th>
            <th width="17%">School</th>
          </tr>
          </thead>



          <tbody>
          <template v-for="(row, rowIndex) in value">
            <tr :key="rowIndex">
              <td>{{row.student_name}}</td>
              <td>{{row.status_display}}</td>
              <td>{{row.enrollment}}</td>
              <td>{{row.coordinator}}</td>
              <td>
                <template v-for="(disability, dIndex) in row.disabilities">{{ disability }}<br :key="dIndex"></template>
              </td>
              <td><template v-for="(accommodation, aIndex) in row.accommodations">{{ accommodation }}<br :key="aIndex"></template></td>
              <td>{{row.school}}</td>
            </tr>

          </template>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'



export default {
  metaInfo () {
    return {
      title: "Students by Course Instructor"
    }
  },

  components: {
  },
  data () {
    return {
      urlParams: {},
      data: [],
      processedData: {},
      semesterId: 0,
      loading: null
    }
  },
  methods: {
    load: function() {
      this.urlParams = this.$route.query;
      this.semesterId = this.urlParams.semester_id;
      this.getData();

    },
    getData: function() {
      this.loading = this.$loading({lock: true, text: 'Loading...'});
      axios.get('/api/report/students-by-course-instructor/', {params: {semester_id: this.semesterId}})
          .then(response => {
            if(response.data.success) {
              this.data = response.data.rows;
              this.loading.close();
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
    },
    // processData: function() {
    //     // for(var i = 0; i < data.length; i++){
    //     //     if(!this.processedData[data[i].course_id]){
    //     //         this.$set(this.processedData, data[i].course_id, []);
    //     //     }
    //     //     this.processedData[data[i].course_id].push(data[i]);
    //     // }
    //     this.loading.close();
    //
    // },
    renderDayArray(days){
      if(days.length > 2){
        return days[0] + "-" + days[days.length - 1];
      }
      return days.join(" ");
    }
  },
  created () {
    this.load();
  }
}
</script>
<style scoped>
</style>
