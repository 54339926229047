<template>
  <div id="container">
    <div id="content">
      <h1>Group Email Signatures</h1>
      <el-table :data="templates" height="350" style="width: 650px; float: left">
        <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']" :min-width="20.0"></el-table-column>
        <el-table-column label="Operations" :min-width="10.0">
          <template slot-scope="scope">
            <el-button @click.native.prevent="handleShow(scope.$index, templates)" type="text" size="small">Show</el-button>
            <el-button @click.native.prevent="handleEdit(scope.$index, templates)" type="text" size="small">Edit</el-button><br/>
            <el-button @click.native.prevent="useAsSignature(scope.$index, templates)" type="text" size="small">Use Signature</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div id="preview">
        <p class="subject">{{name}}</p>
        <div id="html-preview" v-html="html" style="float: left;"></div>
      </div>
      <br clear="all">
      <el-button type="primary" style="margin-top: 10px" size="small" @click="onSubmit">Create a new email signature</el-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Email Signatures"
      }
    },
    components: {
    },
    data () {
      return {
        name: '',
        html: 'Click "Show" to see template here',
        templates: []
      }
    },
    //computed: {
    //   html () {
    //       return converter.makeHtml(this.content)
    //   }
    //},
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      handleShow: function(index, rows) {
        this.name = rows[index].name
        this.html = converter.makeHtml(rows[index].body)
      },
      handleEdit: function(index, rows) {
        this.$router.push({ path: '/group-email/email-signatures/edit', query: { id: rows[index].id}});

      },
      useAsSignature: function(index, rows) {

        axios
          .post('/api/group-email/use-signature', {
            id: rows[index].id
          })
          .then(response => {
            this.$alert("Your signature has been set");
          })
          .catch(function (error) {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      load: function () {
        axios
          .get('/api/group-email/email-signatures')
          .then(response => {
            if(response.data.success) {
              this.templates = response.data.rows;
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onSubmit: function() {
        this.$router.push({ path: '/group-email/email-signatures/edit'});
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
  .subject {
    margin-left: 10px;
    padding-left: 10px;
    margin-top: 0;

  }
  #content .el-table__body-wrapper {
    overflow-y: auto;
  }
  #preview {
    width: 80ch;
    float: left;
    margin-top: 0px;
  }

</style>
