<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getRequests">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Note taking service requests pending hire of note taker</h1>
            <note-taking-service-nav selected="unfilled-requests"></note-taking-service-nav>
            <p>To enter note taker information, click on the course name.</p>
            <el-table :default-sort="{prop: 'student_name', order: 'ascending'}" :data="activeRequests" class="table-top-align">
                <el-table-column label="Name" prop="student_name" sortable :sort-orders="['ascending', 'descending']" :min-width="19.9">
                    <template slot-scope="scope">
                        <a :href="'/student/services/' + scope.row.student_id">{{ scope.row.student_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Preferred name" prop="student_preferred_name" sortable :sort-orders="['ascending', 'descending']" :min-width="15.2"></el-table-column>
                <el-table-column label="Course name" prop="class_name" sortable :sort-orders="['ascending', 'descending']" :min-width="21.8">
                    <template slot-scope="scope">
                        <a :href="'/note-taking-service/show/' + scope.row.id">{{ scope.row.class_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Course ID and Section #" prop="course_id_and_section" sortable :sort-orders="['ascending', 'descending']" :min-width="21.8"></el-table-column>
                <el-table-column label="Requested" prop="requested_date" sortable :sort-orders="['ascending', 'descending']" :min-width="10.6"><template slot-scope="scope">{{ scope.row.requested_date | formatDateMD }}</template></el-table-column>
                <el-table-column label="Note Taking Style" prop="note_taking_style" sortable :sort-orders="['ascending', 'descending']" :min-width="16.4"></el-table-column>
                <el-table-column label="Dates Emailed Professor" prop="emailed_professor_dates" sortable :sort-orders="['ascending', 'descending']" :min-width="22.3">
                    <template slot-scope="scope">
                        <template v-for="(date, index) in scope.row.emailed_professor_dates">
                            {{ date | formatDateMD }}<br :key="index">
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Emailed Class" prop="emailed_class_dates" sortable :sort-orders="['ascending', 'descending']" :min-width="13.0">
                    <template slot-scope="scope">
                        <template v-for="(date, index) in scope.row.emailed_class_dates">
                            {{ date | formatDateMD }}<br :key="index">
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Comments" prop="comments" sortable :sort-orders="['ascending', 'descending']" :min-width="40.0"><!-- TODO: Check this once prop exists -->
                </el-table-column>
            </el-table>
            <p v-if="activeTotal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ activeTotal }} records)</p>

            <template v-if="cancelledTotal">
                <p>The following {{ cancelledTotal }} courses have been cancelled.</p>
                <el-table :default-sort="{prop: 'student_name', order: 'ascending'}" :data="cancelledRequests" class="table-top-align">
                    <el-table-column label="Name" prop="student_name" sortable :sort-orders="['ascending', 'descending']" :min-width="9.2">
                        <template slot-scope="scope">
                            <a :href="'/student/services/' + scope.row.student_id">{{ scope.row.student_name }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column label="Preferred name" prop="student_preferred_name" sortable :sort-orders="['ascending', 'descending']" :min-width="8.2"></el-table-column>
                    <el-table-column label="Course name" prop="class_name" sortable :sort-orders="['ascending', 'descending']" :min-width="15.6">
                        <template slot-scope="scope">
                            <a :href="'/note-taking-service/show/' + scope.row.id">{{ scope.row.class_name }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column label="Course ID and Section #" prop="course_id_and_section" sortable :sort-orders="['ascending', 'descending']" :min-width="8.5"></el-table-column>
                    <el-table-column label="Requested" prop="requested_date" sortable :sort-orders="['ascending', 'descending']" :min-width="8.5"><template slot-scope="scope">{{ scope.row.requested_date | formatDateMD }}</template></el-table-column>
                    <el-table-column label="Note Taking Style" prop="note_taking_style" sortable :sort-orders="['ascending', 'descending']" :min-width="6.3"></el-table-column>
                    <el-table-column label="Dates Emailed Professor" prop="emailed_professor_dates" sortable :sort-orders="['ascending', 'descending']" :min-width="8.7">
                        <template slot-scope="scope">
                            <template v-for="(date, index) in scope.row.emailed_professor_dates">
                                {{ date | formatDateMD }}<br :key="index">
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Emailed Class" prop="emailed_class_dates" sortable :sort-orders="['ascending', 'descending']" :min-width="8.1">
                        <template slot-scope="scope">
                            <template v-for="(date, index) in scope.row.emailed_class_dates">
                                {{ date | formatDateMD }}<br :key="index">
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Cancellation Date" prop="cancellation_date" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0"><template slot-scope="scope">{{ scope.row.cancellation_date | formatDateMDY }}</template></el-table-column>
                    <el-table-column label="Cancellation Reason" prop="cancellation_reason" sortable :sort-orders="['ascending', 'descending']" :min-width="38.3"></el-table-column>
                    <el-table-column label="Comments" prop="comments" sortable :sort-orders="['ascending', 'descending']" :min-width="59.6"><!-- TODO: Check this once prop exists -->
                    </el-table-column>
                </el-table>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ cancelledTotal }} records)</p>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Note taking service requests pending hire of note taker"
            }
        },
        components: {
        },
        data () {
            return {
                activeRequests: [],
                cancelledRequests:[],
                semesters: [],
                semesterId: null,
                activeTotal: null,
                cancelledTotal: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getRequests();
                    });
            },
            getRequests: function() {
                axios.get('/api/notetaking-service/unfilled-requests/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.activeRequests = response.data.active_rows;
                            this.cancelledRequests = response.data.cancelled_rows;
                            
                            if(this.activeRequests) this.activeTotal = this.activeRequests.length;
                            if(this.cancelledRequests) this.cancelledTotal = this.cancelledRequests.length;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
