<template>
    <div id="container">
<div id="content">
            <h1>Contact Note by Contact Reason ({{ startDate | formatDateMDY }} - {{ endDate | formatDateMDY }}) (<a :href="'/api/report/contact-note-by-reason.xlsx?contact_reason=' + contactReason + '&start_date=' + startDate + '&end_date=' + endDate" target="_blank">CSV</a>)</h1>
            <template v-for="(row, rowIndex) in data">
                <h2 :key="rowIndex">Reason: {{ row.reason }} ({{ row.contact_notes.length }} notes)</h2>
                <el-table :key="rowIndex" :default-sort="{prop: 'updated_at', order: 'ascending'}" :data="row.contact_notes" style="width: 100%" class="table-top-align">
                    <el-table-column prop="updated_at" label="Date of Contact" sortable :sort-orders="['ascending', 'descending']" width="100">
                        <template slot-scope="scope">{{ scope.row.updated_at | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="110">
                        <template slot-scope="scope">
                            <a :href="'/student/notes/' + scope.row.student_id">{{ scope.row.student_name }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reasons" label="Reason(s)" sortable :sort-orders="['ascending', 'descending']">
                        <template slot-scope="scope">
                            <template v-if="scope.row.reasons && scope.row.reasons.length">
                                {{ scope.row.reasons.join(', ')}}<br>
                            </template>
                            <template v-if="scope.row.accommodation_types && scope.row.accommodation_types.length">
                                <i>Accommodation Types: {{ scope.row.accommodation_types.join(', ')}}</i>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']">
                        <template slot-scope="scope">
                            <a :href="'/contact-notes/show/' + scope.row.id">{{ scope.row.subject }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="author_name" label="Author" sortable :sort-orders="['ascending', 'descending']" width="80"></el-table-column>
                    <el-table-column prop="status" label="Status" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                    <el-table-column prop="has_attachment" label="Has Attachment?" sortable :sort-orders="['ascending', 'descending']" width="110">
                        <template slot-scope="scope">{{ scope.row.has_attachment ? 'Yes' : '' }}</template>
                    </el-table-column>
                </el-table>

                <template v-if="row.supervisor_contact_notes && row.supervisor_contact_notes.length">
                    <h3 :key="rowIndex">Supervisor notes: ({{ row.supervisor_contact_notes.length }} notes)</h3>
                    <el-table :key="rowIndex" :default-sort="{prop: 'updated_at', order: 'ascending'}" :data="row.supervisor_contact_notes" style="width: 100%" class="table-top-align">
                        <el-table-column prop="updated_at" label="Date of Contact" sortable :sort-orders="['ascending', 'descending']" width="100">
                            <template slot-scope="scope">{{ scope.row.updated_at | formatDateMDY }}</template>
                        </el-table-column>
                        <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="110">
                            <template slot-scope="scope">
                                <a :href="'/student/notes/' + scope.row.student_id">{{ scope.row.student_name }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="reasons" label="Reason(s)" sortable :sort-orders="['ascending', 'descending']">
                            <template slot-scope="scope">
                                <template v-if="scope.row.reasons && scope.row.reasons.length">
                                    {{ scope.row.reasons.join(', ')}}<br>
                                </template>
                                <template v-if="scope.row.accommodation_types && scope.row.accommodation_types.length">
                                    <i>Accommodation Types: {{ scope.row.accommodation_types.join(', ')}}</i>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']">
                            <template slot-scope="scope">
                                <a :href="'/contact-notes/show/' + scope.row.id">{{ scope.row.subject }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column prop="author_name" label="Author" sortable :sort-orders="['ascending', 'descending']" width="80"></el-table-column>
                        <el-table-column prop="status" label="Status" sortable :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                        <el-table-column prop="has_attachment" label="Has Attachment?" sortable :sort-orders="['ascending', 'descending']" width="110">
                            <template slot-scope="scope">{{ scope.row.has_attachment ? 'Yes' : '' }}</template>
                        </el-table-column>
                    </el-table>
                </template>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: 'Contact Note by Contact Reason (' + moment(this.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.endDate).format('MM/DD/YYYY') + ')'
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                contactReason: null,
                accommodation: null,
                startDate: null,
                endDate: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.contactReason = decodeURIComponent(this.urlParams.contact_reason);
                if (this.contactReason == 'Accommodations'){
                    this.accommodation = decodeURIComponent(this.urlParams.accommodation);
                }
                this.startDate = this.urlParams.start_date;
                this.endDate = this.urlParams.end_date;
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/contact-note-by-reason/', {params: {contact_reason: this.contactReason, accommodation: this.accommodation, start_date: this.startDate, end_date: this.endDate}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
