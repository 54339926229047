<template>
    <div id="container">
<div id="content">
            <student-nav selected="testing" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="currentSemesterId" @change="onCurrentSemesterChange">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="student-testing-reminder" v-if="showEmailReminders">
                Email student regarding upcoming exams between:<br/>
                <el-date-picker type="date" size="mini" v-model="reminderStartValue" value-format="yyyy-MM-dd"></el-date-picker> -
                <el-date-picker type="date" size="mini" v-model="reminderEndValue" value-format="yyyy-MM-dd"></el-date-picker>
                <el-button size="mini" type="primary" @click="sendEmailReminders">Submit</el-button>
            </div>
            <h2>Testing accommodations</h2>
            <student-upcoming-and-current-exams :id="id" :exams="upcomingAndCurrentExams" :cancellationReasons="cancellationReasons" v-on:refreshdata="getData"></student-upcoming-and-current-exams>
            <student-enrolled-courses :id="id" :courses="enrolledCourses"></student-enrolled-courses>
            <a :href="'/student/add-course/' + id + '?back=testing'">Add a course not listed here</a>
            <student-exams :id="id" :exams="completedExams" :isCompletedExams="true"></student-exams>
            <student-exams :id="id" :exams="cancelledExams" :isCompletedExams="false"></student-exams>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                semesters: [],
                currentSemesterId: null,
                currentSemesterName: null,
                upcomingAndCurrentExams: [],
                enrolledCourses: [],
                completedExams: [],
                cancelledExams: [],
                cancellationReasons: [],
                showEmailReminders: false,
                reminderStart: moment(new Date()),
                reminderEnd: moment(new Date()),
                reminderStartValue: '',
                reminderEndValue: ''
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/testing/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                this.getData();
            },
            sendEmailReminders: function(){
                if(!this.reminderStartValue || !this.reminderEndValue){
                    this.$alert("Please select a date range for sending emails");
                } else {
                    var startValue = moment(this.reminderStartValue);
                    var endValue = moment(this.reminderEndValue).add(1, 'days');

                    var regardingIds = [];

                    for(var i = 0; i < this.upcomingAndCurrentExams.length; i++){
                        var exam = this.upcomingAndCurrentExams[i];
                        var thisStart = moment(exam.start_time);

                        if(!exam.opt_student_out_of_emails && !exam.is_placeholder_exam && exam.status == 'active') {
                            if (thisStart >= startValue && thisStart <= endValue) {
                                regardingIds.push(exam.service_id);
                            }
                        }

                    }

                    if(regardingIds.length == 0){
                        this.$alert("There are no eligible exams to send an email reminder about for this date range");
                    } else {

                        var recipients = [{
                            recipient_id: parseInt(this.id),
                            recipient_class: "Student",
                            regarding_ids: regardingIds,
                            regarding_class: "TestingService",
                            period_start: this.reminderStartValue,
                            period_end: this.reminderEndValue
                        }];

                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: "student_exam_time_reminder_for_period",
                            recipients: recipients
                        })
                            .then(response => {
                                this.$alert("The reminder email has been sent.");
                                this.getData();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }
                }
            },
            getData: function(){
                axios.get("/api/testing-service/testing-enrollment-cancellation-reasons/")
                    .then(response => {
                        this.cancellationReasons = response.data.reasons;
                    });

                axios.get("/api/testing-service/current-and-upcoming-exams-for-student/", {params:{id: this.id}})
                    .then(response => {
                        this.upcomingAndCurrentExams = response.data.rows;

                        for(var i = 0; i < this.upcomingAndCurrentExams.length; i++){
                            var exam = this.upcomingAndCurrentExams[i];


                            if(!exam.opt_student_out_of_emails && !exam.is_placeholder_exam && exam.status == 'active'){
                                this.showEmailReminders = true;
                            }

                            var thisStart = moment(exam.start_time);

                            if(thisStart < this.reminderStart){
                                this.reminderStart = thisStart;
                            }

                            if(thisStart > this.reminderEnd){
                                this.reminderEnd = thisStart;
                            }

                            // if(exam.start_time){
                            //     exam.date = moment(exam.start_time).format("M/D/YYYY");
                            //     exam.start_time = moment(exam.start_time).format("h:mma");
                            // }
                            //
                            // if(!exam.date && exam.end_time){
                            //     exam.date = moment(exam.end_time).format("M/D/YYYY");
                            // }
                            //
                            // if(exam.end_time){
                            //     exam.end_time = moment(exam.end_time).format("h:mma")
                            // }

                            this.$set(exam, 'showCancelFields', false);
                        }


                        this.reminderStartValue = this.reminderStart.format('YYYY-MM-DD');
                        this.reminderEndValue = this.reminderEnd.format('YYYY-MM-DD');
                    });

                axios.get("/api/student/semesters/", {params:{id: this.id}})
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.currentSemesterId = this.semesters[i].id;
                                this.currentSemesterName = this.semesters[i].name;
                            }
                        }

                        if(!this.currentSemesterId && response.data.rows[0]){
                            this.currentSemesterId = response.data.rows[0].id;
                            this.currentSemesterName = response.data.rows[0].name;
                        }

                        axios.get("/api/testing-service/enrolled-courses-for-student/", {params:{id: this.id, semester_id: this.currentSemesterId}})
                            .then(response => {
                                this.enrolledCourses = response.data.rows;

                                for(var i = 0; i < this.enrolledCourses.length; i++){
                                    this.$set(this.enrolledCourses[i], 'show_add_exam', false);
                                    if(this.enrolledCourses[i].next_meeting){
                                        var lengthHours = Math.floor(this.enrolledCourses[i].next_meeting.length_in_minutes / 60);
                                        var lengthMinutes = this.enrolledCourses[i].next_meeting.length_in_minutes % 60;
                                        this.$set(this.enrolledCourses[i], 'exam', {date: this.enrolledCourses[i].next_meeting.date, time: this.enrolledCourses[i].next_meeting.time, length_hours: lengthHours, length_minutes: lengthMinutes});
                                    } else {
                                        this.$set(this.enrolledCourses[i], 'exam', {date: null, time: null, length_hours: 0, length_minutes: 0});
                                    }
                                }
                            });
                    });

                axios.get("/api/testing-service/past-exams-for-student/", {params:{id: this.id}})
                    .then(response => {
                        this.completedExams = response.data.rows;

                        for(var i = 0; i < this.completedExams.length; i++){
                            var exam = this.completedExams[i];

                            // if(exam.start_time){
                            //     exam.date = moment(exam.start_time).format("M/D/YYYY");
                            //     exam.start_time = moment(exam.start_time).format("h:mma");
                            // }
                            //
                            // if(!exam.date && exam.end_time){
                            //     exam.date = moment(exam.end_time).format("M/D/YYYY");
                            // }
                            //
                            // if(exam.end_time){
                            //     exam.end_time = moment(exam.end_time).format("h:mma")
                            // }

                            if(exam.comments){
                                this.$set(exam, 'show_comments_more', exam.comments.length > 80);
                            }

                            if(exam.exam_comments) {
                                for(var j = 0; j < exam.exam_comments.length; j++){
                                    var exam_comment = exam.exam_comments[j];
                                    this.$set(exam_comment, 'show_exam_comment_more', exam_comment.content.length > 80);
                                }
                            }
                        }
                    });

                axios.get("/api/testing-service/past-cancelled-exams-for-student/", {params:{id: this.id}})
                    .then(response => {
                        this.cancelledExams = response.data.rows;

                        for(var i = 0; i < this.cancelledExams.length; i++){
                            var exam = this.cancelledExams[i];

                            // if(exam.start_time){
                            //     exam.date = moment(exam.start_time).format("M/D/YYYY");
                            //     exam.start_time = moment(exam.start_time).format("h:mma");
                            // }
                            //
                            // if(!exam.date && exam.end_time){
                            //     exam.date = moment(exam.end_time).format("M/D/YYYY");
                            // }
                            //
                            // if(exam.end_time){
                            //     exam.end_time = moment(exam.end_time).format("h:mma")
                            // }

                            if(exam.comments){
                                this.$set(exam, 'show_comments_more', exam.comments.length > 80);
                            }

                            if(exam.exam_comments) {
                                for(var j = 0; j < exam.exam_comments.length; j++){
                                    var exam_comment = exam.exam_comments[j];
                                    this.$set(exam_comment, 'show_exam_comment_more', exam_comment.content.length > 80);
                                }
                            }
                        }
                    });
            },
            onCurrentSemesterChange: function(){
                for(var i = 0; i < this.semesters.length; i++){
                    if(this.semesters[i].id === this.currentSemesterId){
                        this.currentSemesterName = this.semesters[i].name;
                    }
                }

                axios.get("/api/testing-service/enrolled-courses-for-student/", {params:{id: this.id, semester_id: this.currentSemesterId}})
                    .then(response => {
                        this.enrolledCourses = response.data.rows;

                        for(var i = 0; i < this.enrolledCourses.length; i++){
                            this.$set(this.enrolledCourses[i], 'show_add_exam', false);
                            if(this.enrolledCourses[i].next_meeting){
                                var lengthHours = Math.floor(this.enrolledCourses[i].next_meeting.length_in_minutes / 60);
                                var lengthMinutes = this.enrolledCourses[i].next_meeting.length_in_minutes % 60;
                                this.$set(this.enrolledCourses[i], 'exam', {date: Date.parse(this.enrolledCourses[i].next_meeting.date.replace(/-/g, '/')), time: this.enrolledCourses[i].next_meeting.time, length_hours: lengthHours, length_minutes: lengthMinutes});
                            } else {
                                this.$set(this.enrolledCourses[i], 'exam', {date: null, time: null, length_hours: 0, length_minutes: 0});
                            }
                        }
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .term-select {
        width: 250px;
        float:right;
    }
</style>
