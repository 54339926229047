<template>
    <div id="container">
<div id="content">
            <h1>Folder Review Verification Process</h1>
            <p>This is a list of all active and expired students in Doris.</p>
            <el-table :default-sort="{prop: 'name', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.id">{{ scope.row.name }}</a></template>
                </el-table-column>
                <el-table-column prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="graduation_date" label="Grad Date" sortable :sort-orders="['ascending', 'descending']" width="110">
                    <template slot-scope="scope">{{ scope.row.graduation_date | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column prop="status_display" label="ODS Reg Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="folder_review_verified" label="Verified?" sortable :sort-orders="['ascending', 'descending']" width="100">
                    <template slot-scope="scope">{{ scope.row.folder_review_verified ? 'Yes' : 'No' }}</template>
                </el-table-column>
                <el-table-column width="100">
                    <template slot-scope="scope">
                        <template v-if="scope.row.folder_review_verified">
                            (<a @click="clearVerifyFolder(scope.row)">Clear</a>)
                        </template>
                        <template v-else>
                            (<a @click="verifyFolder(scope.row)">Verify</a>)
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Folder Review Verification Process"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                primaryOrder: null,
                secondaryOrder: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.primaryOrder = this.urlParams.primary_order;
                this.secondaryOrder = this.urlParams.secondary_order;
                this.getData();
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/folder-review-process/')
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            verifyFolder: function(row){
                axios.put('/api/student/verify-folder/', {id: row.id})
                    .then(response => {
                        if(response.data.success){
                            row.folder_review_verified = true;
                        }
                    });
            },
            clearVerifyFolder: function(row){
                axios.put('/api/student/clear-verify-folder/', {id: row.id})
                    .then(response => {
                        if(response.data.success){
                            row.folder_review_verified = false;
                        }
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
