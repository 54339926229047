<template>
    <div id="container">
<div id="content">
            <h1>{{ task.subject }}</h1>
            <table>
                <tbody><tr>
                    <th>Subject</th>
                    <td>{{ task.subject }}</td>
                </tr>
                <tr>
                    <th>Author</th>
                    <td>{{ task.author_name }}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{{ task.type }}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>{{ task.status_display }}</td>
                </tr>
                <tr>
                    <th>Recipients / Assigned to</th>
                    <td>{{ recipients }}</td>
                </tr>
                <tr>
                    <th>CC'd to</th>
                    <td>{{ ccRecipients }}</td>
                </tr>
                <tr v-if="task.student_id">
                    <th>Student</th>
                    <td><a :href="'/student/profile/' + task.student_id">{{ task.student_name }} &lt;{{ task.student_uni }}></a></td>
                </tr>
                <tr v-if="task.contact_note_id">
                    <th>Contact Note</th>
                    <td v-if="task.can_be_viewed"><a :href="'/contact-notes/show/' + task.contact_note_id">{{ task.contact_note_subject }}</a></td>
                    <td v-if="!task.can_be_viewed">Access Denied</td>
                </tr>
                <tr>
                    <th>Text</th>
                    <td><p v-html="html"></p></td>
                </tr>
                <tr>
                    <th>Comments</th>
                    <td>
                        <template v-for="comment in task.supervisor_comments">
                            <div class="formatted-text" :key="comment.id"><p>{{ comment.text }}</p></div>
                            <div class="comment-author" :key="comment.id">-{{ comment.supervisor_name }}, {{ comment.updated_at | formatDateTimeMDYhma }}</div>
                        </template>
                        <template v-if="!task.supervisor_comments || task.supervisor_comments.length == 0">
                            <p><span class="text-italic">No comments yet</span></p>
                        </template>
                        <p v-if="!showCommentSection"><a @click="showCommentSection = true">Write a comment</a></p>
                        <template v-if="showCommentSection">
                            <el-input size="mini" type="textarea" :rows="10" v-model="commentText"></el-input>
                            Add CC: <el-autocomplete style="width:350px;" :fetch-suggestions="handleCCSearch" :trigger-on-focus="false" size="mini" @select="handleCCSelect" v-model="ccUsersStr"></el-autocomplete>
                            <template v-for="user in ccUsers">
                                <br :key="user.id">
                                <el-checkbox :key="user.id" :label="user.value" @change="removeUserFromCCUsers(user)" checked>{{user.value}}</el-checkbox>
                            </template>
                            <br><el-button size="mini" type="primary" @click="submitComment">{{ setStatusToPending ? 'Submit comment and set status to Pending' : 'Submit comment' }}</el-button> or <a @click="cancelComment">Cancel</a>
                        </template>
                    </td>
                </tr>
            </tbody></table>
            <p>Last updated {{ task.last_updated_at | formatDateMDY }} {{ task.latest_change_by_identifier }}</p>

            <!-- Formatting here is intentionally messy as line breaks in between template tags add extra spaces in between buttons. -->
            <template v-if="showFinishedButton">
                <el-button size="mini" type="primary" @click="updateTaskStatus('finished')">Finished</el-button>
            </template>
            <template v-if="showDontShowUntilUpdatedButton">
                <el-button size="mini" type="primary" @click="hideTask">Don’t show me this message until it is updated</el-button>
            </template>
            <template v-if="showShowThisTaskOnHomepageButton">
                <el-button size="mini" type="primary" @click="unhideTask">Show this task on my home page again</el-button>
            </template>
            <template v-if="showNotifyMeButton">
                <el-button size="mini" type="primary" @click="hideTask">Notify me when this task is updated</el-button>
            </template>
            <template v-if="showDeleteButton">
                <el-button size="mini" type="primary" @click="deleteTask">Delete</el-button>
            </template>
            <template v-if="showSendButton">
                <el-button size="mini" type="primary" @click="updateTaskStatus('send_assign')">Send/Assign</el-button>
            </template>
            <template v-if="showNotFinishedSection">
                <el-button size="mini" type="primary" @click="setStatusToPending = true;showCommentSection = true">Not Finished</el-button>
            </template>
            <template v-if="showCloseButton">
                <el-button size="mini" type="primary" @click="updateTaskStatus('closed')">Close</el-button>
            </template>
            <template v-if="showEditLink"><a :href="'/tasks/edit/' + this.id">Edit task</a></template>

            <template v-if="showHistory">
                <utils-history :id="id" type="Task" :show-title="true"></utils-history>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    import showdown from 'showdown'
    const converter = new showdown.Converter()
    converter.setOption('simpleLineBreaks',true)

    export default {
        metaInfo () {
            return {
                title: this.task.subject
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                task: {},
                html: "",
                showCommentSection: false,
                commentText: null,
                ccUsers: [],
                ccUsersStr: null,
                showFinishedButton: false,
                showDontShowUntilUpdatedButton: false,
                showShowThisTaskOnHomepageButton: false,
                showNotifyMeButton: false,
                showDeleteButton: false,
                showSendButton: false,
                showNotFinishedSection: false,
                showCloseButton: false,
                showEditLink: false,
                showHistory: false,
                setStatusToPending: false
            }
        },
        computed: {
            recipients: function(){
                if(this.task.recipients){
                    var recipients = [];
                    this.task.recipients.forEach(function(obj){
                        recipients.push(obj.name + " <" + obj.email + ">");
                    });

                    return recipients.join(", ");
                }
                return "";
            },
            ccRecipients: function(){
                if(this.task.cc_recipients) {
                    var ccRecipients = [];
                    this.task.cc_recipients.forEach(function(obj){
                        ccRecipients.push(obj.name + " <" + obj.email + ">");
                    });

                    return ccRecipients.join(", ");
                }
                return "";
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/tasks/show/')[1];
                this.getTask();
            },
            getTask: function(){
                axios.get("/api/task/", {params: {id: parseInt(this.id)}})
                    .then(response => {
                        this.task = response.data.task;
                        // eslint-disable-next-line
                        console.log(this.task.body);
                        this.html = converter.makeHtml(this.task.body);
                        // eslint-disable-next-line
                        console.log(this.task.body);

                        this.showFinishedButton = response.data.show_finished_button;
                        this.showDontShowUntilUpdatedButton = response.data.show_dont_show_until_updated_button;
                        this.showShowThisTaskOnHomepageButton = response.data.show_show_this_task_on_homepage_button;
                        this.showNotifyMeButton = response.data.show_notify_me_button;
                        this.showDeleteButton = response.data.show_delete_button;
                        this.showSendButton = response.data.show_send_button;
                        this.showNotFinishedSection = response.data.show_not_finished_section;
                        this.showCloseButton = response.data.show_close_button;
                        this.showEditLink = response.data.show_edit_link;
                        this.showHistory = response.data.show_history;
                    });
            },
            updateTaskStatus: function(status) {
                this.$message.closeAll();
                axios.post('/api/task/update-tasks-status/', {ids: [parseInt(this.id)], status: status})
                    .then(response => {
                        if(response.data.success){
                            this.getTask();
                            var message = null;
                            if(status == 'finished') {
                                message = "The task has been marked as finished.";
                            } else if(status == 'send_assign'){
                                message = "The task has been sent.";
                            } else if(status == 'closed'){
                                message = "The task has been closed.";
                            }
                            this.$message({
                                showClose: true,
                                duration: 0,
                                type: 'success',
                                message: message
                            });
                        }
                    });
            },
            hideTask: function(){
                axios.post('/api/task/hide-tasks/', {ids: [parseInt(this.id)]})
                    .then(response => {
                        if(response.data.success){
                          this.$router.push({ path: '/home'});
                        }
                    });
            },
            unhideTask: function(){
                axios.post('/api/task/unhide-tasks/', {ids: [parseInt(this.id)]})
                    .then(response => {
                        if(response.data.success){
                          this.$router.push({ path: '/home'});
                        }
                    });
            },
            deleteTask: function(){
                this.$confirm('Are you sure you want to delete this task?')
                    .then(() => {
                        axios.delete("/api/task", {params: {id: parseInt(this.id)}})
                            .then(response => {
                              this.$router.push({ path: '/tasks'});
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            submitComment: function(){
                var ccUserIds = [];
                this.ccUsers.forEach(function(user){
                    ccUserIds.push(user.id);
                });

                axios.post('/api/task/add-comment/', {id: parseInt(this.id), text: this.commentText, cc_ids: ccUserIds, set_status_to_pending: this.setStatusToPending})
                    .then(response => {
                        if(response.data.success){
                            this.cancelComment();
                            this.getTask();
                        }
                    });
            },
            cancelComment: function(){
                this.showCommentSection = false;
                this.setStatusToPending = false;
                this.commentText = null;
                this.ccUsers = [];
            },
            handleCCSearch: function(queryString, cb) {
                axios.get('/api/contact-note/search-active-users/?query=' + queryString)
                    .then(response => {
                        cb(response.data.rows);
                    });
            },
            handleCCSelect: function(user){
                this.ccUsers.push(user);
                this.ccUsersStr = null;
            },
            removeUserFromCCUsers: function(user){
                this.ccUsers.splice(this.ccUsers.indexOf(user), 1);
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .comment-author {
        font-style: italic;
        text-align: right;
        padding-bottom: 0.25em;
        border-bottom: 1px solid #ccc;
    }
</style>
