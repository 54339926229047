<template>
    <div id="container">
<div id="content">
            <div v-if="type == 'HaDecisionLetter'" style="float:right;">
                Other dates with HA decision letters:<br>
                <el-select size="mini" v-model="date" style="width:125px;">
                    <el-option v-for="date in dates" :key="date" :value="date.date" :label="date.date"></el-option>
                </el-select>
                <el-button size="mini" type="primary" @click="getDocuments">Select</el-button>
            </div>
            <h1>Document search results</h1>
            <el-table :default-sort="{prop: 'email', order: 'ascending'}" :data="documents" v-loading="loading" element-loading-text="Loading...">
                <el-table-column v-if="type == 'HaDecisionLetter'" width="45">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.sendDecisionLetter"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-if="type != 'HaNotificationLetterBatch' && type != 'HaDecisionLetter'" prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="100">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.first_name }}</a></template>
                </el-table-column>
                <el-table-column v-if="type != 'HaNotificationLetterBatch' && type != 'HaDecisionLetter'" prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="110">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.last_name }}</a></template>
                </el-table-column>
                <el-table-column v-if="type != 'HaNotificationLetterBatch' && type != 'HaDecisionLetter'" prop="preferred_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column v-if="type == 'HaDecisionLetter'" prop="last_name" label="Student Name" sortable :sort-orders="['ascending', 'descending']" width="145">
                    <template slot-scope="scope"><a :href="'/student/ha-profile/' + scope.row.student_id">{{ scope.row.first_name }} {{ scope.row.last_name }}</a></template>
                </el-table-column>
                <el-table-column v-if="type != 'HaNotificationLetterBatch'" prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']" width="165">
                    <template slot-scope="scope"><a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a></template>
                </el-table-column>
                <el-table-column prop="type_display" label="Type" sortable :sort-orders="['ascending', 'descending']" :width="type == 'HaNotificationLetterBatch' ? '200' : '125'"></el-table-column>
                <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']" :width="type == 'HaNotificationLetterBatch' ? '225' : '125'"></el-table-column>
                <el-table-column prop="status_display" label="Status" sortable :sort-orders="['ascending', 'descending']" width="85"></el-table-column>
                <el-table-column prop="asset_file_name" label="File" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope"><a class="no-break" :href="'/api/document/download/' + scope.row.id" target="_blank">{{ scope.row.asset_file_name }}</a></template>
                </el-table-column>
                <el-table-column v-if="type == 'HaDecisionLetter'" prop="asset_file_name" label="Preview Email" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope"><a class="no-break" :href="'/api/document/download/' + scope.row.id" target="_blank">{{ scope.row.asset_file_name }}</a></template>
                </el-table-column>
                <el-table-column prop="last_updated_at" label="Last Updated" sortable :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}</template></el-table-column>
                <el-table-column prop="doc_date" label="Date" sortable :sort-orders="['ascending', 'descending']" width="100"><template slot-scope="scope">{{ scope.row.doc_date | formatDateMDY }}</template></el-table-column>
                <el-table-column v-if="type != 'HaNotificationLetterBatch'" width="53"><template slot-scope="scope"><a :href="'/document/edit/' + scope.row.id + '?student_id=' + scope.row.student_id">edit</a></template></el-table-column>
            </el-table>
            <a v-if="type != 'HaDecisionLetter'" :href="csvUrl" target="_blank">Download as CSV</a>
            <div style="padding-top:3px;">
                <a v-if="type == 'HaDecisionLetter'" @click="checkAllLetters">Check all</a>
                <a v-if="type == 'HaDecisionLetter'" @click="clearAllLetters">Clear all</a>
                <el-button v-if="type == 'HaDecisionLetter'" size="mini" type="primary" @click="sendDecisionLetters">Send HA Decision Letters</el-button>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: "Documents List"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                documents: [],
                type: null,
                status: null,
                name: null,
                date: moment(new Date()).format('YYYY-MM-DD'),
                dates: [],
                loading: false
            }
        },
        computed: {
            csvUrl: function() {
                var url = '/api/document/documents-by-type.xlsx?'
                if(this.type){
                    url += 'type=' + this.type;
                }
                if(this.status){
                    if(this.type) url += '&';
                    url += 'status=' + this.status;
                }
                if(this.name){
                    if(this.type || this.status) url += '&';
                    url += 'name=' + this.name;
                }

                return url;
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.type = this.urlParams.type;
                this.status = this.urlParams.status;
                if(this.urlParams.name){
                    this.name = decodeURI(this.urlParams.name);
                }

                if(this.type == "HaDecisionLetter"){
                    this.getDocuments();
                    axios.get("/api/document/ha-decision-letter-dates/")
                        .then(response => {
                            this.dates = response.data.rows;
                            if(this.dates.length){
                                this.date = this.dates[0].date;
                            }
                        });
                } else {
                    this.getDocuments();
                }
            },
            getDocuments: function(){
                this.documents = [];
                this.loading = true;
                axios.get("/api/document/documents-by-type/", {params: {type: this.type, status: this.status, name: this.name, date: this.date}})
                    .then(response => {
                        this.documents = response.data.rows;
                        this.clearAllLetters();
                        this.loading = false;
                    });
            },
            checkAllLetters: function(){
                for(var i = 0; i < this.documents.length; i++){
                    this.$set(this.documents[i], 'sendDecisionLetter', true);
                }
            },
            clearAllLetters: function(){
                for(var i = 0; i < this.documents.length; i++){
                    this.$set(this.documents[i], 'sendDecisionLetter', false);
                }
            },
            sendDecisionLetters: function(){
                this.$alert("Placeholder for send HA decision letters");
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    table td, table th {
        font-size:11.7px;
    }

    .no-break {
        word-break:break-all;
    }

    h1 {
        padding-bottom:.9em;
    }
</style>
