<template>
    <div id="container">
<div id="content">
            <student-nav selected="documents" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div class="document-upload-links">
                <h2>Upload ODS Documents</h2>
                <ul>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=ApplicationDocument'">Enter a new ODS application</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=DisabilityDocument'">Enter new disability documentation</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=RequestForSupplementalAccommodation'">Enter a new Request for Supplemental Accommodations and Services</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=AppealDocument'">Enter a new accommodation appeal document</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=OtherDocument'">Enter another type of document</a></li>
                </ul>
            </div>
            <div v-if="hasHousingProfile" class="document-upload-links">
                <h2>Upload HA Documents</h2>
                <ul>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaApplicationDocument'">HA application</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaDisabilityDocument'">HA disability documentation</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaDecisionLetter'">HA decision letter</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaAppealDocument'">HA appeal request</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaAppealDisabilityDocument'">HA appeal disability documentation</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaAppealDecisionLetter'">HA appeal decision letter</a></li>
                    <li><a :href="'/document/edit?student_id=' + id + '&type=HaOtherDocument'">Other</a></li>
                </ul>
            </div>
            <h2>Documents</h2>
            <student-documents v-for="category in documentsAndCategories" :key="category.category" :title="category.category" :studentId="id" :documents="category.documents"></student-documents>
            <p v-if="documentsAndCategories.length === 0">There are no documents to display.</p>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
     import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                hasHousingProfile: false,
                documentsAndCategories: []
            }
        },
        methods: {
            getDocuments: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/documents/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                        this.hasHousingProfile = response.data.hasHousingProfile;
                    });

                axios.get('/api/document/non-accommodation-letter-documents-for-student/', {params:{student_id: this.id}})
                    .then(response => {
                        this.documentsAndCategories = response.data.rows;
                    })
                    .catch(error => { // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.getDocuments();
        }
    }
</script>

<style scoped>
    p {
        font-style:italic
    }
    .document-upload-links{
        float:left;
        width:40em;
    }
</style>
