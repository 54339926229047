<template>
    <div id="container">
<div id="content">
            <student-nav selected="ha-profile" :id="id" :name="name" :uni="uni" :haStatus="haStatus"></student-nav>
            <br clear="all">
            <student-ha-workflow-and-status :id="id" :name="name"></student-ha-workflow-and-status>
            <student-information :id="id" :showReferral="false"></student-information>
            <student-contact-information :id="id"></student-contact-information>
            <student-history :id="id" :isAcademic="false"></student-history>
        </div>
        </div>
</template>

<script>
     import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | : " + this.haStatus
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                haStatus: ""
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/ha-profile/')[1];
                axios.get("/api/student/" + this.id, { params: { id: this.id }})
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.haStatus = response.data.haStatus;
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
