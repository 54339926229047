<template>
    <div id="container">
<div id="content">
            <h1>{{ task.subject }}</h1>
            <table><tbody>
                <tr>
                    <th>Task Date</th>
                    <td>{{ task.task_date | formatDateMDY }}</td>
                </tr>
                <tr>
                    <th>Subject</th>
                    <td>{{ task.subject }}</td>
                </tr>
                <tr>
                    <th>Author</th>
                    <td>{{ task.author_name }}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{{ task.type }}</td>
                </tr>
                <tr>
                    <th>Recipients / Assigned to</th>
                    <td>{{ recipients }}</td>
                </tr>
                <tr>
                    <th>Student</th>
                    <td><a :href="'/student/profile/' + task.student_id">{{ task.student_name }}<template v-if="task.student_uni"> &lt;{{ task.student_uni }}&gt;</template></a></td>
                </tr>
                <tr>
                    <th>Text</th>
                    <td><p><span class="formatted-text">{{ task.body }}</span></p></td>
                </tr>
            </tbody></table>
            <p><a :href="'/tasks/future-tasks/'">Show future tasks</a> <a v-if="!task.is_sent" :href="'/tasks/future-tasks/edit/' + this.id">Edit Task</a> <a v-if="!task.is_sent" @click="sendTaskNow">Send this task NOW</a></p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.task.subject
            }
        },
        components: {
            },
        data () {
            return {
                id: 0,
                task: {},
                showCommentSection: false,
                commentText: null,
                ccUsers: [],
                ccUsersStr: null
            }
        },
        computed: {
            recipients: function(){
                if(this.task.recipients){
                    var recipients = [];
                    this.task.recipients.forEach(function(obj){
                        recipients.push(obj.name + " <" + obj.email + ">");
                    });

                    return recipients.join(", ");
                }
                return "";
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/tasks/future-tasks/show/')[1];
                this.getTask();
            },
            getTask: function(){
                axios.get("/api/future-task/", {params: {id: parseInt(this.id)}})
                    .then(response => {
                        this.task = response.data.task;

                    });
            },
            sendTaskNow: function(){
                axios.post('/api/future-task/send-future-task-now/', {id: parseInt(this.id)})
                    .then(response => {
                        if(response.data.success){
                          this.$router.push({ path: '/tasks/future-tasks'});
                        }
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .comment-author {
        font-style: italic;
        text-align: right;
        padding-bottom: 0.25em;
        border-bottom: 1px solid #ccc;
    }
</style>
