<template>
    <div id="container">
        <div id="content">
            <h1>Contact Notes</h1>
            <div style="float:left; margin-right:100px;">
                <h2>List by Author</h2>
                <table><tbody>
                    <tr><td colspan="2">Active Users</td></tr>
                    <tr v-for="author in activeUsers" :key="author.id">
                        <td><a :href="'/contact-notes/author/' + author.id">{{ author.name }}</a></td>
                        <td>{{ author.count }}</td>
                    </tr>
                    <tr><td colspan="2" style="padding-top:20px;">Inactive Users</td></tr>
                    <tr v-for="author in inactiveUsers" :key="author.id">
                        <td><a :href="'/contact-notes/author/' + author.id">{{ author.name }}</a></td>
                        <td>{{ author.count }}</td>
                    </tr>
                </tbody></table>
            </div>
            <div style="float:left; width:900px;">
                <h2>Search Contact Notes</h2>
                <el-form style="width:100%">
                    <el-form-item>
                        <span class="label">Search item 1 </span><el-select style="width: 100px" v-model="contactNotesSearchField1" placeholder="Please Select" size="mini">
                            <el-option v-for="item in contactNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="contactNotesSearchTerm1" style="width:350px;" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="label">Search item 2 </span><el-select style="width: 100px" v-model="contactNotesSearchField2" placeholder="Please Select" size="mini">
                            <el-option v-for="item in contactNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="contactNotesSearchTerm2" style="width:350px;" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="label">Search item 3 </span><el-select style="width: 100px" v-model="contactNotesSearchField3" placeholder="Please Select" size="mini">
                            <el-option v-for="item in contactNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="contactNotesSearchTerm3" style="width:350px;" size="mini"></el-input>
                        <el-button type="primary"  size="mini" @click="handleContactNotesSearch">Search</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="float:left; width:900px;">
                <h2>Search Intake & Housing Intake Notes</h2>
                <el-form style="width:100%">
                    <el-form-item>
                        <span class="label">Search item 1 </span><el-select style="width: 100px" v-model="intakeNotesSearchField1" placeholder="Please Select" size="mini">
                            <el-option v-for="item in intakeNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="intakeNotesSearchTerm1" style="width:350px;" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="label">Search item 2 </span><el-select style="width: 100px" v-model="intakeNotesSearchField2" placeholder="Please Select" size="mini">
                            <el-option v-for="item in intakeNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="intakeNotesSearchTerm2" style="width:350px;" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="label">Search item 3 </span><el-select style="width: 100px" v-model="intakeNotesSearchField3" placeholder="Please Select" size="mini">
                            <el-option v-for="item in intakeNotesOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="intakeNotesSearchTerm3" style="width:350px;" size="mini"></el-input>
                        <el-button type="primary"  size="mini" @click="handleIntakeNotesSearch">Search</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <h2>List by Status</h2>
            <table><tbody>
                <tr v-for="(status, index) in statuses" :key="index">
                    <td><a :href="'contact-notes/status/' + status.status">{{ status.status_display }}</a></td>
                    <td>{{ status.count }}</td>
                </tr>
            </tbody></table>
            <p><a :href="'/contact-notes/edit'">Enter a new contact note</a><br>(Use this link to create a contact note or a contact note draft online)</p>
            <p><a :href="'/contact-notes/list-templates'">Edit contact note templates</a><br>(Use this link to create or edit a contact note template)</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: 'Contact Notes'
            }
        },
        components: {
            },
        data () {
            return {
                activeUsers: [],
                inactiveUsers: [],
                statuses: [],
                contactNotesSearchField1: 'subject',
                contactNotesSearchTerm1: '',
                contactNotesSearchField2: 'subject',
                contactNotesSearchTerm2: '',
                contactNotesSearchField3: 'subject',
                contactNotesSearchTerm3: '',
                contactNotesOptions: [{
                    'value': 'subject',
                    'label': 'Subject'
                }, {
                    'value': 'author',
                    'label': 'Author'
                }, {
                    'value': 'text',
                    'label': 'Text'
                }, {
                    'value': 'student',
                    'label': 'Student'
                }, {
                    'value': 'status',
                    'label': 'Status'
                }],
                intakeNotesSearchField1: 'content',
                intakeNotesSearchTerm1: '',
                intakeNotesSearchField2: 'content',
                intakeNotesSearchTerm2: '',
                intakeNotesSearchField3: 'content',
                intakeNotesSearchTerm3: '',
                intakeNotesOptions: [{
                    'value': 'content',
                    'label': 'Content'
                }, {
                    'value': 'student',
                    'label': 'Student'
                }, {
                    'value': 'author',
                    'label': 'Author'
                }]
            }
        },
        methods: {
            load: function () {
                axios.get("/api/contact-note/counts-by-author/")
                    .then(response => {
                        this.activeUsers = response.data.active_users;
                        this.inactiveUsers = response.data.inactive_users;
                    });

                axios.get("/api/contact-note/counts-by-status/")
                    .then(response => {
                        this.statuses = response.data.rows;
                    });
            },
            handleContactNotesSearch: function() {
              let query = {};
                //var url = '/contact-notes/search?';
                if(this.contactNotesSearchTerm1){
                  query.search_field_1 = this.contactNotesSearchField1;
                  query.search_term_1 = this.contactNotesSearchTerm1;
                  //url += 'search_field_1=' + this.contactNotesSearchField1 + '&' + 'search_term_1=' + this.contactNotesSearchTerm1;
                }
                if(this.contactNotesSearchTerm2){
                  //  if(this.contactNotesSearchTerm1) url += '&';
                  query.search_field_2 = this.contactNotesSearchField2;
                  query.search_term_2 = this.contactNotesSearchTerm2;
                  //url += 'search_field_2=' + this.contactNotesSearchField2 + '&' + 'search_term_2=' + this.contactNotesSearchTerm2;
                }
                if(this.contactNotesSearchTerm3){
                  query.search_field_3 = this.contactNotesSearchField3;
                  query.search_term_3 = this.contactNotesSearchTerm3;
                  //  if(this.contactNotesSearchTerm1 || this.contactNotesSearchTerm2) url += '&';
                  //  url += 'search_field_3=' + this.contactNotesSearchField3 + '&' + 'search_term_3=' + this.contactNotesSearchTerm3;
                }

                //window.location = url;
                this.$router.push({ path: '/contact-notes/search', query: query });
            },
            handleIntakeNotesSearch: function() {
              let query = {};
                //var url = '/intake-notes/search?';
                if(this.intakeNotesSearchTerm1){
                  query.search_field_1 = this.intakeNotesSearchField1;
                  query.search_term_1 = this.intakeNotesSearchTerm1;
                  //  url += 'search_field_1=' + this.intakeNotesSearchField1 + '&' + 'search_term_1=' + this.intakeNotesSearchTerm1;
                }
                if(this.intakeNotesSearchTerm2){
                  query.search_field_2 = this.intakeNotesSearchField2;
                  query.search_term_2 = this.intakeNotesSearchTerm2;
                  //if(this.intakeNotesSearchTerm1) url += '&';
                  //url += 'search_field_2=' + this.intakeNotesSearchField2 + '&' + 'search_term_2=' + this.intakeNotesSearchTerm2;
                }
                if(this.intakeNotesSearchTerm3){
                  query.search_field_3 = this.intakeNotesSearchField3;
                  query.search_term_3 = this.intakeNotesSearchTerm3;
                  //if(this.intakeNotesSearchTerm1 || this.intakeNotesSearchTerm2) url += '&';
                  //url += 'search_field_3=' + this.intakeNotesSearchField3 + '&' + 'search_term_3=' + this.intakeNotesSearchTerm3;
                }

              //window.location = url;
              this.$router.push({ path: '/intake-notes/search', query: query });
            }
        },
        created () {
            this.load();
        }
    }
</script>

<style scoped>
    .label {
        font-size: small;
    }
</style>
