<template>
  <div id="container">
    <div id="content">
      <h1>Edit Proctoring Location</h1>
      <el-form :model="proctoringLocation" :rules="rules" ref="proctoringLocationForm" label-width="160px" label-position="left">
        <el-form-item label="Building" prop="building">
          <el-input size="mini" v-model="proctoringLocation.building" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Room">
          <el-input size="mini" v-model="proctoringLocation.room" style="width:250px;"></el-input>
        </el-form-item>
        <el-form-item label="Wheelchair accessible?">
          <el-checkbox style="margin-top:6px;" v-model="proctoringLocation.wheelchair_accessible"></el-checkbox>
        </el-form-item>
        <el-form-item label="Is Graduate Location?">
          <el-checkbox style="margin-top:6px;" v-model="proctoringLocation.is_graduate_location"></el-checkbox>
        </el-form-item>
        <el-form-item label="Is Active?">
          <el-checkbox style="margin-top:6px;" v-model="proctoringLocation.is_active"></el-checkbox>
        </el-form-item>
        <el-form-item label="Room Capacity">
          <el-input-number size="mini" v-model="proctoringLocation.room_capacity" :min="0" controls-position="right" class="room-capacity-input"></el-input-number>
        </el-form-item>
        <el-form-item label="Lock Room (no students can be added) ">
          <el-checkbox v-model="proctoringLocation.is_locked"></el-checkbox>
        </el-form-item>
        <el-form-item label="Seating Style">
          <el-select size="mini" v-model="proctoringLocation.seating_style" style="width:300px;">
            <el-option :key="''" :value="null" :label="''"></el-option>
            <el-option v-for="style in seatingStyles" :key="style" :label="style" :value="style"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="proctoringLocation.seating_style == 'Other'" label="Other Seating Style">
          <el-input size="mini" v-model="proctoringLocation.other_seating_style" style="width:250px;"></el-input>
        </el-form-item>
      </el-form>

      <el-form v-if="proctoringLocation.room_notes && proctoringLocation.room_notes.length" label-width="160px" label-position="left">
        <template v-for="(note, index) in proctoringLocation.room_notes">
          <el-form-item label="Room Note" :key="note.id">
            <el-select size="mini" v-model="note.note" style="width:525px;">
              <el-option :key="''" :value="null" :label="''"></el-option>
              <el-option v-for="option in roomNoteOptions" :key="option" :label="option" :value="option"></el-option>
            </el-select>
            <span class="form-text"><a @click="handleRemoveRoomNote(index)">remove</a></span>
          </el-form-item>
          <el-form-item v-if="note.note == 'Other'" label="Other" :key="note.id">
            <el-input type="textarea" :rows="2" v-model="note.note_other" style="width:250px;"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <a @click="addRoomNote">[+] Add room note</a><br>

      <template v-if="proctoringLocation.room_diagrams && proctoringLocation.room_diagrams.length">
        <h4>Existing diagrams:</h4>
        <table><tbody>
        <tr v-for="(diagram, index) in proctoringLocation.room_diagrams" :key="diagram.id">
          <td><a :href="'/api/document/download/' + diagram.id" target="_blank">{{ diagram.asset_file_name }}</a></td>
          <td><a @click="deleteFile(index)">[-] Delete File</a></td>
        </tr>
        </tbody></table>
      </template>

      <h4>Upload Room Diagram or Picture:</h4>
      <el-upload drag action="/api/proctoring-location/upload-files/" ref="upload" :auto-upload="false" :file-list="fileList"
                 :on-change="onChange" :on-remove="onRemove">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      </el-upload>

      <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "Edit Proctoring Location"
      }
    },
    components: {
    },
    data () {
      return {
        proctoringLocation: {
          id: null,
          building: null,
          room: null,
          room_capacity: 0,
          seating_style: null,
          other_seating_style: null,
          wheelchair_accessible: false,
          is_graduate_location: false,
          is_active: false,
          room_notes: [],
          room_note_ids_to_delete: [],
          room_diagram_ids_to_delete: []
        },
        rules: {
          building: [{ required: true, whitespace: true, message: 'Building is required' }]
        },
        fileList: [],
        seatingStyles: [],
        roomNoteOptions: []
      }
    },
    methods: {
      load: function(){
        this.proctoringLocation.id = location.pathname.split('/proctoring-location/edit/')[1];
        if(this.proctoringLocation.id){
          this.proctoringLocation.id = parseInt(this.proctoringLocation.id);
          this.getProctoringLocation();
        }

        axios.get("/api/proctoring-location/form-items/")
          .then(response => {
            this.seatingStyles = response.data.seating_styles;
            this.roomNoteOptions = response.data.room_note_options;
          });
      },
      getProctoringLocation: function(){
        axios.get("/api/proctoring-location/", {params: {id: this.proctoringLocation.id}})
          .then(response => {
            this.proctoringLocation = response.data.proctoring_location;
            if(!this.proctoringLocation.room_notes){
              this.proctoringLocation.room_notes = [];
            }
            this.proctoringLocation.room_note_ids_to_delete = [];
            this.proctoringLocation.room_diagram_ids_to_delete = [];
          });
      },
      onSubmit: function(){
        this.$refs["proctoringLocationForm"].validate((valid) => {
          if(valid){
            if(this.proctoringLocation.id){
              axios.put('/api/proctoring-location/', this.proctoringLocation)
                .then(response => {
                  if(response.data.success){
                    if(this.fileList.length){
                      this.performUpload();
                    } else {
                      this.$router.push({ path: '/proctoring-location/show/' + this.proctoringLocation.id});
                    }
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              axios.post('/api/proctoring-location/', this.proctoringLocation)
                .then(response => {
                  if(response.data.success && response.data.proctoring_location_id){
                    this.proctoringLocation.id = response.data.proctoring_location_id;
                    if(this.fileList.length){
                      this.performUpload();
                    } else {
                      this.$router.push({ path: '/proctoring-location/show/' + this.proctoringLocation.id});
                    }
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          }
        });
      },
      performUpload: function() {
        var data = new FormData();
        data.append('id', parseInt(this.proctoringLocation.id));

        for(var i = 0; i < this.fileList.length; i++){
          if(this.fileList[i].raw){
            data.append('file', this.fileList[i].raw);
          }
        }

        axios.post('/api/proctoring-location/upload-files/', data)
          .then(response => {
              if(response.data.success){
                this.$router.push({ path: '/proctoring-location/show/' + this.proctoringLocation.id});
              } else {
                this.$alert("Error: "+response.data.message);
              }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      },
      addRoomNote: function(){
        this.proctoringLocation.room_notes.push({note: null, note_other: null})
      },
      handleRemoveRoomNote: function(index){
        if(this.proctoringLocation.id){
          this.$confirm('Are you sure you want to remove this room note?')
            .then(() => {
              this.removeRoomNote(index);
            }).catch(() => {});
        } else {
          this.removeRoomNote(index);
        }
      },
      removeRoomNote: function(index){
        if(this.proctoringLocation.room_notes[index].id){
          this.proctoringLocation.room_note_ids_to_delete.push(this.proctoringLocation.room_notes[index].id);
        }
        this.proctoringLocation.room_notes.splice(index, 1);
      },
      deleteFile: function(index){
        this.$confirm('Are you sure you want to delete this file?')
          .then(() => {
            this.proctoringLocation.room_diagram_ids_to_delete.push(this.proctoringLocation.room_diagrams[index].id);
            this.proctoringLocation.room_diagrams.splice(index, 1);
          }).catch(() => {});
      },
      onChange: function(file, fileList){
        this.fileList = fileList;
      },
      onRemove: function(file, fileList){
        this.fileList = fileList;
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .el-form {
    width: 735px;
  }
  /*.el-input-number .el-input-number__increase {*/
  /*    width: 12px;*/
  /*}*/
  /*.el-input-number .el-input-number__decrease {*/
  /*    width: 12px;*/
  /*}*/
  /*.room-capacity-input {*/
  /*    width: 65px;*/
  /*}*/
  /*#content .el-input-number.is-controls-right .el-input__inner {*/
  /*    padding-right: 23.5px;*/
  /*    padding-left: 11.5px;*/
  /*}*/
</style>
