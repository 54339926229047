<template>
  <div id="container">
    <div id="content">
      <br><div><b>Users</b> | <a :href="'/semester/list'">Academic Calendar</a> | <a :href="'/trace'">Trace</a></div>
      <h1>System Administration: Users</h1>
      <el-table :default-sort="{prop: 'first_names', order: 'ascending'}" :data="activeUsers">
        <el-table-column prop="first_names" label="First" width="90" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="last_name" label="Last" width="110" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="email" label="Email" width="235" sortable :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope"><a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a></template>
        </el-table-column>
        <el-table-column label="Role">
          <template slot-scope="scope">{{ scope.row.roles.join(", ") }}</template>
        </el-table-column>
        <el-table-column prop="last_login_at" label="Last Login" width="115" sortable :sort-orders="['ascending', 'descending']">
          <template slot-scope="scope">
            {{scope.row.last_login_at | formatDateMDY}}
          </template>
        </el-table-column>
        <el-table-column width="237">
          <template slot-scope="scope">
            <a :href="'/admin/edit-user/' + scope.row.id">Edit</a>&nbsp;
            <a @click.prevent="deactiveUser(scope.row.id, scope.row.first_names + ' ' + scope.row.last_name)">Deactivate</a>&nbsp;
            <a @click.prevent="logInAsUser(scope.row.email)">Log in as user</a>&nbsp;
          </template>
        </el-table-column>
      </el-table>
      <a :href="'/admin/edit-user'">Create a new user</a>

      <h2>Deactivated users</h2>
      <el-table :default-sort="{prop: 'first_names', order: 'descending'}" :data="inactiveUsers" :show-header="false" style="width:75%;">
        <el-table-column prop="first_names" width="90" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="last_name" width="130" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="job_title" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column width="140">
          <template slot-scope="scope"><a @click.prevent="activeUser(scope.row.id, scope.row.first_names + ' ' + scope.row.last_name)">Make active again</a></template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    metaInfo () {
      return {
        title: 'System Administration: Users'
      }
    },
    components: {
    },
    data () {
      return {
        activeUsers: [],
        inactiveUsers: []
      }
    },
    methods: {
      ...mapActions('auth', ['getUser']),

      load: function () {
        axios.get("/api/admin/active-users/")
          .then(response => {
            this.activeUsers = response.data.rows;
          });

        axios.get("/api/admin/inactive-users/")
          .then(response => {
            this.inactiveUsers = response.data.rows;
          });
      },
      deactiveUser: function(id, name){
        this.$confirm('Are you sure you want to deactivate the user record for ' + name + '?')
          .then(() => {
            axios.post('/api/admin/deactivate-user/', {id: id})
              .then(response => {
                this.load();
              })
              .catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
          }).catch(() => {
        });
      },
      activeUser: function(id, name){
        this.$confirm('Are you sure you wish to reactivate user ' + name + '?')
          .then(() => {
            axios.post('/api/admin/activate-user/', {id: id})
              .then(response => {
                this.load();
              })
              .catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
          }).catch(() => {
        });
      },
      logInAsUser: function(email){
        axios.post('/api/admin/login-as-user/', {username: email})
          .then(response => {
            this.getUser({
            }).then(token => {
              this.$router.push({path: '/home/'});
            });
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      }
    },
    created () {
      this.load();
    }
  }
</script>

<style scoped>
  .user-table td, .user-table th {
    border: none;
    font-size: 1em;
  }

  .user-table th {
    padding-left: 0px;
  }
</style>
