<template>
  <div id="container">
    <div id="content">
      <h1>Edit Intake Note</h1>
      <el-form label-width="200px" label-position="left">
        <el-form-item label="Intake Note">
          <el-input size="mini" type="textarea" :rows="15" v-model="content"></el-input>
        </el-form-item>
        <el-form-item label="Status">
          <el-select size="mini" v-model="status" style="width:100px">
            <el-option label="Active" value="active"></el-option>
            <el-option label="Archived" value="archived"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" @click="onSubmit">Save Changes</el-button> or <a :href="'/student/profile/' + studentId">Cancel and return to student record</a><br>
      <a @click="deleteNote">Delete Intake Note</a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: this.urlParams.housing ? "Edit Housing Intake Note" : "Edit Intake Note"
      }
    },
    components: {
    },
    data () {
      return {
        urlParams: {},
        id: null,
        studentId: null,
        content: null,
        status: null
      }
    },
    methods: {
      load: function() {
        this.id = location.pathname.split('/intake-notes/edit/')[1];
        axios.get('/api/intake-note/', {params: {id: parseInt(this.id)}})
          .then(response => {
            this.content = response.data.content;
            this.status = response.data.status;
            this.studentId = response.data.student_id;
          });
      },
      onSubmit: function(){
        this.$message.closeAll();

        if(this.content && this.content.trim() != ''){
          axios.put('/api/intake-note/', {id: parseInt(this.id), status: this.status, content: this.content})
            .then(response => {
              if(response.data.success){
                this.$router.push({ path: '/student/profile/' + this.studentId });
              }
            });
        } else {
          this.$message({
            showClose: true,
            duration: 20000,
            type: 'error',
            message: "One error prohibited this intake note from being saved: Content can't be blank."
          });
        }
      },
      deleteNote: function(){
        this.$confirm('Are you sure you want to delete this intake note?')
          .then(() => {
            axios.delete("/api/intake-note/", {params: {id: parseInt(this.id)}})
              .then(response => {
                this.$router.push({ path: '/student/profile/' + this.studentId });
              });
          }).catch(() => {
          // do nothing
        });
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  #content {
    width:600px;
  }
</style>
