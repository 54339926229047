<template>
    <div id="container">
<div id="content">
            <h1>Proctoring Sessions between {{ startDate | formatDateMDY }} and {{ endDate | formatDateMDY }}</h1>
            <div style="margin-bottom:2px;">
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="startDate" value-format="yyyy-MM-dd"></el-date-picker> -
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="endDate" value-format="yyyy-MM-dd"></el-date-picker><br>
            </div>
            Use drop-down menu to select entire semester:
            <el-select size="mini" v-model="semesterId">
                <el-option label="Select a Semester" :value="null"></el-option>
                <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
            </el-select> <el-button size="mini" type="primary" @click="onFilterProctoringSessions">Submit</el-button>

            <p><a :href="'/proctoring-session/edit'">Add new proctoring session</a></p>

            <p>
                <a class="trailing-space" @click="toggleBooleans('deleted')" v-if="!showDeleted">Show deleted sessions</a>
                <a class="trailing-space" @click="toggleBooleans('deleted')" v-if="showDeleted">Unshow deleted sessions</a>
                <a class="trailing-space" v-if="!showGraduate" @click="toggleBooleans('graduate')">Show only graduate sessions</a>
                <a class="trailing-space" v-if="!showUndergraduate" @click="toggleBooleans('undergraduate')">Show only undergraduate sessions</a>
                <a class="trailing-space" v-if="showGraduate || showUndergraduate" @click="toggleBooleans('all')">Show all sessions</a>
                <a :href="'/proctoring-session-requestor/'">View Graduate Session Requestors</a>
            </p>

            <el-table :default-sort="{prop: 'start_time', order: 'ascending'}" :data="proctoringSessions" v-loading="loading" element-loading-text="Loading...">
                <el-table-column prop="location" label="Location" sortable :sort-orders="['ascending', 'descending']" width="110"></el-table-column>
                <el-table-column prop="start_time" label="Date" sortable :sort-orders="['ascending', 'descending']" width="90"><template slot-scope="scope">{{ scope.row.start_time | formatDateMDY }}</template></el-table-column>
                <el-table-column prop="start_time" label="Room Start time" sortable :sort-orders="['ascending', 'descending']" width="70"><template slot-scope="scope">{{ scope.row.start_time | formatTimehma }}</template></el-table-column>
                <el-table-column prop="end_time" label="Room End time" sortable :sort-orders="['ascending', 'descending']" width="65"><template slot-scope="scope">{{ scope.row.end_time | formatTimehma }}</template></el-table-column>
                <el-table-column prop="room_reserved" label="Room reserved?" sortable :sort-orders="['ascending', 'descending']" width="80"><template slot-scope="scope">{{ scope.row.room_reserved ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column v-if="!showGraduate" :key="'exams'" prop="exams" label="Exams" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="(exam, index) in scope.row.exams">
                            <template v-if="index !== 0">, </template><a :href="'/testing/show/' + exam.id" :key="index">{{ exam.class_name}}</a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column v-if="!showGraduate" :key="'students'" prop="students" label="Students" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="(student, index) in scope.row.students">
                            <template v-if="index !== 0">, </template><a :href="'/student/services/' + student.id" :key="index">{{ student.student_name}}</a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="service_schedules" label="Proctors" sortable :sort-orders="['ascending', 'descending']" width="135">
                    <template slot-scope="scope">
                        <template v-for="(service_schedule, index) in scope.row.service_schedules">
                            <br v-if="index !== 0" :key="index">{{ service_schedule.proctor_name}}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="service_schedules" label="Scheduled Hours" sortable :sort-orders="['ascending', 'descending']" width="125">
                    <template slot-scope="scope">
                        <template v-for="(service_schedule, index) in scope.row.service_schedules">
                            <br v-if="index !== 0" :key="index">{{ service_schedule.start_time | formatTimehma }} - {{ service_schedule.end_time | formatTimehma }}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="proctor_confirmation_sent" label="Proctor Conf. Emails Sent" sortable :sort-orders="['ascending', 'descending']" width="65"><template slot-scope="scope">{{ scope.row.proctor_confirmation_sent ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column prop="graduate_session" label="Graduate Session?" sortable :sort-orders="['ascending', 'descending']" width="65"><template slot-scope="scope" width="65">{{ scope.row.graduate_session ? "Yes" : "" }}</template></el-table-column>
                <el-table-column prop="num_students" label="# Students" sortable :sort-orders="['ascending', 'descending']" width="65"></el-table-column>
                <el-table-column label="View" width="45"><template slot-scope="scope"><a :href="'/proctoring-session/show/' + scope.row.id">view</a></template></el-table-column>
                <el-table-column label="Delete Session" width="65">
                    <template slot-scope="scope">
                        <a v-if="scope.row.show_delete_link && (!scope.row.service_schedules || scope.row.service_schedules.length == 0)" @click="deleteProctoringSession(scope.row.id)">delete</a>
                        <a v-if="scope.row.show_undelete_link" @click="undeleteProctoringSession(scope.row.id)">undelete</a>
                    </template>
                </el-table-column>
                <el-table-column prop="hours" label="Total Hours" width="75">
                    <template slot-scope="scope">
                        <template v-if="scope.row.hours">{{ scope.row.hours }} hours</template> <template v-if="scope.row.minutes">{{ scope.row.minutes }} minutes</template>
                    </template>
                </el-table-column>
            </el-table>
            <p><a :href="'/proctoring-session/edit'">Add new proctoring session</a></p>
            <p v-if="total"><a :href="csvUrl" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'


    export default {
        metaInfo () {
            return {
                title: "Proctoring Sessions between " + this.startDate + " and " + this.endDate
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                loading: false,
                startDate: moment(new Date()).format('YYYY-MM-DD'),
                endDate: moment(new Date()).format('YYYY-MM-DD'),
                semesters: [],
                semesterId: null,
                total: null,
                proctoringSessions: [],
                showDeleted: false,
                showGraduate: false,
                showUndergraduate: false
            }
        },
        computed: {
            csvUrl: function(){
                var url = '/api/proctoring-session/proctoring-sessions.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate + '&show_deleted=' + this.showDeleted + '&show_only_graduate_sessions=' + this.showGraduate + '&show_only_undergraduate_sessions=' + this.showUndergraduate;

                if(this.semesterId) url += '&semester_id=' + this.semesterId;

                return url;
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-service-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                    });

                this.urlParams = this.$route.query;
                if(this.urlParams.start_date) this.startDate = this.urlParams.start_date;
                if(this.urlParams.end_date) this.endDate = this.urlParams.end_date;

                this.getProctoringSessions();
            },
            getProctoringSessions: function() {
                this.proctoringSessions = [];
                this.loading = true;
                axios.get('/api/proctoring-session/proctoring-sessions/', {params: {start_date: this.startDate, end_date: this.endDate, semester_id: this.semesterId, show_deleted: this.showDeleted, show_only_graduate_sessions: this.showGraduate, show_only_undergraduate_sessions: this.showUndergraduate}})
                    .then(response => {
                        if(response.data.success) {
                            this.proctoringSessions = response.data.rows;
                            this.total = response.data.total;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onFilterProctoringSessions: function(){
                if(this.semesterId){
                    var semesterId = this.semesterId;
                    var semester = this.semesters.find(function(semesterObj){
                            return semesterObj.id == semesterId;
                        });
                    if(semester){
                        this.startDate = semester.start_date;
                        this.endDate = semester.end_date;
                    }
                }
                this.getProctoringSessions();
            },
            toggleBooleans: function(val){
                this.showGraduate = false;
                this.showUndergraduate = false;

                if(val === 'deleted'){
                    this.showDeleted = !this.showDeleted;
                } else if(val === 'graduate'){
                    this.showGraduate = true;
                } else if(val === 'undergraduate'){
                    this.showUndergraduate = true;
                }

                this.getProctoringSessions();
            },
            deleteProctoringSession: function(id){
                this.$confirm('This will delete the proctoring session. Are you sure?')
                    .then(() => {
                        axios.post('/api/proctoring-session/delete/', {id: id})
                            .then(response => {
                                this.getProctoringSessions();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                              console.log(error);
                            });
                    }).catch(() => {});
            },
            undeleteProctoringSession: function(id){
                this.$confirm('This will undelete the proctoring session. Are you sure?')
                    .then(() => {
                        axios.post('/api/proctoring-session/undelete/', {id: id})
                            .then(response => {
                                this.getProctoringSessions();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {});
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-table td {
        vertical-align: top;
    }
</style>
