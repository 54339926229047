<template>
    <div id="container">
<div id="content">
            <student-nav selected="contact-notes" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <student-contact-notes :id="id" :contactNotes="contactNotes" :isCurrentYear="!showAllContactNotes">
                <a v-if="showAllContactNotes" class="toggle-link" @click="toggleShowAllContactNotes">Show only notes for current year</a>
                <a v-if="!showAllContactNotes" class="toggle-link" @click="toggleShowAllContactNotes">Show notes for all years</a>
            </student-contact-notes>
            <a :href="'/contact-notes/edit?student_id=' + this.id">Enter a new contact note</a><br>(Use this link to create a contact note or a contact note draft online)
            <student-emails :emails="emailsToStudent" :title="'Emails to Student'" :showCCField="false">
                <a v-if="showAllEmailsToStudent" class="toggle-link" @click="toggleShowAllEmailsToStudent">Show only emails for current year</a>
                <a v-if="!showAllEmailsToStudent" class="toggle-link" @click="toggleShowAllEmailsToStudent">Show emails for all years</a>
            </student-emails>
            <student-emails :emails="emailsIncludingStudent" :title="'Group Emails Including Student'" :showCCField="false">
                <a v-if="showAllEmailsIncludingStudent" class="toggle-link" @click="toggleShowAllEmailsIncludingStudent">Show only emails for current year</a>
                <a v-if="!showAllEmailsIncludingStudent" class="toggle-link" @click="toggleShowAllEmailsIncludingStudent">Show emails for all years</a>
            </student-emails>
            <student-emails :emails="emailsRegardingStudent" :title="'Group Emails Regarding this Student'" :showCCField="true">
                <a v-if="showAllEmailsRegardingStudent" class="toggle-link" @click="toggleShowAllEmailsRegardingStudent">Show only emails for current year</a>
                <a v-if="!showAllEmailsRegardingStudent" class="toggle-link" @click="toggleShowAllEmailsRegardingStudent">Show emails for all years</a>
            </student-emails>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    import showdown from 'showdown'
    const converter = new showdown.Converter()

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {

        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                contactNotes: [],
                emailsToStudent: [],
                emailsIncludingStudent: [],
                emailsRegardingStudent: [],
                showAllContactNotes: false,
                showAllEmailsToStudent: false,
                showAllEmailsIncludingStudent: false,
                showAllEmailsRegardingStudent: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/notes/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                axios.get("/api/contact-note/contact-notes-for-student", {params:{id: this.id, showAll: this.showAllContactNotes}})
                    .then(response => {
                        this.contactNotes = response.data.rows;

                        this.contactNotes.forEach(function(note){
                            if(note.num_files === 0){
                                note.num_files = "None";
                            }
                        });
                    });

                axios.get("/api/group-email/group-emails-for-student/", {params:{id: this.id, showAll: this.showAllEmailsToStudent}})
                    .then(response => {
                        this.emailsToStudent = response.data.rows;

                        this.emailsToStudent.forEach(function(email){
                            email.body = converter.makeHtml(email.body);
                        })
                    });

                axios.get("/api/group-email/group-emails-including-student/", {params:{id: this.id, showAll: this.showAllEmailsIncludingStudent}})
                    .then(response => {
                        this.emailsIncludingStudent = response.data.rows;

                        this.emailsIncludingStudent.forEach(function(email){
                            email.body = converter.makeHtml(email.body);
                        })
                    });

                axios.get("/api/group-email/group-emails-regarding-student/", {params:{id: this.id, showAll: this.showAllEmailsIncludingStudent}})
                    .then(response => {
                        this.emailsRegardingStudent = response.data.rows;

                        this.emailsRegardingStudent.forEach(function(email){
                            email.body = converter.makeHtml(email.body);
                        })
                    });
            },
            toggleShowAllContactNotes: function(){
                this.showAllContactNotes = !this.showAllContactNotes;
                axios.get("/api/contact-note/contact-notes-for-student", {params:{id: this.id, showAll: this.showAllContactNotes}})
                    .then(response => {
                        this.contactNotes = response.data.rows;

                        this.contactNotes.forEach(function(note){
                            if(note.num_files === 0){
                                note.num_files = "None";
                            }
                        });
                    });
            },
            toggleShowAllEmailsToStudent: function(){
                this.showAllEmailsToStudent = !this.showAllEmailsToStudent;
                axios.get("/api/group-email/group-emails-for-student/", {params:{id: this.id, showAll: this.showAllEmailsToStudent}})
                    .then(response => {
                        this.emailsToStudent = response.data.rows;
                    });
            },
            toggleShowAllEmailsIncludingStudent: function(){
                this.showAllEmailsIncludingStudent = !this.showAllEmailsIncludingStudent;
                axios.get("/api/group-email/group-emails-including-student/", {params:{id: this.id, showAll: this.showAllEmailsIncludingStudent}})
                    .then(response => {
                        this.emailsIncludingStudent = response.data.rows;
                    });
            },
            toggleShowAllEmailsRegardingStudent: function(){
                this.showAllEmailsRegardingStudent = !this.showAllEmailsRegardingStudent;
                axios.get("/api/group-email/group-emails-regarding-student/", {params:{id: this.id, showAll: this.showAllEmailsRegardingStudent}})
                    .then(response => {
                        this.emailsRegardingStudent = response.data.rows;
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style scoped>
    .toggle-link {
        padding-bottom:3px;
    }
</style>
