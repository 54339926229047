<template>
    <div id="container">
<div id="content">
            <h1>Tasks</h1>
            <h2>Search Tasks</h2>
            <el-form style="width:100%">
                <el-form-item>
                    <span class="label">Search item 1 </span><el-select  style="width: 110px" v-model="searchField1" placeholder="Please Select" size="mini">
                        <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm1" style="width:350px;" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <span class="label">Search item 2 </span><el-select  style="width: 110px" v-model="searchField2" placeholder="Please Select" size="mini">
                        <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm2" style="width:350px;" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <span class="label">Search item 3 </span><el-select  style="width: 110px" v-model="searchField3" placeholder="Please Select" size="mini">
                        <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm3" style="width:350px;" size="mini"></el-input>
                    <el-button type="primary"  size="mini" @click="handleSearch">Submit</el-button>
                </el-form-item>
            </el-form>
            <div class="outer-block medium-block">
                <h2>List by Status</h2>
                <table><tbody>
                    <tr v-for="(status, index) in countsByStatus" :key="index">
                        <td><a :href="'tasks/status/' + status.status">{{ status.status_display }}</a></td>
                        <td>{{ status.count }}</td>
                    </tr>
                </tbody></table>
            </div>
            <div class="outer-block">
                <h2 class="text-centered" style="margin-left: -2em;">List by Workflow</h2>
                <div class="inner-block">
                    <h4>My pending Tasks</h4>
                    <table><tbody>
                        <tr v-for="task in countsPendingTasks" :key="task.id">
                            <td><a :href="'tasks/pending/' + task.status">{{ task.status_display }}</a></td>
                            <td>{{ task.count }}</td>
                        </tr>
                    </tbody></table>
                </div>
                <div class="inner-block">
                    <h4>Tasks I'm waiting for</h4>
                    <table><tbody>
                        <tr v-for="task in countsWaitingTasks" :key="task.id">
                            <td><a :href="'tasks/waiting/' + task.value">{{ task.display }}</a></td>
                            <td>{{ task.count }}</td>
                        </tr>
                    </tbody></table>
                </div>
            </div>
            <br clear="all">
            <p><a :href="'/tasks/edit'">Enter a new task/message</a><br>(Use this link to assign tasks to others or to send a message)</p>
            <p><a :href="'/tasks/future-tasks/edit'">New Future Task/Message</a><br>(Use this link to assign future tasks to others or to send a future message)</p>
            <p><a :href="'/tasks/future-tasks'">View future tasks</a></p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Tasks"
            }
        },
        components: {
            },
        data () {
            return {
                countsByStatus: [],
                countsPendingTasks: [],
                countsWaitingTasks: [],
                searchField1: 'subject',
                searchTerm1: null,
                searchField2: 'subject',
                searchTerm2: null,
                searchField3: 'subject',
                searchTerm3: null,
                searchOptions: [{
                    'value': 'subject',
                    'label': 'Subject'
                }, {
                    'value': 'author',
                    'label': 'Author'
                }, {
                    'value': 'text',
                    'label': 'Text'
                }, {
                    'value': 'student',
                    'label': 'Student'
                }, {
                    'value': 'status',
                    'label': 'Status'
                }, {
                    'value': 'recipients',
                    'label': 'Recipients'
                }],
            }
        },
        methods: {
            load: function(){
                axios.get("/api/task/task-counts-by-status/")
                    .then(response => {
                        this.countsByStatus = response.data.rows;
                    });

                axios.get("/api/task/count-pending-tasks/")
                    .then(response => {
                        this.countsPendingTasks = response.data.rows;
                    });

                axios.get("/api/task/count-waiting-tasks/")
                    .then(response => {
                        this.countsWaitingTasks = response.data.rows;
                    });
            },
            handleSearch: function() {
                var url = '/tasks/search?';
                if(this.searchTerm1){
                    url += 'search_field_1=' + this.searchField1 + '&' + 'search_term_1=' + this.searchTerm1;
                }
                if(this.searchTerm2){
                    if(this.searchTerm1) url += '&';
                    url += 'search_field_2=' + this.searchField2 + '&' + 'search_term_2=' + this.searchTerm2;
                }
                if(this.searchTerm3){
                    if(this.searchTerm1 || this.searchTerm2) url += '&';
                    url += 'search_field_3=' + this.searchField3 + '&' + 'search_term_3=' + this.searchTerm3;
                }
                this.$router.push({ path: url});
            },
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .label {
        font-size: small;
    }
    .el-form-item {
        margin-bottom: 0px;
    }
</style>
