<template>
    <div id="container">
<div id="content">
            <div style="width:80em;">
                <h1>E Text Services for <i>{{ etextService.class_name }}</i> (<a :href="'mailto:' + etextService.instructor_email">{{ etextService.instructor_name }}</a>) in {{ etextService.semester_name }}</h1>
                <etext-service-nav></etext-service-nav>
                <h2>{{ etextService.department }} {{ etextService.course_number }}, section {{ etextService.section_code }}</h2>
                <table><tbody>
                    <tr v-for="(enrollment, index) in etextService.service_enrollments" :key="index">
                        <th v-if="index === 0" :rowspan="etextService.service_enrollments.length">Students receiving e-text services</th>
                        <td><a :href="'/student/services/' + enrollment.id">{{ enrollment.student_name }}</a></td>
                        <td><a :href="'/group-email/compose/?student_id=' + enrollment.id + '&regarding_id=' + etextService.id + '&regarding_class=EtextService'">{{ enrollment.email }}</a></td>
                    </tr>
                </tbody></table>

                <div class="panel">
                    <h2>Actions taken to acquire reading list</h2>
                    <table style="width:100%;">
                        <thead><tr>
                            <th>Emailed advisor</th>
                            <th>Emailed student</th>
                            <th>Emailed professor</th>
                            <th>Emailed department chair</th>
                            <th>Checked Courseworks</th>
                            <th>Received required reading list</th>
                        </tr></thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in actionsTaken" :key="rowIndex">
                                <td><template v-if="row.emailedAdvisorChange">{{ row.emailedAdvisorChange.email }} {{ row.emailedAdvisorChange.created_at | formatDateMDY }}</template></td>
                                <td><template v-if="row.emailedStudentChange">{{ row.emailedStudentChange.email }} {{ row.emailedStudentChange.created_at | formatDateMDY }}</template></td>
                                <td><template v-if="row.emailedProfessorChange">{{ row.emailedProfessorChange.email }} {{ row.emailedProfessorChange.created_at | formatDateMDY }}</template></td>
                                <td><template v-if="row.emailedDeptChange">{{ row.emailedDeptChange.email }} {{ row.emailedDeptChange.created_at | formatDateMDY }}</template></td>
                                <td><template v-if="row.checkedCourseworksChange">{{ row.checkedCourseworksChange.email }} {{ row.checkedCourseworksChange.created_at | formatDateMDY }}</template></td>
                                <td><template v-if="row.receivedRequiredReadingListChange">{{ row.receivedRequiredReadingListChange.email }} {{ row.receivedRequiredReadingListChange.created_at | formatDateMDY }}</template></td>
                            </tr>
                        </tbody>
                        <tfoot><tr>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('emailed_advisor')">Done</el-button></td>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('emailed_student')">Done</el-button></td>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('emailed_professor')">Done</el-button></td>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('emailed_dept')">Done</el-button></td>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('checked_courseworks')">Done</el-button></td>
                            <td><el-button v-if="etextService.last_status_change !== 'received_required_reading_list'" size="mini" type="primary" @click="updateStatus('received_required_reading_list')">Done</el-button></td>
                        </tr></tfoot>
                    </table>
                    <el-button size="mini" type="primary" @click="updateStatus('undo')">Undo most recent action ({{ etextService.last_status_change_display }})</el-button>
                </div>

                <div class="panel">
                    <h2>Required Reading List</h2>
                    <h4>Syllabus / Required Reading List document</h4>
                    <p>
                        <el-upload drag action="/api/etext-service/upload-syllabus/" ref="syllabusUpload" :auto-upload="false" :limit="1" :data="{id: etextService.id}" :on-success="onUploadSyllabusSuccess">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                        <el-button size="mini" type="primary" @click="uploadSyllabus">Submit document</el-button><br>
                        <template v-if="etextService.asset_file_name">Current document on file is named <em><a :href="'/api/document/download/' + etextService.document_id" target="_blank">{{ etextService.asset_file_name }}</a></em><br>If you specify another file to upload here, the current document will be overwritten.</template>
                    </p>
                    <h4>Media Types</h4>
                    <p>To remove an unwanted type, uncheck the box and be sure the count is blank or zero</p>
                    <table><tbody>
                        <tr>
                            <th>Media type</th>
                            <th># of items on reading list</th>
                            <th># of types added</th>
                        </tr>
                        <tr v-for="(type, index) in etextService.media_types" :key="index">
                            <th>{{ type.media_type }}</th>
                            <td><template v-if="type.media_type != 'Unknown'">
                                <el-input-number size="mini" :controls="false" v-model="type.num_items_on_reading_list" style="width:50px;"></el-input-number> <el-checkbox v-model="type.is_visible"></el-checkbox>
                            </template></td>
                            <td>{{ type.num_of_types_added }}</td>
                        </tr>
                        <tr>
                            <th><el-input size="mini" v-model="newMediaType"></el-input></th>
                            <td><el-input-number size="mini" :controls="false" v-model="newNumItemsOnReadingList" style="width:50px;"></el-input-number>
                            </td>
                            <td></td>
                        </tr>
                    </tbody></table>
                    <el-button size="mini" type="primary" @click="onSubmitMediaTypes">Save changes</el-button><br>
                    <el-table :default-sort="{prop: 'title', order: 'ascending'}" style="margin-top:5px;" :style="{'width': showStatusChangeHistory ? '1200px' : '100%'}" :data="books" class="books-table table-top-align">
                        <el-table-column label="Edit" prop="title" width="103">
                            <template slot-scope="scope">
                                <a @click="editMedia(scope.row)">{{ scope.row.title ? scope.row.title : 'Unknown author and title' }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="ISBN" prop="isbn" sortable :sort-orders="['ascending', 'descending']" width="120"></el-table-column>
                        <el-table-column label="Status" prop="status_display" :width="showStatusChangeHistory ? '207' : '90'">
                            <template slot-scope="scope">
                                {{ scope.row.status_display }} on {{ scope.row.status_date | formatDateMDY }}<br>
                                <div v-if="showStatusChangeHistory" class="sections">
                                    <dl>
                                        <template v-for="change in scope.row.status_changes">
                                            <dt :key="change.id">{{ change.created_at | formatDateMDY }}</dt>
                                            <dd :key="change.id">Status set to {{ change.change_to }} by {{ change.name }}</dd>
                                        </template>
                                    </dl>
                                </div>
                                <a @click="showStatusChangeHistory = !showStatusChangeHistory">{{ showStatusChangeHistory ? "Hide" : "Show" }} status history</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Media type" prop="media_type" sortable :sort-orders="['ascending', 'descending']" width="73"></el-table-column>
                        <el-table-column label="Available from" prop="where_available" sortable :sort-orders="['ascending', 'descending']" width="78"></el-table-column>
                        <el-table-column label="Date needed" prop="date_needed" sortable :sort-orders="['ascending', 'descending']" width="87">
                            <template slot-scope="scope">
                                {{ scope.row.date_needed | formatDateMDY }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Comments" prop="comments" sortable :sort-orders="['ascending', 'descending']" width="87"></el-table-column>
                        <el-table-column label="Filename" sortable :sort-orders="['ascending', 'descending']" width="87">
                            <template slot-scope="scope">
                                <a :href="'/api/document/download/' + scope.row.document_id" target="_blank">{{ scope.row.asset_file_name }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Provided To" prop="enrollments" sortable :sort-orders="['ascending', 'descending']" width="75">
                            <template slot-scope="scope">
                                <template v-for="(enrollment, index) in scope.row.enrollments">
                                    <template v-if="index !== 0">, </template><a :key="index" :href="'/student/services/' + enrollment.student_id">{{ enrollment.student_name }}</a>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="Change status" style="min-width:220px;">
                            <template slot-scope="scope">
                                <el-select size="mini" v-model="scope.row.status" @change="onMediaStatusChange(scope.row)" style="width:100%;">
                                    <el-option :value="scope.row.status" :label="scope.row.status_display"></el-option>
                                    <el-option v-for="status in scope.row.next_statuses" :key="status.status" :label="status.status_display" :value="status.status"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p><a @click="addNewMedia">Enter new media details</a></p>
                </div>

                <div v-if="editingMedia" class="panel" style="position:relative;">
                    <div style="background-color: #ffc; padding: 10px; border: thin solid #ff0">
                        <!-- <h2>Enter media details</h2> -->
                        <div v-if="false" style="position: absolute; right: 10px; top: 10px; text-align: right;">
                            <h4>Look up media data on the web:</h4>
                        </div>
                        <div>
                            <div style="float: right; text-align: right; margin-left: 1em; margin-top: 2em">
                                <h4>Upload scanned document:</h4>
                                <el-upload drag action="/api/etext-service/upload-etext-book-document/" ref="mediaUpload" :auto-upload="false" :limit="1" :data="{id: media.id}" :on-success="onUploadMediaSuccess" class="media-upload">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                </el-upload>
                                <template v-if="media.asset_file_name">Current document on file is named <em><a :href="'/api/document/download/' + media.document_id" target="_blank">{{ media.asset_file_name }}</a></em><br>If you specify another file to upload here, the current document<br>will be overwritten.</template><template v-else><em>No file has been uploaded yet</em></template>

                                <h4>Media type:</h4>
                                <el-select size="mini" v-model="media.media_type" style="width:125px;" @change="media.mediaTypeOther = null">
                                    <el-option v-for="type in mediaTypeOptions" :key="type" :value="type" :label="type"></el-option>
                                </el-select>
                                <el-input v-if="media.media_type == 'Other'" size="mini" v-model="media.mediaTypeOther" style="width:175px;"></el-input>

                                <h4>Where is the media available?</h4>
                                <el-select size="mini" v-model="media.where_available" style="width:150px;" @change="media.whereAvailableOther = null">
                                    <el-option v-for="option in whereAvailableOptions" :key="option" :value="option" :label="option"></el-option>
                                </el-select>
                                <el-input v-if="media.where_available == 'Other'" size="mini" v-model="media.whereAvailableOther" style="width:175px;"></el-input>

                                <template v-if="media.enrollments && media.enrollments.length">
                                    <h4>Provided to Students</h4>
                                    <p>
                                        <template v-for="(enrollment, index) in media.enrollments">
                                            <el-checkbox :key="index" v-model="enrollment.provided_to">{{ enrollment.student_name }}</el-checkbox><br :key="index">
                                        </template>
                                    </p>
                                </template>
                            </div>
                            <div style="width:40em;">
                                <h2>Enter media details</h2>
                                <template v-if="media.service_id">
                                    <p><i>You can add media that has already been entered into the database:</i></p>
                                    <el-select size="mini" v-model="media.etext_book_id" style="width:100%">
                                        <el-option :value="null" :label="'--Choose a book from all sections of ' + etextService.class_name + '--'"></el-option>
                                        <el-option v-for="book in courseBooks" :key="'course' + book.id" :value="book.id" :label="book.truncated_title"></el-option>
                                        <el-option :value="null" :label="'--Choose a book from all courses--'"></el-option>
                                        <el-option v-for="book in allBooks" :key="'all' + book.id" :value="book.id" :label="book.truncated_title"></el-option>
                                    </el-select>
                                    <p><i>or you can enter details about new media:</i></p>
                                </template>
                                <table class="form"><tbody>
                                    <tr>
                                        <th>Title</th><td><el-input size="mini" v-model="media.title" style="width:275px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>Author</th><td><el-input size="mini" v-model="media.author" style="width:275px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>ISBN</th><td><el-input size="mini" v-model="media.isbn" style="width:275px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>Publisher</th><td><el-input size="mini" v-model="media.publisher" style="width:275px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>Page Count (for scanning)</th><td><el-input size="mini" v-model="media.page_count" style="width:275px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>Comments</th><td><el-input type="textarea" v-model="media.comments" style="width:380px;"></el-input></td>
                                    </tr>
                                    <tr>
                                        <th>Date needed</th><td><el-date-picker size="small" type="date" v-model="media.date_needed" value-format="yyyy-MM-dd"></el-date-picker></td>
                                    </tr>
                                </tbody></table>
                            </div>
                            <div v-if="media.id" style="float:right;">
                                <el-button size="mini" type="primary" @click="removeMediaFromReadingList">Remove book from reading list</el-button>
                            </div>
                            <div>
                                <el-button size="mini" type="primary" @click="submitMedia">Save changes</el-button> or <a @click="cancelMediaEditing">Cancel</a>
                            </div>
                        </div>
                        <br clear="all">
                    </div>
                </div>

            </div>
            <div style="width:50%; float:left;">
                <a @click="showDetailedHistory = !showDetailedHistory">{{ showDetailedHistory ? 'Hide' : 'Show' }} detailed change history</a>
                <utils-service-audit-history v-if="showDetailedHistory" :history="detailedHistory" :isStudentHistory="false"></utils-service-audit-history>
            </div>

            <div style="width:45%; float: left;">
                <utils-history :id="etextService.id" :type="'Service'" :show-title="false"></utils-history>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: "E Text Services for " + this.etextService.class_name + " in "+ this.etextService.semester_name
            }
        },
        components: {
        },
        data () {
            return {
                etextService: {},
                media: {
                    id: null,
                    service_id: null,
                    etext_book_id: null,
                    title: null,
                    author: null,
                    isbn: null,
                    publisher: null,
                    page_count: null,
                    comments: null,
                    date_needed: null,
                    media_type: null,
                    where_available: null
                },
                actionsTaken: [],
                books: [],
                history: [],
                detailedHistory: [],
                showDetailedHistory: false,
                courseBooks: [],
                allBooks: [],
                showStatusChangeHistory: false,
                editingMedia: false,
                newMediaType: null,
                newNumItemsOnReadingList: undefined,
                mediaTypeOptions: [],
                whereAvailableOptions: ['Other', 'Course Works', 'Library', 'eReserve', 'Bookstore']
            }
        },
        methods: {
            load: function() {
                this.etextService.id = location.pathname.split('/etext-service/show/')[1];
                if(this.etextService.id){
                    this.etextService.id = parseInt(this.etextService.id);
                    this.refreshData();
                }
            },
            getEtextService: function() {
                axios.get('/api/etext-service/', {params: {id: this.etextService.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.actionsTaken = [];
                            this.etextService = response.data.etext_service;
                            this.getMediaTypeOptions();

                            var emailedAdvisorChangesLength = this.etextService.emailed_advisor_changes ? this.etextService.emailed_advisor_changes.length : 0;
                            var emailedStudentChangesLength = this.etextService.emailed_student_changes ? this.etextService.emailed_student_changes.length : 0;
                            var emailedProfessorChangesLength = this.etextService.emailed_professor_changes ? this.etextService.emailed_professor_changes.length : 0;
                            var emailedDeptChangesLength = this.etextService.emailed_dept_changes ? this.etextService.emailed_dept_changes.length : 0;
                            var checkedCourseworksChangesLength = this.etextService.checked_courseworks_changes ? this.etextService.checked_courseworks_changes.length : 0;
                            var receivedRequiredReadingListChangesLength = this.etextService.received_required_reading_list_changes ? this.etextService.received_required_reading_list_changes.length : 0;

                            var maxLength = Math.max(emailedAdvisorChangesLength, emailedStudentChangesLength, emailedProfessorChangesLength, emailedDeptChangesLength, checkedCourseworksChangesLength, receivedRequiredReadingListChangesLength);

                            for(var i = 0; i < maxLength; i++){
                                var row = {};
                                row.emailedAdvisorChange = this.etextService.emailed_advisor_changes ? this.etextService.emailed_advisor_changes[i] : {};
                                row.emailedStudentChange = this.etextService.emailed_student_changes ? this.etextService.emailed_student_changes[i] : {};
                                row.emailedProfessorChange = this.etextService.emailed_professor_changes ? this.etextService.emailed_professor_changes[i] : {};
                                row.emailedDeptChange = this.etextService.emailed_dept_changes ? this.etextService.emailed_dept_changes[i] : {};
                                row.checkedCourseworksChange = this.etextService.checked_courseworks_changes ? this.etextService.checked_courseworks_changes[i] : {};
                                row.receivedRequiredReadingListChange = this.etextService.received_required_reading_list_changes ? this.etextService.received_required_reading_list_changes[i] : {};

                                this.actionsTaken.push(row);
                            }
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getBooks: function(){
                axios.get('/api/etext-service/get-etext-books-for-service/', {params: {id: this.etextService.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.books = response.data.rows;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getBookOptions: function(){
                axios.get('/api/etext-service/get-etext-book-options/', {params: {id: this.etextService.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.courseBooks = response.data.course_books;
                            this.allBooks = response.data.all_books;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getHistory: function(){
                axios.get('/api/simple/get-status-change-history/', {params: {id: this.etextService.id, type: "Service"}})
                    .then(response => {
                        if(response.data.success) {
                            this.history = response.data.rows;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getDetailedHistory: function(){
                axios.get('/api/etext-service/detailed-history/', {params: {id: this.etextService.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.detailedHistory = response.data.rows;
                        this.detailedHistory.forEach(function(row){
                            //if(row.timestamp){
                            //    row.timestamp = moment(row.timestamp).format("M/D/YYYY h:mma");
                            //}

                            if(row.changes.created_at){
                                row.changes.created_at = moment(row.changes.created_at).format("M/D/YYYY h:mma");
                            }

                            if(row.changes.updated_at){
                                row.changes.updated_at = moment(row.changes.updated_at).format("M/D/YYYY h:mma");
                            }
                        });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getMediaTypeOptions: function(){
                this.mediaTypeOptions = ['Other', 'Textbook', 'Course pack', 'Article'];
                for(var i = 0; i < this.etextService.media_types.length; i++){
                    if(this.mediaTypeOptions.indexOf(this.etextService.media_types[i].media_type) === -1 && this.etextService.media_types[i].is_visible){
                        this.mediaTypeOptions.push(this.etextService.media_types[i].media_type);
                    }
                }
            },
            refreshData: function(){
                this.getEtextService();
                this.getBooks();
                this.getBookOptions();
                this.getDetailedHistory();

                this.newMediaType = null;
                this.newNumItemsOnReadingList = undefined;
                this.editingMedia = false;
                this.media = {
                    id: null,
                    service_id: null,
                    etext_book_id: null,
                    title: null,
                    author: null,
                    isbn: null,
                    publisher: null,
                    page_count: null,
                    comments: null,
                    date_needed: null,
                    media_type: null,
                    where_available: null
                };
            },
            updateStatus: function(status) {
                axios.put('/api/etext-service/update-status/', {id: this.etextService.id, status: status})
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            uploadSyllabus: function() {
                this.$refs["syllabusUpload"].submit();
            },
            onUploadSyllabusSuccess: function(response) {
                if(response.success){
                    this.refreshData();
                } else {
                    this.$alert("Error: "+ response.message);
                }
                this.$refs["syllabusUpload"].clearFiles();
            },
            uploadMedia: function() {
                this.$refs["mediaUpload"].submit();
            },
            onUploadMediaSuccess: function(response) {
                if(response.success){
                    this.refreshData();
                } else {
                    this.$alert("Error: "+ response.message);
                }
                this.$refs["mediaUpload"].clearFiles();
            },
            onSubmitMediaTypes: function(){
                if(this.newMediaType && this.newMediaType.trim() != ''){
                    this.etextService.media_types.push({media_type: this.newMediaType, is_visible: true, num_items_on_reading_list: this.newNumItemsOnReadingList});
                }

                axios.put('/api/etext-service/update-media-types/', {id: this.etextService.id, media_types: this.etextService.media_types})
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            addNewMedia: function(){
                this.media = {
                    id: null,
                    service_id: null,
                    etext_book_id: null,
                    title: null,
                    author: null,
                    isbn: null,
                    publisher: null,
                    page_count: null,
                    comments: null,
                    date_needed: null,
                    media_type: null,
                    where_available: null
                };
                this.media.service_id = this.etextService.id;
                this.media.etext_book_id = null;
                this.media.media_type = 'Other';
                this.media.where_available = 'Other';
                this.editingMedia = true;
            },
            editMedia: function(media){
                this.media = {
                    id: null,
                    service_id: null,
                    etext_book_id: null,
                    title: null,
                    author: null,
                    isbn: null,
                    publisher: null,
                    page_count: null,
                    comments: null,
                    date_needed: null,
                    media_type: null,
                    where_available: null
                };
                this.media = media;

                if(this.mediaTypeOptions.indexOf(this.media.media_type) === -1){
                    this.media.mediaTypeOther = this.media.media_type;
                    this.media.media_type = "Other";
                }

                if(this.whereAvailableOptions.indexOf(this.media.where_available) === -1){
                    this.media.whereAvailableOther = this.media.where_available;
                    this.media.where_available = "Other";
                }

                this.editingMedia = true;
            },
            cancelMediaEditing: function(){
                this.media = {};
                this.editingMedia = false;
            },
            submitMedia: function(){
                if(this.media.media_type == 'Other') this.media.media_type = this.media.mediaTypeOther;
                if(this.media.where_available == 'Other') this.media.where_available = this.media.whereAvailableOther;

                if(this.media.id){
                    axios.put('/api/etext-service/update-media/', this.media)
                        .then(response => {
                            if(response.data.success){
                                this.submitEnrollments()
                                    .then(() => {
                                        if(this.$refs["mediaUpload"].uploadFiles.length){
                                            this.uploadMedia();
                                        } else {
                                            this.refreshData();
                                        }
                                    });
                            }
                        });
                } else {
                    axios.post('/api/etext-service/new-media/', this.media)
                        .then(response => {
                            if(response.data.success && response.data.id){
                                if(this.$refs["mediaUpload"].uploadFiles.length){
                                    this.media.id = response.data.id;
                                    this.uploadMedia();
                                } else {
                                    this.refreshData();
                                }
                            }
                        });
                }
            },
            submitEnrollments: function(){
                return new Promise((resolve, reject) => {
                    if(this.media.enrollments && this.media.enrollments.length){
                        axios.post('/api/etext-service//update-provided-tos/', {etext_book_id: this.media.id, enrollments: this.media.enrollments})
                            .then(response => {
                                if(response.data.success){
                                    resolve();
                                }
                            });
                    } else {
                        resolve();
                    }
                });
            },
            removeMediaFromReadingList: function(){
                this.$confirm("Are you sure you want to remove this book from the list for this service?")
                    .then(() => {
                        axios.delete('/api/etext-service/remove-media/', {params: {service_id: this.etextService.id, etext_book_id: this.media.id}})
                            .then(response => {
                                this.refreshData();
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            onMediaStatusChange: function(media){
                axios.put('/api/etext-service/update-etext-book-status/', {id: media.id, status: media.status})
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
    .panel {
        border: 1px solid #999;
        padding: 0.5em;
        margin: 1em 0;
        background: white;
        clear: right;
    }
    .books-table {
        max-width: none;
    }
    .media-upload .el-upload-list__item {
        width:100%;
    }
</style>
