<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getRequests">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>E-Text Media Requests</h1>
            <etext-service-nav selected="books-by-status"></etext-service-nav>
            <br clear="all">
            <el-table :default-sort="{prop: 'service_courses', order: 'ascending'}" :data="requests">
                <el-table-column label="Courses" prop="service_courses" sortable :sort-orders="['ascending', 'descending']" width="165">
                    <template slot-scope="scope">
                        <template v-for="(course, index) in scope.row.service_courses">
                            <template v-if="index !== 0">, </template><a :href="'/etext-service/show/' + course.service_id" :key="course.id">{{ course.class_name }}</a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Title" prop="title" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column label="Status" prop="latest_status_display" sortable :sort-orders="['ascending', 'descending']" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.latest_status_display }} on {{ scope.row.latest_status_created_at | formatDateMDY }}
                    </template>
                </el-table-column>
                <el-table-column label="Requested By" prop="students" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="(student, index) in scope.row.students">
                            <template v-if="index !== 0">, </template><a :href="'/student/services/' + student.id" :key="student.id">{{ student.student_name }}</a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Date needed" prop="date_needed" sortable :sort-orders="['ascending', 'descending']" width="90">
                    <template slot-scope="scope">{{ scope.row.date_needed | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column label="Comments" prop="comments" sortable :sort-orders="['ascending', 'descending']" width="300"></el-table-column>
            </el-table>
            <p v-if="total">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "E-Text Media Requests"
            }
        },
        components: {
        },
        data () {
            return {
                requests: [],
                semesters: [],
                semesterId: null,
                total: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getRequests();
                    });
            },
            getRequests: function() {
                axios.get('/api/etext-service/media-by-status/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.requests = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
