<template>
    <div id="container">
<div id="content">
            <student-nav selected="accommodations" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div class="menu">
                <template v-if="accommodationStatus == 'requested'">
                    <b>Enter student requests</b>: add accommodations requests made by the student.
                </template>
                <template v-if="accommodationStatus == 'recommended'">
                    <b>Add unrequested accommodations</b>: add accommodations that are recommended, but that have not been requested by the student.
                </template>
                <template v-if="accommodationStatus == 'supplemental'">
                    <b>Add supplemental accommodations</b>:
                </template>
                Make sure to save the changes when you are finished.
            </div>
            <student-accommodations-edit :id="id" :user="user" :availableAccommodations="availableAccommodations" :accommodationStatus="accommodationStatus"></student-accommodations-edit>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },

        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                user: null,
                accommodationStatus: "",
                availableAccommodations: []
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                var urlParams = this.$route.query;
                this.accommodationStatus = urlParams["status"]

                this.id = location.pathname.split('/student/accommodations-edit/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                axios.get("/api/user")
                    .then(response => {
                        if(response.data.success == true) {
                            this.user = response.data.user.identifier;
                        } else {
                            // eslint-disable-next-line
                            console.log("failed to get user");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get("/api/accommodation/available-student-accommodations/", {params:{id: this.id}})
                    .then(response => {
                        this.availableAccommodations = response.data.rows;
                    });
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .menu {
        margin-top: 20px;
        margin-bottom: 3px;
    }
</style>
