<template>
    <div id="container">
<div id="content">
            <ha-committee-under-review :urlParams="urlParams"></ha-committee-under-review>
        </div>
        </div>
</template>

<script>



    export default {
        metaInfo () {
            return {
                title: "Students Under Review"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {}
            }
        },
        methods: {
            load: function () {
             //   this.urlParams = this.getStatusDataParams('/ha-committee/');
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
