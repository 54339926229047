<template>
    <div id="container">
<div id="content">
            <h1>Enter course details for {{ studentName}} - {{ studentUni }}</h1>
            <p>Enter course details here when the student's course registration from SIS does not already provide these details. This is useful for law school courses and for recently added courses.</p>
            <el-form :model="course" :rules="rules" ref="courseForm" label-width="215px" label-position="right">
                <el-form-item label="Course department and number" prop="course_number">
                    <el-select size="mini" v-model="course.department" style="width:125px">
                        <el-option v-for="department in departments" :key="department.department" :value="department.department" :label="department.department"></el-option>
                    </el-select>
                    <el-input size="mini" v-model="course.course_number" style="width:85px;"></el-input>
                </el-form-item>
                <el-form-item label="Section Code (3 digits)" prop="section_code">
                    <el-input size="mini" v-model="course.section_code" style="width:85px;"></el-input>
                    <span class="text-italic">Note: enter 999 here for unlisted courses, and leave the instructor info blank if you don't have it.</span>
                </el-form-item>
                <el-form-item label="Title" prop="class_name">
                    <el-input size="mini" v-model="course.class_name" style="width:350px;"></el-input>
                </el-form-item>
                <el-form-item label="Building">
                    <el-input size="mini" v-model="course.class_building_code" style="width:85px;"></el-input> <b>Room</b>
                    <el-input size="mini" v-model="course.class_room_code" style="width:85px;"></el-input>
                </el-form-item>
                <el-form-item label="Instructor's First Name">
                    <el-input size="mini" v-model="course.instructor_first_name" style="width:350px;"></el-input>
                </el-form-item>
                <el-form-item label="Instructor's Last Name">
                    <el-input size="mini" v-model="course.instructor_last_name" style="width:350px;"></el-input>
                </el-form-item>
                <el-form-item label="Instructor Email">
                    <el-input size="mini" v-model="course.instructor_email" style="width:350px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="onSave">Save Changes</el-button> or <a :href="'/student/' + this.back + '/' + course.id">Cancel and return to student record</a>
                </el-form-item>
            </el-form>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Enter course details for " + this.studentName + " " + this.studentUn
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                studentName: null,
                studentUni: null,
                back: 'services',
                departments: [],
                showClassNameError: false,
                showCourseNumberError: false,
                showSectionCodeError: false,
                course: {
                    id: null,
                    class_name: null,
                    course_number: null,
                    class_room_code: null,
                    class_building_code: null,
                    department: null,
                    section_code: null,
                    instructor_first_name: null,
                    instructor_last_name: null,
                    instructor_email: null
                },
                rules: {
                    course_number: [{ required: true, whitespace: true, message: 'Course Number is required' }],
                    section_code: [{ required: true, message: 'Section Code is required' }, {min: 3, max: 3, message: 'Section Code must be three digits'}],
                    class_name: [{ required: true, whitespace: true, message: 'Title is required' }],
                }
            }
        },
        methods: {
            load: function () {
                this.course.id = parseInt(location.pathname.split('/student/add-course/')[1]);
                this.urlParams = this.$route.query;
                this.back = this.urlParams.back == 'testing' ? 'testing' : 'services';

                axios.get("/api/student/" + this.course.id)
                    .then(response => {
                        this.studentName = response.data.title;
                        this.studentUni = response.data.uni;
                    });

                axios.get("/api/simple/get-course-departments/")
                    .then(response => {
                        this.departments = response.data.rows;
                        if(this.departments && this.departments.length){
                            this.course.department = this.departments[0].department;
                        }
                    });
            },
            onSave: function () {
                this.$message.closeAll();

                this.$refs['courseForm'].validate((valid) => {
                    if(valid){
                        axios.post('/api/student/add-course/', this.course)
                            .then(response => {
                                if(response.data.success){
                                    this.$router.push({ path: '/student/' + this.back + '/' + this.course.id});
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: "One or required fields are missing. Please correct any errors and try saving again."
                        });
                    }
                });
            }
        },
        created () {
            this.load();
        }
    }
</script>

<style scoped>
    #content .el-form {
        width:100%;
    }

    .el-form-item__content {
        font-size: small;
    }

    #content .el-form-item {
        margin-bottom: 5px;
    }

    #content .el-form-item__label {
        line-height: 28px;
    }

    #content .el-form-item__content {
        line-height: inherit;
    }
</style>
