<template>
    <div id="container">
<div id="content">
            <h1>SIS Integration Audit</h1>
            <h2>Students with no SIS data</h2>
            <p>We have received <b>no data</b> from SIS about the following {{ registeredHaOnlyData.length + otherData.length }} students. This could be for various reasons, including:</p>
            <ul>
                <li>Student record in Doris was created within the last 24 hours</li>
                <li>Student does not have a PID in Doris, and does not have a standard UNI-based email address</li>
                <li>Doris data entry error in the student's PID or UNI</li>
                <li>The student has not yet registered for classes and is not present in SIS</li>
                <li>Networking problems preventing the daily refresh from taking place</li>
            </ul>
            <p>If you correct a problem in the PID and/or UNI, the student's record will most likely be refreshed the following day at 11:30 a.m. Please note that we are not showing students whose status is either Inactive, Expired, or Denied.</p>

            <h2>Active Students</h2>
            <p>(Students in the <b>Registered</b> or <b>HA Only</b> statuses)</p>
            <el-table :default-sort="{prop: 'first_name', order: 'ascending'}" :data="registeredHaOnlyData" style="width: 100%" class="table-top-align">
                <el-table-column prop="first_name" label="First name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="last_name" label="Last name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="preferred_name" label="Preferred name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="uni" label="Uni" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.uni ? scope.row.uni : '(none)' }}</a></template>
                </el-table-column>
                <el-table-column prop="pid" label="PID" sortable :sort-orders="['ascending', 'descending']" width="115"></el-table-column>
                <el-table-column prop="created_at" label="Created at" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{ scope.row.created_at | formatDateTimeMDYhma }}</template>
                </el-table-column>
                <el-table-column prop="status_display" label="Student status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
            </el-table>

            <h2>Other Students</h2>
            <p>(Students in other statuses, except <b>Denied</b>, <b>Inactive</b>, <b>Expired</b>, and <b>Alumnus or Guest</b>)</p>
            <el-table :default-sort="{prop: 'first_name', order: 'ascending'}" :data="otherData" style="width: 100%" class="table-top-align">
                <el-table-column prop="first_name" label="First name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="last_name" label="Last name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="preferred_name" label="Preferred name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="uni" label="Uni" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.uni ? scope.row.uni : '(none)' }}</a></template>
                </el-table-column>
                <el-table-column prop="pid" label="PID" sortable :sort-orders="['ascending', 'descending']" width="115"></el-table-column>
                <el-table-column prop="created_at" label="Created at" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{ scope.row.created_at | formatDateTimeMDYhma }}</template>
                </el-table-column>
                <el-table-column prop="status_display" label="Student status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
            </el-table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Sis Integration Audit"
            }
        },
        components: {
            },
        data () {
            return {
                registeredHaOnlyData: [],
                otherData: [],
                loading: null
            }
        },
        methods: {
            load: function() {
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/sis-integration-audit/')
                    .then(response => {
                        if(response.data.success) {
                            this.registeredHaOnlyData = response.data.registered_ha_only_rows;
                            this.otherData = response.data.other_rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
