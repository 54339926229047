<template>
  <div id="container">
    <!-- TODO: Set title -->
    <div id="content">
      <!-- TODO: Set header -->
      <h1>Notify Students of HA Decisions</h1>
      <p>The students listed below are awaiting notification of the decisions made by the Housing Committee
        regarding their requests or appeals. Their current status is "Pending Notification". You may check
        one or more students below and then click on the<br/>"Mark check students as notified button" and the
        following actions will happen:</p>
      <ol>
        <li>A Housing Decision Letter will be generated with a template specific to the decision (Approved,
          Denied, Appeal Approved, Appeal Denied) and the student (Undergradate, Graduate) and attached
          to the student's document section in DORIS</li>
        <li style="margin-top:10px">The student's new status will be either Approved (if at least one request is approved) or
          Denied (all requests denied)</li>
      </ol>
      <p>You will then have the opportunity to download a pdf file containing all the letters for the most
        recent batch of notified students, and a csv containing mailing addresses for envelopes.</p>
      <h2>Students Pending Notification</h2>


      <el-table
        ref="multipleTable"
        :default-sort="{prop: 'student_name', order: 'ascending'}" :data="data" style="width: 100%; margin-bottom: 10px" v-loading="loading"
        element-loading-text="Loading..." class="table-top-align" @selection-change="handleSelect">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="225">
          <template slot-scope="scope">{{scope.row.student_name}} &lt;{{ scope.row.uni }}&gt;</template>
        </el-table-column>
        <el-table-column label="Approved" sortable :sort-orders="['ascending', 'descending']" width="200">
          <template slot-scope="scope">
            <template v-for="item in scope.row.approved_accommodations">
              {{ item }}<br :key="item.id"/>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Denied" sortable :sort-orders="['ascending', 'descending']" width="200">
          <template slot-scope="scope">
            <template v-for="item in scope.row.denied_accommodations">
              {{ item }}<br :key="item.id"/>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-form class="term-select">
        <el-form-item style="float: left">
          Year: <el-select style="width:150px;"  size="mini" v-model="year" @change="getData">
          <el-option v-for="year_option in years" :key="year_option.id" :value="year_option.id" :label="year_option.name"></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <el-button @click="handleNotify" size="mini" style="margin-left: 40px">Mark checked students as notified</el-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'

  //TODO: remove if unnecessary


  export default {
    metaInfo () {
      return {
        title: "Notify Students of HA Decisions"
      }
    },
    //TODO: Remove when unnecessary

    components: {
    },
    data () {
      return {
        //TODO: remove when unnecessary
        urlParams: {},
        data: [],
        years: [],
        loading: false,
        year: null,
        selectedRecords: []
        //TODO: Add any url params
      }
    },
    methods: {
      load: function() {
        this.urlParams = this.$route.query;
        //TODO: Get any necessary url params
        this.getYears();
        // this.getData();
      },
      handleSelect: function(rows) {
        this.selectedRecords = rows;
      },
      getYears: function() {
        this.loading = true;
        axios.get('/api/housing-notification/years/', {params: {}})
          .then(response => {
            if(response.data.success) {
              this.years = response.data.rows;
              if(this.years.length){
                this.year = this.years[0].id;
                this.getData();
              } else {
                this.loading =false;
              }
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });

      },
      getData: function() {
        this.loading = true;

        axios.get('/api/housing-notification/pending-notification/', {params: {year_id: this.year}})
          .then(response => {
            if(response.data.success) {
              this.data = response.data.rows;
              this.loading = false;
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      handleNotify: function() {
        if(!this.selectedRecords.length){
          alert("Please select at least one record");
        } else {
          var studentIds = [];
          for(var i=0; i<this.selectedRecords.length; i++){
            studentIds.push(this.selectedRecords[i].student_id);
          }


          axios.post("/api/housing-notification/mark-notified/",{
            student_ids: studentIds,
            year_id: this.year
          }).then(response => {
            this.$router.push({ path: '/document/ha-decision-letter', query: { dateOption: moment(new Date()).format("YYYY-MM-DD")}});
          });
        }
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
</style>
