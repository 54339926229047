<template>
  <div id="container">
    <div id="content">
      <h1>Custom Email Lists</h1>
      <el-table :data="lists" style="max-width: 900px">
        <el-table-column prop="name" label="List" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column prop="members" label="Members" sortable :sort-orders="['ascending', 'descending']" width="120"></el-table-column>
        <el-table-column label="Operations" width="120">
          <template slot-scope="scope">
            <el-button @click.native.prevent="handleEdit(scope.$index, lists)" type="text" size="small">Edit</el-button>
            <el-button @click.native.prevent="handleDelete(scope.$index, lists)" type="text" size="small">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm"  label-position="top">
        <el-form-item label="Create a new list" prop="name">
          <el-input v-model="editForm.name" size="small" placeholder="List name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSave('editForm')" type="primary" size="small" icon="el-icon-document">Save</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import showdown from 'showdown'

  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Custom Email Lists"
      }
    },
    components: {
    },
    data () {
      return {
        lists: [],
        editForm: {
          name: ''
        },
        rules: {
          name: [{ required: true, message: 'Please enter a name', trigger: 'blur' }],
        }
      }
    },
    //computed: {
    //   html () {
    //       return converter.makeHtml(this.content)
    //   }
    //},
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      handleEdit: function(index, rows) {
        this.$router.push({ path: '/group-email/email-lists/edit', query: { id: rows[index].id}});
      },
      handleDelete: function(index, rows) {
        axios
          .delete('/api/group-email/custom-email-list/' + rows[index].id)
          .then(response => {
            this.load();
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onSave: function(formName) {
        this.$refs[formName].validate(valid => {
          if(valid) {
            axios
              .post('/api/group-email/custom-email-list', {
                name: this.editForm.name
              })
              .then(response => {
                this.load();
                this.editForm.name = "";
              });
          } else {
            // eslint-disable-next-line
            console.log('form not valid');
          }
        });
      },
      load: function() {
        axios
          .get('/api/group-email/custom-email-lists')
          .then(response => {
            if(response.data.success) {
              this.lists = response.data.rows;
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });


      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  .editor-container {
    float: left;
  }
  .editor-container .CodeMirror {
    height: 100%!important;
  }
  .content {
    position: relative;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form-item__content {
    line-height: 14px;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    width: 80ch;
    float: left;
    margin-top: 30px;
  }

</style>
