<template>
    <div id="container">
<!-- TODO: Set title -->
        <div id="content">
            <!-- TODO: Set header -->
            <h1 style="width: 800px; display: inline-block;">HA Decision Letter Email</h1>

            <el-form class="term-select" style="float: right; width:150px">
                Other dates with HA decision letters:<br/>
                <el-form-item >
                    <el-select style="width:150px;"  size="mini" v-model="dateOption" @change="getData">
                        <el-option v-for="date_option in dateOptions" :key="date_option" :value="date_option" :label="date_option"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <p>Note: "Received" means that DORIS has receieved the document; it does not mean the student has received
                an email. Documents in "sent" status indicate that an email has been sent, and will not be re-sent.</p>


            <el-table :default-sort="{prop: 'student_name', order: 'ascending'}" :data="data" style="width: 100%"
                      v-loading="loading" element-loading-text="Loading..." class="table-top-align" @selection-change="handleSelection">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="225">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{scope.row.student_name}} &lt;{{ scope.row.uni }}&gt;</a></template>
                </el-table-column>
                <el-table-column label="Email" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{ scope.row.uni }}@columbia.edu</template>
                </el-table-column>
                <el-table-column label="Type" >HA Decision Letter</el-table-column>
                <el-table-column label="Name"><template slot-scope="scope">{{ scope.row.uni }} - HA Decision Letter</template></el-table-column>
                <el-table-column label="Status" sortable :sort-orders="['ascending', 'descending']" prop="status"></el-table-column>
                <el-table-column label="File" sortable :sort-orders="['ascending', 'descending']" width="275">
                    <template slot-scope="scope"><a :href="'/api/document/download/'+scope.row.id" target="_blank">{{ scope.row.asset_file_name }}</a></template>
                </el-table-column>
                <el-table-column label="Preview Email" width="275">
                    <template slot-scope="scope"><a :href="'/api/document/download-eml/'+scope.row.id" target="_blank">{{ scope.row.asset_file_name ? scope.row.asset_file_name.replace("pdf", "eml") : "" }}</a></template>
                </el-table-column>
                <el-table-column label="Date" prop="expiration"><template slot-scope="scope">{{scope.row.doc_date}}</template></el-table-column>
            </el-table>
            <el-button @click="handleSend" size="mini" style="margin-top: 10px">Send HA Decision Letters</el-button>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    //TODO: remove if unnecessary


    export default {
        metaInfo () {
            return {
                title: "HA Decision Letter Email"
            }
        },
        //TODO: Remove when unnecessary

        components: {
            },
        data () {
            return {
                //TODO: remove when unnecessary
                urlParams: {},
                data: [],
                dateOptions: [],
                loading: false,
                dateOption: null,
                selectedRecords: []
                //TODO: Add any url params
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;

                this.dateOption = this.urlParams.dateOption;

                //TODO: Get any necessary url params
                this.getDateOptions();

                // this.getData();
            },
            getDateOptions: function() {
                this.loading = true;
                axios.get('/api/housing-notification/notification-dates/', {params: {}})
                    .then(response => {
                        if(response.data.success) {
                            this.dateOptions = response.data.rows;

                            if(!this.dateOption){
                                this.dateOption = this.dateOptions[0];
                            } else if(this.dateOptions.indexOf(this.dateOption) == -1){
                                this.dateOption = this.dateOptions[0];
                            }

                            this.getData();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getData: function() {
                this.loading = true;
                //TODO: Replace URL and params
                axios.get('/api/housing-notification/notification-letters/', {params: {date: this.dateOption}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSelection: function(rows){
                this.selectedRecords = rows;
            },
            handleSend: function(){
                if(!this.selectedRecords.length){
                    alert("Please select at least one record");
                } else {
                    var documentIds = [];
                    for(var i=0; i<this.selectedRecords.length; i++){
                        documentIds.push(this.selectedRecords[i].id);
                    }


                    axios.post("/api/housing-notification/send-notifications/",{
                        document_ids: documentIds
                    }).then(response => {
                        alert("Emails Sent");
                        this.getData();
                    });
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
