<template>
  <div id="container">
    <div id="content">
      <h1>Students by School</h1>
      <!-- hide table if only school + total row are present -->
      <table border="0" cellspacing="5" cellpadding="5" v-if="schoolStatusValues.length > 2">
        <thead>
        <tr>
          <th>School</th>
          <th>Students</th>
          <th v-for="status in statusColumns" :key="status">{{ status }}</th>
        </tr>
        </thead>
        <tfoot>
        <tr>
          <th class="left">{{ totalRow.school }}</th>
          <th class="left">{{ totalRow.total_students }}</th>
          <th v-for="status in statusColumns" class="left" :key="status">{{ totalRow[status] }}</th>
        </tr>
        </tfoot>
        <tbody>
        <template v-for="(schoolValue, index) in schoolStatusValues">
          <tr v-if="index !== schoolStatusValues.length - 1" :key="index">
            <td>{{ schoolValue.school }}</td>
            <td>{{ schoolValue.total_students }}</td>
            <td v-for="status in statusColumns" :key="status">{{ schoolValue[status] }}</td>
          </tr>
        </template>
        </tbody>
      </table>

      <template v-for="(value, key) in schoolStudents">
        <h2 :key="key">{{ key }} - {{ value ? value.length : 0 }} total</h2>
        <el-table  :data="value" style="width: 100%" class="table-top-align" :key="key+'-table'">
          <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
          <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
          <el-table-column prop="preferred_name" label="Preferred" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
          <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="75"></el-table-column>
          <el-table-column prop="affiliation" label="Affiliation" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

          <el-table-column v-for="column in extraColumns"  :prop="column.column" :label="column.column_title" :key="column.column" sortable :sort-orders="['ascending', 'descending']"></el-table-column>

          <el-table-column prop="expected_graduation" label="Grad Date" sortable :sort-orders="['ascending', 'descending']" width="100">
            <template slot-scope="scope">{{ scope.row.expected_graduation | formatDateMDY }}</template>
          </el-table-column>
          <el-table-column prop="disabilities" label="Disability" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              <template v-for="(disability, index) in scope.row.ods_categories">
                <template v-if="index !== 0"><br :key="index"></template>{{ disability.ods_category }} <template v-if="disability.count > 1">({{disability.count}})</template>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="accommodations" label="Accommodations" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              <template v-for="(accommodation, index) in scope.row.accommodations"><template v-if="index !== 0"><br :key="index"></template>{{ accommodation }}</template>
            </template>
          </el-table-column>
          <el-table-column prop="coordinator_name" label="Coordinator" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
          <el-table-column prop="ods_registration_date" label="ODS Reg Date" sortable :sort-orders="['ascending', 'descending']" width="100">
            <template slot-scope="scope">{{ scope.row.ods_registration_date | formatDateMDY }}</template>
          </el-table-column>
          <el-table-column prop="last_status_display" label="Latest Status in Period" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
          <el-table-column prop="ha_and_academic" label="HA & Academic" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              {{ scope.row.ha_and_academic ? "Yes" : "" }}
            </template>
          </el-table-column>
        </el-table>
      </template>

      <p><a :href="'/api/report/students-by-school.xlsx?'+ csvUrlParams" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ totalRow.total_students }} student records)</p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'



  export default {
    metaInfo () {
      return {
        title: "Students by School"
      }
    },

    components: {
    },
    data () {
      return {
        urlParams: {},
        schoolStatusValues: [],
        totalRow: {},
        schoolStudents: [],
        statusColumns: [],
        loading: null,
        schools: [],
        statuses: [],
        secondarySort: null,
        startSemesterId: null,
        endSemesterId: null,
        showHaAndAcademic: false,
        veteranStatus: false,
      }
    },
    computed: {
      csvUrlParams: function(){
        return 'schools='+encodeURIComponent(this.schools)+'&statuses='+this.statuses+'&secondary_sort='+this.secondarySort+'&start_semester_id='+this.startSemesterId+'&end_semester_id='+this.endSemesterId+'&veteran_status='+this.veteranStatus;
      }
    },
    methods: {
      load: function() {
        this.urlParams = this.$route.query;
        this.schools = this.urlParams.schools;
        this.statuses = this.urlParams.statuses;
        this.secondarySort = this.urlParams.secondary_sort;
        this.startSemesterId = this.urlParams.start_semester_id;
        this.endSemesterId = this.urlParams.end_semester_id;
        this.veteranStatus = this.urlParams.veteran_status;

        if(typeof(this.schools) === "object"){
          for(var i = 0; i < this.schools.length; i++){
            this.schools[i] = decodeURIComponent(this.schools[i]);
          }

          this.schools = this.schools.join("::");
        }
        if(typeof(this.statuses) === "object"){
          this.statuses = this.statuses.join("::");
        }
        this.getData();
      },
      getData: function() {
        this.loading = this.$loading({lock: true, text: 'Loading...'});
        axios.get('/api/report/students-by-school/', {params: {schools: this.schools, statuses: this.statuses,
            secondary_sort: this.secondarySort, start_semester_id: this.startSemesterId, end_semester_id: this.endSemesterId,
            veteran_status: this.veteranStatus }})
          .then(response => {
            if(response.data.success) {
              this.schoolStatusValues = response.data.school_status_values;
              this.totalRow = this.schoolStatusValues[this.schoolStatusValues.length - 1]
              this.schoolStudents = response.data.school_students;
              this.statusColumns = response.data.status_columns;
              this.extraColumns = response.data.extra_columns;
              this.showHaAndAcademic = response.data.status_columns.indexOf("Ha & Academic") != -1;
              this.loading.close();
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .left {
    text-align: left;
  }
</style>
