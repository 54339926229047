<template>
    <div id="container">
<div id="content">
            <br><div><a :href="'/admin'">Users</a> | <a :href="'/semester/list'">Academic Calendar</a> | <b>Trace</b></div>
            <h1></h1>
            <table><tbody>
                <tr>
                    <td></td>
                    <th v-for="(date, index) in orderedDates" :key="index"><a :href="'/trace/focus?day=' + date">{{ date }}</a></th>
                </tr>
                <tr v-for="(userId, userName) in users" :key="userId">
                    <th><a :href="'/trace/focus?user_id=' + userId">{{ userName }}</a></th>
                    <td v-for="(dataObj, index) in orderedData" :key="index"><a :href="'/trace/focus?day=' + dataObj.key + '&user_id=' + userId">{{ findData(dataObj, userId) }}</a></td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: 'System Administration: Trace'
            }
        },
        computed: {
            orderedDates: function () {
                return this.lodash.orderBy(this.dates, [( o ) => { return eval("o") || ''}], ["desc"])
            },
            orderedData: function () {
                return this.lodash.orderBy(this.data, [( o ) => { return eval("o['key']") || ''}], ["desc"])
            }
        },
        components: {
            },
        data () {
            return {
                dates: [],
                users: [],
                data: []
            }
        },
        methods: {
            load: function(){
                axios.get("/api/admin/trace/")
                    .then(response => {
                        this.dates = response.data.dates;
                        this.users = response.data.users;
                        this.data = response.data.data;
                    });
            },
            formatDate: function(date){
                if (date) {
                    return moment(String(date)).format('YYYY-MM-DD');
                }
            },
            findData: function(dataObj, userId){
                var data = dataObj.user_data.find(o => String(o.key) === userId);
                return data ? data.doc_count : 0;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
