<template>
    <div id="container">
<div id="content">
            <student-nav selected="accommodation-letters" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="currentSemesterId" @change="onCurrentSemesterChange">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h2>Accommodation Letters for {{ currentSemesterName ? currentSemesterName : "current semester" }} courses</h2>
            <template v-if="isGraduate">
                <p class="text-italic">Not required for graduate students</p>
            </template>
            <template v-else>
                <ul>
                    <li><a @click="createAccommodationLetters">Create accommodation letters for all current courses</a></li>
                    <li><a :href="'/document/create-letter-and-course?student_id=' + id">Create an accommodation letter for another course</a></li>
                    <li><a @click="updateLettersStatus('prepared')">All requested letters have been prepared</a></li>
                    <li><a @click="updateLettersStatus('signed_for_by_student')">All prepared letters have been picked up</a></li>
                    <li><a @click="showCurrentCourses = !showCurrentCourses">Create a letter in response to a specific request</a>
                    <div v-if="showCurrentCourses" class="report_options">
                        <template v-for="course in currentTermCourses">
                            <a :key="course.id" :href="'/document/create-letter-for-course?course_id=' + course.id + '&student_id=' + id">{{ course.course_identifier }} - {{ course.name }}</a><br :key="course.id">
                        </template>
                    </div></li>
                    <li><a :href="'/api/document/accommodation-letters-mail-merge/?id=' + this.id" target="blank">Download CSV for letter mail merge</a></li>
                    <li><a :href="'/document/accommodation-letter-doc?student_id=' + this.id">Instructions for preparing Accommodation Letters</a></li>
                </ul>
            </template>
            <student-accommodation-letters :id="id" :accommodationLetters="accommodationLetters" v-on:refreshletters="refreshAccommodationLetterData">
                <a @click="toggleShowAllSemesters">{{ showAllSemesters ? "View accommodation letters for this semester only" : "View accommodation letters from all semesters" }}</a>
                <p v-if="!accommodationLetters.length" class="text-italic">There are no documents to display<template v-if="!showAllSemesters"> for this semester</template>.</p>
            </student-accommodation-letters>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
     import axios from 'axios'
     import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                semesters: [],
                currentSemesterId: null,
                currentSemesterName: null,
                showAllSemesters: false,
                showCurrentCourses: false,
                currentTermCourses: [],
                accommodationLetters: [],
                isGraduate: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/accommodation-letters/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                        this.isGraduate = response.data.isGraduate;
                    });

                axios.get("/api/student/semesters/", {params:{id: this.id}})
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.currentSemesterId = this.semesters[i].id;
                                this.currentSemesterName = this.semesters[i].name;
                            }
                        }

                        if(!this.currentSemesterId){
                            this.currentSemesterId = response.data.rows[0].id;
                            this.currentSemesterName = response.data.rows[0].name;
                        }

                        this.refreshAccommodationLetterData();
                    });

                axios.get('/api/student/academic-information/', {params:{id: this.id}})
                    .then(response => {
                        this.currentTermCourses = response.data.current_term_courses;
                        if(this.currentTermCourses){
                            this.currentTermCourses.sort(function(a,b) {
                                return a.course_identifier.toUpperCase() > b.course_identifier.toUpperCase();
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            refreshAccommodationLetterData: function(){
                axios.get('/api/document/accommodation-letter-documents-for-student/', {params:{student_id: this.id, semester_id: this.currentSemesterId, show_all: this.showAllSemesters}})
                    .then(response => {
                        this.accommodationLetters = response.data.rows;
                        this.accommodationLetters.forEach(function(letter){
                            if(letter.date){
                                letter.date = moment(letter.date).format("M/D/YYYY");
                            }
                        });
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            toggleShowAllSemesters: function(){
                this.showAllSemesters = !this.showAllSemesters;

                this.refreshAccommodationLetterData();
            },
            onCurrentSemesterChange: function(){
                this.showAllSemesters = false;
                for(var i = 0; i < this.semesters.length; i++){
                    if(this.semesters[i].id === this.currentSemesterId){
                        this.currentSemesterName = this.semesters[i].name;
                    }
                }

                this.refreshAccommodationLetterData();
            },
            createAccommodationLetters: function(){
                this.$message.closeAll();

                axios.post("/api/document/create-accommodation-letters-for-student", {id: parseInt(this.id)})
                    .then(response => {
                        if(response.data.success){
                            this.refreshAccommodationLetterData();
                            var message;
                            if(response.data.number_of_letters_created == 1){
                                message = "1 accommodation letter was created.";
                            } else {
                                message = response.data.number_of_letters_created + " accommodation letters were created.";
                            }
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'success',
                                message: message
                            });
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            updateLettersStatus: function(status){
                var statusDisplay = status == 'prepared' ? 'Prepared' : 'Picked Up';
                this.$confirm('This will update the status of all requested letters to ' + statusDisplay +'. Are you sure you want to do this?')
                    .then(() => {
                        axios.put("/api/document/update-accommodation-letters-status-for-student", {id: parseInt(this.id), new_status: status})
                            .then(response => {
                                if(response.data.success){
                                    this.refreshAccommodationLetterData();
                                    this.$message({
                                        showClose: true,
                                        duration: 20000,
                                        type: 'success',
                                        message: response.data.message
                                    });
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error)
                            });
                    }).catch(() => {
                        //service.direct_delivery = !service.direct_delivery;  //tODO: no idea where this came from
                    });
                
            },
            getAccommodationLettersMailMerge: function(){
                axios.get('/api/document/accommodation-letters-mail-merge/', {params:{id: this.id}})
                    .then(response => {
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .term-select {
        width: 250px;
        float:right;
    }
</style>
