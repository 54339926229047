<template>
  <div id="container">
    <div id="content">
      <h1>Show Proctoring Session</h1>
      <a :href="'/proctoring-session'">Back to proctoring sessions</a><br clear="all">

      <div class="container-outer" style="max-width: 1200px">
        <div class="container-inner">
          <div class="section section-left">
            <a :href="'/proctoring-session/edit/' + this.proctoringSession.id">Edit this proctoring session</a>
            <a @click="deleteProctoringSession" style="float:right;" v-if="!serviceSchedules || serviceSchedules.length==0">Delete this session</a>
            <div class="proctor_session_field"><b>Proctoring location:</b> {{ proctoringSession.proctoring_location_name }}</div>
            <div><label><b>Room Capacity:</b></label>{{ proctoringSession.room_capacity }}</div>
            <div><label><b>Students Assigned:</b></label>{{ proctoringSession.student_count }}</div>
            <div><label><b>Remaining Capacity:</b></label>{{ proctoringSession.remaining_capacity }}</div>
            <div style="clear:both;"></div>
            <div class="proctor_session_field"><b>Date:</b> {{ proctoringSession.start_time | formatDateMDY }}</div>
            <div class="proctor_session_field"><b>Start time:</b> {{ proctoringSession.start_time | formatTimehhmmA }}</div>
            <div class="proctor_session_field"><b>End time:</b> {{ proctoringSession.end_time | formatTimehhmmA }}</div>
            <div class="proctor_session_field"><b>Exam Notes:</b><br><span class="formatted-text formatted-text-print" v-html="proctoringSession.notes"></span></div>
            <div class="proctor_session_field"><b>Information for Proctors:</b><br><span class="formatted-text formatted-text-print" v-html="proctoringSession.information_for_proctors"></span></div>
            <div class="proctor_session_field" style="font-size:13px;">
              <b>Room Notes:</b><br>
              <template v-for="note in proctoringSession.room_notes"><p :key="note.id">{{ note.note }}<template v-if="note.note_other">: {{ note.note_other }}</template></p></template>
            </div>
            <div class="proctor_session_field" style="font-size:13px;">
              <b>Location Room Notes:</b><br>
              <template v-for="note in proctoringSession.location_room_notes"><p :key="note.id">{{ note.note }}<template v-if="note.note_other">: {{ note.note_other }}</template></p></template>
            </div>
            <template v-if="proctoringSession.is_locked">
              <div class="proctor_session_field" style="color: red">
                <b>ROOM LOCKED</b> by {{ proctoringSession.locker_name }} on {{ proctoringSession.lock_date_time | formatDateTimeMDYhma }}
              </div>
            </template>
            <template v-if="proctoringSession.graduate_session">
              <div class="proctor_session_field"><b>Graduate Session?</b> Yes</div>
              <div class="proctor_session_field"><b>Campus:</b> {{ proctoringSession.graduate_campus }}</div>
              <div style="display:block;">
                <div class="proctor_session_field">--------------------------------------------------------------------------------</div>
                <div class="proctor_session_field"><b>Name of Student:</b> {{ proctoringSession.graduate_name_of_student }}</div>
                <div class="proctor_session_field"><b>Course Name:</b> {{ proctoringSession.graduate_course_name }}</div>
                <div class="proctor_session_field"><b>Accommodated Exam Start Time:</b> {{ proctoringSession.graduate_accommodated_exam_start_time }}</div>
                <div class="proctor_session_field"><b>Accommodated Exam End Time:</b> {{ proctoringSession.graduate_accommodated_exam_end_time }}</div>
                <div class="proctor_session_field"><b>Location of the Accommodated Exam:</b> {{ proctoringSession.graduate_location_accommodated_exam }}</div>
                <div class="proctor_session_field"><b>Location of the Regular Exam:</b> {{ proctoringSession.graduate_location_regular_exam }}</div>
                <div class="proctor_session_field"><b>Contact Person During Exam:</b> {{ proctoringSession.graduate_contact_during_exam }}</div>
                <div class="proctor_session_field"><b>Contact Person's Phone During Exam:</b> {{ proctoringSession.graduate_contact_phone_during_exam }}</div>
                <div class="proctor_session_field"><b>Contact Person's Email During Exam:</b> {{ proctoringSession.graduate_contact_email_during_exam }}</div>
                <div class="proctor_session_field"><b>Where to pick-up Exam:</b> {{ proctoringSession.graduate_where_to_pick_up_exam }}</div>
                <div class="proctor_session_field"><b>Where to drop-off the completed Exam:</b> {{ proctoringSession.graduate_where_to_drop_off_exam }}</div>
                <div class="proctor_session_field"><b>Requested By:</b> {{ proctoringSession.requestor_name }}</div>
                <div class="proctor_session_field"><b>Requester Confirmation Email:</b> {{ proctoringSession.requestor_email }}</div>
                <div class="proctor_session_field"><b>Equipment needed from DS for this exam (e.g. computer standing desk etc)?:</b> {{ proctoringSession.graduate_equipment_required ? "Yes" : "No" }}</div>
                <div class="proctor_session_field" v-if="proctoringSession.graduate_equipment_required"><b>Please Specify:</b> <span v-html="proctoringSession.graduate_equipment_details_html"></span></div>
                <div class="proctor_session_field"><b>Other:</b> {{ proctoringSession.graduate_other }}</div>
                <div class="proctor_session_field"><b>DS STAFF NOTES:</b><br><span class="formatted-text">{{ proctoringSession.graduate_ds_staff_notes }}</span></div>
                <div class="proctor_session_field" v-if="proctoringSession.proctoring_session_requestor_id"><a @click="emailRequestor">Email Requestor Proctor Information and review details</a></div>
                <div class="proctor_session_field" v-if="serviceSchedules.length"><a @click="emailGraduateProctors">Email Graduate Information to Proctor(s)</a></div>

              </div>
            </template>
          </div>
          <div class="section section-right">
            <b>Service Schedules</b><br><br>
            <a :href="'/proctoring-session/edit/' + this.proctoringSession.id">Edit/Add service schedules</a>
            <el-table :data="serviceSchedules" style="width:100%;" class="no-print" @sort-change="onSortChange">
              <el-table-column prop="name" label="Proctor" sortable :sort-orders="['ascending', 'descending']" :min-width="16.0">
                <template slot-scope="scope">
                  {{ scope.row.name }}
                  <template v-if="scope.row.proctor_is_trainee"> <span style='color:green; font-weight: bold'>(Trainee)</span></template>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']" :min-width="25.0"><template slot-scope="scope"><a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a></template></el-table-column>
              <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']" :min-width="9.0"><template slot-scope="scope">{{ scope.row.start_time | formatTimehma }}</template></el-table-column>
              <el-table-column prop="end_time" label="End" sortable :sort-orders="['ascending', 'descending']" :min-width="9.0"><template slot-scope="scope">{{ scope.row.end_time | formatTimehma }}</template></el-table-column>
            </el-table>

            <table class="printable-table print-only" style="width:100%;">
              <tbody>
              <tr>
                <th style="width: 27.4%">Name</th>
                <th style="width: 42.3%">Email</th>
                <th style="width: 15%">Start</th>
                <th style="width: 15%">End</th>
              </tr>
              <tr v-for="schedule in orderedServiceSchedules" :key="schedule.id">
                <td>
                    {{ schedule.name }}
                    <template v-if="schedule.proctor_is_trainee"> <span style='color:green; font-weight: bold'>(Trainee)</span></template>

                </td>
                <td><a :href="'mailto:' + schedule.email">{{ schedule.email }}</a></td>
                <td>{{ schedule.start_time | formatTimehma }}</td>
                <td>{{ schedule.end_time | formatTimehma }}</td>

              </tr>
              </tbody>
            </table>

            <br>
            <b><a :href="'/group-email/compose/?proctors_for_proctoring_session_id='+proctoringSession.id" target="_blank">Email all session proctors</a></b>
            <template v-if="proctorsOnDutyNow">
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b><a :href="'/group-email/compose/?on_duty_proctors_for_proctoring_session_id='+proctoringSession.id" target="_blank">Email proctors on duty now</a></b>
            </template>
            <template v-for="(gap, gapIndex) in coverageGaps">
              <h3 style="color:red; width:350px;" :key="gapIndex">ALERT - Proctor Required for: {{ gap.start_time | formatTimehma }} - {{ gap.end_time | formatTimehma }}</h3>
              <br :key="gapIndex + '-br1'"><br :key="gapIndex + '-br2'">
            </template>
            <template v-if="missingGraduateFields && missingGraduateFields.length">
              <h3 style="color:red; width:350px;">
                MISSING GRADUATE FIELDS:<br><br>
                <template v-for="(field, fieldIndex) in missingGraduateFields">{{ field }}<br :key="fieldIndex"></template>
              </h3>
            </template>
          </div>
        </div>
      </div>

      <table style="float:left; width:25%; max-width: 300px" v-if="!proctoringSession.graduate_session"><tbody>
      <tr v-for="(count, key) in equipmentCounts" :key="key">
        <td><b>{{ key }}</b></td><td>{{ count }}</td>
      </tr>
      </tbody></table>

      <table style="float:left; margin-left: 20px; width: 72%; max-width: 800px" v-if="!proctoringSession.graduate_session"><tbody>
      <tr><td colspan="4">Room Capacity as of {{ new Date() | formatDateTimeMDYhma }}</td></tr>
      <tr>
        <td>Exam Start Time</td>
        <td># of Students with this start time</td>
        <td># of Other students with exams<br>in progress from earlier start times<br>(includes end time buffer)</td>
        <td>Remaining Capacity<br>(includes end time buffer)</td>
      </tr>
      <template v-for="(capacity, index) in roomCapacities">
        <tr :key="index">
          <td>{{ capacity.start_time | formatTimehhmmA }}</td>
          <td>{{ capacity.num_students_with_start_time }}</td>
          <td>{{ capacity.num_students_in_progress }}</td>
          <td>{{ capacity.remaining_capacity }}</td>
        </tr>
      </template>
      </tbody></table>

      <el-table :data="[{}]" :show-header="false" class="no-border no-print proctoring-sessions-exams-table" style="width:100%">
        <el-table-column :min-width="8.5"></el-table-column>
        <el-table-column  :min-width="6.0"></el-table-column>
        <el-table-column  :min-width="6.0"></el-table-column>
        <el-table-column :min-width="11.0"></el-table-column>
        <el-table-column :min-width="18.3"> </el-table-column>
        <el-table-column  :min-width="6.1"></el-table-column>
        <el-table-column  :min-width="7.5"></el-table-column>
        <el-table-column :min-width="15.4">
          <b>Filter list:</b><br/>
          <el-select size="mini" @change="handlePropSort1" v-model="filter1">
            <el-option label="All" value="all"></el-option>
            <el-option label="Placeholder Exams" value="is_placeholder_exam"></el-option>
            <el-option label="Student Opt Outs" value="opt_student_out_of_emails"></el-option>
          </el-select>
        </el-table-column>
        <el-table-column :min-width="17.5">
          <b>Filter list:</b><br/>
          <el-select size="mini" @change="handleEquipmentSort" v-model="filter2">
            <el-option label="All" value="all"></el-option>
            <el-option label="Computer" value="Computer"></el-option>
            <el-option label="Cd player" value="Cd player"></el-option>
            <el-option label="Digital recorder" value="Digital recorder"></el-option>
            <el-option label="Other" value="Other"></el-option>
            <el-option label="Bluebooks (8 3/8 X 6 3/4)" value="Bluebooks (8 3/8 X 6 3/4)"></el-option>
            <el-option label="Chemistry Scantrons" value="Chemistry Scantrons"></el-option>
          </el-select>
        </el-table-column>
        <el-table-column :min-width="30.0" class-name="student-accommodations-column">
          <b>Filter list:</b><br/>
          <el-select size="mini" @change="handleAccommodationSort" v-model="filter3">
            <el-option label="All" value="all"></el-option>
            <el-option v-for="option in accommodationFilters" :key="option.id" :label="option.name" :value="option.id"></el-option>
          </el-select>
        </el-table-column>
      </el-table>

      <proctoring-session-exams :exams="filteredExams.length ? filteredExams : exams" v-if="!proctoringSession.graduate_session"></proctoring-session-exams>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "Show Proctoring Session"
      }
    },
    components: {
    },
    data () {
      return {
        proctoringSession: {},
        missingGraduateFields: [],
        serviceSchedules: [],
        coverageGaps: [],
        showLockSession: false,
        exams: [],
        filteredExams: [],
        equipmentCounts: [],
        roomCapacities: [],
        accommodationFilters: [],
        proctorsOnDutyNow: false,
        graduateFields: [ "graduate_course_name",
          "graduate_accommodated_exam_start_time", "graduate_accommodated_exam_end_time",
          "graduate_location_accommodated_exam", "graduate_location_regular_exam",
          "graduate_contact_during_exam", "graduate_where_to_pick_up_exam",
          "graduate_where_to_drop_off_exam", "graduate_other",
          "graduate_contact_phone_during_exam", "graduate_contact_email_during_exam"
        ],

        filter1: 'all',
        filter2: 'all',
        filter3: 'all',
        sortField: 'start_time',
        sortOrder: 'asc'
      }
    },
    computed: {
      orderedServiceSchedules: function () {
// eslint-disable-next-line
        return this.lodash.orderBy(this.serviceSchedules, [this.sortField], [this.sortOrder])
      }
    },
    methods: {
      onSortChange: function(sorter){
        this.sortField = sorter.prop;
        this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
      },
      load: function(){
        this.proctoringSession.id = location.pathname.split('/proctoring-session/show/')[1];
        if(this.proctoringSession.id){
          this.proctoringSession.id = parseInt(this.proctoringSession.id);
          this.getProctoringSession();
          this.getExamList();
        }
      },
      getProctoringSession: function(){
        axios.get("/api/proctoring-session/", {params: {id: this.proctoringSession.id}})
          .then(response => {
            this.proctoringSession = response.data.proctoring_session;
            this.missingGraduateFields = response.data.missing_graduate_fields;
            this.serviceSchedules = response.data.service_schedules;
            this.coverageGaps = response.data.coverage_gaps;
            this.showLockSession = response.data.show_lock_session;

            if(this.serviceSchedules){
              for(var i=0; i<this.serviceSchedules.length; i++){
                if(this.serviceSchedules[i].on_duty_now){
                  this.proctorsOnDutyNow = true;
                }
              }
            }
          });
      },
      getExamList: function(){
        axios.get("/api/proctoring-session/exam-list/", {params: {id: this.proctoringSession.id}})
          .then(response => {
            this.exams = response.data.exams;
            this.equipmentCounts = response.data.equipment_counts;
            this.roomCapacities = response.data.room_capacities;
            this.accommodationFilters = response.data.accommodation_filter_options;
          });
      },
      deleteProctoringSession: function(id){
        this.$confirm('This will delete the proctoring session. Are you sure?')
          .then(() => {
            axios.post('/api/proctoring-session/delete/', {id: this.proctoringSession.id})
              .then(response => {
                if(response.data.success){
                  this.$router.push({ path: '/proctoring-session/'});
                }
              });
          }).catch(() => {
          // do nothing
        });
      },
      emailRequestor: function(){
        var allBlank = true;
        for(var i=0; i<this.graduateFields.length; i++){
          var graduateField = this.graduateFields[i];

          if(eval("this.proctoringSession."+graduateField) && eval("this.proctoringSession."+graduateField) != ""){
            allBlank = false;
          }

        }
        if(allBlank){
          this.$alert("All graduate fields are blank, unable to send email");
        } else {

          this.$confirm("Are you sure you wish to email this session's information to requestor?")
            .then(() => {
              var recipients = [];

              recipients.push({
                recipient_id: this.proctoringSession.proctoring_session_requestor_id,
                recipient_class: "ProctoringSessionRequestor",
                email: this.proctoringSession.requestor_email,
                name: this.proctoringSession.requestor_name,
                regarding_id: this.proctoringSession.id,
                regarding_class: "ProctoringSession"
              });


              var identifier = "requestor_graduate_proctoring_session_information";

              axios.post('/api/group-email/send-auto-email/', {
                identifier: identifier,
                recipients: recipients
              }).then(response => {
                this.$router.push("/group-email/show/"+response.data.emailId);

              }).catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
            }).catch(() => {
          });
        }
      },
      emailGraduateProctors: function(){
        var allBlank = true;
        for(var i=0; i<this.graduateFields.length; i++){
          var graduateField = this.graduateFields[i];

          if(eval("this.proctoringSession."+graduateField) && eval("this.proctoringSession."+graduateField) != ""){
            allBlank = false;
          }

        }
        if(allBlank){
          this.$alert("All graduate fields are blank, unable to send email");
        } else {

          this.$confirm("Are you sure you wish to email this session's proctors with this graduate session information?")
            .then(() => {
              var recipients = [];

              for(var i = 0; i < this.serviceSchedules.length; i++){
                var serviceSchedule = this.serviceSchedules[i];

                recipients.push({
                  recipient_id: serviceSchedule.service_provider_id,
                  recipient_class: "ServiceProvider",
                  name: serviceSchedule.first_name + " " +serviceSchedule.last_name,
                  regarding_id: this.proctoringSession.id,
                  regarding_class: "ProctoringSession"
                });
              }


              var identifier = "graduate_proctoring_session_information";

              axios.post('/api/group-email/send-auto-email/', {
                identifier: identifier,
                recipients: recipients
              }).then(response => {
                this.$router.push("/group-email/show/"+response.data.emailId);
              }).catch(error => {
                // eslint-disable-next-line
                console.log(error);
              });
            }).catch(() => {
          });
        }
      },
      handlePropSort1: function(item){
        this.filter2 = 'all';
        this.filter3 = 'all';

        if(this.filter1 == 'all'){
          this.filteredExams = [];
        } else {
          var activeContainsProp = [];
          var activeMissingProp = [];

          for(var i = 0; i < this.exams.length; i++){
            if(this.exams[i][this.filter1]){
              activeContainsProp.push(this.exams[i]);
            } else {
              activeMissingProp.push(this.exams[i]);
            }
          }

          this.filteredExams = activeContainsProp.concat(activeMissingProp);
        }
      },
      handleEquipmentSort: function(item){
        this.filter1 = 'all';
        this.filter3 = 'all';

        if(item == 'all'){
          this.filteredExams = [];
        } else {
          var activeContainsItem = [];
          var activeMissingItem = [];

          for(var i = 0; i < this.exams.length; i++){
            if((item == 'Computer' && this.exams[i].enrollment_accommodations && this.exams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === 8; })) || (this.exams[i].exam_equipment && this.exams[i].exam_equipment.indexOf(item) !== -1)){
              activeContainsItem.push(this.exams[i]);
            } else {
              activeMissingItem.push(this.exams[i]);
            }
          }

          this.filteredExams = activeContainsItem.concat(activeMissingItem);
        }
      },
      handleAccommodationSort: function(item){
        this.filter1 = 'all';
        this.filter2 = 'all';

        if(item == 'all'){
          this.filteredExams = [];
        } else {
          var activeContainsItem = [];
          var activeMissingItem = [];

          for(var i = 0; i < this.exams.length; i++){
            if(this.exams[i].enrollment_accommodations && this.exams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === item; })){
              activeContainsItem.push(this.exams[i]);
            } else {
              activeMissingItem.push(this.exams[i]);
            }
          }

          this.filteredExams = activeContainsItem.concat(activeMissingItem);
        }
      }

    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  label {
    display: block;
    float: left;
    clear: left;
    width: 11em;
  }
  .container-outer {
    /*/display: table;*/
  }
  .container-inner {
    display: flex;
  }
  .section {
    float:left;
    padding: .3em .6em;
    margin: .5em 0;
    background: none;
  }
  .section-left {
    border: 1px solid #CEC1D6;
    width:50%;
  }
  .section-right {
    border-top: 1px solid #CEC1D6;
    border-right: 1px solid #CEC1D6;
    border-bottom: 1px solid #CEC1D6;
    width:50%;
  }
</style>
