<template>
    <div id="container">
<div id="content">
            <h1>Testing Accommodations Master List</h1>
            <a :href="'/api/testing-service/master-list.xlsx?start_date='+this.startDate+'&end_date='+this.endDate" target="_blank">Download CSV</a>
            <div style="margin-bottom:2px; margin-top:1em;">
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="startDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions" style="width:175px;"></el-date-picker> -
                <el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="endDate" value-format="yyyy-MM-dd" :picker-options="pickerOptions" style="width:175px;"></el-date-picker>
                <el-button size="mini" type="primary" @click="setSemester(null)" class="trailing-space">Submit</el-button>
                <a v-if="currentSemester" @click="setSemester(currentSemester)">See all exams for this semester</a> |
                <a v-if="previousSemester" @click="setSemester(previousSemester)">See all exams for {{ previousSemester.name }}</a> |
                <a v-if="nextSemester" @click="setSemester(nextSemester)">See all exams for {{ nextSemester.name }}</a> |
                <a @click="toggleHideAllGraduateSessions" style="color:red;">{{ hideAllGraduateSessions ? "Show" : "Hide" }} all graduate sessions</a> |
                <a @click="showFullComments = !showFullComments" style="color:red;">Show {{ showFullComments ? "abbreviated" : "full" }} comments</a>
            </div>
            <p><a :href="'/proctoring-session/edit/'">Add new proctoring session</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a :href="'/api/testing-service/proctor-list.xlsx?start_date='+this.startDate+'&end_date='+this.endDate+'&hide_graduate_sessions='+hideAllGraduateSessions" target="_blank">Download Proctor List CSV</a>

            </p>

            <table><tbody>
                <tr>
                    <td><b>Total Exams</b></td>
                    <td>{{ this.totalExams }}</td>
                </tr>
                <tr>
                    <td><b>Total Night (finish after 5pm) Exams</b></td>
                    <td>{{ this.totalNightExams }}</td>
                </tr>
                <tr>
                    <td><b>Total Cancelled Exams</b></td>
                    <td>{{ this.totalCancelledExams }}</td>
                </tr>
                <tr>
                    <td><b>Total Placeholder Exams</b></td>
                    <td>{{ this.totalPlaceholderExams }}</td>
                </tr>
                <tr>
                    <td><b>Total Missing Exam Specifications Exams</b></td>
                    <td>{{ this.totalMissingSpecsExams }}</td>
                </tr>
                <tr>
                  <td><b>Total Virtual, Proctored by DS</b></td>
                  <td>{{ this.totalVirtualDSProctoredExams }}</td>
                </tr>
                <tr>
                  <td><b>Total Virtual, Proctored by Professor</b></td>
                  <td>{{ this.totalVirtualProfessorProctoredExams }}</td>
                </tr>
                <tr>
                  <td><b>Total Virtual, Non-proctored</b></td>
                  <td>{{ this.totalVirtualNonProctoredExams }}</td>
                </tr>
                <tr>
                  <td><b>Total Virtual, Virtual platform/proctoring- TBD by prof</b></td>
                  <td>{{ this.totalVirtualTBDExams }}</td>
                </tr>
                <tr>
                  <td><b>Total Exam Type TBD</b></td>
                  <td>{{ this.totalTBDExams }}</td>
                </tr>
            </tbody></table>

            <template v-for="(session, index) in proctoringSessions">
                <p v-if="index == 0" style="width:40%; float: right; font-weight: bold" :key="index + '-p'"><a @click="sendExamTimeReminderToAllStudents()" v-if="activeExams.length > 0">Send Student Email Reminder for All Dates Selected</a></p>
                <h2 v-if="showDateHeader(index)" :key="index + '-h2'">{{ session.start_time | formatDateFullMonth }}</h2>
                <dl class="sessions" :key="index + '-dl'">
                    <dt>
                        {{ session.start_time | formatTimehhmmA }} - {{ session.end_time | formatTimehhmmA }}
                        <a :href="'/proctoring-session/show/' + session.id"><b><span v-if="session.graduate_session" style="color:red;">Graduate </span>Proctoring session in {{ session.location }}<span v-if="session.is_locked" style="color:red;"> (Room locked by {{ session.locker_name }} on {{ session.lock_date_time | formatDateMDY }} @ {{ session.lock_date_time | formatTimehma }} - no students can be added)</span></b></a>
                    </dt>
                    <dd>
                        <template v-if="showCapacity && session.room_capacity !== null"><b class="trailing-space">(Capacity: {{ session.total_enrollments }} of {{ session.room_capacity }})</b></template>
                        <b class="trailing-space"><a :href="'/group-email/compose/?proctors_for_proctoring_session_id='+ session.id">Email all session proctors</a></b>
                        <b v-if="anyOnDuty(index)" class="trailing-space"><a :href="'/group-email/compose/?on_duty_proctors_for_proctoring_session_id='+ session.id">Email proctors on duty now</a></b>
                        <b><template v-if="session.show_sent_email_link"><a :href="'/group-email/sent?regarding_id=' + session.id + '&regarding_class=ProctoringSession&recipient_type=ServiceProvider&sent_from_master_list=true'">Sent email re: this session</a></template><template v-else>No Emails Sent</template></b><br><br>
                        <template v-if="session.service_schedules && session.service_schedules.length">
                            Proctor schedule:
                            <ul>
                                <li v-for="(proctor, proctorIndex) in session.service_schedules" :key="proctorIndex + '-proctor'">{{ proctor.start_time | formatTimehhmmA }} - {{ proctor.end_time | formatTimehhmmA }} &nbsp; {{ proctor.proctor_name }}<template v-if="proctor.phone"> - {{ proctor.phone }}</template><template v-if="proctor.email"> - {{ proctor.email }}</template><template v-if="proctor.proctor_is_trainee"><span style="color:green; font-weight: bold"> (Trainee)</span></template></li>
                            </ul>
                        </template>
                        <template v-else>No proctors scheduled.</template>
                        <template v-for="(gap,gapIndex) in session.coverage_gaps">
                            <h3 style="color:red;" :key="gapIndex + '-gap'">ALERT - Proctor Required for: {{ gap.start_time | formatTimehma }} - {{ gap.end_time | formatTimehma }}</h3>
                        </template>
                        <template v-if="missingGraduateFields(session).length">
                            <h3 style="color:red;">MISSING GRADUATE FIELDS: {{ missingGraduateFields(session).join(", ") }}</h3>
                        </template>
                        <dl class="exams" v-if="session.notes">
                            <dt>Notes:</dt>
                            <dd class="formatted-text formatted-text-print" v-html="session.notes"></dd>
                        </dl>

                        <template v-if="session.graduate_equipment_required">
                            <dt style="background: yellow; display: inline-block">
                                Equipment needed from DS for this exam: <span v-html="session.graduate_equipment_details_html"></span>
                            </dt>
                        </template>
                    </dd>
                </dl>
            </template>

            <a @click="showAdvancedSort = !showAdvancedSort" v-if="!showAdvancedSort" style="font-weight: bold">Show Advanced Sort</a>
            <a @click="showAdvancedSort = !showAdvancedSort" v-if="showAdvancedSort" style="font-weight: bold">Hide Advanced Sort</a>

            <div class="master-list-advanced-sort no-print" v-if="showAdvancedSort">
                <div class="advanced-sort">
                    <b>First Sort</b>
                    <el-select size="mini" v-model="masterListFirstSort" style="width: 36%; margin-left: 1%" >
                        <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                    </el-select>
                    <el-select size="mini" v-model="masterListFirstSortDir" style="width: 36%; margin-left: 1%" >
                        <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                        <el-option key="DESC" value="DESC" label="Descending"></el-option>
                    </el-select>
                </div>
                <div class="advanced-sort">
                    <b>Second Sort</b>
                    <el-select size="mini" v-model="masterListSecondSort" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort" >
                        <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                    </el-select>
                    <el-select size="mini" v-model="masterListSecondSortDir" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort" >
                        <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                        <el-option key="DESC" value="DESC" label="Descending"></el-option>
                    </el-select>
                </div>
                <div class="advanced-sort">
                    <b>Third Sort</b>
                    <el-select size="mini" v-model="masterListThirdSort" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort || !masterListSecondSort" >
                        <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                    </el-select>
                    <el-select size="mini" v-model="masterListThirdSortDir" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort || !masterListSecondSort" >
                        <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                        <el-option key="DESC" value="DESC" label="Descending"></el-option>
                    </el-select>
                </div>
                <div class="advanced-sort">
                    <b>Fourth Sort</b>
                    <el-select size="mini" v-model="masterListFourthSort" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort || !masterListSecondSort || !masterListThirdSort" >
                        <el-option v-for="option in masterListSortOptions" :key="option.value" :value="option.value" :label="option.name"></el-option>
                    </el-select>
                    <el-select size="mini" v-model="masterListFourthSortDir" style="width: 36%; margin-left: 1%" :disabled="!masterListFirstSort || !masterListSecondSort || !masterListThirdSort" >
                        <el-option key="ASC" value="ASC" label="Ascending"></el-option>
                        <el-option key="DESC" value="DESC" label="Descending"></el-option>
                    </el-select>
                </div>
                <el-button :disabled="!masterListFirstSort" size="mini" type="primary" @click="onAdvancedSort">Submit</el-button>
            </div>

            <el-table :data="[{}]" :show-header="false" class="no-border no-print" :min-width="1000">
                <el-table-column :min-width="10.0"></el-table-column>
                <el-table-column  :min-width="7.3"></el-table-column>
                <el-table-column :min-width="11.7"></el-table-column>
                <el-table-column :min-width="17.0" class-name="professor-column"> </el-table-column>
                <el-table-column  :min-width="9.2"></el-table-column>
                <el-table-column  :min-width="7.5"></el-table-column>
                <el-table-column :min-width="15.2">
                    <b>Filter list:</b><br/>
                    <el-select size="mini" @change="handlePropSort1" v-model="filter1">
                        <el-option label="All" value="all"></el-option>
                        <el-option label="Professor Opt Outs" value="opt_professor_out"></el-option>
                    </el-select>
                </el-table-column>
                <el-table-column :min-width="9.0"></el-table-column>
                <el-table-column  :min-width="15.5">
                    <b>Filter list:</b><br/>
                    <el-select size="mini" @change="handlePropSort2" v-model="filter2">
                        <el-option label="All" value="all"></el-option>
                        <el-option label="Placeholder Exams" value="is_placeholder_exam"></el-option>
                        <el-option label="Student Opt Outs" value="opt_student_out_of_emails"></el-option>
                    </el-select>
                </el-table-column>
                <el-table-column  :min-width="9.6"></el-table-column>
                <el-table-column :min-width="11.5">
                    <b>Filter list:</b><br/>
                    <el-select size="mini" @change="handleEquipmentSort" v-model="filter3">
                        <el-option label="All" value="all"></el-option>
                        <el-option label="Computer" value="Computer"></el-option>
                        <el-option label="Cd player" value="Cd player"></el-option>
                        <el-option label="Digital recorder" value="Digital recorder"></el-option>
                        <el-option label="Bluebooks (8 3/8 X 6 3/4)" value="Bluebooks (8 3/8 X 6 3/4)"></el-option>
                        <el-option label="Chemistry Scantrons" value="Chemistry Scantrons"></el-option>
                        <el-option label="Large Monitor" value="Large Monitor"></el-option>
                        <el-option label="Computer/Ipad Headphones" value="Computer/Ipad Headphones"></el-option>
                        <el-option label="Other" value="Other"></el-option>
                    </el-select>
                </el-table-column>
                <el-table-column :min-width="31.5" class-name="student-accommodations-column">
                    <b>Filter list:</b><br/>
                    <el-select size="mini" @change="handleAccommodationSort" v-model="filter4">
                        <el-option label="All" value="all"></el-option>
                        <el-option v-for="option in accommodationFilters" :key="option.id" :label="option.name" :value="option.id"></el-option>
                    </el-select>
                </el-table-column>
            </el-table>

            <master-list-exams :exams="filteredActiveExams.length ? filteredActiveExams : activeExams" :isCancelledExams="false"
                               :isVirtualExams="false"
                               :showFullComments="showFullComments" :loading="activeExamsLoading"
                               :sortField="sortField" :sortOrder="sortOrder" v-on:onSortChange="onSortChange" ref="activeExams"></master-list-exams>
            <h1>Virtual Exams (Proctored by Professor and Non Proctored)</h1>
            <master-list-exams :exams="filteredActiveVirtualExams.length ? filteredActiveVirtualExams : activeVirtualExams" :isCancelledExams="false"
                               :isVirtualExams="true"
                               :showFullComments="showFullComments" :loading="activeVirtualExamsLoading"
                               :sortField="sortField" :sortOrder="sortOrder" v-on:onSortChange="onSortChange" ref="activeExams"></master-list-exams>
            <h1>Cancelled Exams</h1>
            <master-list-exams :exams="filteredCancelledExams.length ? filteredCancelledExams : cancelledExams" :isCancelledExams="true"
                               :isVirtualExams="false"
                               :showFullComments="showFullComments" :loading="cancelledExamsLoading"
                               :sortField="sortField" :sortOrder="sortOrder" v-on:onSortChange="onSortChange" ref="cancelledExams"></master-list-exams>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'




    export default {
        metaInfo () {
            return {
                title: "Testing Accommodations Master List"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {},
                startDate: null,
                endDate: null,
                semesterId: null,
                currentSemester: null,
                previousSemester: null,
                nextSemester: null,
                totalExams: null,
                totalNightExams: null,
                totalPlaceholderExams: null,
                totalCancelledExams: null,
                totalMissingSpecsExams: null,
                totalVirtualDSProctoredExams: null,
                totalVirtualProfessorProctoredExams: null,
                totalVirtualNonProctoredExams: null,
                totalVirtualTBDExams: null,
                totalTBDExams: null,
                hideAllGraduateSessions: false,
                showFullComments: false,
                proctoringSessions: [],
                showCapacity: false,
                activeExams: [],
                activeVirtualExams: [],
                cancelledExams: [],
                filteredActiveExams: [],
                filteredActiveVirtualExams: [],
                filteredCancelledExams:[],
                activeExamsLoading: false,
                activeVirtualExamsLoading: false,
                cancelledExamsLoading: false,
                accommodationFilters: [],
                filter1: 'all',
                filter2: 'all',
                filter3: 'all',
                filter4: 'all',
                masterListSortOptions: [],
                masterListFirstSort: "",
                masterListFirstSortDir: "ASC",
                masterListSecondSort: "",
                masterListSecondSortDir: "ASC",
                masterListThirdSort: "",
                masterListThirdSortDir: "ASC",
                masterListFourthSort: "",
                masterListFourthSortDir: "ASC",
                showAdvancedSort: false,
                sortField: '',
                sortOrder: '',
                pickerOptions: {
                    // disabledDate(time) {
                    //   return time.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                        // }, {
                        //   text: 'Yesterday',
                        //   onClick(picker) {
                        //     const date = new Date();
                        //     date.setTime(date.getTime() - 3600 * 1000 * 24);
                        //     picker.$emit('pick', date);
                        //   }
                    }]
                }
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                if(this.urlParams.start_date) this.startDate = this.urlParams.start_date;
                if(this.urlParams.end_date) this.endDate = this.urlParams.end_date;
                if(this.urlParams.semester_id) this.semesterId = this.urlParams.semester_id;

                if(this.urlParams.sort_1) this.masterListFirstSort = this.urlParams.sort_1;
                if(this.urlParams.sort_1_dir) this.masterListFirstSortDir = this.urlParams.sort_1_dir;

                if(this.urlParams.sort_2) this.masterListSecondSort = this.urlParams.sort_2;
                if(this.urlParams.sort_2_dir) this.masterListSecondSortDir = this.urlParams.sort_2_dir;

                if(this.urlParams.sort_3) this.masterListThirdSort = this.urlParams.sort_3;
                if(this.urlParams.sort_3_dir) this.masterListThirdSortDir = this.urlParams.sort_3_dir;

                if(this.urlParams.sort_4) this.masterListFourthSort = this.urlParams.sort_4;
                if(this.urlParams.sort_4_dir) this.masterListFourthSortDir = this.urlParams.sort_4_dir;

                this.getMasterListSortOptions();
                this.getExamInfo();
                this.getProctoringSessions();
                this.getActiveExams();
            },

            getMasterListSortOptions: function(){
                axios.get("/api/testing-service/master-list-sort-options/", {})
                    .then(response => {
                        this.masterListSortOptions = response.data.master_list_sort_options;
                    });
            },
            getExamInfo: function(){
                axios.get("/api/testing-service/master-list-exam-info/", {params: {start_date: this.startDate, end_date: this.endDate, semester_id: this.semesterId}})
                    .then(response => {
                        this.currentSemester = response.data.current_semester;
                        this.previousSemester = response.data.previous_semester;
                        this.nextSemester = response.data.next_semester;
                        this.totalExams = response.data.total_exams;
                        this.totalNightExams = response.data.total_night_exams;
                        this.totalPlaceholderExams = response.data.total_placeholder_exams;
                        this.totalCancelledExams = response.data.total_cancelled_exams;
                        this.totalMissingSpecsExams = response.data.total_missing_specs_exams;


                        this.totalVirtualDSProctoredExams = response.data.total_virtual_ds_proctored_exams;
                        this.totalVirtualProfessorProctoredExams = response.data.total_virtual_professor_proctored_exams;
                        this.totalVirtualNonProctoredExams = response.data.total_virtual_non_proctored_exams;
                        this.totalVirtualTBDExams = response.data.total_virtual_tbd_exams;
                        this.totalTBDExams = response.data.total_tbd_exams;

                    });
            },
            getProctoringSessions: function(){
                axios.get("/api/proctoring-session/master-list-proctoring-sessions/", {params: {start_date: this.startDate, end_date: this.endDate, semester_id: this.semesterId, hide_graduate_sessions: this.hideAllGraduateSessions}})
                    .then(response => {
                        this.proctoringSessions = response.data.rows;
                        this.showCapacity = response.data.show_capacity;
                    });
            },
            onAdvancedSort: function(){
              this.sortField = "";
              this.sortOrder = "";

              this.$refs["activeExams"].clearSort();
              this.$refs["cancelledExams"].clearSort();

              this.getActiveExams();
            },
            onSortChange: function(sorter){

                this.filter1 = 'all';
                this.filter2 = 'all';
                this.filter3 = 'all';
                this.filter4 = 'all';

                this.sortField = sorter.prop;
                this.sortOrder = sorter.order == "ascending" ? "asc" : "desc";
            },
            getActiveExams: function(){

                this.filteredActiveExams = [];
                this.filteredActiveVirtualExams = [];
                this.filteredCancelledExams = [];

                this.activeExamsLoading = true;
                this.activeVirtualExamsLoading = true;
                this.cancelledExamsLoading = true;
                this.activeExams = [];
                this.filter1 = "all";
                this.filter2 = "all";
                this.filter3 = "all";
                this.filter4 = "all";

                axios.get("/api/testing-service/master-list-active-exams/", {params: {
                        start_date: this.startDate,
                        end_date: this.endDate,
                        semester_id: this.semesterId,
                        sorters: {advanced_sort_1: this.masterListFirstSort,
                        advanced_sort_1_dir: this.masterListFirstSortDir,
                        advanced_sort_2: this.masterListSecondSort,
                        advanced_sort_2_dir: this.masterListSecondSortDir,
                        advanced_sort_3: this.masterListThirdSort,
                        advanced_sort_3_dir: this.masterListThirdSortDir,
                        advanced_sort_4: this.masterListFourthSort,
                        advanced_sort_4_dir: this.masterListFourthSortDir}}})
                    .then(response => {

                        this.getCancelledExams();
                        this.getActiveVirtualExams();

                        this.activeExams = response.data.rows;
                        this.accommodationFilters = response.data.accommodation_filters;

                        for(var i = 0; i < this.activeExams.length; i++){
                            var exam = this.activeExams[i];

                            if(exam.comments){
                                this.$set(exam, 'show_comments_more', exam.comments.length > 80);
                            }

                            if(exam.exam_comments) {
                                for(var j = 0; j < exam.exam_comments.length; j++){
                                    var exam_comment = exam.exam_comments[j];
                                    this.$set(exam_comment, 'show_exam_comment_more', exam_comment.content.length > 80);
                                }
                            }
                        }
                        this.activeExamsLoading = false;
                    });
            },
            getActiveVirtualExams: function(){
              this.activeVirtualExams = [];

              axios.get("/api/testing-service/master-list-active-virtual-exams/", {params: {
                  start_date: this.startDate,
                  end_date: this.endDate,
                  semester_id: this.semesterId,
                  sorters: {advanced_sort_1: this.masterListFirstSort,
                    advanced_sort_1_dir: this.masterListFirstSortDir,
                    advanced_sort_2: this.masterListSecondSort,
                    advanced_sort_2_dir: this.masterListSecondSortDir,
                    advanced_sort_3: this.masterListThirdSort,
                    advanced_sort_3_dir: this.masterListThirdSortDir,
                    advanced_sort_4: this.masterListFourthSort,
                    advanced_sort_4_dir: this.masterListFourthSortDir}}})
                  .then(response => {
                    this.activeVirtualExams = response.data.rows;

                    for(var i = 0; i < this.activeVirtualExams.length; i++){
                      var exam = this.activeVirtualExams[i];

                      if(exam.comments){
                        this.$set(exam, 'show_comments_more', exam.comments.length > 80);
                      }

                      if(exam.exam_comments) {
                        for(var j = 0; j < exam.exam_comments.length; j++){
                          var exam_comment = exam.exam_comments[j];
                          this.$set(exam_comment, 'show_exam_comment_more', exam_comment.content.length > 80);
                        }
                      }
                    }
                    this.activeVirtualExamsLoading = false;
                  });
            },
            getCancelledExams: function(){
                this.cancelledExams = [];

                axios.get("/api/testing-service/master-list-cancelled-exams/", {params: {
                        start_date: this.startDate,
                        end_date: this.endDate,
                        semester_id: this.semesterId,
                        sorters: {advanced_sort_1: this.masterListFirstSort,
                        advanced_sort_1_dir: this.masterListFirstSortDir,
                        advanced_sort_2: this.masterListSecondSort,
                        advanced_sort_2_dir: this.masterListSecondSortDir,
                        advanced_sort_3: this.masterListThirdSort,
                        advanced_sort_3_dir: this.masterListThirdSortDir,
                        advanced_sort_4: this.masterListFourthSort,
                        advanced_sort_4_dir: this.masterListFourthSortDir}}})
                    .then(response => {
                        this.cancelledExams = response.data.rows;

                        for(var i = 0; i < this.cancelledExams.length; i++){
                            var exam = this.cancelledExams[i];

                            if(exam.comments){
                                this.$set(exam, 'show_comments_more', exam.comments.length > 80);
                            }

                            if(exam.exam_comments) {
                                for(var j = 0; j < exam.exam_comments.length; j++){
                                    var exam_comment = exam.exam_comments[j];
                                    this.$set(exam_comment, 'show_exam_comment_more', exam_comment.content.length > 80);
                                }
                            }
                        }
                        this.cancelledExamsLoading = false;
                    });
            },
            setSemester: function(semester){
                this.proctoringSessions = [];
                if(semester){
                    this.semesterId = semester.id;
                    this.startDate = semester.start_date;
                    this.endDate = semester.end_date;
                    history.pushState(null, null, '/master-list?start_date=' + this.startDate + '&end_date=' + this.endDate + '&semester_id=' + this.semesterId);
                } else {
                    this.semesterId = null;
                    history.pushState(null, null, '/master-list?start_date=' + this.startDate + '&end_date=' + this.endDate);
                }
                this.getExamInfo();
                this.getProctoringSessions();
                this.getActiveExams();
                this.getCancelledExams();
            },
            toggleHideAllGraduateSessions: function(){
                this.proctoringSessions = [];
                this.hideAllGraduateSessions = !this.hideAllGraduateSessions;
                this.getProctoringSessions();
            },
            showDateHeader: function(index){
                return index == 0 || !moment(this.proctoringSessions[index].start_time).isSame(this.proctoringSessions[index -1].start_time, 'day');
            },
            anyOnDuty: function(index){
               var proctoringSession = this.proctoringSessions[index];

               var anyOnDuty = false;

               if(proctoringSession.service_schedules){
                   for(var i =0; i<proctoringSession.service_schedules.length; i++){
                       var serviceSchedule = proctoringSession.service_schedules[i];

                       if(serviceSchedule.on_duty_now){
                           anyOnDuty = true;
                       }
                   }
               }

               return anyOnDuty;
                // if(this.serviceSchedules){
                //     for(var i=0; i<this.serviceSchedules.length; i++){
                //         if(this.serviceSchedules[i].on_duty_now){
                //             this.proctorsOnDutyNow = true;
                //         }
                //     }
                // }
            },
            missingGraduateFields: function(session){
                var missingGraduateFields = [];

                if(session.graduate_session){
                    if(!session.graduate_accommodated_exam_start_time || session.graduate_accommodated_exam_start_time == ""){
                        missingGraduateFields.push("Accommodated Exam Start Time");
                    }
                    if(!session.graduate_accommodated_exam_end_time || session.graduate_accommodated_exam_end_time == ""){
                        missingGraduateFields.push("Accommodated Exam End Time");
                    }
                    if(!session.graduate_location_accommodated_exam || session.graduate_location_accommodated_exam == ""){
                        missingGraduateFields.push("Location of the Accommodated Exam");
                    }
                    if(!session.graduate_contact_during_exam || session.graduate_contact_during_exam == ""){
                        missingGraduateFields.push("Contact Person During Exam");
                    }
                    if(!session.graduate_contact_phone_during_exam || session.graduate_contact_phone_during_exam == ""){
                        missingGraduateFields.push("Contact Person's Phone During Exam");
                    }
                    if(!session.graduate_where_to_pick_up_exam || session.graduate_where_to_pick_up_exam == ""){
                        missingGraduateFields.push("Where to pick-up Exam");
                    }
                    if(!session.graduate_where_to_drop_off_exam || session.graduate_where_to_drop_off_exam == ""){
                        missingGraduateFields.push("Where to drop-off the completed Exam");
                    }
                }

                return missingGraduateFields;
            },
            handlePropSort1: function(item){
                this.filter2 = 'all';
                this.filter3 = 'all';
                this.filter4 = 'all';

                this.sortField = '';
                this.sortOrder = '';
                this.$refs["activeExams"].clearSort();
                this.$refs["cancelledExams"].clearSort();


                if(this.filter1 == 'all'){
                    this.filteredActiveExams = [];
                    this.filteredActiveVirtualExams = [];
                    this.filteredCancelledExams = [];
                } else {
                    var activeContainsProp = [];
                    var activeMissingProp = [];
                    var activeVirtualContainsProp = [];
                    var activeVirtualMissingProp = [];
                    var cancelledContainsProp = [];
                    var cancelledMissingProp = [];

                    for(var i = 0; i < this.activeExams.length; i++){
                        if(this.activeExams[i][this.filter1]){
                            activeContainsProp.push(this.activeExams[i]);
                        } else {
                            activeMissingProp.push(this.activeExams[i]);
                        }
                    }

                  for(i = 0; i < this.activeVirtualExams.length; i++){
                    if(this.activeVirtualExams[i][this.filter1]){
                      activeVirtualContainsProp.push(this.activeVirtualExams[i]);
                    } else {
                      activeVirtualMissingProp.push(this.activeVirtualExams[i]);
                    }
                  }

                    for(i = 0; i < this.cancelledExams.length; i++){
                        if(this.cancelledExams[i][this.filter1]){
                            cancelledContainsProp.push(this.cancelledExams[i]);
                        } else {
                            cancelledMissingProp.push(this.cancelledExams[i]);
                        }
                    }

                    this.filteredActiveExams = activeContainsProp.concat(activeMissingProp);
                    this.filteredActiveVirtualExams = activeVirtualContainsProp.concat(activeVirtualMissingProp);
                    this.filteredCancelledExams = cancelledContainsProp.concat(cancelledMissingProp);
                }
            },
            handlePropSort2: function(item){
                this.filter1 = 'all';
                this.filter3 = 'all';
                this.filter4 = 'all';


                this.sortField = '';
                this.sortOrder = '';
                this.$refs["activeExams"].clearSort();
                this.$refs["cancelledExams"].clearSort();

                if(this.filter2 == 'all'){
                    this.filteredActiveExams = [];
                    this.filteredCancelledExams = [];
                } else {
                    var activeContainsProp = [];
                    var activeMissingProp = [];
                    var cancelledContainsProp = [];
                    var cancelledMissingProp = [];

                    for(var i = 0; i < this.activeExams.length; i++){
                        if(this.activeExams[i][this.filter2]){
                            activeContainsProp.push(this.activeExams[i]);
                        } else {
                            activeMissingProp.push(this.activeExams[i]);
                        }
                    }

                    for(i = 0; i < this.cancelledExams.length; i++){
                        if(this.cancelledExams[i][this.filter2]){
                            cancelledContainsProp.push(this.cancelledExams[i]);
                        } else {
                            cancelledMissingProp.push(this.cancelledExams[i]);
                        }
                    }

                    this.filteredActiveExams = activeContainsProp.concat(activeMissingProp);
                    this.filteredCancelledExams = cancelledContainsProp.concat(cancelledMissingProp);
                }
            },
            handleEquipmentSort: function(item){
                this.filter1 = 'all';
                this.filter2 = 'all';
                this.filter4 = 'all';


                this.sortField = '';
                this.sortOrder = '';
                this.$refs["activeExams"].clearSort();
                this.$refs["cancelledExams"].clearSort();

                if(item == 'all'){
                    this.filteredActiveExams = [];
                    this.filteredCancelledExams = [];
                } else {
                    var activeContainsItem = [];
                    var activeMissingItem = [];
                    var cancelledContainsItem = [];
                    var cancelledMissingItem = [];

                    for(var i = 0; i < this.activeExams.length; i++){

                        if((item == 'Computer' && this.activeExams[i].enrollment_accommodations && this.activeExams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === 8; })) || (this.activeExams[i].exam_equipment && this.activeExams[i].exam_equipment.indexOf(item) !== -1)){
                            activeContainsItem.push(this.activeExams[i]);
                        } else {
                            activeMissingItem.push(this.activeExams[i]);
                        }
                    }

                    for(i = 0; i < this.cancelledExams.length; i++){
                        if((item == 'Computer' && this.cancelledExams[i].enrollment_accommodations && this.cancelledExams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === 8; })) || (this.cancelledExams[i].exam_equipment && this.cancelledExams[i].exam_equipment.indexOf(item) !== -1)){
                            cancelledContainsItem.push(this.cancelledExams[i]);
                        } else {
                            cancelledMissingItem.push(this.cancelledExams[i]);
                        }
                    }

                    this.filteredActiveExams = activeContainsItem.concat(activeMissingItem);
                    this.filteredCancelledExams = cancelledContainsItem.concat(cancelledMissingItem);
                }
            },
            handleAccommodationSort: function(item){
                this.filter1 = 'all';
                this.filter2 = 'all';
                this.filter3 = 'all';


                this.sortField = '';
                this.sortOrder = '';
                this.$refs["activeExams"].clearSort();
                this.$refs["cancelledExams"].clearSort();

                if(item == 'all'){
                    this.filteredActiveExams = [];
                    this.filteredCancelledExams = [];
                } else {
                    var activeContainsItem = [];
                    var activeMissingItem = [];
                    var cancelledContainsItem = [];
                    var cancelledMissingItem = [];

                    for(var i = 0; i < this.activeExams.length; i++){
                        if(this.activeExams[i].enrollment_accommodations && this.activeExams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === item; })){
                            activeContainsItem.push(this.activeExams[i]);
                        } else {
                            activeMissingItem.push(this.activeExams[i]);
                        }
                    }

                    for(i = 0; i < this.cancelledExams.length; i++){
                        if(this.cancelledExams[i].enrollment_accommodations && this.cancelledExams[i].enrollment_accommodations.find(function (obj) { return obj.accommodation_type_id === item; })){
                            cancelledContainsItem.push(this.cancelledExams[i]);
                        } else {
                            cancelledMissingItem.push(this.cancelledExams[i]);
                        }
                    }

                    this.filteredActiveExams = activeContainsItem.concat(activeMissingItem);
                    this.filteredCancelledExams = cancelledContainsItem.concat(cancelledMissingItem);
                }
            },
            sendExamTimeReminderToAllStudents: function(){
                var recipients = [];

                for(var i = 0; i < this.activeExams.length; i++){
                    var examEnrollment = this.activeExams[i];

                    if(!examEnrollment.opt_student_out_of_emails){
                        recipients.push({
                            recipient_id: examEnrollment.student_id,
                            recipient_class: "Student",
                            regarding_id:examEnrollment.service_id,
                            regarding_class: "TestingService"
                        });
                    }
                }

                var identifier = "student_exam_time_reminder";

                this.$confirm("Are you sure you wish to notify all exam students of their pending exams?")
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: identifier,
                            recipients: recipients
                        })
                            .then(response => {
                                this.getActiveExams();
                                this.getCancelledExams();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {
                });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    dl.sessions > dt {
        font-size: larger;
    }
    li, dd {
        margin: .6em 2em;
    }
    .no-border td{
        border:0px;
    }
</style>
