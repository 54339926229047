<template>
    <div id="container">
<div id="content">
            <h1>Edit student information</h1>
            <el-form :model="validateFields" :rules="rules" ref="studentForm" label-width="275px" label-position="left">
                <el-form-item label="PID">
                    <el-input size="mini" v-model="pid" style="width:100px;"></el-input>
                </el-form-item>
                <el-form-item label="UNI">
                    <el-input size="mini" v-model="uni" style="width:100px;"></el-input>
                </el-form-item>
                <el-form-item label="First Name" prop="firstName">
                    <el-input size="mini" v-model="validateFields.firstName" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Middle Name">
                    <el-input size="mini" v-model="middleName" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Last Name" prop="lastName">
                    <el-input size="mini" v-model="validateFields.lastName" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Preferred First Name">
                    <el-input size="mini" v-model="preferredFirstName" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Phone">
                    <el-input size="mini" v-model="phone" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input size="mini" v-model="email" style="width:200px;"></el-input>
                </el-form-item>

                <template v-for="(referral_source, index) in studentReferralSources">
                    <el-form-item label="Referral Source" :key="index">
                        <el-select size="mini" value-key="value" v-model="referral_source.name" @change="handleReferralSourceSelect(referral_source.value, index)" style="width:300px">
                            <el-option v-for="referralSource in referralSources" :label="referralSource.name" :value="referralSource.value" :key="referralSource.value"></el-option>
                        </el-select>
                        <template v-if="referral_source.show_other">
                            <br><el-input size="mini" v-model="referral_source.source_other" style="width:300px;"></el-input>
                        </template>
                    </el-form-item>
                </template>

                <template v-if="studentReferralSources.length < 5">

                    <a @click="addStudentReferralSource">[+] Add another</a>
                </template>



                <el-form-item label="Send promotional e-mail?">
                    <el-checkbox v-model="sendPromotionalEmail"></el-checkbox>
                </el-form-item>
                <el-form-item label="Intensive Case Management?">
                    <el-checkbox v-model="intensiveCaseManagement"></el-checkbox>
                </el-form-item>
                <el-form-item label="------------------------------------------------"></el-form-item>
                <el-form-item label="Permission granted to speak with 1">
                    <el-input size="mini" v-model="permissionToSpeakName1" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Relationship to student 1">
                    <el-input size="mini" v-model="permissionToSpeakRelationship1" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Phone 1">
                    <el-input size="mini" v-model="permissionToSpeakPhone1" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="------------------------------------------------"></el-form-item>
                <el-form-item label="Permission granted to speak with 2">
                    <el-input size="mini" v-model="permissionToSpeakName2" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Relationship to student 2">
                    <el-input size="mini" v-model="permissionToSpeakRelationship2" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Phone 2">
                    <el-input size="mini" v-model="permissionToSpeakPhone2" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="------------------------------------------------"></el-form-item>
                <el-form-item label="Permission granted to speak with 3">
                    <el-input size="mini" v-model="permissionToSpeakName3" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Relationship to student 3">
                    <el-input size="mini" v-model="permissionToSpeakRelationship3" style="width:200px;"></el-input>
                </el-form-item>
                <el-form-item label="Phone 3">
                    <el-input size="mini" v-model="permissionToSpeakPhone3" style="width:200px;"></el-input>
                </el-form-item>
                <template v-if="showAdminArea">
                    <h3>The following fields are visible to directors and system administrators only</h3>
                    <el-form-item label="Assign to Coordinator">
                        <el-select size="mini" v-model="coordinatorId" style="width:200px;">
                            <el-option key="" value="" label=""></el-option>
                            <el-option v-for="coordinator in coordinators" :key="coordinator.id" :value="coordinator.id" :label="coordinator.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Eligibility">
                            <el-select v-model="eligibility" size="mini" style="width:325px;">
                                <el-option v-for="item in eligibilities" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        <template v-if="eligibility === 'short_term'">
                            <br><span class="label-text">Select an End Date:</span> <el-date-picker size="mini" type="date" placeholder="Pick a date" v-model="shortTermEndDate" value-format="yyyy-MM-dd"></el-date-picker>
                        </template>
                        <template v-if="eligibility === 'temporary_term'">
                            <br><span class="label-text">Select the End Term:</span>
                            <el-select size="mini" v-model="endOfSemesterId">
                                <el-option v-for="semester in nextSixSemesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                    <el-form-item label="ODS Registration Date">
                        <el-date-picker size="mini" type="date" placeholder="Pick a date" v-model="odsRegistrationDate" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="ODS Expiration Date">
                        <el-date-picker size="mini" type="date" placeholder="Pick a date" v-model="expirationDate" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Expired Reason">
                        <el-select size="mini" v-model="expiredReason" style="width:300px;">
                            <el-option v-for="reason in expiredReasons" :key="reason.name" :value="reason.name" :label="reason.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Expired Reason Comment">
                        <el-input size="mini" v-model="expiredReasonComment" style="width:300px;"></el-input>
                    </el-form-item>
                    <el-form-item v-if="showMoveToRegistered" label="Bypass Application Process">
                        <el-checkbox v-model="moveToRegistered"></el-checkbox>
                    </el-form-item>
                </template>
            </el-form>
            <p>Last updated {{ lastUpdatedAt | formatDateMDY }} by {{ lastUpdatedBy }}</p>
            <el-button size="mini" type="primary" @click="onSubmit">Save Changes</el-button> or <a :href="'/student/profile/' + id">Cancel and return to student record</a>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Edit student information"
            }
        },
        components: {
            },
        data () {
            return {
                id: 0,
                pid: null,
                uni: null,
                firstName: null,
                middleName: null,
                lastName: null,
                preferredFirstName: null,
                phone: null,
                email: null,
                studentReferralSources: [],
                sendPromotionalEmail: null,
                intensiveCaseManagement: null,
                permissionToSpeakName1: null,
                permissionToSpeakName2: null,
                permissionToSpeakName3: null,
                permissionToSpeakRelationship1: null,
                permissionToSpeakRelationship2: null,
                permissionToSpeakRelationship3: null,
                permissionToSpeakPhone1: null,
                permissionToSpeakPhone2: null,
                permissionToSpeakPhone3: null,
                coordinatorId: null,
                eligibility: null,
                oldEligibility: null,
                shortTermEndDate: null,
                endOfSemesterId: null,
                odsRegistrationDate: null,
                expirationDate: null,
                expiredReason: null,
                expiredReasonComment: null,
                moveToRegistered: false,
                showAdminArea: false,
                showMoveToRegistered: false,
                showReferralSourceOther: false,
                lastUpdatedBy: null,
                lastUpdatedAt: null,
                //Dropdown values
                referralSources: [],
                coordinators: [],
                eligibilities: [
                    {name: '', value: ''},
                    {name: 'Tenure Eligibility: Current Academic Program', value: 'permanent'},
                    {name: 'Temporary Eligibility: End of term', value: 'temporary_term'},
                    {name: 'Short term eligibility', value: 'short_term'}
                ],
                expiredReasons: [],
                nextSixSemesters: [],
                validateFields: {
                    firstName: null,
                    lastName: null,
                },
                rules: {
                    firstName: [{ required: true, whitespace: true, message: 'First Name is required' }],
                    lastName: [{ required: true, whitespace: true, message: 'Last Name is required' }],
                }
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/student/edit/')[1];

                axios.get("/api/student/referral-sources/")
                    .then(response => {
                        if(response.data.success) {
                            this.referralSources = response.data.rows;

                            axios.get('/api/student/edit-information/', {params: {id: this.id}})
                                .then(studentResponse => {
                                    this.id = studentResponse.data.id;
                                    this.pid = studentResponse.data.pid;
                                    this.uni = studentResponse.data.uni;
                                    this.validateFields.firstName = studentResponse.data.first_name;
                                    this.middleName = studentResponse.data.middle_name;
                                    this.validateFields.lastName = studentResponse.data.last_name;
                                    this.preferredFirstName = studentResponse.data.preferred_first_name;
                                    this.phone = studentResponse.data.phone;
                                    this.email = studentResponse.data.email;
                                    this.studentReferralSources = studentResponse.data.referral_sources;
                                    this.sendPromotionalEmail = studentResponse.data.send_promotional_email;
                                    this.intensiveCaseManagement = studentResponse.data.intensive_case_management;
                                    this.permissionToSpeakName1 = studentResponse.data.permission_to_speak_name_1;
                                    this.permissionToSpeakName2 = studentResponse.data.permission_to_speak_name_2;
                                    this.permissionToSpeakName3 = studentResponse.data.permission_to_speak_name_3;
                                    this.permissionToSpeakRelationship1 = studentResponse.data.permission_to_speak_relationship_1;
                                    this.permissionToSpeakRelationship2 = studentResponse.data.permission_to_speak_relationship_2;
                                    this.permissionToSpeakRelationship3 = studentResponse.data.permission_to_speak_relationship_3;
                                    this.permissionToSpeakPhone1 = studentResponse.data.permission_to_speak_phone_1;
                                    this.permissionToSpeakPhone2 = studentResponse.data.permission_to_speak_phone_2;
                                    this.permissionToSpeakPhone3 = studentResponse.data.permission_to_speak_phone_3;
                                    this.coordinatorId = studentResponse.data.coordinator_id;
                                    this.oldEligibility = studentResponse.data.eligibility;
                                    this.odsRegistrationDate = studentResponse.data.ods_registration_date;
                                    this.expirationDate = studentResponse.data.expiration_date;
                                    this.expiredReason = studentResponse.data.expired_reason;
                                    this.expiredReasonComment = studentResponse.data.expired_reason_comment;
                                    this.moveToRegistered = studentResponse.data.move_to_registered;
                                    this.lastUpdatedAt = studentResponse.data.last_updated_at;
                                    this.lastUpdatedBy = studentResponse.data.latest_change_by_identifier;
                                    this.showAdminArea = studentResponse.data.show_admin_area;
                                    this.showMoveToRegistered = studentResponse.data.show_move_to_registered;
                                    this.endOfSemesterId = studentResponse.data.expiration_semester_id;
                                    this.nextSixSemesters = studentResponse.data.next_six_semesters;

                                    if(this.studentReferralSources.length == 0){
                                        this.studentReferralSources.push({"name": ""});
                                    } else {
                                        for(var i=0; i < this.studentReferralSources.length; i++){
                                            this.handleReferralSourceSelect(this.studentReferralSources[i].name, i);
                                        }
                                    }

                                    if(studentResponse.data.eligibility === 'permanent' || studentResponse.data.eligibility === 'temporary_term' || studentResponse.data.eligibility === 'short_term'){
                                        this.eligibility = studentResponse.data.eligibility;
                                    } else {
                                        this.eligibility = '';
                                    }


                                    axios.get("/api/simple/get-active-users-by-role/", {params: {role: 'coordinator', current_coordinator_id: this.coordinatorId}})
                                        .then(response => {
                                            if(response.data.success) {
                                                this.coordinators = response.data.rows;
                                            }
                                        });
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error)
                                });
                        }


                    });





                axios.get("/api/simple/get-expiration-reasons")
                    .then(response => {
                        this.expiredReasons = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(){
                this.$message.closeAll();

                this.$refs['studentForm'].validate((valid) => {
                    if(valid){
                        var eligibility = this.eligibility == '' ? this.oldEligibility : this.eligibility;

                      // eslint-disable-next-line
                      console.log(eligibility)
                        var params = {
                            id: this.id,
                            pid: this.pid,
                            uni: this.uni,
                            first_name: this.validateFields.firstName,
                            middle_name: this.middleName,
                            last_name: this.validateFields.lastName,
                            preferred_first_name: this.preferredFirstName,
                            phone: this.phone,
                            email: this.email,
                            referral_sources: this.studentReferralSources,
                            send_promotional_email: this.sendPromotionalEmail,
                            intensive_case_management: this.intensiveCaseManagement,
                            permission_to_speak_name_1: this.permissionToSpeakName1,
                            permission_to_speak_name_2: this.permissionToSpeakName2,
                            permission_to_speak_name_3: this.permissionToSpeakName3,
                            permission_to_speak_relationship_1: this.permissionToSpeakRelationship1,
                            permission_to_speak_relationship_2: this.permissionToSpeakRelationship2,
                            permission_to_speak_relationship_3: this.permissionToSpeakRelationship3,
                            permission_to_speak_phone_1: this.permissionToSpeakPhone1,
                            permission_to_speak_phone_2: this.permissionToSpeakPhone2,
                            permission_to_speak_phone_3: this.permissionToSpeakPhone3,
                            coordinator_id: this.coordinatorId,
                            eligibility: eligibility,
                            expiration_date: this.expirationDate,
                            expired_reason: this.expiredReason,
                            expired_reason_comment: this.expiredReasonComment,
                            end_of_semester_id: this.endOfSemesterId,
                            short_term_end_date: this.shortTermEndDate,
                            move_to_registered: this.moveToRegistered,
                            ods_registration_date: this.odsRegistrationDate
                        }

                        axios.put("/api/student/edit-information/", params)
                            .then(response => {
                                    if(response.data.success){
                                        this.$router.push({ path: '/student/profile/' + this.id});
                                    } else {
                                        this.$message({
                                            showClose: true,
                                            duration: 20000,
                                            type: 'error',
                                            message: response.data.message
                                        });
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error)
                                });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: "One or required fields are missing. Please correct any errors and try saving again."
                        });
                    }
                });
            },
            handleReferralSourceSelect: function(referralSourceValue, index){
                if(referralSourceValue && referralSourceValue != "" && this.referralSources){
                    var referralSource = this.referralSources.find(function(source){return source.value == referralSourceValue});
                    if(referralSource){
                        this.studentReferralSources[index].show_other = referralSource.show_other;
                    } else {
                        this.studentReferralSources[index].show_other = false;
                    }
                } else {
                    this.studentReferralSources[index].show_other = false;
                }
            },
            addStudentReferralSource: function() {
                this.studentReferralSources.push({name: "", source_other: ""})
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 100%;
    }
    .label-text {
        font-size: 13px;
    }
</style>
