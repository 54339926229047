<template>
    <div id="container">
<div id="content">
            <br><div><a :href="'/admin'">Users</a> | <a :href="'/semester/list'">Academic Calendar</a> | <a :href="'/trace'">Trace</a></div>
            <center><a :href="'/trace'">Return to Overview</a> | <a :href="csvLink" target="_blank">Download CSV</a></center>
            <el-table :data="sortedData.length ? sortedData : data" @sort-change="sortByUsers" style="margin-top:5px;">
                <el-table-column prop="user_id" label="User" sortable="custom"><template slot-scope="scope">{{ users[String(scope.row["_source"].user_id)] }}</template></el-table-column>
                <el-table-column prop="created_at" label="Date/Time" sortable="custom"><template slot-scope="scope">{{ scope.row["_source"].created_at | formatDateTimeMDYhma }}</template></el-table-column>
                <el-table-column prop="url" label="URL" sortable="custom"><template slot-scope="scope">{{ scope.row["_source"].url }}</template></el-table-column>
                <el-table-column prop="method" label="Method" sortable="custom"><template slot-scope="scope">{{ scope.row["_source"].method }}</template></el-table-column>
                <el-table-column prop="status" label="Status" sortable="custom"><template slot-scope="scope">{{ scope.row["_source"].status }}</template></el-table-column>
                <el-table-column prop="remote_ip" label="IP" sortable="custom"><template slot-scope="scope">{{ scope.row["_source"].remote_ip }}</template></el-table-column>
            </el-table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: 'System Administration: Trace Details'
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                date: null,
                userId: null,
                data: [],
                sortedData: [],
                users: []
            }
        },
        computed: {
            csvLink: function(){
                if(this.date || this.userId){
                    var url = "/api/admin/focused-trace.xlsx?";
                    if(this.date){
                        url += 'day=' + this.date;
                    }
                    if(this.userId){
                        if(this.date) url += '&';
                        url += 'user_id=' + this.userId;
                    }
                    return url;
                }
                return '#';
            }
        },
        methods: {
            load: function(){
                this.urlParams = this.$route.query;
                if(this.urlParams.day) this.date = this.urlParams.day;
                if(this.urlParams.user_id) this.userId = this.urlParams.user_id;

                if(this.date || this.userId){
                    axios.get("/api/admin/focused-trace/", {params:{user_id: this.userId, date: this.date}})
                        .then(response => {
                            this.data = response.data.data;
                            this.users = response.data.users;
                        });
                }
            },
            sortByUsers: function({column, prop, order}){
                if(order){
                    this.sortedData = this.data.slice();
                    var users = this.users;
                    this.sortedData.sort(function(a, b){
                        if(prop == "created_at"){
                            var dateA = moment(a["_source"][prop]);
                            var dateB = moment(b["_source"][prop]);

                            if (dateA.isBefore(dateB)){
                                if(order == "ascending"){
                                    return -1;
                                } else {
                                    return 1;
                                }

                            }
                            if (dateA.isAfter(dateB)){
                                if(order == "ascending"){
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                            return 0;
                        } else if(prop == "status"){
                            return a["_source"][prop] - b["_source"][prop];
                        } else {
                            var propA = null;
                            var propB = null;
                            if(prop == "user_id"){
                                propA = users[String(a["_source"][prop])].toUpperCase();
                                propB = users[String(b["_source"][prop])].toUpperCase();
                            } else {
                                propA = a["_source"][prop].toUpperCase();
                                propB = b["_source"][prop].toUpperCase();
                            }

                            if(propA < propB){
                                if(order == "ascending"){
                                    return -1;
                                } else {
                                    return 1;
                                }
                            }
                            if(propA > propB){
                                if(order == "ascending"){
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                            return 0;
                        }
                    });
                } else {
                    this.sortedData = [];
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
