<template>
    <div id="container">
<div id="content">
            <h1>Editing task</h1>
            <el-form :model="validateField" :rules="rules" ref="taskForm" label-width="165px" label-position="left">
                <el-form-item label="Subject" prop="subject">
                    <el-input size="mini" v-model="validateField.subject" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item label="Author">
                    <span v-if="createMode" class="form-text">{{ user ? user.name : '' }}</span>
                    <span v-else class="form-text">{{ authorName }}</span>
                </el-form-item>
                <el-form-item label="Type">
                    <el-select v-if="createMode" size="mini" v-model="type" style="width:100px;">
                        <el-option label="Task" value="Task"></el-option>
                        <el-option label="Message" value="Message"></el-option>
                    </el-select>
                    <span v-else class="form-text">{{ type }}</span>
                    <br><span class="form-text">Note: A <span class="text-italic">task</span> requires some action on the part of the recipient, and once created it remains open until the author of the task closes it. A <span class="text-italic">message</span> is used just to send information and closes when the recipient chooses to mark it as read.</span>
                </el-form-item>
                <el-form-item label="Status">
                    <span class="form-text">{{ statusDisplay }}</span>
                </el-form-item>
                <el-form-item label="Recipients / Assigned to">
                    <el-autocomplete style="width:500px;" :fetch-suggestions="handleUserSearch" :trigger-on-focus="false" size="mini" @select="handleUserSelect" v-model="recipientsStr" :disabled="sendToAllUsers"></el-autocomplete> <span class="form-text">or</span> <el-checkbox v-model="sendToAllUsers" label="All Doris Users"></el-checkbox>
                    <br><span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                    <template v-if="!sendToAllUsers">
                        <template v-for="user in recipients">
                            <template v-if="user.value">
                                <el-checkbox :key="user.id" :label="user.value" @change="removeUserFromRecipients(user)" checked></el-checkbox><br :key="user.id">
                            </template>
                            <template v-else>
                                <el-checkbox :key="user.id" :label="user.name + ' <' + user.email + '>'" @change="removeUserFromRecipients(user)" checked></el-checkbox><br :key="user.id">
                            </template>
                        </template>
                    </template>
                </el-form-item>
                <el-form-item label="Student Name">
                    <el-autocomplete v-model="studentName" :fetch-suggestions="querySearch" :trigger-on-focus="false" @select="function(student) {studentId = student.id}" size="mini" style="width:500px"></el-autocomplete>
                    <br><span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                </el-form-item>
                <el-form-item label="Text">
                    <el-input size="mini" type="textarea" :rows="15" v-model="body" style="width:600px;"></el-input>
                </el-form-item>
            </el-form>
            <template v-if="createMode || status === 'draft'">
                <el-button size="mini" type="primary" @click="onSubmit('pending', false)">Send / Assign</el-button>
                <el-button size="mini" type="primary" @click="onSubmit('draft', false)">Save as draft</el-button>
                or <a :href="'/tasks'">Cancel</a>
            </template>
            <template v-else>
                <el-button size="mini" type="primary" @click="onSubmit('closed', false)">Mark as Closed</el-button>
                <el-button size="mini" type="primary" @click="onSubmit(status, false)">Save changes</el-button>
                or <a :href="'/tasks'">Cancel</a>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: "Editing Task"
            }
        },

        components: {

        },
        data () {
            return {
                id: 0,
                user: null,
                createMode: true,
                showSubjectError: false,
                //form data
                authorName: null,
                type: "Task",
                status: 'draft',
                statusDisplay: 'Draft',
                sendToAllUsers: false,
                recipients: [],
                recipientsStr: null,
                studentId: null,
                studentName: null,
                body: null,
                validateField: {
                    subject: null
                },
                rules: {
                    subject: [{ required: true, whitespace: true, message: 'Subject is required' }]
                }
            }
        },
        methods: {
            load: function() {
                this.id = location.pathname.split('/tasks/edit/')[1];

                setInterval(() => {
                    if(this.id){
                        this.onSubmit(this.status, true);
                    } else {
                        this.onSubmit('draft', true);
                    }
                }, 120000); //two minutes

                if(this.id){
                    this.createMode = false;
                    axios.get('/api/task/', {params: {id: this.id}})
                        .then(response => {
                            this.validateField.subject = response.data.task.subject;
                            this.type = response.data.task.type;
                            this.authorName = response.data.task.author_name;
                            this.status = response.data.task.status;
                            this.statusDisplay = response.data.task.status_display;
                            this.recipients = response.data.task.recipients;
                            this.sendToAllUsers = response.data.task.send_to_all_users;
                            if(response.data.task.student_name){
                                this.studentName = response.data.task.student_name;
                            }
                            if(response.data.task.student_uni){
                                this.studentName += " <" + response.data.task.student_uni + ">";
                            }
                            this.studentId = response.data.task.student_id;
                            this.body = response.data.task.body;
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                            console.log(error)
                        });
                } else {
                    this.urlParams = this.$route.query;
                    if(this.urlParams.student_id){
                        this.studentId = parseInt(this.urlParams.student_id);

                        axios.get("/api/student/" + this.studentId)
                            .then(response => {
                                this.studentName = response.data.title + " <" + response.data.uni + ">";
                            });
                    }

                    if(this.urlParams.message){
                        this.type = 'Message';
                    }
                }

                axios.get("/api/user")
                    .then(response => {
                        if(response.data.success == true) {
                            this.user = response.data.user;
                        } else {
                            // eslint-disable-next-line
                            console.log("failed to get user");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(status, isAutoSave){
                if(!isAutoSave || (this.validateField.subject && this.validateField.subject.trim() != '')) {
                    this.$refs['taskForm'].validate((valid) => {
                        if(valid){
                            this.$message.closeAll();
                            var recipientIds = [];
                            this.recipients.forEach(function(user){
                                recipientIds.push(user.id);
                            });

                            if(this.id){
                                axios.put("/api/task/", {id: parseInt(this.id), subject: this.validateField.subject,
                                    recipient_ids: recipientIds, send_to_all_users: this.sendToAllUsers, student_id: this.studentId, body: this.body, status: status})
                                .then(response => {
                                    if (response.data.success) {
                                        if(!isAutoSave){
                                          this.$router.push({ path: '/tasks/show/' + this.id});
                                        } else {
                                            this.$message({
                                                showClose: true,
                                                duration: 0,
                                                type: 'success',
                                                message: "This task was automatically saved at " + moment(new Date()).format('hh:mma')
                                            });
                                        }
                                    } else {
                                       //handle?
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error)
                                });
                            } else {
                                axios.post("/api/task/", {subject: this.validateField.subject, type: this.type, recipient_ids: recipientIds, send_to_all_users: this.sendToAllUsers, student_id: this.studentId, body: this.body, status: status})
                                .then(response => {
                                    if (response.data.success) {
                                        if(!isAutoSave){
                                            if(response.data.id){
                                              this.$router.push({ path: '/tasks/show/' + response.data.id});
                                            }
                                        } else {
                                            if(response.data.id){
                                                this.id = response.data.id;
                                                this.$message({
                                                    showClose: true,
                                                    duration: 0,
                                                    type: 'success',
                                                    message: "This task was automatically saved at " + moment(new Date()).format('hh:mma')
                                                });
                                            }
                                        }
                                    } else {
                                       //handle?
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error)
                                });
                            }
                        } else if(!isAutoSave) {
                            this.$message.closeAll();
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: "One error prohibited this task from being saved: Subject can't be blank."
                            });
                        }
                    });
                }
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            handleUserSearch: function(queryString, cb) {
                axios.get('/api/contact-note/search-active-users/?query=' + queryString)
                    .then(response => {
                        cb(response.data.rows);
                    });
            },
            handleUserSelect: function(user){
                this.$message.closeAll();
                var existingUser = this.recipients.find(function(existingUserObj){
                    return existingUserObj.id == user.id;
                });

                if(!existingUser){
                    this.recipients.push(user);
                } else {
                    this.$message({
                        showClose: true,
                        duration: 0,
                        type: 'error',
                        message: "This user already exists in the list."
                    });
                }
                this.recipientsStr = null;
            },
            removeUserFromRecipients: function(user){
                this.recipients.splice(this.recipients.indexOf(user), 1);
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 100%;
    }
</style>
