<template>
    <div id="container">
<div id="content">
            <h1>Caseload by Date</h1>
            <h2>Selected Date: {{ date | formatDateFullMonth }}</h2>
            <el-date-picker type="date" size="mini" v-model="date" value-format="yyyy-MM-dd"></el-date-picker>
            <el-button size="mini" type="primary" @click="getData">Submit</el-button>
            <table><tbody>
                <tr><th>Status</th><th>Number of students</th></tr>
                <tr v-for="(row, index) in data" :key="index">
                    <td>{{ row.status }}</td><td>{{ row.count }}</td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Caseload by Date"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                date: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.date = this.urlParams.date;
                this.getData();
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/caseload-by-date/', {params: {date: this.date}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
