<template>
  <div id="container">
    <div id="content">
      <h1>{{ isCreateUser ? "Create User" : "Edit User" }}</h1>
      <el-form label-width="300px" status-icon :model="user" :rules="rules" ref="userForm" label-position="right">
        <el-form-item label="First Names">
          <el-input size="mini" v-model="user.firstNames"></el-input>
        </el-form-item>
        <el-form-item label="Last Name">
          <el-input size="mini" v-model="user.lastName"></el-input>
        </el-form-item>
        <el-form-item label="Job Title">
          <el-input size="mini" v-model="user.jobTitle"></el-input>
        </el-form-item>
        <el-form-item label="Email Address" prop="email">
          <el-input size="mini" v-model="user.email"></el-input>
        </el-form-item>
        <el-form-item label="Roles">
          <el-checkbox-group v-model="user.roles">
            <template v-for="role in roles">
              <el-checkbox :key="role.id" :label="role.id">{{role.name}}</el-checkbox><br :key="role.id +'-br'">
            </template>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Contact Note Supervisor">
          <el-select size="mini" v-model="user.contactNoteSupervisor">
            <el-option v-for="supervisor in contactNoteSupervisors" :key="supervisor.id" :value="supervisor.id" :label="supervisor.first_names + ' ' + supervisor.last_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Accommodation Approval Supervisor">
          <el-select size="mini" v-model="user.accommodationApprovalSupervisor">
            <el-option v-for="supervisor in accommodationApprovalSupervisors" :key="supervisor.id" :value="supervisor.id" :label="supervisor.first_names + ' ' + supervisor.last_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Opt out of Housing Committee emails?">
          <el-checkbox style="margin-top:6px;" v-model="user.optOutHousingCommitteeEmail"></el-checkbox>
        </el-form-item>
        <el-form-item label="Can set self as reply-to email address">
          <el-checkbox style="margin-top:6px;" v-model="user.setSelfAsReplyToEmail"></el-checkbox>
        </el-form-item>
        <el-form-item label="Account is locked?">
          <el-checkbox style="margin-top:6px;" v-model="user.accountIsLocked"></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="onSave('userForm')">Save Changes</el-button> or <a :href="'/admin'">Cancel</a>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: 'Edit Name and Address'
      }
    },
    components: {
    },
    data () {
      return {
        isCreateUser: true,
        user: {
          id: null,
          firstNames: null,
          lastName: null,
          jobTitle: null,
          email: null,
          roles: [],
          contactNoteSupervisor: null,
          accommodationApprovalSupervisor: null,
          optOutHousingCommitteeEmail: false,
          setSelfAsReplyToEmail: false,
          accountIsLocked: false
        },
        roles: [],
        initialRolesForUser: [],
        contactNoteSupervisors: [],
        accommodationApprovalSupervisors: [],
        rules: {
          email: [{ required: true, message: 'Email is required', trigger: 'blur' }]
        }
      }
    },
    methods: {
      load: function () {
        if(location.pathname.split('/admin/edit-user/').length > 1){
          this.user.id = parseInt(location.pathname.split('/admin/edit-user/')[1],10);
          this.isCreateUser = false;
          this.getUser();
        } else {
          this.getSupervisors();
        }
        this.getRoles();
      },
      getRoles: function() {
        axios.get("/api/admin/roles/")
          .then(response => {
            this.roles = response.data.rows;
          });
      },
      getSupervisors: function() {
        axios.get("/api/admin/contact-note-supervisors/",
            {
              params: {
                withUser: this.user.contactNoteSupervisor
              }
            })
          .then(response => {
            this.contactNoteSupervisors = response.data.rows;
            this.contactNoteSupervisors.unshift({id: null, first_names: "", last_name: ""});
          });
        axios.get("/api/admin/accommodation-approval-supervisors/",
            {
              params: {
                withUser: this.user.accommodationApprovalSupervisor
              }
            })
          .then(response => {
            this.accommodationApprovalSupervisors = response.data.rows;
            this.accommodationApprovalSupervisors.unshift({id: null, first_names: "", last_name: ""});
          });
      },

      getUser: function () {
        axios.get("/api/admin/user/", {params: {id: this.user.id}})
          .then(response => {
            this.user.firstNames = response.data.first_names;
            this.user.lastName = response.data.last_name;
            this.user.jobTitle = response.data.job_title;
            this.user.email = response.data.email;
            this.user.roles = response.data.roles;
            this.initialRolesForUser = response.data.roles;
            this.user.contactNoteSupervisor = response.data.contact_note_supervisor_id;
            this.user.accommodationApprovalSupervisor = response.data.accommodation_approval_supervisor_id;
            this.user.optOutHousingCommitteeEmail = response.data.opt_out_of_ha_committee_emails;
            this.user.setSelfAsReplyToEmail = response.data.can_set_replyto_to_self;
            this.user.accountIsLocked = response.data.is_locked;


            this.getSupervisors();
          });
      },
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      onSave: function (formName) {
        this.$refs[formName].validate((valid) => {
          if(valid){
            if(this.isCreateUser){
              //console.log("isCreateUser");
              //console.log(this.user);
              axios.post('/api/admin/user/', this.user)
                .then(response => {
                  //console.log(response.data);
                  if(response.data.success) {
                    //console.log("success");
                    //console.log(response.data.id);
                    this.$router.push({ path: '/admin'});
                  } else {
                    // eslint-disable-next-line
                    console.log("failed:" + response.data.message);
                    this.displayError(response.data.message);
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              axios.put('/api/admin/user/', this.user)
                .then(response => {
                  if(response.data.success) {
                    //console.log("success");
                    this.$router.push({ path: '/admin'});
                  } else {
                    // eslint-disable-next-line
                    console.log("failed:" + response.data.message);
                    this.displayError(response.data.message);
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          } else {
            //return message
            // eslint-disable-next-line
            console.log("not valid");
          }
        });
      }
    },
    created () {
      this.load();
    }
  }
</script>

<style scoped>
  .error-msg p {
    color: red;
  }
  #content .el-form {
    width:550px;
  }
</style>
