<template>
    <div id="container">
<div id="content">
            <h1>Proctor Hours Report</h1>
            <p>
                <el-date-picker type="date" size="mini" v-model="startDate" value-format="yyyy-MM-dd"></el-date-picker> - <el-date-picker type="date" size="mini" v-model="endDate" value-format="yyyy-MM-dd"></el-date-picker><br>
                Use drop-down menu to select entire semester (will result in a CSV only):
                <el-select size="mini" v-model="semesterId">
                    <el-option :value="null" label="Select a Semester"></el-option>
                    <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                </el-select><br><br>
                Select a particular proctor:
                <el-select size="mini" v-model="proctorId">
                    <el-option :value="null" label="Select a Proctor"></el-option>
                    <el-option v-for="proctor in proctors" :key="proctor.id" :value="proctor.id" :label="proctor.name"></el-option>
                </el-select>
                <el-button size="mini" type="primary" @click="getData">Submit</el-button>
                <br/>

                <a :href="'/api/report/proctor-hours.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate + '&semester_id=' + this.semesterId + '&proctor_id=' + this.proctorId" target="_blank">Download as CSV</a>
            </p>

            <table><tbody>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th v-for="date in dates" colspan="4" :key="date">{{ date | formatDateDayOfWeek }}</th>
                    <th></th>
                </tr>
                <tr>
                    <th>Proctor Name</th>
                    <th>Payroll Status</th>
                    <th>Columbia Affiliation</th>
                    <template v-for="date in dates">
                        <th :key="date+'a'">Start Time</th>
                        <th :key="date+'b'">End Time</th>
                        <th :key="date+'c'">Day Hours</th>
                        <th :key="date+'d'">Location</th>
                    </template>
                    <th>Total Hours</th>
                </tr>
                <tr v-for="(row, index) in data" :key="index">
                    <td>{{ row.first_name }} {{ row.last_name }}</td>
                    <td>{{ row.proctor_university_status }}</td>
                    <td>{{ row.proctor_columbia_affiliation }}</td>
                    <template v-for="(date, dateIndex) in dates">
                        <template v-if="row[date] && row[date].length">
                            <td :key="dateIndex+'a'">
                                <template v-for="(session, sessionIndex) in row[date]">
                                    {{ session.start_time | formatTimehhmmA }} <br :key="sessionIndex" v-if="sessionIndex != row[date].length-1"/>
                                </template>
                            </td>
                            <td :key="dateIndex+'b'">
                                <template v-for="(session, sessionIndex) in row[date]">
                                    {{ session.end_time | formatTimehhmmA }} <br :key="sessionIndex" v-if="sessionIndex != row[date].length-1"/>
                                </template>
                            </td>
                            <td :key="dateIndex+'c'">
                                <template v-for="(session, sessionIndex) in row[date]">
                                    {{ session.hours }} hrs <br :key="sessionIndex" v-if="sessionIndex != row[date].length-1"/>
                                </template>
                            </td>
                            <td :key="dateIndex+'d'">
                                <template v-for="(session, sessionIndex) in row[date]">
                                    {{ session.location }} hrs <br :key="sessionIndex" v-if="sessionIndex != row[date].length-1"/>
                                </template>
                            </td>
                        </template>
                        <template v-else>
                            <td colspan="4" :key="dateIndex"></td>
                        </template>
                    </template>
                    <td>{{ row.total_hours }}</td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Proctor Hours Report"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                proctors: [],
                semesters: [],
                dates: [],
                loading: null,
                startDate: null,
                endDate: null,
                semesterId: null,
                proctorId: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.startDate = this.urlParams.start_date;
                this.endDate = this.urlParams.end_date;
                this.getData();


            },
            getData: function() {
                if(this.semesterId) {
                    var url = '/api/report/proctor-hours.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate + '&semester_id=' + this.semesterId + '&proctor_id=' + this.proctorId;
                    window.open(url, '_blank');
                } else {
                    this.loading = this.$loading({lock: true, text: 'Loading...'});
                    axios.get('/api/report/proctor-hours/', {params: {start_date: this.startDate, end_date: this.endDate, proctor_id: this.proctorId}})
                        .then(response => {
                            if(response.data.success) {
                                this.proctors = response.data.proctor_options;
                                this.dates = response.data.dates;
                                this.data = response.data.rows;
                                this.semesters = response.data.semesters;
                                this.loading.close();
                            }
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                            console.log(error)
                        });
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .proctor-hours-tables {
        background: white;
    }
</style>
