<template>
    <div id="container">
<div id="content">
            <h1>Catalog of Automatic Change Notifications in Doris</h1>
            <p>The Message feature was originally intended to be a communication technique for written messages from one Doris user to another. In day-to-day use, however, it is the case that many times a user will change something in the database and simply want another user to be notified of the change. Rather than making them enter repetitive messages, we have expanded the original concept of messages to include automatically generated change notifications. This document will list all of the ways that Doris can notify its users of particular changes that have taken place. The messages appear on the user's home page in the "My Tasks / Messages" area.</p>
            <dl>
                <dt>Automatic expiration / deactivation</dt>
                <dd>Each morning Doris automatically scans the list of registered students to determine if there are any who should be expired due to their expiration date, graduation date, or current registration status. The list of applicants is also scanned to determine if any of them should be deactivated due to inactivity. When a student's status is changed for any of these reasons, a message is sent to the student's coordinator. The message appears to have been written by the system administrator (Luke Pond). The Subject will read either "Automatic expiration: John Doe" or "Automatic deactivation: John Doe".</dd>
                <dt>Manual expiration or reactivation of a student</dt>
                <dd>A coordinator may change a Registered student's status to "Expired", or vice-versa. When this is done, a message is sent to the coordinator's supervisor explaining the change.</dd>
                <dt>Status change to "Needs assignment"</dt>
                <dd>Upon adding a second document, the student status changes to "Needs Assignment" - in this case, a message is sent to all directors with the subject "Needs Assignment". The sender of the message is the Doris user who uploaded the document.</dd>
                <dt>Coordinator assignment</dt>
                <dd>When the Director assigns a coordinator to a student the student's status changes to "Coordinator Review". In this case, a message is sent to the coordinator with the subject "Coordinator Assignment". The message comes from the Director. The same message is sent whenever the director reassigns the student's coordinator.</dd>
                <dt>Documentation is added to a student record</dt>
                <dd>If the student has a coordinator assignment, that coordinator will receive a Message with the subject "New documentation available".</dd>
                <dt>Supervisor Comment on a Contact Note</dt>
                <dd>When a supervisor comment is added to a contact note, the text of the comment is also placed in a message to the author of the contact note. The subject of this message is "Supervisor comment on your contact note".</dd>
                <dt>New accommodation recommended for a Registered student</dt>
                <dd>A student's coordinator may use the Accommodations interface to recommend a new accommodation after the student has already been registered and has completed the review process. In this case, a message is sent to the coordinator's supervisor with the subject "New supplemental accommodation to review". The body of the message summarizes the new recommended accommodation.</dd>
                <dt>Change in Expected Graduation</dt>
                <dd>When a student's expected graduation date is changed by SIS, a message is sent to the student's coordinator with the subject "Change in Expected Graduation". The message appears to have been written by the system administrator (Luke Pond).</dd>
            </dl>
            <p><em>Last updated on 8/10/2007</em></p>
        </div>
        </div>
</template>

<script>

    export default {
        metaInfo () {
            return {
                title: "Catalog of Automatic Change Notifications in Doris"
            }
        },
        components: {
            },
        data () {
            return {
            }
        },
        methods: {
        },
        created () {
        }
    }
</script>
<style scoped>
    dt { 
        font-weight: bold;
    }
</style>
