<template>
    <div id="container">
      <div id="content">
            <h1>Edit Contact Note Template</h1>
            <div class="template-form-container">
                <el-form class="template-form" label-width="85px">
                    <el-form-item label="Subject Line">
                        <el-input size="mini" v-model="title"></el-input>
                    </el-form-item>
                    <el-form-item label="Body">
                        <el-input size="mini" type="textarea" :rows="15" v-model="body"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" type="primary" @click="onSubmit">Save</el-button>
                    </el-form-item>
                </el-form>
                <p><a @click="deleteTemplate">Delete this template</a></p>
            </div>
            <p><a :href="'/contact-notes/list-templates'">Back to list</a></p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Edit Contact Note Template"
            }
        },
        components: {
            },
        data () {
            return {
                id: 0,
                title: '',
                body: ''
            }
        },
        methods: {
            load: function() {
                this.id = location.pathname.split('/contact-notes/edit-template/')[1];
                axios.get('/api/contact-note/contact-note-template/', {params: {id: this.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.title = response.data.template.title;
                            this.body = response.data.template.body;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            deleteTemplate: function(id) {
                this.$confirm('By clicking on "OK" you will be deleting the contact note template you\'re editing. This will not affect contact notes already sent using this template, but this template will no longer be available for new contact notes.<br><br>If you\'re not sure, click Cancel', {dangerouslyUseHTMLString: true})
                    .then(() => {
                        axios.delete("/api/contact-note", {params: {id: parseInt(this.id)}})
                            .then(response => {
                                this.$router.push({ path: '/contact-notes/list-templates' });
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            onSubmit: function(){
                axios.put("/api/contact-note/contact-note-template/", {id: parseInt(this.id), title: this.title, body: this.body})
                    .then(response => {
                        if (response.data.success) {
                            this.$router.push({ path: '/contact-notes/list-templates' });
                        } else {
                           //handle?
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .template-form-container {
        float: left;
        margin-right: 100px;
    }
    .template-form {
        width:700px;
    }
</style>
