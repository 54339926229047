<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getProctors">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>{{ activeStatus.replace(/^\w/, c => c.toUpperCase()) }} Proctors</h1>
            <a class="trailing-space" v-if="activeStatus != 'active'" @click="changeActiveStatus('active')">Show active proctors only</a>
            <a class="trailing-space" v-if="activeStatus != 'inactive'"  @click="changeActiveStatus('inactive')">Show inactive proctors only</a>
            <a v-if="activeStatus != 'all'" @click="changeActiveStatus('all')">Show all proctors</a>
            <el-table :data="[{}]" :show-header="false" class="no-border">
                <el-table-column :min-width="9.0"></el-table-column>
                <el-table-column :min-width="12.0"></el-table-column>
                <el-table-column :min-width="20.0"></el-table-column>
                <el-table-column :min-width="5.7"></el-table-column>
                <el-table-column :min-width="6.5"></el-table-column>
                <el-table-column :min-width="24.0">
                    <b>Filter list:</b>
                    <el-select size="mini" @change="handleFilter" v-model="filter">
                        <el-option label="All" value="all"></el-option>
                        <el-option label="Exam Proctors Only" value="exam_proctors_only"></el-option>
                        <el-option label="Exam Proctors + Exam Proctor Trainee" value="exam_proctors_and_trainees"></el-option>
                        <el-option label="Exam Proctor Trainee only" value="exam_proctor_trainees"></el-option>
                        <el-option label="Admin Proctors only" value="admin_proctors_only"></el-option>
                        <el-option label="Special Assignment only" value="special_assignment_only"></el-option>
                    </el-select>

                </el-table-column>
                <el-table-column :min-width="10.0"></el-table-column>
                <el-table-column :min-width="10.0"></el-table-column>
                <el-table-column :min-width="21.0"></el-table-column>
                <el-table-column :min-width="20.5"></el-table-column>
                <el-table-column :min-width="20.5"></el-table-column>
            </el-table>
            <el-table :default-sort="{prop: 'name', order: 'ascending'}" :data="filteredProctors.length ? filteredProctors : proctors">
                <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']" :min-width="9.0">
                    <template slot-scope="scope">
                        <a :href="'proctor/show/' + scope.row.id">{{ scope.row.name }}</a>
                        <br/><br/>
                        <a :href="'/proctor/edit/' + scope.row.id">edit</a><br>
                        <a :href="'/proctor/show/' + scope.row.id">show</a>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="Phone" sortable :sort-orders="['ascending', 'descending']" :min-width="12.0"></el-table-column>
                <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']" :min-width="20.0"><template slot-scope="scope"><a :href="'/group-email/compose?proctor_id=' + scope.row.id">{{ scope.row.email }}</a></template></el-table-column>
                <el-table-column prop="active" label="Active" sortable :sort-orders="['ascending', 'descending']" :min-width="5.7"><template slot-scope="scope">{{ scope.row.active ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column prop="cumc_proctor" label="CUMC Proctor" sortable :sort-orders="['ascending', 'descending']" :min-width="6.5"><template slot-scope="scope">{{ scope.row.cumc_proctor ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column prop="proctor_title" label="Title" sortable :sort-orders="['ascending', 'descending']" :min-width="24.0">
                    <template slot-scope="scope">
                        {{ scope.row.proctor_title }}
                        <template v-if="scope.row.proctor_is_trainee"> <span style="color: green; font-weight:bold">(Trainee)</span></template>
                        <template v-if="scope.row.proctor_special_assignment_note"><br>{{ scope.row.proctor_special_assignment_note }}</template>
                    </template>
                </el-table-column>
                <el-table-column prop="proctor_university_status" label="Payroll Status" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0">
                    <template slot-scope="scope">
                        {{ scope.row.proctor_university_status }}<template v-if="scope.row.proctor_university_status_other">: {{ scope.row.proctor_university_status_other }}</template>
                        <template v-if="scope.row.proctor_payroll_status_max_hours"><br><span class="red">{{ scope.row.proctor_payroll_status_max_hours }} hours</span></template>
                    </template>
                </el-table-column>
                <el-table-column prop="proctor_columbia_affiliation" label="Columbia Affiliation" sortable :sort-orders="['ascending', 'descending']" :min-width="10.0"></el-table-column>
                <el-table-column prop="proctoring_sessions" label="Undergraduate sessions" sortable :sort-orders="['ascending', 'descending']" :min-width="21.0">
                    <template slot-scope="scope">
                        <template v-for="(session, sessionIndex) in scope.row.undergraduate_proctoring_sessions">
                            <template v-if="scope.row.undergraduate_proctoring_sessions.indexOf(session) != 0"><br :key="sessionIndex + '-br1'"/><br :key="sessionIndex + '-br2'"/></template>
                            <a :key="sessionIndex" :href="'/proctoring-session/edit/' + session.id">{{ session.start_time | formatDateTimeMDYhma }}-{{ session.end_time | formatDateTimeMDYhma }} in {{ session.location }}</a>
                        </template>
                    </template>
                    <br/>
                    <br/>
                </el-table-column>
                <el-table-column prop="proctoring_sessions" label="Graduate sessions" sortable :sort-orders="['ascending', 'descending']" :min-width="20.5">
                    <template slot-scope="scope">
                        <template v-for="(session, sessionIndex) in scope.row.graduate_proctoring_sessions">
                            <template v-if="scope.row.graduate_proctoring_sessions.indexOf(session) != 0"><br :key="sessionIndex + '-br1'"/><br :key="sessionIndex + '-br2'"/></template>
                            <a :key="sessionIndex" :href="'/proctoring-session/edit/' + session.id">{{ session.start_time | formatDateTimeMDYhma }}-{{ session.end_time | formatDateTimeMDYhma }} in {{ session.location }}</a>
                        </template>
                    </template>
                    <br/>
                    <br/>
                </el-table-column>
                <el-table-column prop="proctoring_sessions" label="Other sessions" sortable :sort-orders="['ascending', 'descending']" :min-width="20.5">
                    <template slot-scope="scope">
                        <template v-for="(session, sessionIndex) in scope.row.other_proctoring_sessions">
                            <template v-if="scope.row.other_proctoring_sessions.indexOf(session) != 0"><br :key="sessionIndex + '-br1'"/><br :key="sessionIndex + '-br2'"/></template>
                            <a :key="sessionIndex" :href="'/proctoring-session/edit/' + session.id">{{ session.start_time | formatDateTimeMDYhma }}-{{ session.end_time | formatDateTimeMDYhma }} in {{ session.location }}</a>
                        </template>
                        <br/>
                        <br/>
                    </template>
                </el-table-column>
            </el-table>
            <p><a :href="'/proctor/edit'">Add new proctor</a></p>
            <p v-if="total">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.activeStatus.replace(/^\w/, c => c.toUpperCase()) + "Proctors"
            }
        },
        components: {
            },
        data () {
            return {
                activeStatus: 'active',
                proctors: [],
                filteredProctors: [],
                semesters: [],
                semesterId: null,
                total: null,
                filter: 'all'
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getProctors();
                    });
            },
            changeActiveStatus: function(status){
                this.activeStatus = status;
                this.getProctors();
            },
            getProctors: function() {
                axios.get('/api/proctor/proctors-for-semester/', {params: {semester_id: this.semesterId, active_status: this.activeStatus}})
                    .then(response => {
                        if(response.data.success) {
                            this.proctors = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleFilter: function(){
                this.filteredProctors = [];
                if(this.filter != "all"){
                    for(var i = 0; i < this.proctors.length; i++){
                        var proctor = this.proctors[i];

                        if(this.filter == "exam_proctors_only"){
                            if(proctor.proctor_title == 'Exam Proctor' && !proctor.proctor_is_trainee){
                                this.filteredProctors.push(proctor);
                            }
                        } else if(this.filter == "exam_proctors_and_trainees"){
                            if(proctor.proctor_title == 'Exam Proctor'){
                                this.filteredProctors.push(proctor);
                            }
                        } else if(this.filter == "exam_proctor_trainees"){
                            if(proctor.proctor_title == 'Exam Proctor' && proctor.proctor_is_trainee){
                                this.filteredProctors.push(proctor);
                            }
                        } else if(this.filter == "admin_proctors_only"){
                            if(proctor.proctor_title == 'Admin Proctor'){
                                this.filteredProctors.push(proctor);
                            }
                        } else if(this.filter == "special_assignment_only"){
                            if(proctor.proctor_title == 'Special Assignment'){
                                this.filteredProctors.push(proctor);
                            }
                        }
                    }
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
