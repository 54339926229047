<template>
    <div id="container">
<div id="content">
            <student-nav selected="files-attached-to-contact-notes" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <student-files :files="files"></student-files>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
     import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                files: []
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/files-attached-to-contact-notes/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                axios.get('/api/contact-note/contact-notes-with-files-for-student/', {params:{id: this.id}})
                    .then(response => {
                        this.files = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
