<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getStudents">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>E-Text Students by School</h1>
            <etext-service-nav></etext-service-nav>
            <br clear="all">
            <el-table :default-sort="{prop: 'school', order: 'ascending'}" :data="students">
                <el-table-column label="School" prop="school" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column label="Student name" prop="student_name" sortable :sort-orders="['ascending', 'descending']" width="155">
                    <template slot-scope="scope">
                        <a :href="'/student/services/' + scope.row.id">{{ scope.row.student_name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Preferred name" prop="student_preferred_name" sortable :sort-orders="['ascending', 'descending']" width="145"></el-table-column>
                <el-table-column label="Accommodations" prop="accommodations" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="accommodation in scope.row.accommodations">
                            {{ accommodation.name }}<template v-if="accommodation.description"> - {{ accommodation.description }}</template><template v-if="accommodation.comment"> - <span v-html="accommodation.comment" :key="accommodation.id"></span></template>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Courses" prop="service_courses" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="(course, index) in scope.row.service_courses">
                            <template v-if="index !== 0">, </template><a :href="'/etext-service/show/' + course.service_id" :key="course.id">{{ course.class_name }}</a>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <p v-if="total">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "E-Text Students by School"
            }
        },
        components: {
        },
        data () {
            return {
                students: [],
                semesters: [],
                semesterId: null,
                total: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getStudents();
                    });
            },
            getStudents: function() {
                axios.get('/api/etext-service/students-by-school/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.students = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
