<template>
    <div id="container">
<div id="content">
            <h1>Status Data By Date - {{ date | formatDateMDY }}</h1>
            Date:
            <el-select size="mini" v-model="date" style="width:125px">
                <el-option v-for="option in  dates" :key="option" :label="formatDate(option)" :value="option"></el-option>
            </el-select>
            <el-button size="mini" type="primary" @click="runReport">Run Report</el-button>
            <br clear="all">

            <div style="float: left; margin-right: 100px;" class="outer-block medium-block">
                <h2>Records by ODS Status</h2>
                <table><tbody>
                    <template v-for="record in content.records_by_ods_status">
                        <tr v-for="(item, index) in record.sub_items" :key="index">
                            <th v-if="index == 0" :rowspan="record.sub_items.length">{{ record.title }}</th>
                            <th>{{ item.title }}</th>
                            <td>{{ item.count }}</td>
                        </tr>
                    </template>
                </tbody></table>
            </div>
            <div style="float: left;">
                <div class="panel medium-block">
                    <h2>Students by Coordinator</h2>
                    <table><tbody>
                        <tr>
                            <th></th>
                            <th>Active</th>
                            <th>Coordinator Review</th>
                            <th>1 Doc Submitted</th>
                            <th>Pending Orientation</th>
                            <th>Registered Temporary</th>
                            <th>Registered Tenure</th>
                        </tr>
                        <tr v-for="(row, index) in content.students_by_coordinator" :key="index">
                            <th>{{ row.name }}</th>
                            <th>{{ row.active }}</th>
                            <th>{{ row.coordinator_review }}</th>
                            <th>{{ row.one_doc }}</th>
                            <th>{{ row.pending_orientation }}</th>
                            <th>{{ row.registered_temp }}</th>
                            <th>{{ row.registered_tenure }}</th>
                        </tr>
                    </tbody></table>
                </div>
                <div class="panel medium-block" style="margin-top: 20px">
                    <h2>Faculty by Coordinator</h2>
                    <table><tbody>
                        <tr v-for="(row, index) in content.faculty_by_coordinator" :key="index">
                            <th>{{ row.name }}</th>
                            <td>{{ row.count }}</td>
                        </tr>
                    </tbody></table>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: "Status Data by Date"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                date: null,
                dates: [],
                content: {}
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.date = this.urlParams.date;
                this.getData();
                axios.get('/api/report/report-options/')
                    .then(response => {
                        if(response.data.success){
                            this.dates = response.data.status_date_snapshot_dates;
                        }
                    });
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/status-data-per-date/', {params: {date: this.date}})
                    .then(response => {
                        if(response.data.success) {
                            this.content = response.data.content;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            formatDate: function(val){
                if(val){
                    return moment(val).format('MM/DD/YYYY')
                }
            },
            runReport: function(){
                history.pushState(null, null, '/report/status-data-by-date?date=' + this.date);
                this.getData();
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
