<template>
    <div id="container">
<div id="content">
            <h1>Future Tasks</h1>

            <h2>Future Tasks assigned to Me</h2>
            <el-table :default-sort="{prop: 'task_date', order: 'descending'}" class="table-small" v-if="assignedToMe.length" :data="assignedToMe">
                <el-table-column prop="task_date" label="Date of Task" sortable :sort-orders="['ascending', 'descending']" width="130"><template slot-scope="scope">{{ scope.row.task_date | formatDateMDY }}</template></el-table-column>
                <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column width="65"><template slot-scope="scope"><a :href="'/tasks/future-tasks/show/' + scope.row.id">View</a></template></el-table-column>
            </el-table>
            <p v-else>There are no future tasks assigned to you at this time</p>

            <h2>Past Future Tasks sent by me</h2>
            <el-table :default-sort="{prop: 'task_date', order: 'descending'}" class="table-large" v-if="sentByMe.length" :data="sentByMe">
                <el-table-column prop="task_date" label="Date of Task" sortable :sort-orders="['ascending', 'descending']" width="130"><template slot-scope="scope">{{ scope.row.task_date | formatDateMDY }}</template></el-table-column>
                <el-table-column label="Assigned To" width="200"><template slot-scope="scope">{{ constructRecipientsList(scope.row.recipients) }}</template></el-table-column>
                <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column width="65"><template slot-scope="scope"><a :href="'/tasks/future-tasks/show/' + scope.row.id">View</a></template></el-table-column>
            </el-table>
            <p v-else>There are no future tasks assigned to you at this time</p>

            <h2>All Future Tasks</h2>
            <el-table :default-sort="{prop: 'task_date', order: 'descending'}" class="table-large" v-if="allUnsent.length" :data="allUnsent">
                <el-table-column prop="task_date" label="Date of Task" sortable :sort-orders="['ascending', 'descending']" width="130"><template slot-scope="scope">{{ scope.row.task_date | formatDateMDY }}</template></el-table-column>
                <el-table-column label="Assigned To" width="200"><template slot-scope="scope">{{ constructRecipientsList(scope.row.recipients) }}</template></el-table-column>
                <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column width="65"><template slot-scope="scope"><a :href="'/tasks/future-tasks/show/' + scope.row.id">View</a></template></el-table-column>
            </el-table>
            <p v-else>There are no future tasks assigned to you at this time</p>

            <p><a :href="'/tasks/future-tasks/edit'">Enter a new task</a><br>(Use this link to assign tasks to others or to send a message)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Future Tasks"
            }
        },
        components: {
            },
        data () {
            return {
                assignedToMe: [],
                sentByMe: [],
                allUnsent: []
            }
        },
        methods: {
            load: function() {
                axios.get('/api/future-task/future-task-lists/')
                    .then(response => {
                        if(response.data.success) {
                            this.assignedToMe = response.data.assigned_to_me;
                            this.sentByMe = response.data.sent_by_me;
                            this.allUnsent = response.data.all_unsent;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            constructRecipientsList: function(recipientsObjs){
                if(recipientsObjs){
                    var recipientsStrs = [];
                    recipientsObjs.forEach(function(obj){
                        recipientsStrs.push(obj.name);
                    });

                    return recipientsStrs.join(", ");
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .table-small {
        width: 700px;
    }
    .table-large {
        width: 900px;
    }
</style>
