<template>
    <div id="container">
<div id="content">
            <div style="width:80em;">
                <h1>Note Taking Services for <i>{{ notetakingService.class_name }}</i> ({{ notetakingService.course_number_and_section }})<br>(<a :href="'/group-email/compose/?course_id=' + notetakingService.course_id + '&regarding_id=' + notetakingService.id + '&regarding_class=NoteTakingService'">{{ notetakingService.instructor_name }}</a>) in {{ notetakingService.semester_name }}</h1>

                <div style="clear:both;">
                    <h2>{{ notetakingService.course_number_and_section }}</h2>
                    <table><tbody>
                        <tr v-for="(enrollment, index) in notetakingService.enrollments" :key="index">
                            <th v-if="index === 0" :rowspan="notetakingService.enrollments.length">Students receiving note-taking services</th>
                            <th><a :href="'/student/services/' + enrollment.student_id">{{ enrollment.student_name }}</a></th>
                            <td><a :href="'/group-email/compose/?student_id=' + enrollment.student_id + '&regarding_id=' + notetakingService.id + '&regarding_class=NoteTakingService'">{{ enrollment.email }}</a></td>
                            <td>Added: {{ enrollment.created_at | formatDateMDY }}</td>
                            <td><template v-if="enrollment.cancellation_date">Cancelled: {{ enrollment.cancellation_date | formatDateMDY }}<br>Cancellation Reason: {{ enrollment.cancellation_reason }}</template></td>
                        </tr>
                        <tr>
                            <th>Note taker</th>
                            <th>{{ notetakingService.note_taker_first_name }} {{ notetakingService.note_taker_last_name }}</th>
                            <td><a :href="'/group-email/compose?note_taker_id=' + notetakingService.service_provider_id">{{ notetakingService.note_taker_email }}</a></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody></table>
                </div>

                <div class="panel">
                    <h2>Employment Process - current status is {{ notetakingService.status_display }}</h2>
                    <el-select size="mini" v-model="nextStatus" style="width:275px;" @change="onStatusChange">
                        <el-option :value="null" label="--Choose a next action to record--"></el-option>
                        <el-option v-for="(status, sIndex) in notetakingService.next_statuses" :key="sIndex" :label="status.display" :value="status.value"></el-option>
                        <el-option :value="undefined" label="----"></el-option>
                        <el-option value="undo" label="Undo previous action"></el-option>
                    </el-select>

                    <div class="sections">
                        <dl>
                            <template v-for="(change, index) in notetakingService.status_changes">
                                <dt :key="index+'dt'">{{ change.created_at | formatDateMDY }}</dt>
                                <dd :key="index+'dd'">Status set to {{ change.display }} by {{ change.changed_by }}</dd>
                            </template>
                        </dl>
                    </div>

                    <h4 style="float: right; display: inline;"><a :href="'/group-email/compose/?course_id=' + notetakingService.course_id + '&regarding_id=' + notetakingService.id + '&regarding_class=NoteTakingService'">Email special request (editable text) to instructor</a></h4><br><br>

                    <div>
                        <br>
                        <el-checkbox v-model="notetakingService.opt_out_group_emails"><b>Opt Service out of bulk emails?</b></el-checkbox>

                        <template v-if="notetakingService.status != 'hired' && notetakingService.status != 'emailed_prof_second' && showAutomaticReminderLink">
                            <h4 style="float: right; display: inline;"><a @click.prevent="sendAutomaticRequest">Send automatic request/reminder about note taking services to instructor</a></h4><br><br>
                            <p style="float: right; font-style: italic">
                                This link will send a scripted email to the professor<br>
                                that includes variables about the class based on<br>
                                the current status of this note taking service.<br>
                                <br><br>
                                Requested - standard email #1<br>
                                Emailed prof first time - standard email #2<br>
                                Emailed Class - standard email #2<br>
                                Emailed prof second time - link not available<br>
                                Hired - link not available<br>
                            </p>
                        </template>

                        <div style="clear:both;"></div>
                    </div>
                </div>

                <div class="panel">
                    <h2>Comments</h2>
                    <el-input type="textarea" :rows="4" v-model="notetakingService.comments" style="width:500px;"></el-input>
                </div>

                <div class="panel">
                    <h2>Note Taker</h2>
                    <template v-if="notetakingService.service_provider_id">
                        <table class="form" style="float: left; margin-right: 4em;"><tbody>
                            <tr>
                                <td>First:</td>
                                <td>{{ notetakingService.note_taker_first_name }}</td>
                                <td>Last:</td>
                                <td>{{ notetakingService.note_taker_last_name }}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{{ notetakingService.note_taker_email }}</td>
                                <td>Cell Phone:</td>
                                <td>{{ notetakingService.note_taker_phone }}</td>
                            </tr>
                        </tbody></table>
                        <p><a :href="'/service-provider/show/' + notetakingService.service_provider_id">Edit Note Taker by Following this Link</a></p>
                        <br clear="both">
                    </template>
                    <template v-else>
                        <p><b>Please select note-taker from the drop down list below. If not found, click 'Create a new service provider' and fill out the fields.</b></p>
                        <el-select size="mini" v-model="newNoteTakerId">
                            <el-option :value="null" label="-Select a provider"></el-option>
                            <el-option v-for="noteTaker in noteTakers" :key="noteTaker.id" :value="noteTaker.id" :label="noteTaker.name"></el-option>
                        </el-select> or
                        <a @click="showCreateNoteTakerFields = true">Create a new service provider</a><br clear="both">
                        <el-form v-if="showCreateNoteTakerFields" :inline="true" :rules="rules" :model="newNoteTaker" ref="newNoteTakerForm">
                            <table class="form"><tbody>
                                <tr>
                                    <td>First*:</td>
                                    <td><el-form-item prop="first_name"><el-input size="mini" v-model="newNoteTaker.first_name" style="width:130px;"></el-input></el-form-item></td>
                                    <td>Last*:</td>
                                    <td><el-form-item prop="last_name"><el-input size="mini" v-model="newNoteTaker.last_name" style="width:130px;"></el-input></el-form-item></td>
                                </tr>
                                <tr>
                                    <td>Email*:</td>
                                    <td><el-form-item prop="email"><el-input size="mini" v-model="newNoteTaker.email" style="width:130px;"></el-input></el-form-item></td>
                                    <td>Cell Phone:</td>
                                    <td><el-form-item><el-input size="mini" v-model="newNoteTaker.phone" style="width:130px;"></el-input></el-form-item></td>
                                </tr>
                                <tr>
                                    <td>UNI:</td>
                                    <td colspan="3"><el-form-item><el-input size="mini" v-model="newNoteTaker.uni" style="width:130px;"></el-input></el-form-item></td>
                                </tr>
                                <tr><td colspan="4">Contact Information:</td></tr>
                                <tr><td colspan="4"><el-form-item><el-input type="textarea" :rows="4" v-model="newNoteTaker.contact_information" style="width:380px;"></el-input></el-form-item></td></tr>
                            </tbody></table>
                        </el-form>

                        <p>Note format:</p>
                        <el-select size="mini" v-model="noteFormat">
                            <el-option label="TBD" value="TBD"></el-option>
                            <el-option label="Hand-written" value="Hand-written"></el-option>
                            <el-option label="Electronic" value="Electronic"></el-option>
                        </el-select>
                    </template>
                </div>

                <div v-if="notetakingService.service_provider_id" class="panel">
                    <h2>ODS Checklist for {{ notetakingService.note_taker_first_name }} {{ notetakingService.note_taker_last_name }}</h2>
                    <template v-if="editHiringSection">
                        <div style="width: 20em; float: left;">
                            <h3>Specialist</h3>
                            <el-checkbox-group v-model="notetakingService.specialist_flags">
                                <template v-for="(flag, fIndex) in specialistFlags">
                                    <el-checkbox :key="fIndex" :label="flag"></el-checkbox><br :key="flag">
                                </template>
                            </el-checkbox-group>
                        </div>
                        <div style="width: 20em; float: left;">
                            <h3>Payroll status</h3>
                            <el-select size="mini" v-model="notetakingService.payroll_flag" @change="onPayrollFlagChange">
                                <el-option :value="null"></el-option>
                                <el-option v-for="flag in payrollStatusFlags" :key="flag" :value="flag" :label="flag"></el-option>
                            </el-select>

                            <h3 v-if="showPayrollFlagDate">Date</h3>
                            <el-date-picker size="mini" v-model="notetakingService.info_sent_to_business_office_date" value-format="yyyy-MM-dd" v-if="showPayrollFlagDate"></el-date-picker>
                        </div>
                    </template>
                    <template v-else>
                        <a v-if="!editHiringSection" @click="editHiringSection = true">Edit this section</a>
                        <div style="width: 20em; float: left;">
                            <h3>Specialist</h3>
                            <template v-for="(flag, fIndex) in specialistFlags">
                                <div :key="fIndex"><template v-if="notetakingService.specialist_flags && notetakingService.specialist_flags.indexOf(flag) !== -1">&#9746;</template><template v-else>&#9744;</template> {{ flag }}</div>
                            </template>
                        </div>
                        <div style="width: 20em; float: left;">
                            <h3>Payroll status</h3>
                            {{ notetakingService.payroll_flag }}

                            <div v-if="showPayrollFlagDate">
                                <h3>Date:</h3>
                                {{ notetakingService.info_sent_to_business_office_date | formatDateMDY }}
                            </div>
                        </div>
                    </template>
                    <br clear="left">
                </div>

                <el-button size="mini" type="primary" @click="onSubmit">Save Changes</el-button>

                <div class="panel">
                    <student-emails :emails="emails" :title="'Group Emails Regarding this service'" :showCCField="true"></student-emails>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Note Taking Services for " + this.notetakingService.class_name + " in " + this.notetakingService.semester_name
            }
        },
        components: {
        },
        data () {
            return {
                notetakingService: {},
                nextStatus: null,
                showAutomaticReminderLink: true,
                showCreateNoteTakerFields: false,
                editHiringSection: false,
                specialistFlags: [],
                payrollStatusFlags: [],
                noteTakers: [],
                newNoteTaker: {},
                newNoteTakerId: null,
                noteFormat: "TBD",
                payrollStatusDate: null,
                showPayrollFlagDate: false,
                emails: [],
                rules: {
                    first_name: [{ required: true, message: 'First Name is required', trigger: 'blur' }],
                    last_name: [{ required: true, message: 'Last Name is required', trigger: 'blur' }],
                    email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
                }
            }
        },
        methods: {
            onPayrollFlagChange: function(){
              if(this.notetakingService.payroll_flag == 'Info sent to Business office'){
                  this.showPayrollFlagDate = true;
              } else {
                  this.showPayrollFlagDate = false;
              }
            },

            load: function() {
                this.notetakingService.id = location.pathname.split('/note-taking-service/show/')[1];
                if(this.notetakingService.id){
                    this.notetakingService.id = parseInt(this.notetakingService.id);
                    this.refreshData();
                    this.getFormItems();

                }
            },
            getNotetakingService: function() {
                axios.get('/api/notetaking-service/', {params: {id: this.notetakingService.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.notetakingService = response.data.notetaking_service;
                            this.noteFormat = this.notetakingService.note_format;
                            if(this.notetakingService.opt_out_group_emails) this.showAutomaticReminderLink = false;

                            this.getNoteTakers();
                            this.onPayrollFlagChange();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getFormItems: function(){
                axios.get('/api/notetaking-service/form-items/')
                    .then(response => {
                        if(response.data.success) {
                            this.specialistFlags = response.data.specialist_flags;
                            this.payrollStatusFlags = response.data.payroll_status_flags;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getNoteTakers: function(){
                axios.get('/api/notetaking-service/note-takers/', {params:{semester_id: this.notetakingService.semester_id}})
                    .then(response => {
                        if(response.data.success) {
                            this.noteTakers = response.data.rows;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            getEmails: function(){
                axios.get("/api/group-email/group-emails-regarding-generic/", {params: {id: this.notetakingService.id, type: "NoteTakingService"}})
                    .then(response => {
                        this.emails = response.data.rows;
                    });
            },
            refreshData: function(){
                this.nextStatus = null;
                this.showAutomaticReminderLink = true;
                this.showCreateNoteTakerFields = false;
                this.editHiringSection = false;
                this.newNoteTaker = {};
                this.newNoteTakerId = null;
                this.noteFormat = "TBD";
                
                this.getNotetakingService();
                this.getEmails();
            },
            onStatusChange: function(status){
                if(status){
                    axios.put('/api/notetaking-service/update-status/', {id: this.notetakingService.id, status: status})
                        .then(response => {
                          if(response.data.success){
                            this.refreshData();
                          } else {
                            this.$message({
                              showClose: true,
                              duration: 6000,
                              type: 'error',
                              message: response.data.message,
                              customClass: 'error-msg'
                            });
                          }
                        });
                }
            },
            onSubmit: function() {
                if(!this.notetakingService.service_provider_id && !this.newNoteTakerId && this.showCreateNoteTakerFields){
                    this.$message.closeAll();
                    this.$refs["newNoteTakerForm"].validate((valid) => {
                        if(valid){
                            this.notetakingService.note_taker_id = this.newNoteTakerId;
                            this.notetakingService.new_note_taker = this.newNoteTaker;
                            this.notetakingService.note_format = this.noteFormat;

                            axios.put('/api/notetaking-service/', this.notetakingService)
                                .then(response => {
                                    if(response.data.success){
                                        this.refreshData();
                                    }
                                });
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: "This form could not be saved due to one or more missing note taker fields. Please correct all errors and submit again."
                            });
                        }
                    });
                } else {
                    this.notetakingService.note_taker_id = this.newNoteTakerId;
                    this.notetakingService.note_format = this.noteFormat;
                    axios.put('/api/notetaking-service/', this.notetakingService)
                        .then(response => {
                            if(response.data.success){
                                this.refreshData();
                            }
                        });
                }
            },
             sendAutomaticRequest: function() {

                var identifier = this.notetakingService.status == null || this.notetakingService.status == "requested" ? "notetaking_initial_request" : "notetaking_subsequent_request";

                this.$confirm('Are you sure you wish to send an email regarding note taking services?')
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                                identifier: identifier,
                                recipients: [{
                                    recipient_id: this.notetakingService.course_id,
                                    recipient_class: "Course",
                                    regarding_id: this.notetakingService.id,
                                    regarding_class: "NoteTakingService"
                                }]
                            })
                            .then(response => {
                                this.refreshData();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                              console.log(error);
                            });
                    }).catch(() => {
                });
             }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-table th {
        vertical-align: top;
    }
    .panel {
        border: 1px solid #999;
        padding: 0.5em;
        margin: 1em 0;
        background: white;
        clear: right;
    }
</style>
