<template>
    <div id="container">
<div id="content">
            <h1>Current Accommodations</h1>
            <template v-for="accommodation in data">
                <template v-if="accommodation.students && accommodation.students.length">
                    <h2 :key="accommodation.id">{{ accommodation.accommodation_category_name }}: {{ accommodation.accommodation_type_name }} - {{ accommodation.students ? accommodation.students.length : 0 }} Total</h2>
                    <el-table :key="accommodation.id" :default-sort="{prop: 'first_name', order: 'ascending'}" :data="accommodation.students" style="width: 100%" class="table-top-align">
                        <el-table-column prop="last_name" label="Last Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="first_name" label="First Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="preferred_first_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="enrollment" label="Enrollment" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="expected_graduation" label="Grad Date" sortable :sort-orders="['ascending', 'descending']">
                            <template slot-scope="scope">{{ scope.row.expected_graduation | formatDateMDY }}</template>
                        </el-table-column>
                        <el-table-column prop="disabilities" label="Disability" sortable :sort-orders="['ascending', 'descending']">
                            <template slot-scope="scope">
                                <template v-for="(disability, index) in scope.row.disabilities"><template v-if="index !== 0"><br :key="index"></template>{{ disability }}</template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accommodation_status" label="Acc Status" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column v-if="accommodation.requires_description" prop="accommodation_details" label="Acc Details" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                        <el-table-column prop="comments" label="Comments" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                    </el-table>
                </template>
            </template>
            <a :href="'/api/report/students-by-accommodation.xlsx?option=' + this.option" target="_blank">Download as CSV</a>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Current Accommodations"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                option: null,
                secondaryOrder: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.option = decodeURIComponent(this.urlParams.option);
                this.secondaryOrder = this.urlParams.secondaryOrder;
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/students-by-accommodation/', {params: {option: this.option}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
