<template>
    <div id="container">
<div id="content">
            <student-nav selected="services" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="currentSemesterId" @change="onCurrentSemesterChange">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <student-services-courses v-on:refreshdata="getData" :id="id" :courses="courses" :semester="currentSemesterName"
                                      :hasNoteTakingService="hasNoteTakingService" :hasCommunicationService="hasCommunicationService"
                                      :hasEtextService="hasEtextService" :canUncancel="canUncancel" ></student-services-courses>
            <a :href="'/student/add-course/' + id" v-if="!hideAddCourse">Add a course not listed here</a>
            <student-note-taking-services :id="id" :noteTakingServices="noteTakingServices" :semester="currentSemesterName"></student-note-taking-services>
            <p v-if="!showCourseRemovalForm"><a @click="showCourseRemovalForm = !showCourseRemovalForm">Show course removal form</a></p>
            <student-course-removal-form v-if="showCourseRemovalForm" :id="id" :courses="courses"></student-course-removal-form>
            <a @click="showHistoryAudit = !showHistoryAudit">{{ showHistoryAudit ? "Hide" : "Show" }} detailed change history</a>
            <student-history-audit v-if="showHistoryAudit" :history="historyAudit" :isStudentHistory="true"></student-history-audit>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                semesters: [],
                currentSemesterId: null,
                currentSemesterName: null,
                courses: [],
                hasNoteTakingService: false,
                hasCommunicationService: false,
                hasEtextService: false,
                noteTakingServices: [],
                showCourseRemovalForm: false,
                showHistoryAudit: false,
                historyAudit: [],
                hideAddCourse: false,
                canUncancel: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/services/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                this.getData();
            },
            getData: function () {
                axios.get("/api/student/semesters/", {params:{id: this.id}})
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.currentSemesterId = this.semesters[i].id;
                                this.currentSemesterName = this.semesters[i].name;
                            }
                        }

                        if(!this.currentSemesterId){
                            axios.get("/api/simple/get-current-semester/")
                                .then(innerResponse => {
                                    this.semesters.push(innerResponse.data.semester);
                                    this.currentSemesterId = innerResponse.data.semester.id;
                                    this.currentSemesterName = innerResponse.data.semester.name;
                                });
                        }

                        axios.get("/api/service/courses-and-services-for-student/", {params:{student_id: this.id, semester_id: this.currentSemesterId}})
                            .then(response => {
                                this.canUncancel = response.data.can_uncancel;
                                this.courses = response.data.rows;

                                if(this.courses.length){
                                    if(this.courses[0].hasOwnProperty('note_taking_status')){
                                        this.hasNoteTakingService = true;
                                    }
                                    // if(this.courses[0].hasOwnProperty('communication_status')){
                                    //     this.hasCommunicationService = true;
                                    // }
                                    if(this.courses[0].hasOwnProperty('etext_status')){
                                        this.hasEtextService = true;
                                    }
                                }

                                for(var i = 0; i < this.courses.length; i++){
                                    this.$set(this.courses[i], 'show_cancel_fields', false);
                                    if (this.courses[i].times.length > 1) {
                                        var arr1 = [];
                                        var arr2 = [];
                                        for(var j = 0; j < this.courses[i].days.length; j++){
                                            if(j % 2 == 0){
                                                arr1.push(this.courses[i].days[j]);
                                            } else {
                                                arr2.push(this.courses[i].days[j]);
                                            }
                                        }
                                        this.courses[i].days = [arr1.join(" "), arr2.join(" ")];
                                    }
                                }

                                this.courses.push({showAll: true, times: [], days: []});
                            });

                        axios.get("/api/service/current-notetaking-services-for-student/", {params:{student_id: this.id, semester_id: this.currentSemesterId}})
                            .then(response => {
                                this.noteTakingServices = response.data.rows;

                                this.noteTakingServices.forEach(function(service){
                                    if(service.date_added){
                                        service.date_added = moment(service.date_added).format("M/D/YYYY");
                                    }
                                    if(service.cancellation_date){
                                        service.cancellation_date = moment(service.cancellation_date).format("M/D/YYYY");
                                    }
                                    if(!service.student_preferred_format){
                                        service.student_preferred_format = "Typed Notes";
                                    }
                                });
                            });
                    });

                axios.get("/api/service/audit-history-for-student/", {params:{student_id: this.id}})
                    .then(response => {
                        this.historyAudit = response.data.rows;

                        this.historyAudit.forEach(function(row){
                            //if(row.timestamp){
                            //    row.timestamp = moment(row.timestamp).format("M/D/YYYY h:mma");
                            //}

                            if(row.changes.created_at){
                                row.changes.created_at = moment(row.changes.created_at).format("M/D/YYYY h:mma");
                            }

                            if(row.changes.updated_at){
                                row.changes.updated_at = moment(row.changes.updated_at).format("M/D/YYYY h:mma");
                            }
                        });
                    });
            },
            onCurrentSemesterChange: function(){
                for(var i = 0; i < this.semesters.length; i++){
                    if(this.semesters[i].id === this.currentSemesterId){
                        this.currentSemesterName = this.semesters[i].name;
                    }
                }

                axios.get("/api/service/courses-and-services-for-student/", {params:{student_id: this.id, semester_id: this.currentSemesterId}})
                    .then(response => {
                        this.courses = response.data.rows;

                        if(this.courses.length){
                            if(this.courses[0].hasOwnProperty('note_taking_status')){
                                this.hasNoteTakingService = true;
                            }
                            // if(this.courses[0].hasOwnProperty('communication_status')){
                            //     this.hasCommunicationService = true;
                            // }
                            if(this.courses[0].hasOwnProperty('etext_status')){
                                this.hasEtextService = true;
                            }
                        }

                        for(var i = 0; i < this.courses.length; i++){
                            this.$set(this.courses[i], 'show_cancel_fields', false);
                            if (this.courses[i].times.length > 1) {
                                var arr1 = [];
                                var arr2 = [];
                                for(var j = 0; j < this.courses[i].days.length; j++){
                                    if(j % 2 == 0){
                                        arr1.push(this.courses[i].days[j]);
                                    } else {
                                        arr2.push(this.courses[i].days[j]);
                                    }
                                }
                                this.courses[i].days = [arr1.join(" "), arr2.join(" ")];
                            }
                        }

                        this.courses.push({showAll: true, times: [], days: []});
                    });

                axios.get("/api/service/current-notetaking-services-for-student/", {params:{student_id: this.id, semester_id: this.currentSemesterId}})
                    .then(response => {
                        this.noteTakingServices = response.data.rows;

                        this.noteTakingServices.forEach(function(service){
                            if(service.date_added){
                                service.date_added = moment(service.date_added).format("M/D/YYYY");
                            }
                            if(service.cancellation_date){
                                service.cancellation_date = moment(service.cancellation_date).format("M/D/YYYY");
                            }
                        });
                    });
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .empty-text {
        font-style:italic
    }
</style>
