<template>
    <div id="container">
      <div id="content">
        <student-nav v-if="studentId !== 0" selected="contact-notes" :id="studentId" :name="studentName" :uni="uni" :status="studentStatusDisplay"></student-nav>
        <br clear="all">
        <h2>Contact Note: {{ subject }}</h2>
        <template v-if="canViewNote">
            <table class="contact-note-display">
                <tbody><tr>
                    <th>Student Name</th>
                    <td><a :href="'/student/profile/' + this.studentId">{{ studentName }}</a></td>
                </tr>
                <tr>
                    <th>Date</th>
                    <td>{{ date | formatDateMDY }}</td>
                </tr>
                <tr>
                    <th>Type of Contact</th>
                    <td v-html="contactTypesHtml"></td>
                </tr>
                <tr>
                    <th>Reason for Contact</th>
                    <td v-html="reasonsHtml"></td>
                </tr>
                <tr>
                    <th>Accommodations</th>
                    <td v-html="accommodationTypesHtml"></td>
                </tr>
                <tr>
                    <th>Subject/Headline</th>
                    <td>{{ subject }}</td>
                </tr>
                <tr>
                    <th>ODS Staff</th>
                    <td>{{ authorName }}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>{{ status }}</td>
                </tr>
                <tr>
                    <th>Note</th>
                    <td><span class="formatted-text">{{ text }}</span></td>
                </tr>
                <tr>
                    <th>File Attachment(s)</th>
                    <td><span class="text-italic"><template v-if="asset1_file_name"><a :href="'/api/contact-note/download-file?id=' +id + '&file_column=asset1_file_name'" target="_blank">{{ asset1_file_name }}</a></template>
                        <template v-if="asset2_file_name">, <a :href="'/api/contact-note/download-file?id=' +id + '&file_column=asset2_file_name'" target="_blank">{{ asset2_file_name }}</a></template>
                        <template v-if="asset3_file_name">, <a :href="'/api/contact-note/download-file?id=' +id + '&file_column=asset3_file_name'" target="_blank">{{ asset3_file_name }}</a></template>
                        <template v-if="asset4_file_name">, <a :href="'/api/contact-note/download-file?id=' +id + '&file_column=asset4_file_name'" target="_blank">{{ asset4_file_name }}</a></template>
                        <template v-if="asset5_file_name">, <a :href="'/api/contact-note/download-file?id=' +id + '&file_column=asset5_file_name'" target="_blank">{{ asset5_file_name }}</a></template></span>
                    </td>
                </tr>
            </tbody></table>
            <p>Last updated {{ lastUpdatedAt | formatDateMDY }} by {{ lastUpdatedBy }}</p>
            <template v-if="showDestroyButton"><el-button size="mini" type="primary" @click="deleteNote">Delete</el-button></template>
            <template v-if="showApproveButton"><el-button size="mini" type="primary" @click="updateStatus('approved')">Approve</el-button></template>
            <template v-if="showRequiresEditsButton">
                <el-button size="mini" type="primary" @click="onRequiresEdits">Requires Edits</el-button>
            </template>
            <template v-if="showDiscardDraftButton"><el-button size="mini" type="primary" @click="deleteNote">Discard Draft</el-button></template>
            <template v-if="showSubmitForReviewButton"><el-button size="mini" type="primary" @click="updateStatus('submitted')">Submit for Review</el-button></template>
            <template v-if="showEditLink"><a :href="'/contact-notes/edit/' + this.id">Edit contact note</a></template>

            <h2>Supervisor Comments</h2>
            <template v-if="showSupervisorCommentEdit">
                <el-input type="textarea" :rows="15" v-model="supervisorComment" style="width:750px;"></el-input><br>
                <el-button size="mini" type="primary" @click="saveSupervisorComment" v-if="!requiresEdits">Save comments</el-button>
                <el-button size="mini" type="primary" @click="saveSupervisorCommentAndSetEditsRequired" v-if="requiresEdits">Save comments and set status to Edits Required</el-button> or
                <a @click="showSupervisorCommentEdit = false">Cancel</a>
            </template>
            <template v-else>
                <template v-if="supervisorComment">
                    <p><span class="formatted-text">{{ supervisorComment }}</span></p>
                    <p>-{{ supervisorName }}, {{ supervisorCommentDate | formatDateMDY }}</p>
                </template>
                <template v-else><p>No comments yet.</p></template>
                <a @click="showSupervisorCommentEdit = true">Edit comments</a>
            </template>

            <template v-if="relatedContactNotes && relatedContactNotes.length">
                <h2>Related contact notes</h2>
                <el-table :data="relatedContactNotes">
                    <el-table-column prop="student_name" label="Student Name" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
                    <el-table-column prop="subject" label="Subject/Heading" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column>
                    <el-table-column prop="author_name" label="ODS Staff" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                    <el-table-column prop="status_display" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                    <el-table-column prop="last_updated_at" label="Last Updated" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}</template></el-table-column>
                    <el-table-column width="64"><template slot-scope="scope">
                      <router-link :to="'/contact-notes/show/'+scope.row.id">show</router-link>
                    </template></el-table-column>
                    <el-table-column width="100"><template slot-scope="scope">
                      <router-link :to="'/contact-notes/show-group/'+scope.row.group_id"  v-if="scope.row.group_id != null">show group</router-link>
                    </template></el-table-column>
                </el-table>
            </template>

            <h2>Other options</h2>
            <ul><li><a :href="'/tasks/edit?message=true&student_id=' + this.studentId">Send a message regarding this student</a></li></ul>
            <div class="fyi-section">
                <template v-if="showFyiSection">
                    <div><a class="fyi-link" @click="showFyiSection = false">[-]</a><h3>FYI regarding this note</h3></div>
                    <el-form label-width="70px" label-position="right">
                        <el-form-item label="Recipients">
                            <el-autocomplete style="width:100%;" :fetch-suggestions="handleFyiSearch" :trigger-on-focus="false" size="mini" @select="handleFyiSelect" v-model="recipients"></el-autocomplete>
                            <span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                            <template v-for="user in fyiUsers">
                                <el-checkbox :key="user.id" :label="user.value" @change="removeUserFromFyiUsers(user)" checked>{{user.value}}</el-checkbox><br :key="user.id">
                            </template>
                        </el-form-item>
                        <el-form-item label="Subject">
                            <el-input size="mini" v-model="fyiSubject"></el-input>
                        </el-form-item>
                        <el-form-item label="Text">
                            <el-input type="textarea" :rows="5" v-model="fyiMessage"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button size="mini" type="primary" @click="sendFyi">Send FYI</el-button>
                </template>
                <template v-else>
                    <div><a class="fyi-link" @click="showFyiSection = true">[+]</a><h4>Send FYI message regarding this note</h4></div>
                </template>
            </div>

            <utils-history :id="id" type="ContactNote" :show-title="true" :key="componentKey"></utils-history>
        </template>
          <template v-else>
              <p style="color:red">The contents of this contact note are restricted</p>

          </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import showdown from 'showdown'
    const converter = new showdown.Converter()
    converter.setOption('simpleLineBreaks',true)

    export default {
        metaInfo () {
            return {
                title: this.subject
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                studentId: 0,
                studentName: '',
                studentStatusDisplay: '',
                uni: '',
                status: '',
                subject: '',
                date: '',
                contactTypes: [],
                reasons: [],
                accommodationTypes: [],
                contactTypesHtml: "",
                reasonsHtml:"",
                accommodationTypesHtml: "",
                authorName: '',
                text: '',
                asset1_file_name: null,
                asset2_file_name: null,
                asset3_file_name: null,
                asset4_file_name: null,
                asset5_file_name: null,
                lastUpdatedAt: '',
                lastUpdatedBy: '',
                supervisorComment: null,
                supervisorName: null,
                supervisorCommentDate: null,
                recipients: null,
                fyiUsers: [],
                fyiSubject: null,
                fyiMessage: null,
                relatedContactNotes: [],
                showSupervisorCommentEdit: false,
                showFyiSection: false,
                showDestroyButton: false,
                showApproveButton: false,
                showRequiresEditsButton: false,
                showDiscardDraftButton: false,
                showSubmitForReviewButton: false,
                showEditLink: false,
                canViewNote: false,
                requiresEdits: false,
                componentKey: 0
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/contact-notes/show/')[1];
                this.getContactNote();
            },
            getContactNote: function() {
                axios.get("/api/contact-note/", {params: {id: parseInt(this.id)}})
                    .then(response => {
                        this.studentId = response.data.contact_note.student_id;
                        this.studentName = response.data.contact_note.student_name;
                        this.studentStatusDisplay = response.data.contact_note.student_status_display;
                        this.uni = response.data.contact_note.uni;
                        this.status = response.data.contact_note.status_display;
                        this.subject = response.data.contact_note.subject;
                        this.date = response.data.contact_note.date;
                        this.contactTypes = response.data.contact_note.contact_types;
                        this.reasons = response.data.contact_note.reasons;
                        this.accommodationTypes = response.data.contact_note.accommodation_types;

                        var contactTypes = [];
                        if(this.contactTypes){
                            this.contactTypes.forEach(function(x){
                               if(x == "ODS Contact with Third Party"){
                                    contactTypes.push(x + ": "+response.data.contact_note.third_party);
                               } else if(x == "Other"){
                                   contactTypes.push(x + ": "+response.data.contact_note.other_contact_type);
                               } else {
                                   contactTypes.push(x);
                               }
                            });
                        }
                        this.contactTypesHtml = converter.makeHtml(contactTypes.join("\n"));


                        var reasons = [];
                        if(this.reasons){
                            this.reasons.forEach(function(x){
                                if(x == "Referral"){
                                    reasons.push(x + ": "+response.data.contact_note.referral);
                                } else if(x == "Check-in"){
                                    reasons.push(x + ": "+response.data.contact_note.check_in);
                                } else if(x == "Other"){
                                    reasons.push(x + ": "+response.data.contact_note.other_reason);
                                } else {
                                    reasons.push(x);
                                }
                            });
                        }
                        this.reasonsHtml = converter.makeHtml(reasons.join("\n"));

                        var accommodationTypes = [];
                        if(this.accommodationTypes){
                            this.accommodationTypes.forEach(function(x){
                                if(x == "Other"){
                                    accommodationTypes.push(x + ": "+response.data.contact_note.other_accommodation_type);
                                } else {
                                    accommodationTypes.push(x);
                                }
                            });
                        }
                        this.accommodationTypesHtml = converter.makeHtml(accommodationTypes.join("\n"));


                        this.authorName = response.data.contact_note.author_name;
                        this.text = response.data.contact_note.text;
                        this.asset1_file_name = response.data.contact_note.asset1_file_name;
                        this.asset2_file_name = response.data.contact_note.asset2_file_name;
                        this.asset3_file_name = response.data.contact_note.asset3_file_name;
                        this.asset4_file_name = response.data.contact_note.asset4_file_name;
                        this.asset5_file_name = response.data.contact_note.asset5_file_name;
                        this.lastUpdatedAt = response.data.contact_note.last_updated_at;
                        this.lastUpdatedBy = response.data.contact_note.latest_change_by_identifier;
                        this.showDestroyButton = response.data.show_destroy_button;
                        this.showApproveButton = response.data.show_approve_button;
                        this.showRequiresEditsButton = response.data.show_requires_edits_button;
                        this.showDiscardDraftButton = response.data.show_discard_draft_button;
                        this.showSubmitForReviewButton = response.data.show_submit_for_review_button;
                        this.showEditLink = response.data.show_edit_link;
                        this.canViewNote = response.data.can_view_note;

                        this.fyiSubject = "FYI regarding contact note for "+this.studentName;

                        if(response.data.contact_note.supervisor_comments && response.data.contact_note.supervisor_comments.length){
                            this.supervisorComment = response.data.contact_note.supervisor_comments[0].text;
                            this.supervisorName = response.data.contact_note.supervisor_comments[0].supervisor_name;
                            this.supervisorCommentDate = response.data.contact_note.supervisor_comments[0].updated_at;
                        }

                        if(response.data.contact_note.group_id){
                            axios.get("/api/contact-note/related-contact-notes/", {params: {id: parseInt(this.id), group_id: response.data.contact_note.group_id}})
                                .then(response => {
                                    this.relatedContactNotes = response.data.rows;
                                });
                        }

                        this.componentKey +=1;

                    });
            },
            downloadNote: function(fileColumn){
                axios.get("/api/contact-note/download-file/", {params: {id: parseInt(this.id), file_column: fileColumn}})
                    .then(response => {
                        //TODO: do we need to handle this?
                    });
            },
            deleteNote: function(){
                this.$confirm('Are you sure you want to delete this contact note?')
                    .then(() => {
                        axios.delete("/api/contact-note/", {params: {id: parseInt(this.id)}})
                            .then(response => {
                              this.$router.push({ path: '/student/notes/' + this.studentId });
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            updateStatus: function(status){
                axios.post('/api/contact-note/update-status/', {id: parseInt(this.id), status: status})
                    .then(response => {
                        this.getContactNote();
                    });
            },
            onRequiresEdits: function (){
              this.requiresEdits = true;
              this.showSupervisorCommentEdit = true;
            },
            saveSupervisorComment: function(){
                axios.post('/api/contact-note/supervisor-comment/', {id: parseInt(this.id), text: this.supervisorComment})
                    .then(response => {
                        if(response.data.success){
                            this.showSupervisorCommentEdit = false;
                            this.getContactNote();
                        }
                    });
            },
            saveSupervisorCommentAndSetEditsRequired: function(){

                axios.post('/api/contact-note/update-status/', {id: parseInt(this.id), status: "edits_required"})
                    .then(response => {
                        axios.post('/api/contact-note/supervisor-comment/', {
                            id: parseInt(this.id),
                            text: this.supervisorComment
                        })
                            .then(nextResponse => {
                                if (nextResponse.data.success) {
                                    this.showSupervisorCommentEdit = false;
                                    this.getContactNote();
                                }
                            });
                    });
            },
            handleFyiSearch: function(queryString, cb) {
                axios.get('/api/contact-note/search-active-users/?query=' + queryString)
                    .then(response => {
                        cb(response.data.rows);
                    });
            },
            handleFyiSelect: function(user){
                this.$message.closeAll();
                var existingUser = this.fyiUsers.find(function(existingUserObj){
                    return existingUserObj.id == user.id;
                });

                if(!existingUser){
                    this.fyiUsers.push(user);
                } else {
                    this.$message({
                        showClose: true,
                        duration: 0,
                        type: 'error',
                        message: "This user already exists in the list."
                    });
                }
                this.recipients = null;
            },
            sendFyi: function() {
                this.$message.closeAll();

                var fyiUserIds = [];
                this.fyiUsers.forEach(function(user){
                    fyiUserIds.push(user.id);
                });

                axios.post('/api/contact-note/send-fyi/', {id: parseInt(this.id), fyi_user_ids: fyiUserIds, fyi_subject: this.fyiSubject, fyi_message: this.fyiMessage})
                    .then(response => {
                        if(response.data.success){
                            this.showFyiSection = false;
                            this.fyiUsers = [];
                            this.fyiSubject = null;
                            this.fyiMessage = null;
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'success',
                                message: 'FYI message was sent to ' + response.data.recipients + ' recipient(s).'
                            });
                        }
                    })
            },
            removeUserFromFyiUsers: function(user){
                this.fyiUsers.splice(this.fyiUsers.indexOf(user), 1);
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style>

    table.contact-note-display  p {
        margin: 0px !important;
    }
</style>
<style scoped>
    .fyi-section {
        margin-left: 1em;
        padding: 0.25em;
        background-color:#EEF;
        margin-bottom: 1em;
    }
    .fyi-link {
        float: left;
        margin-right: 2em;
    }
    .el-form {
        width: 750px;
    }
</style>
