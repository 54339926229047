<template>
  <div id="container">
    <div id="content">
      <h1>Group Email Drafts</h1>
      <el-table :data="templates" height="750" style="width: 45%; float: left">
        <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column label="Operations" width="200">
          <template slot-scope="scope">
            <el-button @click.native.prevent="handleShow(scope.$index, templates)" type="text" size="small">Show</el-button>
            <el-button @click.native.prevent="handleDelete(scope.$index, templates)" type="text" size="small">Delete</el-button>
            <el-button @click.native.prevent="handleCompose(scope.$index, templates)" type="text" size="small">Compose</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div id="preview">
        <p class="subject"><b>Subject:</b> {{subject}}</p>
        <div id="html-preview" v-html="html" style="float: left;"></div>
      </div>
      <br clear="all">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Draft Group Emails"
      }
    },
    components: {
    },
    data () {
      return {
        subject: '',
        html: 'Click "Show" to see draft here',
        templates: []
      }
    },
    //computed: {
    //   html () {
    //       return converter.makeHtml(this.content)
    //   }
    //},
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      handleShow: function(index, rows) {
        this.subject = rows[index].subject
        this.html = converter.makeHtml(rows[index].body)
      },
      handleDelete: function(index, rows) {
        //window.location = "/group-email/email-templates/edit/?id=" + rows[index].id
        axios
          .delete('/api/group-email/draft/?id=' + rows[index].id)
          .then(response => {
            this.load();

          });

      },
      handleCompose: function(index, rows) {
        this.$router.push({ path: '/group-email/compose', query: { draft: rows[index].id}});
      },
      load: function () {
        axios
          .get('/api/group-email/drafts')
          .then(response => {
            if(response.data.success) {
              this.templates = response.data.rows;
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
  .subject {
    margin-left: 10px;
    padding-left: 10px;
    margin-top: 0;

  }
  #content .el-table__body-wrapper {
    overflow-y: auto;
  }
  #preview {
    width: 80ch;
    float: left;
    margin-top: 0px;
  }

</style>
