<template>
  <div id="container">
    <div id="content">
      <h1>Group Email</h1>
      <el-steps :active="active" finish-status="success">
        <el-step title="Step 1" description="Select recipients"></el-step>
        <el-step title="Step 2" description="Compose email"></el-step>
        <el-step title="Step 3" description="Preview and send"></el-step>
        <el-step title="Step 4" description="Finished"></el-step>
      </el-steps>
      <div id="recipient-table">
        <label class="el-form-item__label">Recipients</label>
        <el-table ref="multipleTable" :data="recipients" @selection-change="handleRecipientSelect">
          <el-table-column property="firstName" label="First" width="120"></el-table-column>
          <el-table-column property="lastName" label="Last" width="120"></el-table-column>
          <el-table-column property="uni" label="UNI" width="120"></el-table-column>
          <el-table-column property="email" label="E-mail" show-overflow-tooltip></el-table-column>
          <el-table-column type="selection" width="55"></el-table-column>
        </el-table>

        <div v-if="cc && cc.length>0">
          <br/><b>cc: {{cc.join(",")}}</b><br/><br/>
        </div>

        <el-button @click="handleRemoveSelect" size="mini" type="primary">Remove selected</el-button>
        <el-button @click="handleRemoveOthers" size="mini" type="primary">Remove others</el-button>
        <el-button @click="handleRemoveAll" size="mini" type="primary">Remove all</el-button>
        <p style="font-weight: bold">All recipients above will receive the email. Use selection and buttons only to edit the list.</p>
      </div>
      <div id="preview">
        <p class="subject"><b>Subject:</b> {{subject}}</p>
        <div id="html-preview" class="group-email-preview" v-html="html"></div>
        <div class="subject"><b>Attachments: </b>
          <el-upload  action="/api/document/create-email-attachment/" :file-list="attachments" :on-preview="handleAttachmentPreview" :on-remove="handleAttachmentRemove"></el-upload>
        </div>
      </div>
      <br clear="all">
      <div id="bottom">
        <el-checkbox v-model="overrideNoEmails">Override "no emails, please" preferences for all students</el-checkbox><br>
        <el-checkbox v-model="sendInTestMode">Send this email in testing mode</el-checkbox><br><br>
        <p class="color-red" v-if="recipients.length == 0">All recipients have been removed, unable to send</p>
        <el-button @click="prev">&lt; Previous step</el-button>
        <el-button type="primary" @click="send" :disabled="disableSend || recipients.length == 0">Send &gt;</el-button>
      </div>

      <div id="testingmode">
        <h3>About Testing Mode:</h3>
        <p>When an email (bulk or automatic) in <u>testing mode</u> is sent, the following changes are made:</p>
        <ul>
          <li>The body will be prefixed with text explaining that the email was sent in testing mode.</li>
          <li>The email will be sent to: disability@columbia.edu.</li>
          <li>The body will list the actual email addresses that the email <b>would</b> have been sent to if the email were <b>not</b> in testing mode.</li>
          <li>The sent email <b>will not appear</b> on the contact notes pages for the students would have been sent to.</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>


  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)
  export default {
    metaInfo () {
      return {
        title: "Preview Group Email"
      }
    },

    components: {
    },
    data () {
      return {
        active: 2,
        draft: 0,
        subject: '',
        body: '',
        recipients: [],
        cc: [],
        selectedRecipients: [],
        attachments: [],
        replyTo: '',
        sendInTestMode: true,
        overrideNoEmails: false,
        disableSend: false
      }
    },
    computed: {

      html () {
        var preHtml = "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" class=\"body\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;\">\n" +
                "    <tr>\n" +
                "        <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">&nbsp;</td>\n" +
                "        <td class=\"container\" style=\"font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width:100; padding: 0px; width: 636px;\">\n" +
                "            <div class=\"content\" style=\"box-sizing: border-box; display: block; Margin: 0 auto; max-width: 636px; padding: 0px;\">\n" +
                "\n" +
                "                <!-- START CENTERED WHITE CONTAINER -->\n" +
                "<!--                <span class=\"preheader\" style=\"color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;\">This is preheader text. Some clients will show this text as a preview.</span>-->\n" +
                "                <table class=\"main\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; border-radius: 3px;\">\n" +
                "\n"
        "                    <!-- START MAIN CONTENT AREA -->\n" +
        "                    <tr>\n" +
        "                        <td class=\"wrapper\" style=\"font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;\">\n" +
        "                            <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;\">\n" +
        "                                <tr>\n" +
        "                                    <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">";

        var postHtml = "</td>\n" +
                "                                </tr>\n" +
                "                            </table>\n" +
                "                        </td>\n" +
                "                    </tr>\n" +
                "\n" +
                "                    <!-- END MAIN CONTENT AREA -->\n" +
                "                </table>\n" +
                "                <!-- END CENTERED WHITE CONTAINER -->\n" +
                "            </div>\n" +
                "        </td>\n" +
                "        <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">&nbsp;</td>\n" +
                "    </tr>\n" +
                "</table>";

        return preHtml + converter.makeHtml(this.body) + postHtml
      }
    },
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      handleRecipientSelect: function(val) {
        for(var i=0; i<val.length; i++) {
          this.selectedRecipients.push(val[i]);
        }

      },
      handleRemoveSelect: function() {
        for(var i=0; i<this.recipients.length; i++) {
          for(var j=0; j<this.selectedRecipients.length; j++) {
            if(this.recipients[i].id == this.selectedRecipients[j].id) {
              this.recipients.splice(i,1);
            }
          }
        }
        this.selectedRecipients = [];
        this.saveRecipients();
      },
      handleRemoveOthers: function() {
        this.recipients = this.selectedRecipients.slice(0); //copy all
        this.selectedRecipients = [];
        this.saveRecipients();
      },
      handleRemoveAll: function() {
        this.recipients = [];
        this.selectedRecipients = [];
        this.saveRecipients();
      },
      saveRecipients: function() {
        this.emailParams.recipients = this.recipients
        axios.put("/api/group-email/draft/",{
          id: this.draft,
          subject: this.subject,
          body: this.body,
          emailParams: this.emailParams
        }).then(response => {
          // eslint-disable-next-line
          console.log("recipients saved")});
      },
      loadDraft: function(id)  {
        axios.get("/api/group-email/draft/" + (id ? "?id=" + id + '&preview=true' : ''))
          .then(response => {
            if(response.data.id) {
              this.draft = response.data.id;
              this.subject = response.data.subject;
              this.body = response.data.body;
              this.emailParams = response.data.params;

              this.recipients = this.emailParams.recipients;
              this.replyTo = this.emailParams.replyTo;
              this.cc = this.emailParams.cc;

              if(this.emailParams.regardingId && this.emailParams.regardingClass){
                this.regardingId = this.emailParams.regardingId;
                this.regardingClass = this.emailParams.regardingClass;
              }

              if(this.recipients.length == 1 && this.recipients[0].sendPromoEmail == false) {
                //enable override and warn
                this.overrideNoEmails = true;
                this.displayError('Warning: This student prefers not to receive promotional email, but the "override" option has been automatically enabled.');
              }

              this.loadDraftAttachments(id);
            } else {
              this.prev();
            }
          });
      },
      loadDraftAttachments: function(id) {
        axios.get("/api/group-email/draft-email-attachments" + (id ? "/?id=" + id : ''))
          .then(response => {
            if(response.data.success) {
              for(var i=0; i<response.data.rows.length; i++) {
                this.attachments.push({
                  id: response.data.rows[i].id,
                  name: response.data.rows[i].asset_file_name,
                  fileSize: response.data.rows[i].asset_file_size,
                  contentType: response.data.rows[i].asset_content_type
                });
              }
            }
          });
      },
      handleAttachmentPreview: function(file) {
        // eslint-disable-next-line
        console.log("in preview");
        var url = '/api/group-email/download-draft-attachment/' + this.draft + '/' + file.id + '/' + file.name;
        window.open(url);

      },
      handleAttachmentRemove: function(file) {
        axios.delete("/api/group-email/draft-email-attachment?id=" + file.id + "&draft=" + this.draft + "&name=" + file.name)
          .then(response => {
            // eslint-disable-next-line
            console.log('attachment removed: ' + file.id);
          });
      },
      prev: function() {
        let params = this.$route.query;
        if(this.draft){
          this.$router.push({ path: "/group-email/compose/edit", query: { draft: this.draftId, ...params }});
        } else {
          this.$router.push({ path: "/group-email/compose/edit", query: params })
        }
      },
      send: function() {
        //console.log('sending');
        this.disableSend = true;
        axios.post("/api/group-email/send/", {
          "id": this.draft,
          "sendInTestMode": this.sendInTestMode,
          "overrideNoEmails": this.overrideNoEmails,
          "subject": this.subject,
          "body" : this.body,
          "html": this.html,
          "attachments": this.attachments,
          "replyTo": this.replyTo
        }).then(response => {
          this.disableSend = false;
          this.$router.push({ path: '/group-email/compose/finished', query: { id: this.draft }});
        });
      }
    },
    mounted() {
      this.params = this.$route.query;
      this.draft = parseInt(this.params.draft);
      this.loadDraft(this.params.draft);
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  /*#html-preview h1, #html-preview h2, #html-preview h3 {*/
  /*  color: black;*/
  /*  border-bottom: none;*/
  /*  padding: 0;*/
  /*}*/
  #html-preview {
    margin-left: 20px;
    padding: 0px 10px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
  .subject {
    margin-left: 10px;
    padding-left: 10px;
  }
  #recipient-table {
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 600px;
  }
  #preview {
    width: 686px;
    float: left;
    margin-top: 10px;
  }
  #testingmode {
    margin-top: 20px;
    margin-left: 20px;
  }
  #bottom {
    margin-left: 20px;
  }
</style>
