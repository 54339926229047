<template>
<div id="content">
            <h1>Status tables</h1>
            <div><a :href="'/search'">Click here to search for a student</a></div>
            <div style="float: left; margin-right: 100px;" class="outer-block medium-block">
              <student-records-by-ods-status></student-records-by-ods-status>
              <div style="float: left;clear: left; margin-top: 20px">
                <student-students-by-ha-status></student-students-by-ha-status>
              </div>
            </div>

            <div style="float: left;">
              <student-students-by-coordinator></student-students-by-coordinator>
              <student-faculty-by-coordinator></student-faculty-by-coordinator>
            </div>
            
        </div>
</template>

<script>


    export default {
        metaInfo () {
            return {
                title: "Status Tables"
            }
        },
        components: {
        },
        data () {
            return {
            }
        },
        methods: {
        },
        created () {
        }
    }
</script>

<style lang="postcss" scoped>
    .medium-block {
      width: 35em;
    }
</style>
