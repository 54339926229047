<template>
    <div id="container">
<div id="content">
            <student-nav selected="ha-accommodations" :id="id" :name="name" :uni="uni" :haStatus="haStatus"></student-nav>
            <br clear="all">
            <student-ha-instructions :id="id"></student-ha-instructions>
            <student-ha-review :id="id"></student-ha-review>
            <student-housing-committee-notes :id="id"></student-housing-committee-notes>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'


    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: ""
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/ha-accommodations-review/')[1];

                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.haStatus = response.data.haStatus;
                    });
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
