<template>
    <div id="container">
      <div id="content">
            <h1>New Contact Note Template</h1>
            <el-form class="template-form" label-width="85px">
                <el-form-item label="Subject Line">
                    <el-input size="mini" v-model="title"></el-input>
                </el-form-item>
                <el-form-item label="Body">
                    <el-input size="mini" type="textarea" :rows="15" v-model="body"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="mini" type="primary" @click="onSubmit">Save</el-button>
                </el-form-item>
            </el-form>
            <p><a :href="'/contact-notes/list-templates'">Back to list</a></p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "New Contact Note Template"
            }
        },
        components: {
            },
        data () {
            return {
                title: '',
                body: ''
            }
        },
        methods: {
            onSubmit: function(){
                axios.post("/api/contact-note/contact-note-template/", {title: this.title, body: this.body})
                    .then(response => {
                        if (response.data.success) {
                            this.$router.push({ path: '/contact-notes/list-templates' });
                        } else {
                           //handle?
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
          //  this.load();
        }
    }
</script>
<style scoped>
    .template-form {
        width:700px;
    }
</style>
