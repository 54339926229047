<template>
    <div id="container">
<div id="content">
            <student-nav selected="accommodations" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div class="menu">
            <a @click="onShowAllAccommodationsChange">{{ showAllAccommodations ? "Show Active" : "Show All" }}</a> | 
            Enter accommodations: [ <a :href="'/student/accommodations-edit/' + id + '?status=requested'">student requests</a> &nbsp;|&nbsp; <a :href="'/student/accommodations-edit/' + id + '?status=recommended'">unrequested</a><template v-if="rawStatus == 'registered'"> &nbsp;|&nbsp; <a :href="'/student/accommodations-edit/' + id + '?status=supplemental'">supplemental</a></template> ] &nbsp; <a :href="'/student/accommodations-review/' + id">Review accommodations</a>
            <!-- TODO: Only users who are authorized to recommend accommodations should see the 'unrequested' link above -->
            </div>
            <student-accommodations :accommodationsAndCategories="accommodationsAndCategories"></student-accommodations>
            <h2>Application Process Reviews</h2>
            <student-application-process-reviews :id="id" :limited=false></student-application-process-reviews>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                rawStatus: "",
                showAllAccommodations: true,
                accommodationsAndCategories: []
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/accommodations/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                        this.rawStatus = response.data.status;
                    });

                axios.get("/api/accommodation/all-student-accommodations/", {params:{id: this.id}})
                    .then(response => {
                        this.accommodationsAndCategories = response.data.rows;

                        for(var i = 0; i < this.accommodationsAndCategories.length; i++){
                            var accommodationAndCategory = this.accommodationsAndCategories[i];

                            for(var j = 0; j < accommodationAndCategory.accommodations.length; j++){
                                var accommodation = accommodationAndCategory.accommodations[j];

                                if(accommodation.request_creation){
                                    accommodation.request_creation = moment(accommodation.request_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.recommended_creation){
                                    accommodation.recommended_creation = moment(accommodation.recommended_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.eligible_creation){
                                    accommodation.eligible_creation = moment(accommodation.eligible_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.no_longer_needed_creation){
                                    accommodation.no_longer_needed_creation = moment(accommodation.no_longer_needed_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.declined_by_student_creation){
                                    accommodation.declined_by_student_creation = moment(accommodation.declined_by_student_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.needs_further_review_creation){
                                    accommodation.needs_further_review_creation = moment(accommodation.needs_further_review_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.expired_creation){
                                    accommodation.expired_creation = moment(accommodation.expired_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.supervisor_comment_text){
                                    this.$set(accommodation, 'show_comment_edit', false);
                                }

                                if(accommodation.supervisor_comment_text){
                                    accommodation.supervisor_comment_text = accommodation.supervisor_comment_text.replace("<br>", "\n").replace("<br/>", "\n");
                                }
                            }

                            accommodationAndCategory.accommodations.unshift({categoryName: accommodationAndCategory.category})
                        }
                    });
            },
            onShowAllAccommodationsChange: function(){
                this.showAllAccommodations = !this.showAllAccommodations;

                var urlStr = this.showAllAccommodations ? "/api/accommodation/all-student-accommodations/" : "/api/accommodation/active-student-accommodations/";

                axios.get(urlStr, {params:{id: this.id}})
                    .then(response => {
                        this.accommodationsAndCategories = response.data.rows;

                        for(var i = 0; i < this.accommodationsAndCategories.length; i++){
                            var accommodationAndCategory = this.accommodationsAndCategories[i];

                            for(var j = 0; j < accommodationAndCategory.accommodations.length; j++){
                                var accommodation = accommodationAndCategory.accommodations[j];

                                if(accommodation.request_creation){
                                    accommodation.request_creation = moment(accommodation.request_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.recommended_creation){
                                    accommodation.recommended_creation = moment(accommodation.recommended_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.eligible_creation){
                                    accommodation.eligible_creation = moment(accommodation.eligible_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.no_longer_needed_creation){
                                    accommodation.no_longer_needed_creation = moment(accommodation.no_longer_needed_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.declined_by_student_creation){
                                    accommodation.declined_by_student_creation = moment(accommodation.declined_by_student_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.needs_further_review_creation){
                                    accommodation.needs_further_review_creation = moment(accommodation.needs_further_review_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.expired_creation){
                                    accommodation.expired_creation = moment(accommodation.expired_creation).format("MM/DD/YYYY");
                                }

                                if(accommodation.supervisor_comment_text){
                                    accommodation.supervisor_comment_text = accommodation.supervisor_comment_text.replace("<br>", "\n").replace("<br/>", "\n");
                                }

                            }

                            accommodationAndCategory.accommodations.unshift({categoryName: accommodationAndCategory.category})
                        }
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .menu {
        margin-top: 20px;
        margin-bottom: 3px;
    }
</style>
