import { render, staticRenderFns } from "./Compose.vue?vue&type=template&id=6f24e100&scoped=true&"
import script from "./Compose.vue?vue&type=script&lang=js&"
export * from "./Compose.vue?vue&type=script&lang=js&"
import style0 from "./Compose.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Compose.vue?vue&type=style&index=1&id=6f24e100&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f24e100",
  null
  
)

export default component.exports