<template>
    <div id="container">
<div id="content">
            <h1>Accommodation Letter Tracking {{ semesterName }}</h1>
            <template v-for="(row,index) in data">
                <h2 :key="index">Coordinator: {{ row.name }}</h2>
                <el-table :key="index" :default-sort="{prop: sortProp, order: 'ascending'}" :data="row.rows" style="width: 100%" class="table-top-align" :span-method="spanMethod">
                    <el-table-column prop="last_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="135">
                        <template slot-scope="scope">
                            <a :href="'/student/documents/' + scope.row.student_id">{{ scope.row.first_name }} {{ scope.row.last_name }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="class_name" label="Course Title" sortable :sort-orders="['ascending', 'descending']">
                        <template slot-scope="scope">
                            <template v-if="!scope.row.class_name || scope.row.class_name == ''">No course registrations from SIS</template>
                            <template v-else>{{ scope.row.class_name }}</template>
                        </template>
                    </el-table-column>
                    <el-table-column prop="requested_date" label="Requested" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.requested_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="prepared_date" label="Prepared" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.prepared_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="picked_up_date" label="Picked" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.picked_up_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="returned_date" label="Returned" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.returned_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="withdrawn_date" label="Withdrawn" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.withdrawn_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="emailed_date" label="Emailed" sortable :sort-orders="['ascending', 'descending']" width="90">
                        <template slot-scope="scope">{{ scope.row.emailed_date | formatDateMDY }}</template>
                    </el-table-column>
                    <el-table-column prop="adv" label="Adv" sortable :sort-orders="['ascending', 'descending']" width="60">
                        <template slot-scope="scope">{{ scope.row.adv ? 'Yes' : 'No' }}</template>
                    </el-table-column>
                    <el-table-column prop="advisor" label="Advisor" sortable :sort-orders="['ascending', 'descending']" width="135">
                        <template slot-scope="scope"><template v-if="scope.row.advisor">{{ scope.row.advisor.first_name }} {{ scope.row.advisor.last_name }}<br><a :href="'mailto:' + scope.row.advisor.email">{{ scope.row.advisor.email }}</a></template></template>
                    </el-table-column>
                    <el-table-column prop="sent" label="Sent" sortable :sort-orders="['ascending', 'descending']" width="65">
                        <template slot-scope="scope">{{ scope.row.sent ? 'Yes' : 'No' }}</template>
                    </el-table-column>
                    <el-table-column label="Letter" width="75">
                        <template slot-scope="scope"><a :href="'/document/edit/' + scope.row.document_id">edit</a></template>
                    </el-table-column>
                    <el-table-column prop="note" label="Note" width="75">
                        <template slot-scope="scope"><template v-if="scope.row.note"><a :href="'/contact-notes/show/' + scope.row.note.id">note</a></template></template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Accommodation Letter Tracking " + this.semesterName
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                coordinatorId: null,
                semesterId: null,
                semesterName: null,
                secondarySort: 'Student Name'
            }
        },
        computed: {
            sortProp: function(){
                if(this.secondarySort == "Student Name"){
                    return 'last_name';
                } else if(this.secondarySort == "Course Title"){
                    return 'class_name';
                } else if(this.secondarySort == "Recently Requested"){
                    return 'requested_date';
                } else if(this.secondarySort == "Recently Prepared"){
                    return 'prepared_date';
                } else if(this.secondarySort == "Recently Picked Up"){
                    return 'picked_up_date';
                } else if(this.secondarySort == "Recently Returned"){
                    return 'returned_date';
                } else if(this.secondarySort == "Recently Withdrawn"){
                    return 'withdrawn_date';
                } else if(this.secondarySort == "Recently Emailed"){
                    return 'emailed_date';
                }
                return '';
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.semesterId = this.urlParams.semester_id;
                this.coordinatorId = this.urlParams.coordinator_id;
                this.secondarySort = decodeURIComponent(this.urlParams.secondary_order);
                this.getData();

                axios.get('/api/simple/get-semester-name/', {params: {id: this.semesterId}})
                    .then(response=> {
                        this.semesterName = response.data.name;
                    })
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/accommodation-letter-tracking/', {params: {user_id: this.coordinatorId, semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            spanMethod: function({row, column, rowIndex, columnIndex}){
                if(columnIndex == 1){
                    if(!row.class_name || row.class_name == ''){
                        return {rowspan: 1, colspan: 12};
                    }
                }
                return {rowspan: 1, colspan: 1};
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
