<template>
    <div id="container">
<div id="content" class="testing-services-show" v-loading="loading">
            <h1>Exam for <span class="text-italic">{{ testingService.class_name }}</span> ({{ testingService.instructor_first_name }} {{ testingService.instructor_last_name }}) in {{ testingService.semester_name }}<br>{{ testingService.course_number_and_section }}</h1>
            <a v-if="!editAll" class="trailing-space" @click="setEditAll">Edit entire page</a>
            <a class="trailing-space" :href="'/api/testing-service/exam.xlsx?id=' + this.testingService.id" target="_blank">Download CSV</a>

            <span v-if="testingService.next_weeks_services && testingService.next_weeks_services.length" style="color:red;">Exams for this course in the next 7 days:</span>
            <span v-if="testingService.past_weeks_services && testingService.past_weeks_services.length" style="color:red;" :style="pastWeekServicesSpanStyle">Exams for this course in the previous 7 days:</span>
            <div style="clear:both;"></div>
            <ul v-if="testingService.next_weeks_services && testingService.next_weeks_services.length" style="float: left; margin: 0 0 5px 260px; list-style-type: none">
                <li v-for="service in testingService.next_weeks_services" style="margin: 5px 0 0" :key="service.id"><a :href="'/testing/show/' + service.service_id">{{ service.start_time | formatDateMDY }} at {{ service.start_time | formatTimehhmmA }}</a></li>
            </ul>
            <ul v-if="testingService.past_weeks_services && testingService.past_weeks_services.length" style="float: left; list-style-type: none" :style="pastWeekServicesListStyle">
                <li v-for="service in testingService.past_weeks_services" style="margin: 5px 0 0" :key="service.id"><a :href="'/testing/show/' + service.service_id">{{ service.start_time | formatDateMDY }} at {{ service.start_time | formatTimehhmmA }}</a></li>
            </ul>
            <div style="clear:both;"></div>

            <div class="section" style="position:relative">
                <h3 style="max-width: 35%; float: left">Exam permissions for all students</h3>
                <div style="float: right; width:65%; font-weight: bold; text-align: right; font-size:15px">
                    # Scheduled: {{ num_scheduled }}&nbsp;&nbsp;&nbsp;&nbsp;
                    # Cancelled: {{ num_cancelled }}&nbsp;&nbsp;&nbsp;&nbsp;
                    # Placeheld: {{ num_placeheld }}&nbsp;&nbsp;&nbsp;&nbsp;
                    # Not Scheduled: {{ eligibleStudents.length }}
                </div>
                <div style="clear: both"></div>

                <div class="panel">
                    <a v-if="!editAll && !editingTestingService" @click="editingTestingService = true">Edit this section</a>
                    <template v-if="editAll || editingTestingService">
                        <div class="accommodations">
                            <template v-for="option in permissionOptions">
                                <template v-if="option.name != 'Listening section or film clip' && option.name != 'PowerPoint slideshow included with exam'">
                                    <el-checkbox :key="option.id + '-cb1'" :label="option.id" @change="toggleExamPermissionsCheckbox($event, option)" :value="testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">{{ option.name }}</el-checkbox><br :key="option.id + '-br'">
                                    <template v-if="option.name == 'Calculator' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        Specify Type:
                                        <el-select size="mini" v-model="testingService.calculator_type" style="width:190px" class="accommodation-option" :key="option.id + '-select'">
                                            <el-option v-for="calculatorOption in calculatorOptions" :key="calculatorOption" :label="calculatorOption" :value="calculatorOption"></el-option>
                                        </el-select>
                                        <template v-if="testingService.calculator_type == 'Other'">
                                            Other:
                                            <el-input size="mini" v-model="testingService.calculator_type_other" :key="option.id+ '-input'"></el-input>
                                        </template>
                                    </template>
                                    <template v-else-if="option.name == 'Student permitted to bring own notes/formula sheets' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        <div style="display:block; margin-left:15px;" :key="option.id+ '-div'">
                                            <el-input-number  size="mini" v-model="testingService.number_of_pages" :min="0" controls-position="right"
                                                             class="length-input exam-length-input pages-number"></el-input-number> Pages of notes (8.5X11)<br>
                                            <el-checkbox v-model="testingService.single_sided_notes" @change="toggleSingleSidedNotes">Single Sided</el-checkbox><br>
                                            <el-checkbox v-model="testingService.double_sided_notes" @change="toggleDoubleSidedNotes">Double Sided</el-checkbox>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <div>
                                Additional Info/Other permission:
                                <el-input type="textarea" :rows="2" v-model="testingService.other_exam_permission"></el-input>
                            </div>
                            <h3>Exam Format Includes</h3>
                            <template v-for="option in permissionOptions">
                                <template v-if="option.name == 'Listening section or film clip' || option.name == 'PowerPoint slideshow included with exam'">
                                    <el-checkbox :key="option.id + '-cb2'" :label="option.id" @change="toggleExamPermissionsCheckbox($event, option)" :value="testingService.exam_permission_ids.indexOf(option.id) !== -1">{{ option.name }}</el-checkbox><br :key="option.id">
                                    <template v-if="option.name == 'Listening section or film clip' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        Professors will:<br :key="option.id + '-br'">
                                        <div style="display:block; margin-left:15px" :key="option.id + '-div'">
                                            <el-checkbox-group v-model="testingService.listening_film_option_ids">
                                                <template v-for="listeningFilmOption in listeningFilmOptions">
                                                    <el-checkbox :key="listeningFilmOption.id + '-cb'" :label="listeningFilmOption.id" @change="toggleListeningFilmOptionsCheckbox($event, listeningFilmOption.name)">{{ listeningFilmOption.name }}</el-checkbox><br :key="listeningFilmOption.id">
                                                </template>
                                            </el-checkbox-group>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="accommodations">
                            <template v-for="option in permissionOptions">
                                <template  v-if="option.name != 'Listening section or film clip' && option.name != 'PowerPoint slideshow included with exam'">
                                    <div :key="option.id + '-div1'"><template v-if="testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> {{ option.name }}</div>
                                    <template v-if="option.name == 'Calculator' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        Specify Type: {{ testingService.calculator_type}}
                                        <template v-if="testingService.calculator_type_other"><br :key="option.id">Other: {{ testingService.calculator_type_other }}</template>
                                    </template>
                                    <template v-else-if="option.name == 'Student permitted to bring own notes/formula sheets' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        <div style="display:block; margin-left:15px;" :key="option.id">
                                            {{ testingService.number_of_pages }} Pages of notes (8.5X11)<br>
                                            <div><template v-if="testingService.single_sided_notes"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> Single Sided</div>
                                            <div><template v-if="testingService.double_sided_notes"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> Double Sided</div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <h3>Exam Format Includes</h3>
                            <template v-for="option in permissionOptions">
                                <template  v-if="option.name == 'Listening section or film clip' || option.name == 'PowerPoint slideshow included with exam'">
                                    <div :key="option.id + '-div2'"><template v-if="testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> {{ option.name }}</div>
                                    <template v-if="option.name == 'Listening section or film clip' && testingService.exam_permission_ids && testingService.exam_permission_ids.indexOf(option.id) !== -1">
                                        Professors will:<br :key="option.id + 'br'">
                                        <div style="display:block; margin-left:15px" :key="option.id">
                                            <template v-for="listeningFilmOption in listeningFilmOptions">
                                                <div :key="listeningFilmOption.id"><template v-if="testingService.listening_film_option_ids && testingService.listening_film_option_ids.indexOf(listeningFilmOption.id) !== -1"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> {{ listeningFilmOption.name }}</div>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </template>

                    <div style="clear:both;"></div>

                    <template v-if="editAll || editingTestingService">
                        <div style="margin-top:10px;">
                            <el-checkbox v-model="testingService.is_missing_exam_specs">Missing Exam Specs</el-checkbox>
                        </div>
                        <div v-if="testingService.is_missing_exam_specs" style="display:block;">
                            <br>Indicate which specs are missing
                            <el-input type="textarea" :rows="4" v-model="testingService.missing_exam_specs_notes"></el-input>
                        </div>
                    </template>
                    <template v-else>
                        <div style="margin-top:10px;" :class="{ aqua: testingService.is_missing_exam_specs }"><template v-if="testingService.is_missing_exam_specs"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> Missing Exam Specs</div>
                        <template v-if="testingService.missing_exam_specs_notes && testingService.missing_exam_specs_notes != ''">
                            <br>Indicate which specs are missing<br><div style="width: 21.0em; background-color: #e4e4e4; " class="formatted-text">{{ testingService.missing_exam_specs_notes }}</div>
                        </template>
                    </template>

                    <h3>Equipment</h3>
                    <template v-if="editAll || editingTestingService">
                        <template v-for="(option, equipmentOptionIndex) in equipmentOptions">
                            <template v-if="option.is_unselectable">
                                <div :key="option.id + '-unselectable'"><template v-if="testingService.equipment_ids && testingService.equipment_ids.indexOf(option.id) !== -1"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template> {{ option.name }}</div>
                            </template>
                            <template v-else>
                                <el-checkbox :key="option.id + '-selectable'" :label="option.id" @change="toggleEquipmentCheckbox($event, option)" :value="testingService.equipment_ids.indexOf(option.id) !== -1">{{ option.name }}</el-checkbox>
                            </template>
                            <template v-if="option.name == 'Computer'">
                                <template v-if="testingService.equipment_ids.indexOf(option.id) !== -1">
                                    <div style="display:block" :key="option.id +'-type'">
                                        - Must select comp. type (Default is ANY) -
                                        <!-- TODO: Check this once call is implemented -->
                                        <el-select style="width:295px;" size="mini" v-model="testingService.computer_type_preference">
                                            <el-option v-for="type in computerTypeOptions" :key="type.value" :label="type.name" :value="type.value"></el-option>
                                        </el-select>
                                    </div>
                                </template>
                                <br :key="option.id + '-br'">Computer Use Notes:
                                <el-input type="textarea" :rows="2" v-model="testingService.computer_use_notes" :key="option.id"></el-input>
                            </template>
                            <template v-if="option.name == 'Other'">
                                -<br :key="equipmentOptionIndex + '-br1'"><el-input size="mini" v-model="testingService.equipment_other" :key="equipmentOptionIndex+'-elinput'"></el-input>
                            </template>
                            <br v-if="equipmentOptionIndex !== equipmentOptions.length -1" :key="equipmentOptionIndex + '-br2'">
                        </template>
                    </template>
                    <template v-else>
                        <template v-for="option in equipmentOptions">
                            <div :key="option.id">
                                <template v-if="testingService.equipment_ids && testingService.equipment_ids.indexOf(option.id) !== -1"><span class="exam-checkbox">&#9746;</span></template>
                                <template v-else><span class="exam-checkbox">&#9744;</span></template> {{ option.name }}
                                <template v-if="option.name == 'Computer'"> - {{ testingService.computer_type_preference }} Required</template>
                                <template v-if="option.name == 'Other'">
                                    - {{ testingService.equipment_other }}
                                </template>
                            </div>
                            <template v-if="option.name == 'Computer' && testingService.computer_use_notes && testingService.computer_use_notes != ''">
                                Computer Use Notes:<br :key="option.id + '-br'">
                                <div class="formatted-text" style="width: 17.5em; background-color: #e4e4e4;" :key="option.id + '-div'">{{ testingService.computer_use_notes }}</div>
                            </template>
                        </template>
                    </template>

                    <div style="margin-top:10px;">
                        <template v-if="editAll || editingTestingService">
                            <el-checkbox v-model="testingService.manual_opt_professor_out">Manually opt professor out of automatic reminders for this exam?</el-checkbox><br>
                            <span v-if="testingService.manual_opt_professor_out" class="red">Manually opted out<br></span>
                            <span v-if="testingService.is_auto_opted_out" class="red">Auto opted out<br></span>
                            <span v-if="testingService.is_placeholder_opted_out" class="red">Placeholder opted out<br></span>
                          <span v-if="testingService.is_virtual_opted_out" class="red">Virtual exam opted out<br></span>

                            <el-checkbox v-model="testingService.exam_received">Exam received?</el-checkbox><br>

                            <template v-if="showFlashDriveMessage || showLargePrintMessage">
                                <p style="color:red; font-size:15px; font-weight: bold">
                                    <template v-if="showFlashDriveMessage">Electronic copy needed<br/></template>
                                    <template v-if="showLargePrintMessage">Large Print Exam needed<br/></template>
                                </p>
                            </template>

                            Requests for Exam:
                            <el-select size="mini" v-model="testingService.exam_requested_from_professor" style="width:250px">
                                <el-option v-for="option in examRequestedFromProfessionOptions" :key="option" :label="option" :value="option"></el-option>
                            </el-select>
                        </template>
                        <template v-else>
                            <template v-if="testingService.opt_professor_out"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template>Opt professor out of automatic reminders for this exam?<br>
                            <span v-if="testingService.manual_opt_professor_out" class="red">Manually opted out<br></span>
                            <span v-if="testingService.is_auto_opted_out" class="red">Auto opted out<br></span>
                            <span v-if="testingService.is_placeholder_opted_out" class="red">Placeholder opted out<br></span>
                            <span v-if="testingService.is_virtual_opted_out" class="red">Virtual exam opted out<br></span>
                            <template v-if="testingService.exam_received"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template>Exam received?<br>
                            <span v-if="testingService.exam_received_modified_by && testingService.exam_received_modified_by != ''" class="red">^ Modified by {{ testingService.exam_received_modifier }}<br></span>


                            <template v-if="showFlashDriveMessage || showLargePrintMessage">
                                <p style="color:red; font-size:15px; font-weight: bold">
                                    <template v-if="showFlashDriveMessage">Electronic copy needed<br/></template>
                                    <template v-if="showLargePrintMessage">Large Print Exam needed<br/></template>
                                </p>
                            </template>

                            Requests for Exam:<br>
                            <div style="background-color:#E4E4E4; width:17.5em;">{{ testingService.exam_requested_from_professor }}</div>
                            <span v-if="testingService.exam_requested_modifier && testingService.exam_requested_modifier != ''" class="red">^ Modified by {{ testingService.exam_requested_modifier }}<br></span>
                        </template>
                        <template v-if="showRescheduledExamReceivedMessage">
                            <br><br clear="both">
                            <h2 style="color: red">Rescheduled Alert: This exam was rescheduled from
                                <template v-if="testingService.rescheduled_from_exam_start_time">
                                    {{ testingService.rescheduled_from_exam_start_time | formatDateTimeMDYhma }}
                                </template>
                                <template v-else>
                                    N/A
                                </template> to {{ testingService.start_time | formatDateTimeMDYhma }}
                                <template v-if="testingService.exam_received">
                                    <br/><br/>Exam is marked received. If exam is not received or professor will send new exam, please <u>uncheck</u> “exam received” before saving.
                                </template></h2>

                                <template v-if="testingService.old_exam_reschedule_reason">
                                    <br/><br/>Reschedule Reason:<br/>
                                    {{testingService.old_exam_reschedule_reason}}
                                    <template v-if="testingService.old_exam_other_reschedule_reason">
                                        - {{testingService.old_exam_other_reschedule_reason}}
                                    </template>
                                </template>
                        </template>
                        <template v-else-if="testingService.rescheduled_from_exam_id">
                            <br><br clear="both">
                            <p class="red">
                                Rescheduled from
                                <template v-if="testingService.rescheduled_from_exam_start_time">
                                    {{ testingService.rescheduled_from_exam_start_time | formatDateTimeMDYhma }}
                                </template>
                                <template v-else>
                                    N/A
                                </template> to {{ testingService.start_time | formatDateTimeMDYhma }}
                                <template v-if="testingService.rescheduler_name">by {{ testingService.rescheduler_name }}</template>

                                <template v-if="testingService.old_exam_reschedule_reason">
                                    <br/><br/>Reschedule Reason:<br/>
                                    {{testingService.old_exam_reschedule_reason}}
                                    <template v-if="testingService.old_exam_other_reschedule_reason">
                                         - {{testingService.old_exam_other_reschedule_reason}}
                                    </template>
                                </template>
                            </p>
                            <br><br>
                        </template>
                        <template v-if="editAll || editingTestingService">
                            <br/>Add comment:
                            <el-input type="textarea" :rows="2" v-model="testingService.new_comment"></el-input><br/><br/>
                            Add course comment:
                            <el-input type="textarea" :rows="2" v-model="testingService.new_course_comment"></el-input>
                        </template>
                    </div>
                </div>

                <div class="panel">
                    <template v-if="editAll || editingTestingService">
                        <el-form style="width:100%" ref="testingServiceForm" label-width="100px" label-position="left" :rules="rules" :model="testingService">
                            <el-form-item class="no-padding" label="Exam date:" prop="date"><el-date-picker style="width:150px" size="mini" v-model="testingService.date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                            <el-form-item class="no-padding" label="Exam time:" prop="start_time_input"><el-input style="width:100px" size="mini" v-model="testingService.start_time_input"></el-input></el-form-item>
                        </el-form>
                        <el-form label-width="50px" label-position="left" class="form-small-275">
                            <el-form-item label="Length:">
                                <el-input-number size="mini" v-model="testingService.length_hours" :min="0" :max="24" controls-position="right" class="length-input exam-length-input"></el-input-number> Hrs
                                <el-input-number size="mini" v-model="testingService.length_minutes" :min="0" controls-position="right" class="length-input exam-length-input"></el-input-number> Mins
                            </el-form-item>
                        </el-form>
                    </template>
                    <template v-else>
                        Exam date: {{ testingService.start_time | formatDateMDY }}<br>
                        Exam time: {{ testingService.start_time | formatTimehma }}<br>
                        Length: {{ formatLength(testingService.length) }}<br>
                    </template>
                    (defaults to class time)
                    <br/>
                    <br/>

                    <h3>Exam Type</h3>
                    <template v-if="editAll || editingTestingService">
                      <template v-if="!testingService.exam_type || testingService.exam_type === ''">
                        <span style="color:red">Please select an exam type</span>
                      </template>
                      <el-checkbox-group v-model="testingService.exam_type">
                        <template v-for="option in examTypes">
                          <el-checkbox :true-label="option" false-label="" :key="option">{{option}}</el-checkbox>

                          <template v-if="option === 'Virtual' && testingService.exam_type === 'Virtual'">
                            <div :key="option+'div'" style="padding-left:10px">
                              <span :key="option+'span'" style="color:red" class="small-font" v-if="!virtualExamType || virtualExamType === ''">
                                Please select a virtual exam type
                              </span>
                              <el-checkbox-group :key="option + 'vex'" v-model="virtualExamType">
                                <template v-for="(voption, i) in virtualExamTypes">
                                  <el-checkbox :true-label="voption" false-label="" :key="i">{{voption}}</el-checkbox>
                                </template>
                              </el-checkbox-group>
                            </div>
                          </template>
                        </template>
                      </el-checkbox-group>
                    </template>
                    <template v-else>
                      {{ testingService.exam_type }}<br/>
                      <template v-if="testingService.exam_type === 'Virtual'">
                        Virtual Exam Type: {{ testingService.virtual_exam_type }}
                      </template>
                    </template>


                    <h3>Delivery Method</h3>
                    <template v-if="editAll || editingTestingService">
                        <el-checkbox-group v-model="testingService.delivery_instruction_ids">
                            <template v-for="option in deliveryInstructions">
                                <el-checkbox :key="option.id" :label="option.id">{{ option.name }}</el-checkbox>
                                <template v-if="option.name === 'Special Delivery' && showSpecialDeliveryLocation">
                                  <div :key="option.id + '-bssr1'" style="font-size:12px;  margin-bottom: 5px">
                                    Special Delivery Location:<br>
                                    <el-input type="textarea" v-model="testingService.special_delivery_location"></el-input>
                                  </div>
                                </template>
                                <template v-else-if="option.name === 'Campus Delivery' && showCampusDeliveryLocation">
                                  <div :key="option.id + '-bssr1'" style="font-size:12px;  margin-bottom: 5px">
                                    Campus Delivery Location:<br>
                                    <el-input type="textarea" v-model="testingService.campus_delivery_location"></el-input>
                                  </div>
                                </template>
                                <template v-else-if="option.name === 'Urgent Delivery' && showUrgentDeliveryInstructions">
                                  <div :key="option.id + '-bssr1'" style="font-size:12px; margin-bottom: 5px">
                                    Urgent Instructions:<br>
                                    <el-input type="textarea" v-model="testingService.urgent_delivery_instructions"></el-input>
                                  </div>
                                </template>
                                <template v-else>
                                  <br :key="option.id + '-br'">
                                </template>
                            </template>
                        </el-checkbox-group>
                    </template>
                    <template v-else>
                        <template v-for="option in deliveryInstructions">
                            <div :key="option.id">
                                <template v-if="testingService.delivery_instruction_ids && testingService.delivery_instruction_ids.indexOf(option.id) !== -1"><span class="exam-checkbox">&#9746;</span></template>
                                <template v-else><span class="exam-checkbox">&#9744;</span></template> {{ option.name }}

                                <template v-if="option.name === 'Special Delivery' && showSpecialDeliveryLocation">
                                  <div :key="option.id + '-bssr1'" style="font-size:14px">
                                    <div style="display:block;">
                                      Special Delivery Location:<br>
                                      <div style="width: 21.0em; background-color: #e4e4e4; margin-bottom: 5px">{{ testingService.special_delivery_location }}</div>
                                    </div>
                                  </div>
                                </template>

                                <template v-if="option.name === 'Campus Delivery' && showCampusDeliveryLocation">
                                  <div :key="option.id + '-bssr1'" style="font-size:14px">
                                    <div style="display:block;">
                                      Campus Delivery Location:<br>
                                      <div style="width: 21.0em; background-color: #e4e4e4; margin-bottom: 5px">{{ testingService.campus_delivery_location }}</div>
                                    </div>
                                  </div>
                                </template>

                                <template v-if="option.name === 'Urgent Delivery' && showUrgentDeliveryInstructions">
                                  <div :key="option.id + '-bssr1'" style="font-size:14px">
                                    <div style="display:block;">
                                      Urgent Instructions:<br>
                                      <div style="width: 21.0em; background-color: #e4e4e4;">{{ testingService.urgent_delivery_instructions }}</div>
                                    </div>
                                  </div>
                                </template>
                            </div>
                        </template>
                    </template>

                    <template v-if="showDeliveryInstructionsOther">
                        <template v-if="editAll || editingTestingService">
                                Other:<br>
                                <el-input type="textarea" v-model="testingService.delivery_instructions_other"></el-input>
                        </template>
                        <template v-else>
                            <div style="display:block;">
                                Other:<br>
                                <div style="width: 21.0em; background-color: #e4e4e4;">{{ testingService.delivery_instructions_other }}</div>
                            </div>
                        </template>
                    </template>
                    <br><br>

                    <template v-if="editAll || editingTestingService">
                        Upload Exam Correction:<br>
                        <el-upload drag action="/api/testing-service/add-exam-correction/" ref="examCorrectionUpload" :auto-upload="false"
                                   :file-list="fileList" :on-remove="onRemove" :on-change="onChange" :before-remove="beforeRemove">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <template v-if="testingService.exam_correction_document_ids && testingService.exam_correction_document_ids.length">
                            <template v-for="(id, index) in testingService.exam_correction_document_ids">
                                Exam Correction {{ index + 1 }}: <a :href="'/api/document/download/' + id" target="_blank" :key="id+index">Exam Correction {{ index + 1 }}</a><br :key="id">
                            </template>
                        </template>
                        <template v-else>No Exam Corrections<br><br></template>
                    </template>

                    <h3>Exam Updates</h3>
                    <template v-if="editAll || editingTestingService">
                        <template v-if="(!testingService.exam_updates || testingService.exam_updates.length == 0)">No Exam Updates<br><br></template>

                        <template v-for="(update, updateIndex) in testingService.exam_updates">
                            <div style="margin-bottom: 5px; word-wrap: break-word" class="exam-update" :key="updateIndex">
                                <el-select size="mini" v-model="update.exam_update_id" style="width:150px">
                                    <el-option v-for="option in examUpdateOptions" :key="option.id" :value="option.id" :label="option.name"></el-option>
                                </el-select><br>
                                <template v-if="showExamUpdateOther(update)">
                                    Other <el-input style="width: 175px;" size="mini" v-model="update.other"></el-input>
                                </template>
                                <template v-if="update.exam_update_id">
                                    <el-input type="textarea" v-model="update.content"></el-input>
                                </template>
                                Upload file<br>
                                <el-upload class="small" drag action="/api/testing-service/add-exam-update/" :auto-upload="false" :file-list="update.documents" :on-remove="function(file, filelist){onExamUpdateRemove(file, fileList, update)}" :on-change="function(file, filelist){onExamUpdateChange(file, fileList, update)}" :before-remove="beforeExamUpdateRemove" :key="updateIndex">
                                    <i class="el-icon-upload"></i>
                                </el-upload>
                                <a style="float:right;" @click="deleteExamUpdate(update)">Delete this exam update</a>
                                <div style="clear:both;"></div><br><hr>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="testingService.exam_updates && testingService.exam_updates.length">
                            <template v-for="update in testingService.exam_updates">
                                <div style="margin-bottom: 5px; word-wrap: break-word" class="exam-update" :key="update.id">
                                    {{ update.name }}<template v-if="update.other">: {{ update.other }}</template><br>
                                    <span class="formatted-text">{{ update.content }}</span>
                                    <template v-for="(doc) in update.documents">
                                        <br :key="doc.id"/><a :key="doc.id +'-a'" :href="'/api/document/download/' + doc.id" target="_blank">Update document {{ doc.asset_file_name }}</a><br :key="doc.id+'-br'">
                                    </template>
                                    <div style="clear:both;"></div><br><hr>
                                </div>
                            </template>
                        </template>
                        <template v-if="(!testingService.exam_updates || testingService.exam_updates.length == 0)">No Exam Updates<br><br></template>
                    </template>
                    <template v-if="editAll || editingTestingService"><a @click="addExamUpdate">[+] Add exam update</a></template>
                </div>

                <div class="panel">
                    <h3>Historical Prof. Contact Info</h3>
                    <template v-if="allowHistoricalContactInfoEditing && (editAll || editingTestingService)">
                        <el-input type="textarea" v-model="testingService.contact_information"></el-input>
                    </template>
                    <template v-else>
                        <div style="width: 17.5em; background-color: #e4e4e4;" class="formatted-text">{{ testingService.contact_information }}</div>
                    </template>
                    (enter as much as necessary)
                    <br><br>
                    <b style="color: #704091;font-size:medium">SIS Instructor Email</b><br>{{ testingService.instructor_email }}<br><br/>

                    <b>Primary Alternate Instructor Email</b><br>
                    <template v-if="editAll || editingTestingService">
                        <el-input size="mini" v-model="testingService.alternate_instructor_email"></el-input>
                    </template>
                    <template v-else>
                        {{ testingService.alternate_instructor_email }}<br>
                    </template>
                    <b>Secondary Alternate Instructor Email</b><br>
                    <template v-if="editAll || editingTestingService">
                        <el-input size="mini" v-model="testingService.secondary_alternate_instructor_email"></el-input>
                    </template>
                    <template v-else>
                        {{ testingService.secondary_alternate_instructor_email }}<br>
                    </template>
                    <b>Instructor Primary Phone #</b><br>
                    <template v-if="editAll || editingTestingService">
                        <el-input size="mini" v-model="testingService.instructor_primary_number"></el-input>
                    </template>
                    <template v-else>
                        {{ testingService.instructor_primary_number }}<br>
                    </template>
                    <b>Instructor Secondary Phone #</b><br>
                    <template v-if="editAll || editingTestingService">
                        <el-input size="mini" v-model="testingService.instructor_secondary_number"></el-input>
                    </template>
                    <template v-else>
                        {{ testingService.instructor_secondary_number }}<br>
                    </template>
                    <!-- Are you sure you wish to notify all exam students of their pending exam? -->
                    <h4 v-if="testingService.students_not_opted_out"><a @click="sendExamTimeReminderToAllStudents()">Email All Students of Exam Start Time</a></h4>
                    <h4 v-if="testingService.total_non_cancelled != 0 && testingService.students_not_opted_out_not_cancelled && testingService.students_not_opted_out_not_cancelled.length != 0"><a :href="'/group-email/compose/?exam_id='+testingService.id">Email All Students (editable text)</a></h4>
                    <h4 v-if="testingService.total_non_cancelled == 0 && testingService.total_cancelled_not_opted_out > 0"><a :href="'/group-email/compose/?exam_id='+testingService.id+'&includeCancelled'">Email All Students (editable text)</a></h4>

                    <!-- Are you sure you wish to send a request for the exam? -->
                    <template v-if="!testingService.exam_received  && !testingService.opt_professor_out">
                        <template v-if="testingService.alternate_instructor_email && testingService.alternate_instructor_email != ''">
                          <template v-if="!testingService.opt_teaching_assistants_out_of_group_emails && testingService.teaching_assistants">
                            <h4><a @click="sendAutomaticRequest(true, false, true)">Send automatic request/reminder for exam to instructor (UNI+Primary Alternate Email) and TAs</a></h4>
                          </template>
                          <template v-else>
                            <h4><a @click="sendAutomaticRequest(true, false, false)">Send automatic request/reminder for exam to instructor (UNI+Primary Alternate Email)</a></h4>
                          </template>
                        </template>
                        <template v-else>
                           <template v-if="!testingService.opt_teaching_assistants_out_of_group_emails && testingService.teaching_assistants">
                             <h4><a @click="sendAutomaticRequest(false, false, true)">Send automatic request/reminder for exam to instructor (UNI) and TAs</a></h4>
                           </template>
                           <template v-else>
                             <h4><a  @click="sendAutomaticRequest(false, false, false)">Send automatic request/reminder for exam to instructor (UNI)</a></h4>
                           </template>
                        </template>

                        <template v-if="testingService.secondary_alternate_instructor_email && testingService.secondary_alternate_instructor_email != ''">
                              <template v-if="!testingService.opt_teaching_assistants_out_of_group_emails && testingService.teaching_assistants">
                                <h4><a @click="sendAutomaticRequest(true, true, true)" style="color:red">Send automatic request/reminder for exam to instructor (UNI, Primary + Secondary Alternate Email) and TAs</a></h4>
                              </template>
                              <template v-else>
                                <h4><a @click="sendAutomaticRequest(true, true, false)" style="color:red">Send automatic request/reminder for exam to instructor (UNI, Primary + Secondary Alternate Email)</a></h4>
                              </template>
                        </template>

                    </template>
                    <template v-if="!testingService.exam_received && testingService.opt_professor_out && !testingService.opt_teaching_assistants_out_of_group_emails && testingService.teaching_assistants">
                         <h4><a @click="sendAutomaticRequestToTAs()">Send automatic request/reminder for exam to TAs</a></h4>
                    </template>

                    <h4><a :href="'/group-email/compose/?course_id=' + testingService.course_id + '&regarding_id=' + testingService.id + '&regarding_class=TestingService'">Email special request (editable text) to instructor</a></h4>

                    <template v-if="testingService.teaching_assistants">
                      <h4><a :href="'/group-email/compose/?course_id=' + testingService.course_id + '&regarding_id=' + testingService.id + '&regarding_class=TestingService&ccTAs=true'">Email special request (editable text) to instructor and TAs</a></h4>

                    </template>

                    <h4><a @click="showCancelExamSection = !showCancelExamSection">Cancel all student exams</a></h4>
                    <div v-if="showCancelExamSection" style="display:block">
                      Cancellation Reason
                      <el-select size='mini' v-model="cancellationReason"><el-option v-for="reason in cancellationReasons" :key="reason" :label="reason" :value="reason"></el-option></el-select>
                      Cancellation Comment
                      <el-input size="mini" v-model="cancellationComment"></el-input>
                      <el-button size="mini" type="primary" @click="cancelExam">Confirm Cancellation</el-button>
                    </div>

                    <h4><a @click="showRescheduleExamSection = !showRescheduleExamSection">Cancel and Reschedule all student exams</a></h4>
                    <div v-if="showRescheduleExamSection" style="display:block">
                        Enter Rescheduled Exam Date and Time<br>
                        <el-form :rules="rules" ref="rescheduleExamForm" :model="rescheduleExam" label-position="left" label-width="90px">
                            <el-form-item label="Exam date:" prop="date"><el-date-picker size="mini" v-model="rescheduleExam.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                            <el-form-item label="Exam time:" prop="start_time"><el-input style="width:100px" size="mini" v-model="rescheduleExam.start_time"></el-input></el-form-item>

                            Reschedule Reason<br/>
                            <el-select size='mini' v-model="examRescheduleReason" style="width: 286px"><el-option v-for="reason in cancellationReasons" :key="reason" :label="reason" :value="reason"></el-option></el-select>
                            <br/>Reschedule Comment<br/>
                            <el-input size="mini" v-model="examRescheduleComment" style="width: 286px"></el-input>
                        </el-form>
                        <el-button size="mini" type="primary" @click="cancelAndRescheduleExam">Confirm Reschedule</el-button>
                    </div>
                    <h4><a style="color:green;" @click="showScheduleExamSection = !showScheduleExamSection">Schedule exam for all students on additional date(s)</a></h4>
                    <div v-if="showScheduleExamSection && num_active > 0" style="display:block">
                        Enter New Date and Time<br>
                        <el-form v-for="(startTime, index) in scheduleExam.start_times" :rules="rules" :model="startTime" label-position="left" label-width="90px" :key="index">
                            <el-form-item label="Exam date:" prop="date"><el-date-picker size="mini" v-model="startTime.date" type="date" value-format="yyyy-MM-dd"></el-date-picker></el-form-item>
                            <el-form-item label="Exam time:" prop="start_time"><el-input style="width:100px" size="mini" v-model="startTime.start_time"></el-input></el-form-item><br>
                        </el-form>
                        <el-button size="mini" type="primary" @click="scheduleAdditionalExams">Confirm New Exam(s)</el-button> &nbsp;&nbsp;&nbsp; <a @click="addAdditionalExam">[+] Add another</a>
                    </div>
                    <div v-if="showScheduleExamSection && num_active == 0" style="display:block">
                      <p>There are no non-cancelled student exams that can be scheduled for additional exams</p>
                    </div>
                    <el-button v-if="editAll || editingTestingService" size="mini" type="primary" @click="onSubmit">Save changes</el-button>
                </div>

                <div style="width: 415px; float: right; min-height: 150px;">
                    <h3>{{ testingService.course_number_and_section }}</h3>
                    <template v-if="testingService.teaching_assistants && testingService.teaching_assistants.length">
                        <template v-if="editAll || editingTestingService">
                            <el-checkbox v-model="testingService.manual_opt_tas_out">Opt teaching assistants out of group emails?</el-checkbox><br><br>
                        </template>
                        <template v-else>
                            <template v-if="testingService.opt_teaching_assistants_out_of_group_emails"><span class="exam-checkbox">&#9746;</span></template><template v-else><span class="exam-checkbox">&#9744;</span></template>Opt teaching assistants out of group emails?<br>
                            <template v-if="testingService.opt_teaching_assistants_out_of_group_emails">
                                <template v-if="testingService.manual_opt_tas_out"><span class="red">Manually opted out</span><br/></template>
                                <template v-if="testingService.is_placeholder_opted_out"><span class="red">Placeholder opted out</span><br/></template>
                            <template v-if="testingService.is_virtual_opted_out"><span class="red">Virtual exam opted out</span><br/></template>
                            </template>
                        </template>
                        <a v-if="!showAddTaSection" @click="showAddTaSection = true">Add TA Information</a>
                        <table><tbody>
                            <tr v-for="(ta, taIndex) in testingService.teaching_assistants" :key="taIndex">
                                <th rowspan="1">TA:</th>
                                <th>{{ ta.first_name }} {{ ta.last_name }}</th>
                                <td><a :href="'/group-email/compose?service_provider_id=' + ta.id + '&regarding_class=TestingService&regarding_id=' + testingService.id">{{ ta.email }}</a><br>{{ ta.phone }}</td>
                                <td style="width:100px">
                                    <a :href="'/service-provider/show/' + ta.id">Show TA Record</a><br>
                                    <a @click="showEditTa(ta)">Edit</a><br>
                                    <a @click="removeTa(ta.id)">Remove</a>
                                </td>
                            </tr>
                        </tbody></table>
                    </template>
                    <template v-else>
                        <a v-if="!showAddTaSection" @click="showAddTaSection = true">Add TA Information</a>
                        <table><tbody><tr><td>No TAs</td></tr></tbody></table>
                    </template>
                </div>

                <br clear="both">
                <template v-if="testingService.exam_comments && testingService.exam_comments.length">
                    <div style="float:left">
                        Exam Comments
                        <table style="width:600px;" id="comment-table"><tbody>
                            <tr v-for="comment in testingService.exam_comments" style="height:35px" :key="comment.id">
                                <td @click="comment.showEditField = true" @mouseover="comment.isHovered = true" @mouseleave="comment.isHovered = false">
                                    <template v-if="comment.showEditField">
                                        <el-input size="mini" type="textarea" :rows="2" v-model="comment.content"></el-input>
                                        <el-button size="mini" type="primary" @click="updateComment(comment)">Save</el-button> <a @click="getTestingService">cancel</a>
                                    </template>
                                    <template v-else><span :class="{ hover: comment.isHovered }" v-html="comment.contentHtml"/></template>
                                </td>
                                <td style="width:165px;">{{ comment.name }}<template v-if="comment.is_automatic_comment"><br><span class="text-italic">(automatically generated)</span></template></td>
                                <td style="width:150px; position: relative;">
                                    {{ comment.created_at | formatDateTimeMDYhma }}
                                    <div style="position: absolute; bottom: 0px; right: 5px;"><a @click="comment.showEditField = true">Edit Exam Comment</a></div>
                                </td>
                            </tr>
                        </tbody></table>
                    </div>
                </template>

              <br clear="both">
              <template v-if="testingService.course_comments && testingService.course_comments.length">
                <div style="float:left">
                  Course Comments
                  <table style="width:600px;" id="comment-table"><tbody>
                  <tr v-for="comment in testingService.course_comments" style="height:35px" :key="comment.id">
                    <td @click="comment.showEditField = true" @mouseover="comment.isHovered = true" @mouseleave="comment.isHovered = false">
                      <template v-if="comment.showEditField">
                        <el-input size="mini" type="textarea" :rows="2" v-model="comment.content"></el-input>
                        <el-button size="mini" type="primary" @click="updateCourseComment(comment)">Save</el-button> <a @click="getTestingService">cancel</a>
                      </template>
                      <template v-else><span :class="{ hover: comment.isHovered }" v-html="comment.contentHtml"/></template>
                    </td>
                    <td style="width:165px;">{{ comment.name }}</td>
                    <td style="width:150px; position: relative;">
                      {{ comment.created_at | formatDateTimeMDYhma }}
                      <template v-if="canEditCourseComments">
                        <div style="position: absolute; bottom: 0px; right: 5px;"><a @click="comment.showEditField = true">Edit Course Comment</a></div>
                      </template>
                    </td>
                  </tr>
                  </tbody></table>
                </div>
              </template>

                <template v-if="showEditTaSection">
                    <div style="float: right; width: 415px;">
                        Edit TA: <el-input size="mini" v-model="editTa.first_name" placeholder="First Name" style="width:100px;"></el-input> <el-input size="mini" v-model="editTa.last_name" placeholder="Last Name" style="width:100px;"></el-input><br>
                        <el-input size="mini" v-model="editTa.email" placeholder="Email" style="width:275px;"></el-input><br>
                        <el-input size="mini" v-model="editTa.phone" placeholder="Phone" style="width:175px;"></el-input><br><br>
                        <el-button size="mini" type="primary" @click="updateTa">Save changes</el-button>
                    </div>
                    <br clear="both">
                </template>
                <template v-if="showAddTaSection">
                    <div style="float: right; width: 415px;">
                        Add TA: <el-input size="mini" v-model="addTa.first_name" placeholder="First Name" style="width:100px;"></el-input> <el-input size="mini" v-model="addTa.last_name" placeholder="Last Name" style="width:100px;"></el-input><br>
                        <el-input size="mini" v-model="addTa.email" placeholder="Email" style="width:275px;"></el-input><br>
                        <el-input size="mini" v-model="addTa.phone" placeholder="Phone" style="width:175px;"></el-input><br><br>
                        <el-button size="mini" type="primary" @click="createTa">Save changes</el-button>
                    </div>
                </template>
                <br clear="both">
            </div>

            <template v-for="enrollment in examEnrollments">
                <testing-exam-enrollment  :key="enrollment.id" :enrollment="enrollment" :cancellationReasons="cancellationReasons"
                                          :examUpdateOptions="studentExamUpdateOptions" :editAll="editAll"
                                          :examType="testingService.exam_type" :virtualExamType="virtualExamType"
                                          v-on:refreshenrollments="refreshData"
                                          v-on:submitenrollments="onSubmit"
                                          v-on:ready="onEnrollmentReady"></testing-exam-enrollment>
            </template>

            <template v-if="testingService.can_be_deleted">
                <template v-if="editAll">
                    <el-button size="mini" type="primary" @click="deleteExam">Delete exam</el-button>
                </template>
                <template v-else>
                    <p>This exam can be deleted here by clicking &quot;<a @click="editAll = true">Edit entire page</a>&quot;.</p>
                </template>
            </template>
            <template v-else>
                <p>This exam can't be deleted because it's in the past, or has uploaded forms. An uploaded form can be removed only by removing the student with the uploaded form from the exam.</p>
            </template>

            <h3>The following students are eligible for this exam but not enrolled in it</h3>
            <ul class="student section">
                <li v-for="student in eligibleStudents" :key="student.id">{{ student.student_name }} - {{ student.uni }} ({{ student.status_display }}) - <a @click="addStudentToExam(student.id)">Add to Exam</a></li>
            </ul>

            <a @click="showHistory = !showHistory">Show / Hide history</a>
            <div v-if="showHistory && history && history.length">
                <h2>History</h2>
                <table class="display"><tbody>
                    <tr v-for="(item, index) in history" :key="index">
                        <th>{{ item.created_at | formatDateTimeMDYhma }}</th>
                        <td>{{ item.description_of_change }} by {{ item.user_name }}</td>
                    </tr>
                </tbody></table>
            </div>

            <student-emails :emails="emails" :title="'Emails Sent Regarding this Exam'" :showCCField="true"></student-emails>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    import showdown from 'showdown'
    const converter = new showdown.Converter()
    converter.setOption('simpleLineBreaks',true)


    export default {
        metaInfo () {
            return {
                title: "Exam for "+ this.testingService.class_name + " in " + this.testingService.semester_name
            }
        },
        components: {
        },
        data () {
            var validateTime = (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('Please input the time'));
                } else if(!moment(value, ["h:mm a","h:mma", "h a", "ha"], true).isValid()) {
                    callback(new Error('Enter time - HH:MM AM or HH AM'));
                } else {
                    callback();
                }
            };
            return {
              disableLink: false,
                testingService: {},
                allowHistoricalContactInfoEditing: false,
                editingTestingService: false,
                editAll: false,
                examEnrollments: [],
                eligibleStudents: [],
                history: [],
                emails: [],
                fileList: [],
                deliveryInstructions: [],
                equipmentOptions: [],
                permissionOptions: [],
                listeningFilmOptions: [],
                calculatorOptions: [],
                cancellationReasons: [],
                computerTypeOptions: [],
                examRequestedFromProfessionOptions: [],
                examUpdateOptions: [],
                studentExamUpdateOptions: [],
                noExamUpdates: false,
                showHistory: false,
                showEditTaSection: false,
                examRescheduleReason: null,
                examRescheduleComment: null,
                cancellationReason: null,
                cancellationComment: null,
                showRescheduledExamReceivedMessage: false,
                editTa: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    phone: null
                },
                showAddTaSection: false,
                addTa: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    phone: null
                },
                showRescheduleExamSection: false,
                showCancelExamSection: false,
                rescheduleExam: {
                    date: null,
                    start_time: null
                },
                showScheduleExamSection: false,
                scheduleExam: {
                    start_times: [
                        {date: null, start_time: null}
                    ]
                },
                rules: {
                    date: [
                        {required: true, message: 'Date is required'}
                    ],
                    start_time: [
                        { validator: validateTime, trigger: 'blur' }
                    ],
                    start_time_input: [
                        { validator: validateTime, trigger: 'blur' }
                    ]
                },
                num_scheduled: 0,
                num_active: 0,
                num_cancelled: 0,
                num_placeheld: 0,
                num_not_scheduled: 0,
                enrolled_student_ids: [],
                showFlashDriveMessage: false,
                showLargePrintMessage: false,
                num_enrollments_ready:0,
                loading: false,
                examTypes: [
                  'In-person, proctored by DS',
                  'Virtual',
                  'To be determined'
                ],
               virtualExamTypes: [
                  'Proctored by DS',
                  'Proctored by Professor',
                  'Non-proctored exam',
                  'Virtual platform/proctoring- TBD by prof'
               ],
              virtualExamType: '',
              canEditCourseComments: false,
              originalExamType: '',
              originalVirtualExamType: '',
            }
        },
        computed: {
            pastWeekServicesListStyle: function(){
                if(this.testingService.next_weeks_services && this.testingService.next_weeks_services.length){
                    return {margin: '0 0 5px 120px'};
                }
                return {margin: '0 0 5px 260px'}
            },
            pastWeekServicesSpanStyle: function(){
                if(this.testingService.next_weeks_services && this.testingService.next_weeks_services.length){
                    return {marginLeft: '10px'};
                }
                return {marginLeft: 0}
            },

          showCampusDeliveryLocation: function(){
            if(this.deliveryInstructions && this.testingService.delivery_instruction_ids){
              var obj = this.deliveryInstructions.find(o => o.name === 'Campus Delivery');
              if(obj){
                return this.testingService.delivery_instruction_ids.indexOf(obj.id) !== -1;
              }
              return false;
            }
            return false;
          },
          showSpecialDeliveryLocation: function(){
            if(this.deliveryInstructions && this.testingService.delivery_instruction_ids){
              var obj = this.deliveryInstructions.find(o => o.name === 'Special Delivery');
              if(obj){
                return this.testingService.delivery_instruction_ids.indexOf(obj.id) !== -1;
              }
              return false;
            }
            return false;
          },
            showUrgentDeliveryInstructions: function(){
                if(this.deliveryInstructions && this.testingService.delivery_instruction_ids){
                    var urgentDeliveryObj = this.deliveryInstructions.find(o => o.name === 'Urgent Delivery');
                    if(urgentDeliveryObj){
                        return this.testingService.delivery_instruction_ids.indexOf(urgentDeliveryObj.id) !== -1;
                    }
                    return false;
                }
                return false;
            },
            showDeliveryInstructionsOther: function(){
                if(this.deliveryInstructions && this.testingService.delivery_instruction_ids){
                    var otherObj = this.deliveryInstructions.find(o => o.name === 'Other');
                    if(otherObj){
                        return this.testingService.delivery_instruction_ids.indexOf(otherObj.id) !== -1;
                    }
                }
                return false;
            }
        },
        methods: {
            onEnrollmentReady: function(){
              this.num_enrollments_ready += 1;
              // console.log(th)
              if(this.num_enrollments_ready == this.examEnrollments.length){

                  // setTimeout(() => {
                      var hash = window.location.hash.substr(1);
                      if(hash){
                          var i = hash.indexOf("?");
                          if(i != -1){
                              hash = hash.slice(0, i);
                          }
                          document.getElementById(hash).scrollIntoView();
                      }
                  // }, 2000);
              }
            },
            load: function(){
                this.testingService.id = location.pathname.split('/testing/show/')[1];
                if(this.testingService.id){
                    this.testingService.id = parseInt(this.testingService.id);
                    this.refreshData();
                }

                axios.get("/api/testing-service/exam-form-options/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.deliveryInstructions = response.data.delivery_instructions;
                        this.equipmentOptions = response.data.equipment_options;
                        this.permissionOptions = response.data.permission_options;
                        this.listeningFilmOptions = response.data.listening_film_options;
                        this.calculatorOptions = response.data.calculator_options;
                        this.examRequestedFromProfessionOptions = response.data.exam_requested_from_professor_options;
                        this.computerTypeOptions = response.data.exam_equipment_computer_options;
                        this.examUpdateOptions = response.data.exam_update_options;
                        this.studentExamUpdateOptions = response.data.student_exam_update_options;
                    });

                axios.get("/api/testing-service/testing-enrollment-cancellation-reasons/")
                    .then(response => {
                        this.cancellationReasons = response.data.reasons;
                    });

              axios.get("/api/simple/check-permissions/", {params: {permissions: ['can_edit_course_comments']}})
                  .then(response => {
                    this.canEditCourseComments = response.data.permissions.can_edit_course_comments;
                  }) ;
            },
            getTestingService: function(){
                axios.get("/api/testing-service/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.testingService = null;
                        this.testingService = response.data.testing_service;
                        this.allowHistoricalContactInfoEditing = response.data.allow_historical_contact_info_editing;

                        this.showRescheduledExamReceivedMessage = response.data.show_rescheduled_exam_received_message;

                        this.originalExamType = this.testingService.exam_type;
                        this.originalVirtualExamType = this.testingService.virtual_exam_type;

                        if(this.testingService.exam_comments){
                            for(var i = 0; i < this.testingService.exam_comments.length; i++){
                                this.$set(this.testingService.exam_comments[i], 'showEditField', false);
                                this.$set(this.testingService.exam_comments[i], 'isHovered', false);
                                this.$set(this.testingService.exam_comments[i], 'contentHtml', converter.makeHtml(this.testingService.exam_comments[i].content.trim()));
                            }
                        }

                        if(this.testingService.course_comments){
                          for(i = 0; i < this.testingService.course_comments.length; i++){
                            this.$set(this.testingService.course_comments[i], 'showEditField', false);
                            this.$set(this.testingService.course_comments[i], 'isHovered', false);
                            this.$set(this.testingService.course_comments[i], 'contentHtml', converter.makeHtml(this.testingService.course_comments[i].content.trim()));
                          }
                        }

                        if(this.testingService.start_time){
                            this.$set(this.testingService, 'date', new Date(this.testingService.start_time));
                            this.$set(this.testingService, 'start_time_input', moment(this.testingService.start_time).format("hh:mmA"));
                        }

                        if(this.testingService.length){
                            var h = Math.floor(this.testingService.length / 3600);
                            var m = Math.floor((this.testingService.length - (3600*h)) / 60);
                            this.$set(this.testingService, 'length_hours', h);
                            this.$set(this.testingService, 'length_minutes', m);
                        }

                        this.fileList = [];
                        if(this.testingService.exam_correction_document_ids){
                            for(i = 0; i < this.testingService.exam_correction_document_ids.length; i++){
                                var id = this.testingService.exam_correction_document_ids[i];
                                var name = 'Exam Correction ' + (i + 1);
                                this.fileList.push({name: name, id: id});
                            }
                        }

                        if(!this.testingService.exam_updates) this.$set(this.testingService, 'exam_updates', []);

                        if(this.testingService.virtual_exam_type){
                          this.virtualExamType = this.testingService.virtual_exam_type;
                        }


                        for(i = 0; i < this.testingService.exam_updates.length; i++){
                            var update = this.testingService.exam_updates[i];
                            if(update.documents){
                                for(var j = 0; j < update.documents.length; j++){
                                    this.$set(update.documents[j],'name', update.documents[j].asset_file_name);
                                }
                            } else {
                                this.$set(update, 'documents', []);
                            }
                        }
                    });
            },
            getExamEnrollments: function(){
                this.examEnrollments = [];
                axios.get("/api/testing-service/testing-service-enrollments/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.examEnrollments = response.data.rows;


                        this.num_scheduled = 0;
                        this.num_active = 0;
                        this.num_cancelled = 0;
                        this.num_placeheld = 0;


                        for(var i=0; i < this.examEnrollments.length; i++){
                           var enrollment = this.examEnrollments[i];

                           this.enrolled_student_ids.push(enrollment.student_id);

                           if(enrollment.status == 'active' && !enrollment.is_placeholder_exam){
                               this.num_scheduled += 1;
                               this.num_active += 1;
                           } else if(enrollment.status == 'active' && enrollment.is_placeholder_exam){
                               this.num_placeheld += 1;
                               this.num_active += 1;
                           } else if(enrollment.status == 'cancelled'){
                               this.num_cancelled += 1;
                           }


                           if(enrollment.status == 'active' && enrollment.enrollment_accommodations){
                               for(var j=0; j<enrollment.enrollment_accommodations.length; j++){
                                   var enrollmentAccommodation = enrollment.enrollment_accommodations[j];
                                   if(enrollmentAccommodation.name == "Electronic copy of exam provided to student"){
                                       this.showFlashDriveMessage = true;
                                   }
                                   if(enrollmentAccommodation.name.indexOf("Large print copy of quizzes and exams.") == 0){
                                       this.showLargePrintMessage = true;
                                   }
                               }
                           }


                            enrollment.comments_html = converter.makeHtml(enrollment.comments);
                        }


                    });
            },
            getEligibleStudents: function(){
                axios.get("/api/testing-service/eligible-students/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.eligibleStudents = response.data.rows;
                    });
            },
            getHistory: function(){
                axios.get("/api/testing-service/history/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.history = response.data.rows;
                    });
            },
            getGroupEmails: function(){
                axios.get("/api/group-email/group-emails-regarding-generic/", {params: {id: this.testingService.id, type: "TestingService"}})
                    .then(response => {
                        this.emails = response.data.rows;
                    });
            },
            deleteExam: function(){
                this.$confirm('This will permanently delete this exam record. Are you sure?')
                    .then(() => {
                        axios.delete("/api/testing-service/", {params: {id: this.testingService.id}})
                            .then(response => {
                                if(response.data.success){
                                    //window.location = '/testing?start_date=' + moment().startOf('isoWeek').format('YYYY-MM-DD') + '&end_date=' + moment().endOf('isoWeek').format('YYYY-MM-DD');
                                    this.$router.push({
                                      path: '/testing',
                                      query: {
                                        start_date: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                                        end_date: moment().endOf('isoWeek').format('YYYY-MM-DD')
                                    }});
                                }
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            uploadStudentExamDocument: function(fileList, documentType, enrollmentId){

                return new Promise((resolve, reject) => {
                    if(fileList.length && fileList[0].raw){
                        var file = fileList[0];
                        var data = new FormData();
                        data.append('file', file.raw);
                        if(file.id){
                            data.append('id', file.id);

                            axios.put('/api/testing-service/update-student-exam-document/', data)
                                .then(response => {
                                    if(response.data.success){
                                        resolve();
                                    } else {
                                        this.$alert("Error: "+response.data.message);
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                    reject(error);
                                });
                        } else {
                            data.append('id', enrollmentId)
                            data.append('document_type', documentType);

                            axios.post('/api/testing-service/upload-student-exam-document/', data)
                                .then(response => {
                                    if(response.data.success){
                                        resolve();
                                    } else {
                                        this.$alert("Error: "+response.data.message);
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                    reject(error);
                                });
                        }
                    } else {
                        resolve();
                    }
                });
            },
            uploadExamDocument: function(examDocumentFileList){

            },
            uploadDeliveryDocument: function(deliveryDocumentFileList){

            },
            onSubmit: function(){
                this.$message.closeAll();
                if(this.hasEnrollmentErrors()){
                    this.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: 'One or more errors prevented this form from being saved. Please correct all errors and submit again.'
                    });
                } else {
                    this.loading=true;
                    if(this.editAll || this.editingTestingService){
                        this.$refs["testingServiceForm"].validate((valid) => {
                            if(valid) {
                              //format start_time and length
                              this.testingService.length = null;
                              if (this.testingService.length_hours) {
                                this.testingService.length = this.testingService.length_hours * 3600;
                              }
                              if (this.testingService.length_minutes) {
                                if (this.testingService.length) {
                                  this.testingService.length += this.testingService.length_minutes * 60;
                                } else {
                                  this.testingService.length = this.testingService.length_minutes * 60;
                                }
                              }
                              this.testingService.start_time = moment(this.testingService.date).format('YYYY-MM-DD') + 'T' + moment(this.testingService.start_time_input, ["h:mm a", "h:mma", "h a", "ha"], true).format('HH:mm');

                              if (this.virtualExamType !== '') {
                                this.testingService.virtual_exam_type = this.virtualExamType
                              } else {
                                this.testingService.virtual_exam_type = null
                              }

                              if (!this.testingService.exam_type) {
                                this.$message({
                                  showClose: true,
                                  duration: 20000,
                                  type: 'error',
                                  message: 'Please select an exam type.'
                                });

                                this.loading=false;
                              } else if (this.testingService.exam_type === 'Virtual' && (!this.testingService.virtual_exam_type || this.testingService.virtual_exam_type === '')) {
                                this.$message({
                                  showClose: true,
                                  duration: 20000,
                                  type: 'error',
                                  message: 'Please select a virtual exam type.'
                                });

                                this.loading=false;
                              } else {

                                axios.put('/api/testing-service/', this.testingService)
                                    .then(response => {
                                      if(response.data.success){
                                        Promise.all([this.uploadExamCorrections(), this.uploadExamUpdates(),
                                          this.submitExamEnrollments()]).then(() => { this.refreshData(); });
                                      }
                                    });
                              }

                            } else {
                                this.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'error',
                                    message: 'One or more errors prevented this form from being saved. Please correct all errors and submit again.'
                                });

                                this.loading=false;
                            }
                        });
                    } else {
                        this.submitExamEnrollments().then(() => { this.refreshData(); });
                    }
                }
            },
            hasEnrollmentErrors: function() {
                for(var i = 0; i < this.examEnrollments.length; i++){
                    if(this.examEnrollments[i].editMode && this.examEnrollments[i].hasFormErrors){
                        return true;
                    }
                }
                return false;
            },
            submitExamEnrollments: function(){
                return new Promise((resolve, reject) => {
                    if(this.examEnrollments && this.examEnrollments.length){

                        var total = 0;
                        var finished = [];
                        if(this.editAll){
                            total = this.examEnrollments.length;
                        } else {
                            for(var i = 0; i < this.examEnrollments.length; i++){
                                if(this.examEnrollments[i].editMode){
                                    total += 1;
                                }
                            }
                        }
                        if(total){
                            for(i = 0; i < this.examEnrollments.length; i++){
                                var enrollment = this.examEnrollments[i];
                                if(this.editAll || enrollment.editMode){
                                    var totalComputers = 0;
                                    var totalRestBreaks = 0;
                                    var totalExtensions = 0;

                                    enrollment.accommodation_ids.forEach(function(x){
                                        var accommodation = enrollment.enrollment_accommodations ? enrollment.enrollment_accommodations.find(o => o.id === x) : null;
                                        if(!accommodation){
                                            accommodation = enrollment.available_accommodations.find(o => o.id === x);
                                        }

                                        if(accommodation.name.indexOf("Use of computer") != -1 ){
                                            totalComputers += 1;
                                        }
                                        if(accommodation.name.indexOf("Rest Break") != -1 ){
                                            totalRestBreaks += 1;
                                        }
                                        if(accommodation.name.indexOf("Extended") != -1 ){
                                            totalExtensions += 1;
                                        }
                                    });

                                    if(totalComputers > 1){
                                        this.$alert("Error: You may only select 1 computer option per student");

                                      this.loading = false;
                                       resolve();
                                    } else if(totalRestBreaks > 1 || totalExtensions > 1){
                                        this.$alert("Error: You may only select 1 rest break and 1 extended time option per student");

                                      this.loading = false;
                                        resolve();
                                    } else {

                                        enrollment.time_taken = null;
                                        if(enrollment.proctoring_session_start_time && enrollment.completion_time){
                                            enrollment.time_taken = (moment(enrollment.completion_time, ["h:mm a","h:mma", "h a", "ha"], true) - moment(moment(enrollment.proctoring_session_start_time).format('HH:mm'), "HH:mm")) / 1000;
                                        }

                                        this.updateEnrollment(enrollment, finished, total, resolve);
                                    }



                                }
                            }
                        } else {
                            this.loading = false;
                            window.scrollTo(0,0);
                            resolve();
                        }
                    } else {
                        this.loading = false;
                        window.scrollTo(0,0);
                        resolve();
                    }
                });
            },
            updateEnrollment: function(enrollment, finished, total, resolve){

                var studentExamUpdates = enrollment.student_exam_updates;
                var examDocumentFileList = enrollment.examDocumentFileList;
                var deliveryDocumentFileList = enrollment.deliveryDocumentFileList;
                var tarfDocumentFileList = enrollment.tarfDocumentFileList;
                var enrollmentId = enrollment.id;

                if(this.originalExamType !== "Virtual" && this.testingService.exam_type === "Virtual") {

                  enrollment.proctoring_session_id = null;
                } else if(this.testingService.exam_type === 'Virtual'){
                  if(this.originalVirtualExamType !== this.testingService.virtual_exam_type && this.testingService.virtual_exam_type !== 'Proctored by DS'){
                    enrollment.proctoring_session_id = null;
                  }

                  // // eslint-disable-next-line
                  // console.log("original: " +this.originalVirtualExamType);
                  // // eslint-disable-next-line
                  // console.log("current: " +this.testingService.virtual_exam_type);
                }

                axios.put('/api/testing-service/update-testing-service-enrollment/', enrollment)
                    .then(response => {
                        if(response.data.success){
                            Promise.all([this.uploadStudentExamUpdates(studentExamUpdates),
                                this.uploadStudentExamDocument(tarfDocumentFileList, 'TARF Document', enrollmentId),
                                this.uploadStudentExamDocument(examDocumentFileList, 'Exam Document', enrollmentId),
                                this.uploadStudentExamDocument(deliveryDocumentFileList, 'Delivery Verification Document',enrollmentId)])
                                .then(() => {
                                    finished.push(enrollment);
                                    if(finished.length == total){
                                        this.loading = false;
                                        window.scrollTo(0,0);

                                        this.getHistory()
                                        resolve();
                                    }
                                });
                        }
                    });
            },
            updateComment: function(comment){
                axios.put("/api/testing-service/update-exam-comment/", {id: comment.id, content: comment.content})
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            updateCourseComment: function(comment){
                axios.put("/api/testing-service/update-course-comment/", {id: comment.course_comment_id,
                  content: comment.content,
                  service_id: comment.service_id})
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            formatLength: function(seconds){
                var h = Math.floor(seconds / 3600);
                var m = Math.floor((seconds - (3600*h)) / 60);
                return h + " h " + m + " m"
            },
            cancelExam: function() {
              axios.post('/api/testing-service/cancel-exam/',
                  {
                    id: this.testingService.id,
                    cancellation_reason: this.cancellationReason,
                    cancellation_comment: this.cancellationComment
                  })
                  .then(response => {
                    if(response.data.success){
                      this.refreshData();
                      this.cancellationReason = null
                      this.cancellationComment = null
                      this.showCancelExamSection = false
                    }
                  })
            },
            cancelAndRescheduleExam: function(){
                this.$message.closeAll();
                this.$refs["rescheduleExamForm"].validate((valid) => {
                    if(valid){
                        var params = {};
                        params.id = this.testingService.id;
                        params.start_time = moment(this.rescheduleExam.date).format('YYYY-MM-DD') + 'T' + moment(this.rescheduleExam.start_time, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm');
                        params.reschedule_reason = this.examRescheduleReason;
                        params.reschedule_comment = this.examRescheduleComment;

                        axios.post('/api/testing-service/cancel-and-reschedule-exam/', params)
                            .then(response => {
                                if(response.data.success){
                                    this.refreshData();
                                }
                            });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: 'Please enter a valid date and time and submit this form again.'
                        });
                    }
                });
            },
            addAdditionalExam: function(){
                this.scheduleExam.start_times.push({date: null, start_time: null});
            },
            scheduleAdditionalExams: function(){
                var formsAreValid = true;
                var params = {};
                params.id = this.testingService.id;
                params.start_times = [];

                for(var i = 0; i < this.scheduleExam.start_times.length; i++){
                    if(this.scheduleExam.start_times[i].date && this.scheduleExam.start_times[i].start_time){
                        if(moment(this.scheduleExam.start_times[i].start_time, ["h:mm a","h:mma", "h a", "ha"], true).isValid()){
                            params.start_times.push({start_time: moment(this.scheduleExam.start_times[i].date).format('YYYY-MM-DD') + 'T' + moment(this.scheduleExam.start_times[i].start_time, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm')});
                        } else {
                            formsAreValid = false;
                        }
                    } else {
                        formsAreValid = false;
                    }
                }

                if(formsAreValid){
                    axios.post('/api/testing-service/schedule-additional-exams/', params)
                        .then(response => {
                            if(response.data.success){
                                this.refreshData();
                            }
                        });
                } else {
                    this.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: 'One or more errors prevented this form from being saved. Please correct all errors and submit again.'
                    });
                }
            },
            showEditTa: function(ta){
                this.editTa = ta;
                this.showEditTaSection = true;
            },
            createTa: function(){
                this.addTa.service_id = this.testingService.id;
                axios.post('/api/testing-service/add-ta/', this.addTa)
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            updateTa: function(){
                axios.put('/api/testing-service/update-ta/', this.editTa)
                    .then(response => {
                        if(response.data.success){
                            this.refreshData();
                        }
                    });
            },
            removeTa: function(id){
                this.$confirm('Are you sure you want to delete this TA from this exam?')
                    .then(() => {
                        axios.put("/api/testing-service/remove-ta/", {service_id: this.testingService.id, service_provider_id: id})
                            .then(response => {
                                if(response.data.success){
                                    this.refreshData();
                                }
                            });
                    }).catch(() => {
                        // do nothing
                    });
            },
            addStudentToExam: function(id){
              if(!this.disableLink){
                this.disableLink = true;
                axios.post('/api/testing-service/add-students-to-exam/', {service_id: this.testingService.id, student_ids: [id]})
                    .then(response => {
                      if(response.data.success){
                        this.refreshData();
                      }
                    });
              }
            },
            toggleExamPermissionsCheckbox: function(val, option){
                if(val){
                    this.testingService.exam_permission_ids.push(option.id);
                    if(option.name == 'PowerPoint slideshow included with exam'){
                        var computerId = this.equipmentOptions.find(o => o.name === 'Computer').id;
                        if(this.testingService.equipment_ids.indexOf(computerId) == -1){
                            this.testingService.equipment_ids.push(computerId);
                        }
                    }
                } else {
                    this.testingService.exam_permission_ids.splice(this.testingService.exam_permission_ids.indexOf(option.id), 1);
                }
            },
            toggleSingleSidedNotes: function(val){
                if(val){
                    this.testingService.double_sided_notes = false;
                }
            },
            toggleDoubleSidedNotes: function(val){
                if(val){
                    this.testingService.single_sided_notes = false;
                }
            },
            toggleListeningFilmOptionsCheckbox: function(val, name){
                if(name != 'Administer' && val){
                    var cdPlayerId = this.equipmentOptions.find(o => o.name === 'Cd player').id;
                    if(this.testingService.equipment_ids.indexOf(cdPlayerId) == -1){
                        this.testingService.equipment_ids.push(cdPlayerId);
                    }
                }
            },
            toggleEquipmentCheckbox: function(val, option){
                if(val){
                    this.testingService.equipment_ids.push(option.id);
                } else {
                    this.testingService.equipment_ids.splice(this.testingService.equipment_ids.indexOf(option.id), 1);
                }
            },
            beforeRemove: function(file, fileList){
                if(file.id) {
                    return new Promise((resolve, reject) => {
                        this.$confirm('Are you sure you want to delete this file?')
                            .then(() => resolve(true))
                            .catch(() => reject)
                        });
                }
            },
            onRemove: function(file, fileList){
                if(file.id){
                    axios.delete('/api/testing-service/delete-exam-correction/', {params: {id: file.id}})
                        .then(response => {
                            if(response.data.success){
                                this.fileList = fileList;
                            }
                        });
                }
            },
            onChange: function(file, fileList){
                this.fileList = fileList;
            },
            uploadExamCorrections: function() {
                return new Promise((resolve, reject) => {
                    if(this.fileList && this.fileList.length){
                        var total = 0;
                        var finished = [];
                        for(var i = 0; i < this.fileList.length; i++){
                            if(this.fileList[i].raw){
                                total += 1;
                            }
                        }
                        if(total){
                            for(i = 0; i < this.fileList.length; i++){
                                var file = this.fileList[i];
                                if(file.raw){
                                    var data = new FormData();
                                    data.append('id', this.testingService.id);
                                    data.append('file', file.raw);
                                    // eslint-disable-next-line
                                    axios.post('/api/testing-service/add-exam-correction/', data)
                                        .then(response => {
                                            if(response.data.success){
                                                finished.push(file);
                                                if(finished.length == total){
                                                    resolve();
                                                }
                                            } else {
                                                this.$alert("Exam Correction Error: "+response.data.message);
                                                this.fileList.splice(this.fileList.indexOf(file), 1);
                                            }
                                        })
                                        .catch(error => {
                                            // eslint-disable-next-line
                                            console.log(error);
                                            reject(error);
                                        });
                                }
                            }

                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                });
            },
            uploadExamUpdates: function(){
                return new Promise((resolve, reject) => {
                    if(this.testingService.exam_updates && this.testingService.exam_updates.length){
                        var finished = [];
                        for(var i = 0; i < this.testingService.exam_updates.length; i++){
                            var update = this.testingService.exam_updates[i];
                            var data = new FormData();

                            if(update.id){
                                data.append('id', update.id);
                            } else {
                                data.append('service_id', update.service_id);
                            }

                            data.append('exam_update_id', update.exam_update_id);
                            data.append('other', update.other ? update.other : '');
                            data.append('content', update.content ? update.content : '')

                            for(var j = 0; j < update.documents.length; j++){
                                if(update.documents[j].raw){
                                    data.append('file', update.documents[j].raw);
                                }
                            }

                            if(update.id){
                                axios.put('/api/testing-service/update-exam-update/', data)
                                    .then(response => {
                                        if(response.data.success){
                                            finished.push(update);
                                            if(finished.length == this.testingService.exam_updates.length){
                                                resolve();
                                            }
                                        } else {
                                            this.$alert("Error: "+ response.data.message);
                                        }
                                    })
                                    .catch(error => {
                                        // eslint-disable-next-line
                                        console.log(error);
                                        reject(error);
                                    });
                            } else {
                                axios.post('/api/testing-service/add-exam-update/', data)
                                    .then(response => {
                                        if(response.data.success){
                                            finished.push(update);
                                            if(finished.length == this.testingService.exam_updates.length){
                                                resolve();
                                            }
                                        } else {
                                            this.$alert("Error: "+ response.data.message);
                                        }
                                    })
                                    .catch(error => {
                                        // eslint-disable-next-line
                                        console.log(error);
                                        reject(error);
                                    });
                            }
                        }
                    } else {
                        resolve();
                    }
                });
            },
            uploadStudentExamUpdates: function(examUpdates){
                return new Promise((resolve, reject) => {
                    if(examUpdates && examUpdates.length){
                        var finished = [];
                        for(var i = 0; i < examUpdates.length; i++){
                            var update = examUpdates[i];
                            var data = new FormData();

                            if(update.id){
                                data.append('id', update.id);
                            } else {
                                data.append('testing_service_enrollment_id', update.testing_service_enrollment_id);
                            }

                            data.append('student_exam_update_id', update.student_exam_update_id);
                            data.append('other', update.other ? update.other : '');
                            data.append('content', update.content ? update.content : '')

                            for(var j = 0; j < update.documents.length; j++){
                                if(update.documents[j].raw){
                                    data.append('file', update.documents[j].raw);
                                }
                            }

                            if(update.id){
                                axios.put('/api/testing-service/update-student-exam-update/', data)
                                    .then(response => {
                                        if(response.data.success){
                                            finished.push(update);
                                            if(finished.length == examUpdates.length){
                                                resolve();
                                            }
                                        } else {
                                            this.$alert("Error: "+response.data.message);
                                        }
                                    })
                                    .catch(error => {
                                        // eslint-disable-next-line
                                        console.log(error);
                                        reject(error);
                                    });
                            } else {
                                axios.post('/api/testing-service/add-student-exam-update/', data)
                                    .then(response => {

                                        // eslint-disable-next-line
                                        console.log(response);
                                        if(response.data.success){
                                            finished.push(update);
                                            if(finished.length == examUpdates.length){
                                                resolve();
                                            }
                                        } else if(response.data.message === "Undefined") {
                                          resolve();
                                        } else {
                                            this.$alert("Error: "+response.data.message);
                                        }
                                    })
                                    .catch(error => {
                                        // eslint-disable-next-line
                                        console.log(error);
                                        reject(error);
                                    });
                            }
                        }
                    } else {
                        resolve();
                    }
                });
            },
            showExamUpdateOther: function(update){
                if(this.examUpdateOptions){
                    var examUpdateObj = this.examUpdateOptions.find(o => o.name === 'Other');
                    if(examUpdateObj){
                        return update.exam_update_id === (examUpdateObj.id);
                    }
                    return false;
                }
            },
            beforeExamUpdateRemove: function(file, fileList){
                if(file.id) {
                    return new Promise((resolve, reject) => {
                        this.$confirm('Are you sure you want to delete this file?')
                            .then(() => resolve(true))
                            .catch(() => reject)
                        });
                }
            },
            onExamUpdateRemove: function(file, fileList, update){
                if(file.id){
                    axios.delete('/api/testing-service/delete-exam-update-file/', {params: {id: file.id}})
                        .then(response => {
                            if(response.data.success){
                                update.documents.splice(update.documents.indexOf(file), 1);
                            }
                        });
                } else {
                    update.documents.splice(update.documents.indexOf(file), 1);
                }
            },
            onExamUpdateChange: function(file, fileList, update){
                update.documents.push(file);
            },
            addExamUpdate: function(){
                this.testingService.exam_updates.push({service_id: this.testingService.id, documents: []});
            },
            deleteExamUpdate: function(update){
                if(update.id){
                    this.$confirm('This will permanently delete this exam update. Are you sure?')
                        .then(() => {
                            axios.delete("/api/testing-service/delete-exam-update/", {params: {id: update.id}})
                                .then(response => {
                                    if(response.data.success){
                                        this.testingService.exam_updates.splice(this.testingService.exam_updates.indexOf(update), 1);
                                    }
                                });
                        }).catch(() => {
                            // do nothing
                        });
                } else {
                    this.testingService.exam_updates.splice(this.testingService.exam_updates.indexOf(update), 1);
                }
            },
            refreshData: function(){

                axios.get("/api/testing-service/exam-form-options/", {params: {id: this.testingService.id}})
                    .then(response => {
                        this.deliveryInstructions = response.data.delivery_instructions;
                        this.equipmentOptions = response.data.equipment_options;
                        this.permissionOptions = response.data.permission_options;
                        this.listeningFilmOptions = response.data.listening_film_options;
                        this.calculatorOptions = response.data.calculator_options;
                        this.examRequestedFromProfessionOptions = response.data.exam_requested_from_professor_options;
                        this.computerTypeOptions = response.data.exam_equipment_computer_options;
                        this.examUpdateOptions = response.data.exam_update_options;
                        this.studentExamUpdateOptions = response.data.student_exam_update_options;


                        this.disableLink = false;
                    });

                this.getTestingService();
                this.getExamEnrollments();
                this.getEligibleStudents();
                this.getHistory();
                this.getGroupEmails();

                this.editingTestingService = false;
                this.editAll = false;

                this.showAddTaSection = false;
                this.showEditTaSection = false;
                this.showRescheduleExamSection = false;
                this.showScheduleExamSection = false;
                this.rescheduleExam.date = null;
                this.rescheduleExam.start_time = null;
                this.scheduleExam.start_times = [{date: null, start_time: null}];
                this.addTa.first_name = null;
                this.addTa.last_name = null;
                this.addTa.email = null;
                this.addTa.phone = null;
            },

            sendAutomaticRequest: function(usePrimaryAlternate, useSecondaryAlternate, includeTAs){

              var cc_array = [];
              if(includeTAs && this.testingService.teaching_assistants){
                for(var i = 0; i < this.testingService.teaching_assistants.length; i++){
                    var teachingAssistant = this.testingService.teaching_assistants[i];

                    cc_array.push(teachingAssistant.email);
                }
              }

              if(useSecondaryAlternate){
                  cc_array.push(this.testingService.secondary_alternate_instructor_email);
              }


              var recipients = [{
                  recipient_id: this.testingService.course_id,
                  recipient_class: "Course",
                  regarding_id: this.testingService.id,
                  regarding_class: "TestingService",
                  use_alternate_email: usePrimaryAlternate,
                  cc: cc_array
              }];


              var identifier = !this.testingService.exam_requested_from_professor || this.testingService.exam_requested_from_professor == "Not yet requested" ? "exam_initial_request" : "exam_subsequent_request";

                this.$confirm('Are you sure you wish to send a request for the exam?')
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: identifier,
                            recipients: recipients
                        })
                        .then(response => {
                            this.refreshData();
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                            console.log(error);
                        });
                    }).catch(() => {
                });
            },

            sendAutomaticRequestToTAs: function(){
                var recipients = [];


                for(var i = 0; i < this.testingService.teaching_assistants.length; i++){
                    var teachingAssistant = this.testingService.teaching_assistants[i];
                    recipients.push({
                        recipient_id: teachingAssistant.id,
                        recipient_class: "ServiceProvider",
                        regarding_id: this.testingService.id,
                        regarding_class: "TestingService"
                    });
                }

                var identifier = !this.testingService.exam_requested_from_professor || this.testingService.exam_requested_from_professor == "Not yet requested" ? "exam_initial_request" : "exam_subsequent_request";

                this.$confirm('Are you sure you wish to send a request for the exam?')
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: identifier,
                            recipients: recipients
                        })
                            .then(response => {
                                this.refreshData();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {
                });
            },
            sendExamTimeReminderToAllStudents: function() {
                var recipients = [];

                for(var i = 0; i < this.examEnrollments.length; i++){
                    var examEnrollment = this.examEnrollments[i];

                    if(!examEnrollment.opt_student_out_of_emails && examEnrollment.status == 'active'){
                        recipients.push({
                            recipient_id: examEnrollment.student_id,
                            recipient_class: "Student",
                            regarding_id: this.testingService.id,
                            regarding_class: "TestingService"
                        });
                    }
                }


                var identifier = "student_exam_time_reminder";

                this.$confirm('Are you sure you wish to notify all exam students of their pending exam?')
                    .then(() => {
                        axios.post('/api/group-email/send-auto-email/', {
                            identifier: identifier,
                            recipients: recipients
                        })
                            .then(response => {
                                this.refreshData();
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {
                });
            },
            setEditAll: function(){
                setTimeout(() => {
                    this.editAll = true;
                }, 50);
            }
        },
        created () {
            this.load();
        }
    }
</script>

<style scoped>
    .schedule table tr td:last-child { display: none; }
    .section { width: 80em; padding: 1em; margin: 1em 0; clear: left; background: #dde; page-break-inside: avoid; }
    .shaded { background: #ffc; }
    .section .panel { width: 22em; float: left; padding: 0; position: relative; }
    .section .wide  { width: 42em; }
    .section .panel + .panel { margin-left: 2em; }
    .section > h3 { margin: 0.2em 0; }
    .accommodations { line-height: 1.25em; margin-top: 0pt; float: left; }
    p.accommodations { line-height: 1.75em; }
    .accommodations div { display: block; float: none; min-width: 8em; }
    .accommodations .accommodation-option { display: inline-block; min-width: 0px; }
    .el-checkbox { white-space: normal; }
    .el-checkbox__label { display: inline; }
    ul.no-chrome { list-style-type: none; margin: 1em 0 0 0; padding: 0; }
    ul.no-chrome li { margin: 0; padding: 0; display: block; }
    p.tag { position: absolute; width: 20em; height: 5em; background: #ee3; left: 10em; top: -1em; }
    .hover {
        background-color: rgb(184, 170, 192);
    }
    .no-padding .el-form-item__label {
        padding: 0px;
    }
    .form-small-275 {
        width: 275px;
    }
    #content .form-small-275 .el-form-item {
        margin: 0px;
    }
    #content .form-small-275 .el-form-item .el-form-item__label {
        font-size: inherit;
    }
    #content .form-small-275 .el-form-item .el-form-item__content {
        font-size: inherit;
    }
    .el-input-number .el-input-number__increase {
        width: 12px;
    }
    .el-input-number .el-input-number__decrease {
        width: 12px;
    }
    .length-input {
        width: 65px;
    }
    .pages-number {
        display: inline-block !important;
        min-width: 65px !important;
    }

    .accommodations div.no-min-width {
        min-width:0px;
        display:inline-block;
    }
    .accommodations div.el-input{
        min-width:0px;
    }
    .length-input .el-input-number.is-controls-right .el-input__inner {
        padding-right: 23.5px;
        padding-left: 11.5px;
    }

    .small-font {
      font-size: small;
    }
</style>

<style>


#comment-table tbody tr td p {
  margin: 0;
}
</style>
