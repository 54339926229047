<template>
  <div id="container">
    <div id="content">
      <h1>Group Email Templates</h1>
      <p>View
        <el-select v-model="categoryId" placeholder="Select" size="small"  @change="onFilterTemplates">
          <el-option
            v-for="item in categories"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select></p>
      <el-table :data="templates" >
        <el-table-column prop="subcategory" label="Sub Category" sortable :sort-orders="['ascending', 'descending']"  :min-width="13.0"></el-table-column>
        <el-table-column prop="subject" label="Subject" sortable :sort-orders="['ascending', 'descending']"  :min-width="16.0"></el-table-column>
        <el-table-column prop="body" label="Body" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column label="Operations"   :min-width="12.0">
          <template slot-scope="scope">
            <router-link :to="'/group-email/email-templates/show/' + scope.row.id"  class="trailing-space">Show</router-link>
            <router-link :to="'/group-email/email-templates/edit/?id=' + scope.row.id"  class="trailing-space">Edit</router-link>
            <router-link :to="'/group-email/compose/?id=' + scope.row.id" >Compose</router-link>


          </template>
        </el-table-column>
      </el-table>
      <br clear="all">
      <el-button type="primary" style="margin-top: 10px" size="small" @click="onSubmit">Create a new email template</el-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Email Templates"
      }
    },
    components: {
    },
    data () {
      return {
        subject: '',
        html: 'Click "Show" to see template here',
        templates: [],
        templates1: [],
        templates2: [],
        templates3: [],
        categories: [
          { value: 1, label: 'Student Templates' },
          { value: 2, label: 'Faculty Templates' },
          { value: 3, label: 'Note Taker/Proctor Templates' }
        ],
        categoryId: 1
      }
    },
    //computed: {
    //   html () {
    //       return converter.makeHtml(this.content)
    //   }
    //},
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      handleShow: function(index, rows) {
        this.subject = rows[index].subject
        this.html = converter.makeHtml(rows[index].body)
      },
      handleEdit: function(index, rows) {
        this.$router.push({ path: '/group-email/email-templates/edit', query: { id: rows[index].id }});
      },
      handleCompose: function(index, rows) {
        this.$router.push({ path: '/group-email/compose', query: { id: rows[index].id }});
      },
      load: function () {
        axios
          .get('/api/group-email/email-templates')
          .then(response => {
            if(response.data.success) {
              response.data.rows.forEach(function(row){
                eval("this.templates"+row.category_id+".push(row)");
              }, this);
              this.onFilterTemplates();
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      onFilterTemplates: function() {
        this.templates = eval("this.templates"+this.categoryId);
      },
      onSubmit: function() {
        this.$router.push({ path: '/group-email/email-templates/edit'});
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
  .subject {
    margin-left: 10px;
    padding-left: 10px;
    margin-top: 0;

  }
  #content .el-table__body-wrapper {
    overflow-y: auto;
  }
  #preview {
    width: 80ch;
    float: left;
    margin-top: 0px;
  }

</style>
