<template>
    <div id="container">
<div id="content">
            <h1>Editing Future task</h1>
            <div>
                <el-form :model="validateFields" :rules="rules" ref="taskForm" label-width="165px" label-position="left">
                    <el-form-item label="Task Date" prop="taskDate">
                        <el-date-picker size="mini" v-model="validateFields.taskDate" value-format="yyyy-MM-dd"></el-date-picker>
                        <div v-if="showDateError" class="form-error">Task date must be  in the future</div>
                        <br v-if="!showDateError"><span class="form-text">Note: On this date, the task will appear under the user's "My Tasks" section</span>
                    </el-form-item>
                    <el-form-item label="Subject" prop="subject">
                        <el-input size="mini" v-model="validateFields.subject" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="Author">
                        <span v-if="!this.id" class="form-text">{{ user ? user.name : '' }}</span>
                        <span v-else class="form-text">{{ authorName }}</span>
                    </el-form-item>
                    <el-form-item label="Type">
                        <el-select v-if="!this.id" size="mini" v-model="type" style="width:100px;">
                            <el-option label="Task" value="Task"></el-option>
                            <el-option label="Message" value="Message"></el-option>
                        </el-select>
                        <span v-else class="form-text">{{ type }}</span>
                        <br><span class="form-text">Note: A <span class="text-italic">task</span> requires some action on the part of the recipient, and once created it remains open until the author of the task closes it. A <span class="text-italic">message</span> is used just to send information and closes when the recipient chooses to mark it as read.</span>
                    </el-form-item>
                    <el-form-item label="Recipients / Assigned to">
                        <el-autocomplete style="width:500px;" :fetch-suggestions="handleUserSearch" :trigger-on-focus="false" size="mini" @select="handleUserSelect" v-model="recipientsStr" :disabled="sendToAllUsers"></el-autocomplete> <span class="form-text">or</span> <el-checkbox v-model="sendToAllUsers" label="All Doris Users"></el-checkbox>
                        <br><span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                        <template v-if="!sendToAllUsers">
                            <template v-for="user in recipients">
                                <template v-if="user.value">
                                    <el-checkbox :key="user.id" :label="user.value" @change="removeUserFromRecipients(user)" checked></el-checkbox><br :key="user.id">
                                </template>
                                <template v-else>
                                    <el-checkbox :key="user.id" :label="user.name + ' <' + user.email + '>'" @change="removeUserFromRecipients(user)" checked></el-checkbox><br :key="user.id">
                                </template>
                            </template>
                        </template>
                    </el-form-item>
                    <el-form-item label="Student">
                        <el-autocomplete v-model="studentName" :fetch-suggestions="querySearch" :trigger-on-focus="false" @select="function(student) {studentId = student.id}" size="mini" style="width:500px"></el-autocomplete>
                        <br><span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                    </el-form-item>
                    <el-form-item label="Text">
                        <el-input size="mini" type="textarea" :rows="15" v-model="body" style="width:600px;"></el-input>
                    </el-form-item>
                </el-form>
                <template v-if="!this.id">
                    <el-button size="mini" type="primary" @click="onSubmit">Send / Assign</el-button>
                    or <a :href="'/tasks/future-tasks'">Cancel</a>
                </template>
                <template v-else>
                    <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
                    or <a :href="'/tasks/future-tasks/show' + this.id">Cancel</a>
                </template>
            </div>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        metaInfo () {
            return {
                title: "Edit Future Task"
            }
        },
        components: {
            },
        data () {
            var validateDate = (rule, value, callback) => {
                // eslint-disable-next-line
                console.log(value);
                if (!value || value === '') {
                    callback(new Error('Task Date is required'));
                } else if(!moment(value).isAfter(new Date(), 'day')) {
                    callback(new Error('Task Date must be in the future'));
                } else {
                    callback();
                }
            };
            return {
                id: 0,
                user: null,
                showDateError: false,
                showSubjectError: false,
                //form data
                authorName: null,
                type: "Task",
                sendToAllUsers: false,
                recipients: [],
                recipientsStr: null,
                studentId: null,
                studentName: null,
                body: null,
                validateFields: {
                    taskDate: moment(new Date()).format('YYYY-MM-DD'),
                    subject: null
                },
                rules: {
                    taskDate: [{ validator: validateDate }],
                    subject: [{ required: true, whitespace: true, message: 'Subject is required' }]
                }
            }
        },
        methods: {
            load: function() {
                this.id = location.pathname.split('/tasks/future-tasks/edit/')[1];

                if(this.id){
                    this.createMode = false;
                    axios.get('/api/future-task/', {params: {id: this.id}})
                        .then(response => {
                            this.validateFields.taskDate = response.data.task.task_date;
                            this.validateFields.subject = response.data.task.subject;
                            this.type = response.data.task.type;
                            this.authorName = response.data.task.author_name;
                            this.status = response.data.task.status;
                            this.statusDisplay = response.data.task.status_display;
                            this.recipients = response.data.task.recipients ? response.data.task.recipients : [];
                            this.sendToAllUsers = response.data.task.send_to_all_users;
                            if(response.data.task.student_name){
                                this.studentName = response.data.task.student_name;
                            }
                            if(response.data.task.student_uni){
                                this.studentName += " <" + response.data.task.student_uni + ">";
                            }
                            this.studentId = response.data.task.student_id;
                            this.body = response.data.task.body;
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                            console.log(error)
                        });
                }

                axios.get("/api/user")
                    .then(response => {
                        if(response.data.success == true) {
                            this.user = response.data.user;
                        } else {
                            // eslint-disable-next-line
                            console.log("failed to get user");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(status){
                this.$message.closeAll();

                this.$refs['taskForm'].validate((valid) => {
                    if(valid){
                        var recipientIds = [];
                        this.recipients.forEach(function(user){
                            recipientIds.push(user.id);
                        });

                        if(this.id){
                            axios.put("/api/future-task/", {id: parseInt(this.id), subject: this.validateFields.subject, recipient_ids: recipientIds, send_to_all_users: this.sendToAllUsers, student_id: this.studentId, body: this.body, task_date: this.validateFields.taskDate})
                            .then(response => {
                                if (response.data.success) {
                                  this.$router.push({ path: '/tasks/future-tasks/show/' + this.id});
                                } else {
                                   //handle?
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error)
                            });
                        } else {
                            axios.post("/api/future-task/", {subject: this.validateFields.subject, type: this.type, recipient_ids: recipientIds, send_to_all_users: this.sendToAllUsers, student_id: this.studentId, body: this.body, task_date: this.validateFields.taskDate})
                            .then(response => {
                                if (response.data.success) {
                                  this.$router.push({ path: '/tasks/future-tasks/show/' + response.data.id});
                                } else {
                                   //handle?
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error)
                            });
                        }
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: "One or more errors prevented this task from being saved. Please correct any errors and try saving again."
                        });
                    }
                });
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            handleUserSearch: function(queryString, cb) {
                axios.get('/api/contact-note/search-active-users/?query=' + queryString)
                    .then(response => {
                        cb(response.data.rows);
                    });
            },
            handleUserSelect: function(user){
                this.$message.closeAll();
                var existingUser = this.recipients.find(function(existingUserObj){
                    return existingUserObj.id == user.id;
                });

                if(!existingUser){
                    this.recipients.push(user);
                } else {
                    this.$message({
                        showClose: true,
                        duration: 0,
                        type: 'error',
                        message: "This user already exists in the list."
                    });
                }
                this.recipientsStr = null;
            },
            removeUserFromRecipients: function(user){
                this.recipients.splice(this.recipients.indexOf(user), 1);
            },
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 100%;
    }
    #content .el-form-item {
        margin-bottom: 0px;
    }
    .form-error {
        font-size: 11px;
        color: red;
        line-height: 2;
    }
</style>
