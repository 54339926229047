<template>
    <div id="container">
<div id="content">
            <student-nav selected="disabilities" :id="student_id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div style="position:relative">
                <student-disabilities-edit :id="disability_id" :is_academic="is_academic" :student_id="student_id" :student_name="name"></student-disabilities-edit>
            </div>            
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },

        components: {
        },
        data () {
            return {
                disability_id: 0,
                student_id: 0,
                is_academic: null,
                name: "",
                uni: "",
                status: ""
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                var urlParams = this.$route.query;

                this.student_id = location.pathname.split('/student/disabilities-edit/')[1];
                this.disability_id = urlParams["disability_id"];
                this.is_academic = urlParams["is_academic"] == "true" ? true : false;
                axios.get("/api/student/" + this.student_id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
