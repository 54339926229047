<template>
  <div id="container">
    <div id="content">
      <h1>Group Email Signature</h1>
      <div>
        <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm" class="editor-container" label-position="top">
          <el-form-item label="Name" prop="name">
            <el-input v-model="editForm.name" size="small"></el-input>
          </el-form-item>

          <el-form-item label="Body" prop="body">
            <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 500}" :resize="vertical" cols="80"
                      v-model="editForm.body"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div id="html-preview" v-html="html"></div>
      <br clear="all">
      <template v-if="editForm.id">
        <el-button @click="onSave('editForm')" type="primary" icon="el-icon-document">Save</el-button>
        <el-button @click="onDelete">Delete</el-button>
      </template>
      <template v-else>
        <el-button @click="onSave('editForm')" type="primary" icon="el-icon-document">Create</el-button>
      </template>
    </div>
  </div>
</template>

<script>
  //import markdownEditor from 'components/markdowneditor.vue'

  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)
  //const content = '12345678901234567890123456789012345678901234567890123456789012345678901234567890'

  export default {
    metaInfo () {
      return {
        title: "Edit Email Signature"
      }
    },

    components: {
      //markdownEditor//,
      //VueShowdown
    },
    data () {
      return {
        params: {},
        editForm: {
          id: '',
          name: '',
          body: '',
        },
        rules: {
          name: [{ required: true, message: 'Please enter a name', trigger: 'blur' }],
          body: [{required: true, message: 'Please enter a message body', trigger: 'blur' }]
        }
      }
    },
    computed: {
      html () {
        return converter.makeHtml(this.editForm.body)
      }
    },
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      markdown2Html: function() {
        //this.html = converter.makeHtml(this.content)
      },
      onDelete: function() {
        this.$confirm('This will permanently delete the signature. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(response => {
          axios
            .delete('/api/group-email/email-signature/?id=' + this.editForm.id)
            .then(function(response) {
              this.$router.push({ path: '/group-email/email-signatures'});
            }).catch(function(error) {
            // eslint-disable-next-line
            console.log(error);
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          });
        });
      },
      onSave: function(formName) {
        this.$refs[formName].validate(valid => {
          if(valid) {
            if (this.editForm.id) { //updating
              axios
                .put('/api/group-email/email-signature', {
                  id: +this.editForm.id,
                  name: this.editForm.name,
                  body: this.editForm.body
                })
                .then(response => {
                  this.$router.push({ path: '/group-email/email-signatures', query: { id: this.editForm.id}});
                })
                .catch(function (error) {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else { //creating
              axios
                .post('/api/group-email/email-signature', {
                  name: this.editForm.name,
                  body: this.editForm.body
                })
                .then(response => {
                  this.editForm.id = response.data.id;
                  this.$router.push({ path: '/group-email/email-signatures', query: { id: this.editForm.id}});
                  //this.$message({
                  //   type: 'success',
                  //   message: 'Template saved'
                  //});
                })
                .catch(function (error) {
                  // eslint-disable-next-line
                  console.log(error);
                });

            }
          } else {
            // eslint-disable-next-line
            console.log('form not valid');
          }
        });
      },
      load: function () {
        this.params = this.$route.query;
        this.editForm.id = this.params.id;
        if(this.editForm.id) { //updating
          axios
            .get('/api/group-email/email-signature/?id=' + this.editForm.id)
            .then(response => {
              // eslint-disable-next-line
              console.log(response);
              this.editForm.name = response.data.name;
              this.editForm.body = response.data.body;
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });
        }
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  .editor-container {
    float: left;
  }
  .editor-container .CodeMirror {
    height: 100%!important;
  }
  .content {
    position: relative;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form-item__content {
    line-height: 14px;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    width: 80ch;
    float: left;
    margin-top: 122px;
    margin-bottom: 10px;
    background: #EBEFE5;
  }


</style>
