<template>
    <div id="container">
<!-- TODO: Set title -->
        <div id="content">
            <h1>Housing Accommodation Report</h1>

            <el-form class="term-select">
                <el-form-item style="float: left">
                    <el-select style="width:250px;"  size="mini" v-model="accommodationTypeOption" @change="getData">
                        <el-option v-for="accommodation_type_option in accommodation_type_options" :key="accommodation_type_option.id" :value="accommodation_type_option.id" :label="accommodation_type_option.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: left; margin-left: 20px;">
                    <el-select style="width:150px;"  size="mini" v-model="schoolOption" @change="getData">
                        <el-option v-for="school_option in school_options" :key="school_option.value" :value="school_option.value" :label="school_option.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style=" float: left; margin-left: 20px;">
                    <el-select style="width:150px;" size="mini" v-model="statusOption" @change="getData">
                        <el-option v-for="status_option in status_options" :key="status_option.value" :value="status_option.value" :label="status_option.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-table :default-sort="{prop: 'student_name', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']" width="225">
                    <template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{scope.row.student_name}} &lt;{{ scope.row.uni }}&gt;</a></template>
                </el-table-column>
                <el-table-column label="School" sortable :sort-orders="['ascending', 'descending']" prop="school"></el-table-column>
                <el-table-column label="Accommodation Type" sortable :sort-orders="['ascending', 'descending']"  prop="name"  width="180"></el-table-column>
                <el-table-column label="Status" sortable :sort-orders="['ascending', 'descending']" prop="status"  width="95"></el-table-column>
                <el-table-column label="Description" sortable :sort-orders="['ascending', 'descending']" prop="description"></el-table-column>
                <el-table-column label="Duration" sortable :sort-orders="['ascending', 'descending']" prop="expiration"  width="125"></el-table-column>
            </el-table>
            <p><a :href="'/api/report/housing-accommodation.xlsx?accommodation_type_id='+this.accommodationTypeOption+'&status='+this.statusOption+'&school='+this.schoolOption" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} records)</p>

        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    //TODO: remove if unnecessary


    export default {
        //TODO: Remove when unnecessary
        metaInfo () {
            return {
                title: "Housing Accommodation Report"
            }
        },

        components: {
            },
        data () {
            return {
                //TODO: remove when unnecessary
                urlParams: {},
                data: [],
                status_options: [],
                accommodation_type_options: [],
                school_options: [
                    {name: 'School', value: ''},
                    {name: 'UAH/CUMC Housing', value: 'grad'},
                    {name: 'Undergraduate Housing', value: 'cc_seas'},
                ],
                loading: false,
                statusOption: "",
                accommodationTypeOption: "",
                schoolOption: ""
                //TODO: Add any url params
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                //TODO: Get any necessary url params
                this.getData();
            },
            getData: function() {
                this.loading = true;
                //TODO: Replace URL and params
                axios.get('/api/report/housing-accommodation/', {params: {
                        accommodation_type_id: this.accommodationTypeOption,
                        status: this.statusOption,
                        school: this.schoolOption
                    }})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.status_options = response.data.status_options;
                            this.accommodation_type_options = response.data.accommodation_type_options;
                            this.loading = false;

                            this.accommodation_type_options.unshift({name: 'Accommodation Type', id: ''});
                            this.status_options.unshift({name: 'Status', value: ''});
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
