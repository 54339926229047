<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getData">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Undergraduate Students by 1st term of enrollment at Columbia</h1>
            <p>Note: These students enrolled as undergraduates. They may be graduate students now, and it's possible that students without graduation dates here have actually graduated, but the information hasn't been updated from SIS. Also, students who have graduation dates may still be enrolled at Columbia as grad students or in a different program.</p>
            <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="70">
                    <template slot-scope="scope">
                        <a :href="'/student/profile/' + scope.row.id">{{ scope.row.uni }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="preferred_first_name" label="Preferred" sortable :sort-orders="['ascending', 'descending']" width="90"></el-table-column>
                <el-table-column prop="status_display" label="Student Status" sortable :sort-orders="['ascending', 'descending']" width="135"></el-table-column>
                <el-table-column prop="first_school" label="First school" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="first_semester_name" label="First semester" sortable :sort-orders="['ascending', 'descending']" width="80"></el-table-column>
                <el-table-column prop="first_year" label="First year" sortable :sort-orders="['ascending', 'descending']" width="60"></el-table-column>
                <el-table-column prop="last_school" label="Latest school" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="last_semester_name" label="Latest semester" sortable :sort-orders="['ascending', 'descending']" width="80"></el-table-column>
                <el-table-column prop="last_year" label="Latest year" sortable :sort-orders="['ascending', 'descending']" width="60"></el-table-column>
                <el-table-column prop="last_graduation" label="Latest program graduation" sortable :sort-orders="['ascending', 'descending']" width="125">
                    <template slot-scope="scope">
                        {{ scope.row.last_graduation | formatDateMDY }}
                    </template>
                </el-table-column>
            </el-table>
            <p><a :href="'/api/report/undergraduate-first-term-of-enrollment.xlsx?semester_id=' + this.semesterId" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Undergraduate Students by 1st term of enrollment at Columbia"
            }
        },
        components: {
            },
        data () {
            return {
                data: [],
                loading: false,
                semesterId: null,
                semesters: []
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getData();
                    });
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/undergraduate-first-term-of-enrollment/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
</style>
