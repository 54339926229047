<template>
    <div id="container">
        <div id="content">
            <h1>Proctor Sessions Analysis - {{startDate | formatDateMDY}} to {{endDate | formatDateMDY}}</h1>
            <el-table :default-sort="{prop: 'name', order: 'ascending'}" :data="data" style="width: 900px" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']">

                </el-table-column>
                <el-table-column prop="proctor_title" label="Title" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">{{scope.row.proctor_title}}</template>
                </el-table-column>
                <el-table-column prop="undergraduate_count" label="# Undergraduate Sessions" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="graduate_count" label="# Graduate Sessions" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="other_count" label="# Other Sessions" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
            </el-table>
            <p><a :href="'/api/report/proctor-sessions-analysis.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} records)</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Proctor Sessions Analysis"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                startDate: null,
                endDate: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                if(this.urlParams.start_date){
                    this.startDate = this.urlParams.start_date;
                }
                if(this.urlParams.end_date){
                    this.endDate = this.urlParams.end_date;
                }
                this.getData();
            },
            getData: function() {
                this.data = [];
                this.loading = true;
                axios.get('/api/report/proctor-sessions-analysis/', {params: {start_date: this.startDate, end_date: this.endDate}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;

                            for(var i = 0; i < this.data.length; i++){
                                var datum = this.data[i];

                                if(datum.proctor_is_trainee){
                                    datum.proctor_title = datum.proctor_title + " - Trainee";
                                }
                            }

                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        float:right;
        width: 250px;
    }
</style>
