<template>
    <div id="container">
<div id="content">
            <h1>Documents</h1>
            <template v-for="category in documentCategories">
                <h2 :key="category.id">{{ category.category }}</h2>
                <table :key="category.id"><tbody>
                    <tr v-for="(item, index) in category.rows" :key="index">
                        <td><a :href="createDocumentSearchLink(item)">{{ item.name }}</a></td>
                        <td>{{ item.count }}</td>
                    </tr>
                </tbody></table>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Disability Documents"
            }
        },
        components: {
            },
        data () {
            return {
                documentCategories: []
            }
        },
        methods: {
            load: function () {
                axios.get("/api/document/document-counts-by-category/")
                    .then(response => {
                        this.documentCategories = response.data.rows;
                    });
            },
            createDocumentSearchLink: function(item){
                if(item.type == "HaDecisionLetter"){
                    return "/document/ha-decision-letter/";
                } else {
                    var url = '/document/search?type=' + item.type;

                    if(item.status){
                        url += '&status=' + item.status;
                    } else if(item.type != "OtherDocument" && item.type != "HaDecisionLetter" && item.type != "HaNotificationLetterBatch"){
                        url += '&status=all';
                    } else if(item.type == "OtherDocument") {
                        if(item.name == "All"){
                            url += '&name=all';
                        } else {
                            url += '&name=' + item.name;
                        }
                    }

                    return url;
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
