<template>
    <div id="container">
<div id="content">
            <el-form v-if="semesters.length" class="term-select" label-position="left">
                <el-form-item label="Term:" >
                    <el-select style="width:150px;" size="mini" v-model="semesterId" @change="getStudents">
                        <el-option v-for="semester in semesters" :key="semester.id" :value="semester.id" :label="semester.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <h1>Note Taker Payroll Status for {{ semesterName }}</h1>
            <el-table :default-sort="{prop: 'name', order: 'ascending'}" :data="students">
                <el-table-column label="Name" prop="name" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <a :href="'/service-provider/show/' + scope.row.service_provider_id">{{ scope.row.name }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Email" prop="email" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="Payroll Status" prop="flag" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column label="Course Title" prop="class_name" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <a :href="'/note-taking-service/show/' + scope.row.service_id">{{ scope.row.class_name }}</a>
                    </template>
                </el-table-column>
            </el-table>
            <p v-if="total"><a :href="'/api/notetaking-service/notetaker-payroll-status.xlsx?semester_id=' + this.semesterId" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Note Taker Payroll Status for " + this.semesterName
            }
        },
        components: {
        },
        data () {
            return {
                students: [],
                semesters: [],
                semesterId: null,
                total: null
            }
        },
        computed: {
            semesterName: function() {
                if(this.semesterId){
                    for(var i = 0; i < this.semesters.length; i++){
                        if(this.semesters[i].id == this.semesterId){
                            return this.semesters[i].name;
                        }
                    }
                }
                return '';
            }
        },
        methods: {
            load: function() {
                axios.get('/api/simple/get-current-and-past-semesters')
                    .then(response => {
                        this.semesters = response.data.rows;
                        for(var i = 0; i < this.semesters.length; i++){
                            if(this.semesters[i].is_current){
                                this.semesterId = this.semesters[i].id;
                            }
                        }
                        this.getStudents();
                    });
            },
            getStudents: function() {
                axios.get('/api/notetaking-service/notetaker-payroll-status/', {params: {semester_id: this.semesterId}})
                    .then(response => {
                        if(response.data.success) {
                            this.students = response.data.rows;
                            this.students.forEach(function(x) {
                                x.email = x.email.toLowerCase().trim();
                            });
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .term-select {
        width: 250px;
        float:right;
    }
    .el-table th {
        vertical-align: top;
    }
</style>
