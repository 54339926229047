<template>
    <div id="container">
<div id="content">
            <h1>Students with LD or AD/HD disabilities<template v-if="data.length"> - {{ data.length }} Total</template></h1>
            <el-table :default-sort="{prop: 'uni', order: 'ascending'}" :data="data" style="width: 100%" v-loading="loading" element-loading-text="Loading..." class="table-top-align">
                <el-table-column prop="last_name" label="Last" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="first_name" label="First" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="preferred_first_name" label="Preferred Name" sortable :sort-orders="['ascending', 'descending']" width="100"></el-table-column>
                <el-table-column prop="uni" label="UNI" sortable :sort-orders="['ascending', 'descending']" width="70"></el-table-column>
                <el-table-column prop="affiliation" label="Affiliation" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
                <el-table-column prop="enrollment" label="Enrollment" sortable :sort-orders="['ascending', 'descending']" width="115"></el-table-column>
                <el-table-column prop="expected_graduation" label="Grad Date" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope">{{ scope.row.expected_graduation | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column prop="school" label="School" sortable :sort-orders="['ascending', 'descending']" width="240"></el-table-column>
                <el-table-column prop="ods_categories" label="ODS Category" sortable :sort-orders="['ascending', 'descending']">
                    <template slot-scope="scope">
                        <template v-for="(ods_category, index) in scope.row.ods_categories"><template v-if="index !== 0">; </template>{{ ods_category }}</template>
                    </template>
                </el-table-column>
                <el-table-column prop="disability_types" label="Type" sortable :sort-orders="['ascending', 'descending']" width="240">
                    <template slot-scope="scope">
                        <template v-for="(disability_type, index) in scope.row.disability_types"><template v-if="index !== 0">; </template>{{ disability_type }}</template>
                    </template>
                </el-table-column>
            </el-table>
            <p><a :href="'/api/report/students-with-ld-or-adhd.xlsx?primary_order=' + this.primaryOrder + '&secondary_order=' + this.secondaryOrder" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} student records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Students with LD or AD/HD disabilities"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {},
                data: [],
                primaryOrder: null,
                secondaryOrder: null,
                loading: false
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.primaryOrder = this.urlParams.primary_order;
                this.secondaryOrder = this.urlParams.secondary_order;
                this.getData();

            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/students-with-ld-or-adhd/', {params: {primary_order: this.primaryOrder, secondary_order: this.secondaryOrder}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
