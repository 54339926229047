<template>
  <div id="container">
    <div id="content">
      <h1>Edit Proctoring Session</h1>
      <a :href="'/proctoring-session'">Back to proctoring sessions</a><br clear="all">

      <div class="container">
        <div class="section section-left">
          <div class="proctor_session_field">Proctoring location:<br>
            <el-select size="mini" v-model="proctoringSession.proctoring_location_id" style="width:500px"  @change="handleLocationChange">
              <el-option v-for="location in locations" :key="location.id" :label="location.name" :value="location.id"></el-option>
            </el-select>
          </div>
          <div><label class="form-label">Room Capacity</label>{{ proctoringSession.room_capacity ? proctoringSession.room_capacity : 'N/A' }}<template v-if="!proctoringSession.id && proctoringSession.proctoring_location_id"><span class="text-italic"> --- Capacity After Room Change: {{ roomCapacity }}</span></template></div>
          <div><label class="form-label">Students Assigned:</label>{{ proctoringSession.student_count ? proctoringSession.student_count : '0' }}</div>
          <div><label class="form-label">Remaining Capacity:</label>{{ proctoringSession.remaining_capacity ? proctoringSession.remaining_capacity : 'N/A' }}</div>
          <div style="clear:both;"></div>
          <el-form ref="proctoringSessionForm" :rules="rules" :model="proctoringSession">
            <div class="proctor_session_field">
              <label class="form-label">Date:</label>
              <el-form-item prop="date">
                <el-date-picker size="mini" v-model="proctoringSession.date" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            <div class="proctor_session_field">
              <label class="form-label">Start time:</label>
              <el-form-item prop="start_time_text">
                <el-input style="width:200px" size="mini" placeholder="HH:MM PM" v-model="proctoringSession.start_time_text"></el-input>
              </el-form-item>
            </div>
            <div class="proctor_session_field">
              <label class="form-label">End time:</label>
              <el-form-item prop="end_time">
                <el-input style="width:200px" size="mini" placeholder="HH:MM PM" v-model="proctoringSession.end_time"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="proctor_session_field"><label class="form-label">Exam Notes:</label><el-input style="width:300px" resize="both" type="textarea" :rows="4" v-model="proctoringSession.notes"></el-input></div>
          <div class="proctor_session_field"><label class="form-label">Information for Proctors:</label><el-input style="width:300px" resize="both" type="textarea" :rows="4" v-model="proctoringSession.information_for_proctors"></el-input></div>
          <el-form v-if="proctoringSession.room_notes && proctoringSession.room_notes.length" label-width="11em" label-position="left">
            <template v-for="(note, index) in proctoringSession.room_notes">
              <el-form-item label="Room Note:" :key="note.id">
                <el-select size="mini" v-model="note.note" style="width:450px;">
                  <el-option :key="''" :value="null" :label="''"></el-option>
                  <el-option v-for="option in roomNoteOptions" :key="option" :label="option" :value="option"></el-option>
                </el-select>
                <span class="form-text"><a @click="handleRemoveRoomNote(index)">remove</a></span>
              </el-form-item>
              <el-form-item v-if="note.note == 'Other'" label="Other:" :key="note.id">
                <el-input type="textarea" :rows="2" v-model="note.note_other" style="width:250px;"></el-input>
              </el-form-item>
            </template>
          </el-form>
          <a @click="addRoomNote">[+] Add room note</a><br>

          <div class="proctor_session_field"><label class="form-label">Graduate Session?</label><el-checkbox v-model="proctoringSession.graduate_session"></el-checkbox></div>
          <template v-if="proctoringSession.graduate_session">
            <div class="proctor_session_field">
              <b>Campus:</b><br>
              <el-radio v-model="proctoringSession.graduate_campus" label="CUMC">CUMC</el-radio><br>
              <el-radio v-model="proctoringSession.graduate_campus" label="Morningside">Morningside</el-radio>
            </div>
            <div style="display:block;">
              <div class="proctor_session_field">--------------------------------------------------------------------------------</div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Name of Student:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_name_of_student"></el-input></div>
              <div class
                     ="proctor_session_field">
                <label class="graduate-form-label">Course Name:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_course_name"></el-input></div>
              <div class
                     ="proctor_session_field">
                <label class="graduate-form-label">Accommodated Exam Start Time:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_accommodated_exam_start_time"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Accommodated Exam End Time:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_accommodated_exam_end_time"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Location of the Accommodated Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_location_accommodated_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Location of the Regular Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_location_regular_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Contact Person During Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_contact_during_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Contact Person's Phone During Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_contact_phone_during_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Contact Person's Email During Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_contact_email_during_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Where to pick-up Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_where_to_pick_up_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Where to drop-off the completed Exam:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_where_to_drop_off_exam"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Requested By:</label>
                <el-select size="mini" style="width:525px" v-model="proctoringSession.proctoring_session_requestor_id">
                  <el-option v-for="requestor in requestors" :key="requestor.id" :label="requestor.name + ' - ' + requestor.email" :value="requestor.id"></el-option>
                </el-select>
              </div>


              <div class="proctor_session_field">
                <label class="graduate-form-label">Equipment needed from DS for this exam (e.g. computer standing desk etc)?</label>
                <el-checkbox v-model="proctoringSession.graduate_equipment_required"></el-checkbox><br/>
              </div>
              <div class="proctor_session_field" v-if="proctoringSession.graduate_equipment_required">
                <label class="graduate-form-label">Please specify:</label>
                <el-input style="width:300px" type="textarea" :rows="4" v-model="proctoringSession.graduate_equipment_details"></el-input>
              </div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">Other:</label>
                <el-input size="mini" style="width:300px" v-model="proctoringSession.graduate_other"></el-input></div>
              <div class="proctor_session_field">
                <label class="graduate-form-label">DS STAFF NOTES:</label>
                <el-input style="width:300px" type="textarea" :rows="4" v-model="proctoringSession.graduate_ds_staff_notes"></el-input>
              </div>
            </div>
          </template>
          <template v-if="showLockSession">
            Lock Room (no students can be added) <el-checkbox v-model="proctoringSession.is_locked"></el-checkbox><br>
          </template>
          <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
        </div>
        <div class="section section-right">
          <template v-if="proctoringSession.id">
            <b>Service Schedules:</b>
            <el-table :data="serviceSchedules" style="width:550px;">
              <el-table-column prop="name" label="Proctor" sortable :sort-orders="['ascending', 'descending']" width="115">
                <template slot-scope="scope">
                  {{ scope.row.name }}
                  <template v-if="scope.row.proctor_is_trainee"> <span style='color:green; font-weight: bold'>(Trainee)</span></template>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a></template></el-table-column>
              <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']" width="88">
                <template slot-scope="scope">
                  <el-form :rules="rules" :ref="'startTimeForm' + scope.row.id" :model="scope.row">
                    <el-form-item prop="start_time">
                      <el-input style="width:75px" size="mini" v-model="scope.row.start_time" class="service-schedule-edit"></el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column prop="end_time" label="End" sortable :sort-orders="['ascending', 'descending']" width="88">
                <template slot-scope="scope">
                  <el-form :rules="rules" :ref="'endTimeForm' + scope.row.id" :model="scope.row">
                    <el-form-item prop="end_time">
                      <el-input style="width:75px" size="mini" v-model="scope.row.end_time" class="service-schedule-edit"></el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column width="85">
                <template slot-scope="scope">
                  <a @click="updateServiceSchedule(scope.row)">save</a><br/><a @click="deleteServiceSchedule(scope.row.id)">delete</a>
                </template>
              </el-table-column>
            </el-table>
            <br><br>
            <b>New service schedule:</b>
            <el-form :rules="rules" :model="serviceSchedule" ref="serviceScheduleForm" label-width="125px" label-position="left">
              <el-form-item label="Proctor:">
                <el-select size="mini" style="width:200px" v-model="serviceSchedule.proctor_id" filterable>
                  <el-option v-for="proctor in proctors" :key="proctor.id" :value="proctor.id" :label="proctor.first_name + ' ' + proctor.last_name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Start time:" prop="start_time">
                <el-input size="mini" style="width:115px" v-model="serviceSchedule.start_time"></el-input>
              </el-form-item>
              <el-form-item label="End time:" prop="end_time">
                <el-input size="mini" style="width:115px" v-model="serviceSchedule.end_time"></el-input>
              </el-form-item>
            </el-form>
            <el-button size="mini" type="primary" @click="createServiceSchedule">Save changes</el-button>
          </template>
          <template v-else>
            <b>Service Schedules</b><br><br>
            <el-table :data="serviceSchedules" style="width:500px;">
              <el-table-column prop="name" label="Proctor" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
              <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']"><template slot-scope="scope"><a :href="'mailto:' + scope.row.email">{{ scope.row.email }}</a></template></el-table-column>
              <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']" width="80"><template slot-scope="scope">{{ scope.row.start_time | formatTimehma }}</template></el-table-column>
              <el-table-column prop="end_time" label="End" sortable :sort-orders="['ascending', 'descending']" width="80"><template slot-scope="scope">{{ scope.row.end_time | formatTimehma }}</template></el-table-column>
            </el-table>
          </template>
        </div>
      </div>
      <template v-if="examsAndStudents && examsAndStudents.length">
        <h3>Exams ({{ examsAndStudents.length }})</h3>
        <el-table :data="examsAndStudents" style="width:100%">
          <el-table-column prop="class_name" label="Course Title" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              <a :href="'/testing/show/' + scope.row.service_id">{{ scope.row.class_name }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="start_time" label="Date/Time" sortable :sort-orders="['ascending', 'descending']" width="550">
            <template slot-scope="scope">
              {{ scope.row.start_time | formatDateTimeMDYhma }}
            </template>
          </el-table-column>
        </el-table>
        <h3>Students ({{ examsAndStudents.length }})</h3>
        <el-table :data="examsAndStudents" style="width:100%">
          <el-table-column prop="student_name" label="Student" sortable :sort-orders="['ascending', 'descending']">
            <template slot-scope="scope">
              <a :href="'/student/testing/' + scope.row.student_id">{{ scope.row.student_name }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="start_time" label="Start" sortable :sort-orders="['ascending', 'descending']" width="300">
            <template slot-scope="scope">
              {{ scope.row.start_time | formatTimehma }}
            </template>
          </el-table-column>
          <el-table-column prop="end_time" label="Finish" sortable :sort-orders="['ascending', 'descending']" width="300">
            <template slot-scope="scope">
              {{ scope.row.end_time | formatTimehma }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'

  export default {
    metaInfo () {
      return {
        title: "Edit Proctoring Session"
      }
    },
    components: {
    },
    data () {
      var validateTime = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please input the time'));
        } else if(!moment(value, ["h:mm a","h:mma", "h a", "ha"], true).isValid()) {
          callback(new Error('Enter time - HH:MM AM or HH AM'));
        } else {
          callback();
        }
      };
      return {
        proctoringSession: {
          id: null,
          proctoring_location_id: null,
          date: null,
          start_time: null,
          end_time: null,
          length: null,
          graduate_session: null,
          notes: null,
          graduate_name_of_student: null,
          graduate_course_name: null,
          graduate_accommodated_exam_start_time: null,
          graduate_accommodated_exam_end_time: null,
          graduate_location_accommodated_exam: null,
          graduate_location_regular_exam: null,
          graduate_contact_during_exam: null,
          graduate_where_to_pick_up_exam: null,
          graduate_where_to_drop_off_exam: null,
          graduate_other: null,
          graduate_contact_phone_during_exam: null,
          graduate_contact_email_during_exam: null,
          graduate_ds_staff_notes: null,
          graduate_campus: null,
          information_for_proctors: null,
          proctoring_session_requestor_id: null,
          is_locked: null,
          room_notes: []
        },
        serviceSchedules: [],
        requestors: [],
        locations: [],
        roomNoteOptions: [],
        proctors: [],
        examsAndStudents: [],
        showLockSession: false,
        rules: {
          date: [{required: true, message: 'Date is required'}],
          start_time: [
            { validator: validateTime, trigger: 'blur' }
          ],
          start_time_text: [
            { required: true, message: 'Start Time is required' },
            { validator: validateTime, trigger: 'blur' }
          ],
          end_time: [
            { required: true, message: 'End Time is required' },
            { validator: validateTime, trigger: 'blur' }
          ]
        },
        serviceSchedule: {
          proctor_id: null,
          start_time: "08:00AM",
          end_time: "06:00PM"
        }
      }
    },
    computed: {
      roomCapacity: function(){
        if(this.proctoringSession.proctoring_location_id){
          for(var i = 0; i < this.locations.length; i++){
            if(this.locations[i].id == this.proctoringSession.proctoring_location_id){
              return this.locations[i].room_capacity ? this.locations[i].room_capacity : 'N/A';
            }
          }
          return 'N/A';
        }
        return 'N/A';
      }
    },
    methods: {
      load: function(){
        this.proctoringSession.id = location.pathname.split('/proctoring-session/edit/')[1];
        if(this.proctoringSession.id){
          this.proctoringSession.id = parseInt(this.proctoringSession.id);
          this.getProctoringSession();
          this.getEditFormItems();
        } else {
          this.getPermissions();
        }

        axios.get("/api/proctoring-session/form-items/")
          .then(response => {
            this.requestors = response.data.requestors;
            this.locations = response.data.locations;
            this.roomNoteOptions = response.data.room_note_options;
          });
      },

      getPermissions: function() {


        axios.get("/api/simple/check-permissions/", {params: {permissions: ['can_lock_proctoring_session']}})
          .then(response => {
            this.showLockSession = response.data.permissions.can_lock_proctoring_session;
          }) ;
      },

      getProctoringSession: function(){
        axios.get("/api/proctoring-session/", {params: {id: this.proctoringSession.id}})
          .then(response => {
            this.proctoringSession = response.data.proctoring_session;
            this.missingGraduateFields = response.data.missing_graduate_fields;
            this.serviceSchedules = response.data.service_schedules;
            this.coverageGaps = response.data.coverage_gaps;
            this.showLockSession = response.data.show_lock_session;

            if(!this.proctoringSession.room_notes){
              this.proctoringSession.room_notes = [];
            }

            this.$set(this.proctoringSession, 'date', response.data.proctoring_session.start_time);
            this.$set(this.proctoringSession, 'start_time_text', moment(response.data.proctoring_session.start_time).format("hh:mm A"));
            this.proctoringSession.end_time = moment(response.data.proctoring_session.end_time).format("hh:mm A");

            this.$set(this.serviceSchedule, 'start_time', this.proctoringSession.start_time_text);
            this.$set(this.serviceSchedule, 'end_time', this.proctoringSession.end_time);


            for(var i = 0; i < this.serviceSchedules.length; i++){
              this.serviceSchedules[i].start_time = moment(this.serviceSchedules[i].start_time).format("h:mmA");
              this.serviceSchedules[i].end_time = moment(this.serviceSchedules[i].end_time).format("h:mmA");
            }
          });
      },
      getServiceSchedules: function(){
        axios.get("/api/proctoring-session/", {params: {id: this.proctoringSession.id}})
          .then(response => {
            this.serviceSchedules = response.data.service_schedules;
            this.coverageGaps = response.data.coverage_gaps;

            for(var i = 0; i < this.serviceSchedules.length; i++){
              this.serviceSchedules[i].start_time = moment(this.serviceSchedules[i].start_time).format("h:mmA");
              this.serviceSchedules[i].end_time = moment(this.serviceSchedules[i].end_time).format("h:mmA");
            }
          });
      },
      getEditFormItems: function(){
        axios.get("/api/proctor/active-proctors")
          .then(response => {
            this.proctors = response.data.rows;
          });

        axios.get("/api/proctoring-session/exams-and-students/", {params: {id: this.proctoringSession.id}})
          .then(response => {
            this.examsAndStudents = response.data.exams_and_students;
          });
      },
      onSubmit: function(){
        this.$refs["proctoringSessionForm"].validate((valid) => {
          if(valid){
            this.proctoringSession.length = null;
            this.proctoringSession.length = (moment(this.proctoringSession.end_time, ["h:mm a","h:mma", "h a", "ha"], true) - moment(this.proctoringSession.start_time_text, ["h:mm a","h:mma", "h a", "ha"], true)) / 1000;

            this.proctoringSession.start_time = moment(this.proctoringSession.date).format('YYYY-MM-DD') + 'T' + moment(this.proctoringSession.start_time_text, ["h:mm a","h:mma", "h a", "ha"], true).format('HH:mm');

            if(this.proctoringSession.id){
              axios.put('/api/proctoring-session/', this.proctoringSession)
                .then(response => {
                  if(response.data.success){
                    this.$router.push({ path: '/proctoring-session/show/' + this.proctoringSession.id });
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            } else {
              axios.post('/api/proctoring-session/', this.proctoringSession)
                .then(response => {
                  if(response.data.success && response.data.proctoring_session_id){
                    this.$router.push({ path: '/proctoring-session/show/' +  response.data.proctoring_session_id});
                  }
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            }
          } else {
            this.$message({
              showClose: true,
              duration: 20000,
              type: 'error',
              message: "One or more required fields was missing. Please correct any errors and submit this form again."
            });
          }
        });
      },
      createServiceSchedule: function(){
        this.$message.closeAll();
        this.$refs["serviceScheduleForm"].validate((valid) => {
          if(valid){
            if(this.serviceSchedule.proctor_id){
              this.serviceSchedule.proctoring_session_id = this.proctoringSession.id;
              this.serviceSchedule.length = null;
              if(this.serviceSchedule.start_time && this.serviceSchedule.end_time){
                this.serviceSchedule.length = (moment(this.serviceSchedule.end_time, ["h:mm a","h:mma", "h a", "ha"], true) - moment(this.serviceSchedule.start_time, ["h:mm a","h:mma", "h a", "ha"], true)) / 1000;
              }
              this.serviceSchedule.start_time = moment(this.serviceSchedule.start_time, ["h:mm a","h:mma", "h a", "ha"], true).format("HH:mm");
              axios.post('/api/proctoring-session/add-service-schedule/', this.serviceSchedule)
                  .then(response => {
                    if(response.data.success){
                      this.getProctoringSession();
                      this.serviceSchedule.proctor_id = null;
                      this.serviceSchedule.start_time = '08:00AM';
                      this.serviceSchedule.end_time = '06:00AM';
                    }
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error);
                  });
            } else {
              this.$message({
                showClose: true,
                duration: 20000,
                type: 'error',
                message: "Please select a proctor."
              });
            }
          }
        });
      },
      updateServiceSchedule: function(serviceSchedule){
        this.$refs["startTimeForm" + serviceSchedule.id].validate((valid) => {
          if(valid){
            this.$refs["endTimeForm" + serviceSchedule.id].validate((valid) => {
              if(valid){
                serviceSchedule.length = null;
                if(serviceSchedule.start_time && serviceSchedule.end_time){
                  serviceSchedule.length = (moment(serviceSchedule.end_time, ["h:mm a","h:mma", "h a", "ha"], true) - moment(serviceSchedule.start_time, ["h:mm a","h:mma", "h a", "ha"], true)) / 1000;
                }
                serviceSchedule.start_time = moment(serviceSchedule.start_time, ["h:mm a","h:mma", "h a", "ha"], true).format("HH:mm");
                axios.put('/api/proctoring-session/update-service-schedule/', serviceSchedule)
                  .then(response => {
                    if(response.data.success){
                      this.getServiceSchedules();
                    }
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error);
                  });
              }
            });
          }
        });
      },
      deleteServiceSchedule: function(id){
        this.$confirm('Are you sure you want to delete this service schedule?')
          .then(() => {
            axios.delete("/api/proctoring-session/delete-service-schedule/", {params: {id: id}})
              .then(response => {
                this.getServiceSchedules();
              });
          }).catch(() => {
          // do nothing
        });
      },
      addRoomNote: function(){
        this.proctoringSession.room_notes.push({note: null, note_other: null})
      },
      handleRemoveRoomNote: function(index){
        if(this.proctoringSession.id){
          this.$confirm('Are you sure you want to remove this room note?')
            .then(() => {
              this.removeRoomNote(index);
            }).catch(() => {});
        } else {
          this.removeRoomNote(index);
        }
      },
      removeRoomNote: function(index){
        if(this.proctoringSession.room_notes[index].id){
          this.proctoringSession.room_note_ids_to_delete.push(this.proctoringSession.room_notes[index].id);
        }
        this.proctoringSession.room_notes.splice(index, 1);
      },
      handleLocationChange: function(){
        if(this.showLockSession && this.proctoringSession.proctoring_location_id){
          var locationObj = this.locations.find(o => o.id === this.proctoringSession.proctoring_location_id);

          if(locationObj.is_locked){
            this.proctoringSession.is_locked = true;
          } else {
            this.proctoringSession.is_locked = false;
          }
        }
      }
    },
    created () {
      this.load();
    }
  }
</script>
<style scoped>
  .form-label {
    display: block;
    float: left;
    clear: left;
    width: 11em;
  }
  .graduate-form-label {
    display: block;
    float: left;
    clear: left;
    width: 25em;
  }
  .container {
    width: 100%;
    display:flex;
  }
  .section {
    float:left;
    padding: .3em .6em;
    margin: .5em 0;
    background: none;
  }
  .section-left {
    border: 1px solid #CEC1D6;
    width:50%;
  }
  .section-right {
    border-top: 1px solid #CEC1D6;
    border-right: 1px solid #CEC1D6;
    border-bottom: 1px solid #CEC1D6;
    width:50%;
  }
  .el-form-item__label {
    padding:0px;
  }
  .el-form-item__content {
    font-size: small;
  }
  .el-form {
    width: 100%;
  }

</style>
