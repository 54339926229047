<template>
    <div id="container">
<div id="content">
            <br><div><a :href="'/admin'">Users</a> | <b>Academic Calendar</b> | <a :href="'/trace'">Trace</a></div>
            <h1>Listing semesters</h1>
            <!-- <p>Note: please don't change any of the dates here without first checking with Zocalo Software.</p> -->
            <table><thead>
                <tr>
                    <th style="border-right: medium solid black" colspan="4">Past</th>
                    <th colspan="4">Future</th>
                </tr>
                <tr>
                    <th>Name</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th style="border-right: medium solid black"></th>
                    <th>Name</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th></th>
                </tr>
                <tr v-for="(row, index) in pastSemesters.length > currentAndFutureSemesters.length ? pastSemesters : currentAndFutureSemesters" :key="index">
                    <template v-if="pastSemesters[index]">
                        <td>{{ pastSemesters[index].name }}</td>
                        <td>{{ pastSemesters[index].start_date | formatDateMDY }}</td>
                        <td>{{ pastSemesters[index].end_date | formatDateMDY }}</td>
                        <td style="border-right: medium solid black"><router-link :to="'/semester/edit/'+pastSemesters[index].id">Edit</router-link></td>
                    </template>
                    <template v-else>
                        <td style="border-right: medium solid black" colspan="3">&nbsp;</td>
                    </template>
                    <template v-if="currentAndFutureSemesters[index]">
                        <td>{{ currentAndFutureSemesters[index].name }}</td>
                        <td>{{ currentAndFutureSemesters[index].start_date | formatDateMDY }}</td>
                        <td>{{ currentAndFutureSemesters[index].end_date | formatDateMDY }}</td>
                        <td><router-link :to="'/semester/edit/'+currentAndFutureSemesters[index].id">Edit</router-link></td>
                    </template>
                    <template v-else>
                        <td colspan="3">&nbsp;</td>
                    </template>
                </tr>
            </thead></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'
    export default {
        metaInfo () {
            return {
                title: 'System Administration: Semesters'
            }
        },
        components: {
            },
        data () {
            return {
                pastSemesters: [],
                currentAndFutureSemesters: []
            }
        },
        computed: {
            rows: function() {
                return Math.max(this.pastSemesters.length, this.currentAndFutureSemesters.length) - 1;
            }
        },
        methods: {
            load: function(){
                axios.get("/api/admin/academic-calendar/")
                    .then(response => {
                        this.pastSemesters = response.data.past_semesters;
                        this.currentAndFutureSemesters = response.data.current_and_future_semesters
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
