<template>
  <div id="container">
    <div id="content">
      <h1>Group Email Templates</h1>
      <div id="preview">
        <p class="subject"><b>Category:</b> {{template.category}}</p>
        <p class="subject"><b>Sub-Category:</b> {{template.subcategory}}</p>
        <p class="subject"><b>Subject:</b> {{template.subject}}</p>
        <div id="html-preview" v-html="html"></div>
      </div>
      <br clear="all">

      <router-link :to="'/group-email/compose/?id=' + template.id" >Compose with an email with this template</router-link><br/><br/>
      <router-link :to="'/group-email/email-templates/'" >Back to list</router-link><br/><br/>
      <router-link :to="'/group-email/email-templates/edit/?id=' + template.id"  class="trailing-space">Edit this template</router-link>
      
    </div>
  </div>
</template>

<script>
  //import markdownEditor from 'components/markdowneditor.vue'

  import axios from 'axios'
  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)
  //const content = '12345678901234567890123456789012345678901234567890123456789012345678901234567890'

  export default {
    metaInfo () {
      return {
        title: "Show Email Template"
      }
    },

    components: {
      //markdownEditor//,
      //VueShowdown
    },
    data () {
      return {
        params: {},
        template: {
          id: '',
          category: '',
          subcategory: '',
          subject: '',
          body: '',
        },
        categories: [
          { value: 1, label: 'Student Templates' },
          { value: 2, label: 'Faculty Templates' },
          { value: 3, label: 'Note Taker/Proctor Templates' }
        ],
        subcategories: [
          { value: 1, label: 'Exam Accommodations' },
          { value: 2, label: 'Registration' },
          { value: 3, label: 'Accommodations-non-testing' },
          { value: 4, label: 'Other-miscellaneous' }
        ]
      }
    },
    computed: {
      html () {
        return converter.makeHtml(this.template.body)
      }
    },
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      markdown2Html: function() {
        //this.html = converter.makeHtml(this.content)
      },
      load: function () {
        if(this.$route.params.id) {
          this.template.id = this.$route.params.id;
          axios
            .get('/api/group-email/email-template/?id=' + this.template.id)
            .then(response => {
              this.template.category = this.categories.find(function(x){ return x.value == response.data.email_template_category_id}).label;
              this.template.subcategory = this.subcategories.find(function(x){ return x.value == response.data.email_template_sub_category_id}).label;
              this.template.subject = response.data.subject;
              this.template.body = response.data.body;
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });
        }
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  .editor-container {
    float: left;
  }
  .editor-container .CodeMirror {
    height: 100%!important;
  }
  .content {
    position: relative;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form-item__content {
    line-height: 14px;
  }
  #html-preview {
    margin-left: 20px;
    padding-left: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
  .subject {
    margin-left: 10px;
    padding-left: 10px;
  }
  #preview {
    width: 80ch;
    float: left;
    margin-top: 0px;
  }

</style>
