<template>
    <div id="container">
        <div id="content">
            <template v-if="!showGroupChanges">
                <h1>Editing Contact Note</h1>
                <el-form :model="validateFields" :rules="rules" ref="contactNoteForm" label-width="200px" label-position="left">
                    <el-form-item label="Student Name" prop="studentId">
                        <template v-if="createMode && !studentIdParam">
                            <el-autocomplete v-model="student" :fetch-suggestions="querySearch" :trigger-on-focus="false" @select="handleMainStudentSelect" size="mini" style="width: 100%"></el-autocomplete>
                            <span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                        </template>
                        <template v-else>
                            <span class="form-text"><a :href="'/student/profile/' + this.validateFields.studentId">{{ studentName }} &lt;{{studentUni}}&gt;</a></span>
                        </template>
                    </el-form-item>
                    <el-form-item v-if="(createMode || this.status == 'draft') && !haApp" label="Create copies for">
                        <el-autocomplete style="width:100%;" :fetch-suggestions="querySearch" :trigger-on-focus="false" size="mini" @select="handleStudentSelect" v-model="copyStudentsDisplay"></el-autocomplete>
                        <span class="form-text">Enter a name, a UNI, or an email address. You may continue to enter names to create multiple copies of this contact note.</span><br>
                        <template v-for="student in copyStudents">
                            <el-checkbox :key="student.id" :label="student.value" @change="removeFromCopyStudents(student)" checked>{{student.value}}</el-checkbox><br :key="student.id">
                        </template>
                    </el-form-item>
                    <el-form-item label="Date" prop="date">
                        <el-date-picker size="mini" v-model="validateFields.date" value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Type(s) of Contact" prop="contactTypes">
                        <el-checkbox-group v-model="validateFields.contactTypes">
                            <template v-for="contactType in contactTypesList">
                                <br :key="contactType + '-br'" v-if="(contactTypesList.indexOf(contactType) < 5 && contactTypesList.indexOf(contactType) > 0 && contactTypesList.indexOf(contactType) % 3 == 0) || contactTypesList.indexOf(contactType) >= 5">
                                <el-checkbox :key="contactType + '-checkbox'" :label="contactType"></el-checkbox>
                                <template v-if="contactType == 'ODS Contact with Third Party'">
                                    <el-input :key="contactType" class="checkbox-group-input" size="mini" v-model="thirdParty" :disabled="validateFields.contactTypes.indexOf(contactType) == -1"></el-input>
                                </template>
                                <template v-if="contactType == 'Other'">
                                    <el-input :key="contactType" class="checkbox-group-input" size="mini" v-model="otherContactType" :disabled="validateFields.contactTypes.indexOf(contactType) == -1"></el-input>
                                </template>
                            </template>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="Reason(s) for Contact" prop="reasons" v-if="!haApp">
                        <el-checkbox-group v-model="validateFields.reasons">
                            <template v-for="reason in reasonsList">
                                <el-checkbox :key="reason" :label="reason"></el-checkbox>
                                <template v-if="reason == 'Accommodations'">
                                    <el-checkbox-group :key="reason + '-cb-group'" class="checkbox-group-sub-group" v-model="accommodationTypes" :disabled="validateFields.reasons.indexOf(reason) == -1">
                                        <template v-for="accommodationType in accommodationTypesList">
                                            <br :key="accommodationType + '-br'" v-if="accommodationTypesList.indexOf(accommodationType) != 0">
                                            <el-checkbox :key="accommodationType" :label="accommodationType"></el-checkbox>
                                            <template v-if="accommodationType == 'Other'">
                                                <el-input :key="accommodationType + '-input'" class="checkbox-group-input" size="mini" v-model="otherAccommodationType" :disabled="accommodationTypes.indexOf(accommodationType) == -1"></el-input>
                                            </template>
                                        </template>
                                    </el-checkbox-group>
                                </template>
                                <template v-if="reason == 'Unique Accommodations'">
                                    <el-checkbox-group :key="reason + '-cb-group'" class="checkbox-group-sub-group" v-model="uniqueAccommodations" :disabled="validateFields.reasons.indexOf(reason) == -1">
                                        <template v-for="uniqueAccommodation in uniqueAccommodationsList">
                                            <br :key="uniqueAccommodation + '-br'" v-if="uniqueAccommodationsList.indexOf(uniqueAccommodation) != 0">
                                            <el-checkbox :key="uniqueAccommodation" :label="uniqueAccommodation"></el-checkbox>
                                            <template v-if="uniqueAccommodation == 'Other'">
                                                <el-input :key="uniqueAccommodation + '-input'" class="checkbox-group-input" size="mini" v-model="otherUniqueAccommodation" :disabled="uniqueAccommodations.indexOf(uniqueAccommodation) == -1"></el-input>
                                            </template>
                                        </template>
                                    </el-checkbox-group>
                                </template>
                                <template v-if="reason == 'Other'">
                                    <el-input :key="reason + '-cb-group'" class="checkbox-group-input" size="mini" v-model="otherReason" :disabled="validateFields.reasons.indexOf(reason) == -1"></el-input>
                                </template>
                                <template v-if="reason == 'Referral'">
                                    <el-input :key="reason + '-cb-group'" class="checkbox-group-input" size="mini" v-model="referral" :disabled="validateFields.reasons.indexOf(reason) == -1"></el-input>
                                </template>
                                <template v-if="reason == 'Check-in'">
                                    <el-input :key="reason + '-cb-group'" class="checkbox-group-input" size="mini" v-model="checkIn" :disabled="validateFields.reasons.indexOf(reason) == -1"></el-input>
                                </template>
                                <br :key="reason + '-br'" v-if="reason != 'Accommodations' && reason != 'Unique Accommodations' && ((reasonsList.indexOf(reason) < 6 && reasonsList.indexOf(reason) > 0 && (reasonsList.indexOf(reason)+1) % 3 == 0) || reasonsList.indexOf(reason) >= 6)">
                            </template>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="Subject/Headline" prop="subject">
                        <el-input size="mini" v-model="validateFields.subject"></el-input>
                    </el-form-item>
                    <el-form-item label="ODS Staff">
                        <template v-if="createMode">
                            <span class="form-text">{{ user ? user.name : '' }}</span>
                        </template>
                        <template v-else>
                            <span class="form-text">{{ authorName }}</span>
                        </template>
                    </el-form-item>
                    <el-form-item label="Status">
                        <span class="form-text">{{ statusDisplay }}</span>
                    </el-form-item>
                    <el-form-item label="Notes & Follow-up Needed">
                        <span class="form-text">Insert template:</span><br>
                        <el-select size="mini" v-model="contactNoteTemplate" @change="handleSelectTemplate" style="width:550px;">
                            <el-option v-for="template in contactNoteTemplates" :key="template.title" :label="template.title" :value="template.body"></el-option>
                        </el-select>
                        <el-input size="mini" type="textarea" :rows="15" v-model="text"></el-input>
                    </el-form-item>
                    <el-form-item label="File Attachment">
                        <el-upload drag action="/api/contact-note/upload-files/" ref="upload" :auto-upload="false" :file-list="fileList" :limit="5" :on-remove="onRemove" :on-change="onChange" :before-remove="beforeRemove">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div class="fyi-section" v-if="!haApp">
                    <template v-if="showFyiSection">
                        <div><a class="fyi-link" @click="showFyiSection = false">[-]</a><h3>FYI regarding this note</h3></div>
                        <el-form label-width="70px" label-position="right">
                            <el-form-item label="Recipients">
                                <el-autocomplete style="width:100%;" :fetch-suggestions="handleFyiSearch" :trigger-on-focus="false" size="mini" @select="handleFyiSelect" v-model="recipients"></el-autocomplete>
                                <span class="form-text">Enter a name, a UNI, or an email address.</span><br>
                                <template v-for="user in fyiUsers">
                                    <el-checkbox :key="user.id" :label="user.value" @change="removeUserFromFyiUsers(user)" checked>{{user.value}}</el-checkbox><br :key="user.id">
                                </template>
                            </el-form-item>
                            <el-form-item label="Subject">
                                <el-input size="mini" v-model="fyiSubject"></el-input>
                            </el-form-item>
                            <el-form-item label="Text">
                                <el-input type="textarea" :rows="5" v-model="fyiMessage"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-button size="mini" type="primary" @click="sendFyi">Send FYI</el-button>
                    </template>
                    <template v-else>
                        <div><a class="fyi-link" @click="showFyiSection = true">[+]</a><h4>Send FYI message regarding this note</h4></div>
                    </template>
                </div>
                <template v-if="createMode">
                    <el-button size="mini" type="primary" @click="onSubmit('submitted', false)">Save and submit for review</el-button>
                    <el-button size="mini" type="primary" @click="onSubmit('draft', false)">Save as draft</el-button>
                </template>
                <template v-else>
                    <el-button size="mini" type="primary" @click="onSubmit(status, false)">Save changes {{this.status}}</el-button>
                </template>
                or <a :href=" createMode ? '/contact-notes' : '/contact-notes/show/' + this.id">Leave editing mode</a>
            </template>
            <template v-else>
                <p>This contact is part of a group of related contact notes. Do you want to apply the same changes you just made to the other contact notes in the group?</p>
                <p>Only modifications that are checked will be applied to the other contact notes; and only contact notes that are checked will have the checked modifications applied to them.</p>
                <a @click="selectAllChanges">select all</a> <a @click="selectNoChanges">select none</a>
                <el-checkbox-group v-model="changesToSubmit">
                    <table class="form"><tbody>
                    <template v-for="(value, key) in changes">
                        <tr :key="value" >
                        <td><el-checkbox :key="value" :label="key"><b>{{ getCheckboxLabel(key) }}</b></el-checkbox></td><td><span class="form-text formatted-text">{{ getCheckboxValue(key, value) }}</span></td>
                        </tr>
                    </template>
                    </tbody></table>
                </el-checkbox-group>
                <h2>Related contact notes</h2>
                <a @click="checkAllNotes">select all</a>
                <a @click="clearAllNotes">select none</a>
                <template v-if="uniqueStatuses && uniqueStatuses.length">
                    select by status: <template v-for="(status, index) in uniqueStatuses"><a @click="selectNotesByStatus(status)" :key="index">{{ status }}</a>&nbsp;</template>
                </template>
                <el-table :data="relatedContactNotes">
                    <el-table-column prop="student_name" label="Student Name" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
                    <el-table-column prop="subject" label="Subject/Heading" sortable="custom" :sort-orders="['ascending', 'descending']" width="400"></el-table-column>
                    <el-table-column prop="author_name" label="ODS Staff" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                    <el-table-column prop="status_display" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                    <el-table-column prop="last_updated_at" label="Last Updated" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.last_updated_at | formatDateMDY }} {{ scope.row.latest_change_by_identifier }}</template></el-table-column>
                    <el-table-column width="64"><template slot-scope="scope"><router-link :to="'/contact-notes/show/'+scope.row.id">show</router-link></template></el-table-column>
                    <el-table-column width="100"><template slot-scope="scope"><router-link v-if="scope.row.group_id != null" :to="'/contact-notes/show-group/'+scope.row.group_id">show group</router-link></template></el-table-column>
                    <el-table-column width="45"><template slot-scope="scope"><el-checkbox v-model="scope.row.isChecked"></el-checkbox></template></el-table-column>
                </el-table>
                <br>
                <el-button size="mini" type="primary" @click="skipGroupEdits">Skip this step</el-button>
                <el-button size="mini" type="primary" @click="submitGroupChanges">Apply changes to other contact notes</el-button>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'



    export default {
        metaInfo () {
            return {
                title: "Editing contact note"
            }
        },

        components: {
            },
        data () {
            return {
                id: 0,
                groupId: null,
                user: null,
                createMode: true,
                studentIdParam: null,
                haApp: false,
                urlParams: {},
                contactTypesList: [],
                reasonsList: [],
                accommodationTypesList: [],
                uniqueAccommodationsList: [],
                contactNoteTemplates: [],
                contactNoteTemplate: null,
                showFyiSection: false,
                recipients: null,
                changes: {},
                changesToSubmit: [],
                relatedContactNotes: [],
                uniqueStatuses: [],
                showGroupChanges: false,
                autoSaveInterval: null,
                //form data
                student: null,
                studentName: null,
                studentUni: null,
                // studentId: null,
                authorName: null,
                copyStudents: [],
                copyStudentsDisplay: null,
                // date: null,
                // contactTypes: [],
                thirdParty: null,
                otherContactType: null,
                // reasons: [],
                referral: null,
                checkIn: null,
                otherReason: null,
                accommodationTypes: [],
                otherAccommodationType: null,
                uniqueAccommodations: [],
                otherUniqueAccommodation: null,
                // subject: null,
                status: "draft",
                statusDisplay: "Draft",
                text: null,
                fyiUsers: [],
                fyiSubject: null,
                fyiMessage: null,
                fileList: [],
                validateFields: {
                    studentId: null,
                    date: null,
                    contactTypes: [],
                    reasons: [],
                    subject: null
                },
                rules: {
                    studentId: [{ required: true, message: 'Student is required' }],
                    date: [{ required: true, message: 'Date is required' }],
                    contactTypes: [{ required: true, message: 'Please select at least one Type of Contact' }],
                    reasons: [{ required: true, message: 'Please select at least one Reason' }],
                    subject: [{ required: true, whitespace: true, message: 'Subject is required' }]
                },

                pickerOptions: {
                    // disabledDate(time) {
                    //   return time.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                        // }, {
                        //   text: 'Yesterday',
                        //   onClick(picker) {
                        //     const date = new Date();
                        //     date.setTime(date.getTime() - 3600 * 1000 * 24);
                        //     picker.$emit('pick', date);
                        //   }
                    }]
                }
            }
        },
        methods: {
            load: function() {
                this.id = location.pathname.split('/contact-notes/edit/')[1];

                this.autoSaveInterval = setInterval(() => {
                    if(this.id){
                        this.onSubmit(this.status, true);
                    } else {
                        this.onSubmit('draft', true);
                    }
                }, 120000); //two minutes - 120000

                if(this.id){
                    this.createMode = false;
                    axios.get('/api/contact-note/', {params: {id: this.id}})
                        .then(response => {
                            this.studentName = response.data.contact_note.student_name;
                            this.studentUni = response.data.contact_note.uni;
                            this.validateFields.studentId = response.data.contact_note.student_id;
                            this.authorName = response.data.contact_note.author_name;
                            this.validateFields.date = response.data.contact_note.date;
                            this.validateFields.contactTypes = response.data.contact_note.contact_types;
                            this.thirdParty = response.data.contact_note.third_party;
                            this.otherContactType = response.data.contact_note.other_contact_type;
                            this.validateFields.reasons = response.data.contact_note.reasons;
                            this.referral = response.data.contact_note.referral;
                            this.checkIn = response.data.contact_note.check_in;
                            this.otherReason = response.data.contact_note.other_reason;
                            this.accommodationTypes = response.data.contact_note.accommodation_types;
                            this.otherAccommodationType = response.data.contact_note.other_accommodation_type;
                            this.uniqueAccommodations = response.data.contact_note.unique_accommodations;
                            this.otherUniqueAccommodation = response.data.contact_note.other_unique_accommodation;
                            this.validateFields.subject = response.data.contact_note.subject;
                            this.status = response.data.contact_note.status;
                            this.statusDisplay = response.data.contact_note.status_display;
                            this.text = response.data.contact_note.text;
                            this.groupId = response.data.contact_note.group_id;

                            this.fyiSubject = "FYI regarding contact note for "+this.studentName;

                            if(this.accommodationTypes.length == 1 && this.validateFields.reasons.length == 1 &&
                                  this.accommodationTypes[0] == 'Housing' && this.validateFields.reasons[0] == 'Accommodations'){
                                this.haApp = true;
                            }

                            if(response.data.contact_note.group_id){
                                axios.get("/api/contact-note/related-contact-notes/", {params: {id: parseInt(this.id), group_id: response.data.contact_note.group_id}})
                                    .then(response => {
                                        this.relatedContactNotes = response.data.rows;
                                        this.uniqueStatuses = response.data.unique_statuses;
                                        this.clearAllNotes();
                                    });
                            }

                            if(response.data.contact_note.asset1_file_name){
                                this.fileList.push({name: response.data.contact_note.asset1_file_name, columnName: 'asset1_file_name'});
                            }
                            if(response.data.contact_note.asset2_file_name){
                                this.fileList.push({name: response.data.contact_note.asset2_file_name, columnName: 'asset2_file_name'});
                            }
                            if(response.data.contact_note.asset3_file_name){
                                this.fileList.push({name: response.data.contact_note.asset3_file_name, columnName: 'asset3_file_name'});
                            }
                            if(response.data.contact_note.asset4_file_name){
                                this.fileList.push({name: response.data.contact_note.asset4_file_name, columnName: 'asset4_file_name'});
                            }
                            if(response.data.contact_note.asset5_file_name){
                                this.fileList.push({name: response.data.contact_note.asset5_file_name, columnName: 'asset5_file_name'});
                            }
                        })
                        .catch(error => {
                            // eslint-disable-next-line
                            console.log(error)
                        });
                } else {
                    this.fyiSubject = "FYI regarding contact note for ";
                    //this.urlParams = this.$route.query;
                  this.urlParams = this.$route.query;
                    if(this.urlParams.student_id){
                        this.studentIdParam = this.urlParams.student_id;
                        this.validateFields.studentId = parseInt(this.urlParams.student_id);

                        axios.get("/api/student/" + this.validateFields.studentId)
                            .then(response => {
                                this.student = response.data.title + " <" + response.data.uni + ">";
                                this.studentName = response.data.title;
                                this.studentUni = response.data.uni;

                                this.fyiSubject = "FYI regarding contact note for " + this.studentName;
                            });
                    }

                    if(this.urlParams.ha_app){
                        this.haApp = true;

                        this.validateFields.reasons = ['Accommodations'];
                        this.accommodationTypes = ['Housing'];
                    }

                }

                axios.get("/api/user")
                    .then(response => {
                        if(response.data.success == true) {
                            this.user = response.data.user;
                        } else {
                            // eslint-disable-next-line
                            console.log("failed to get user");
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get('/api/contact-note/simple-items/')
                    .then(response => {
                        this.contactTypesList = response.data.contact_types;
                        this.reasonsList = response.data.reasons;
                        this.accommodationTypesList = response.data.accommodation_types;
                        this.uniqueAccommodationsList = response.data.unique_accommodations;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get('/api/contact-note/contact-note-templates/')
                    .then(response => {
                        this.contactNoteTemplates = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            onSubmit: function(status, isAutoSave){

                if(!isAutoSave || (this.validateFields.studentId && this.validateFields.date && this.validateFields.subject && this.validateFields.subject.trim() != '' && this.validateFields.contactTypes.length > 0 && this.validateFields.reasons.length > 0)) {
                    this.$message.closeAll();
                    this.$refs['contactNoteForm'].validate((valid) => {
                        if(valid){
                            this.$message.closeAll();
                            var copyStudentIds = [];
                            this.copyStudents.forEach(function(student){
                                copyStudentIds.push(student.id);
                            });

                            var fyiUserIds = [];
                            this.fyiUsers.forEach(function(user){
                                fyiUserIds.push(user.id);
                            });

                            if(status == "send_fyi" && (fyiUserIds.length == 0 || !this.fyiSubject || !this.fyiMessage)){
                              return;
                            } else {

                              var params = {student_id: this.validateFields.studentId, status: status,
                                date: this.validateFields.date, contact_types: this.validateFields.contactTypes,
                                reasons: this.validateFields.reasons, copy_student_ids: copyStudentIds,
                                other_contact_type: this.otherContactType, third_party: this.thirdParty,
                                referral: this.referral, check_in: this.checkIn, other_reason: this.otherReason,
                                accommodation_types: this.accommodationTypes, other_accommodation_type: this.otherAccommodationType,
                                unique_accommodations: this.uniqueAccommodations, other_unique_accommodation: this.otherUniqueAccommodation,
                                subject: this.validateFields.subject, text: this.text,
                                is_auto_save: isAutoSave};

                              if(!isAutoSave && status == "send_fyi" && this.fyiSubject && this.fyiMessage){
                                params.fyi_user_ids = fyiUserIds;
                                params.fyi_subject = this.fyiSubject;
                                params.fyi_message = this.fyiMessage;
                              }

                              if(this.id){
                                params.id = parseInt(this.id);
                                axios.put("/api/contact-note/", params)
                                    .then(response => {
                                      if (response.data.success) {
                                        if(response.data.changes){
                                          this.updateChanges(response.data.changes);
                                        }

                                        if(!isAutoSave){
                                          this.performUpload(this.status == "draft");
                                        } else {
                                          this.$message({
                                            showClose: true,
                                            duration: 0,
                                            type: 'success',
                                            message: "This contact note was automatically saved at " + moment(new Date()).format('hh:mma')
                                          });
                                        }
                                      } else {
                                        //handle?
                                      }
                                    })
                                    .catch(error => {
                                      // eslint-disable-next-line
                                      console.log(error)
                                    });
                              } else {

                                axios.post("/api/contact-note/", params)
                                    .then(response => {
                                      if (response.data.success) {
                                        if(response.data.changes){
                                          this.updateChanges(response.data.changes);
                                        }

                                        if(!isAutoSave){
                                          if(response.data.contact_note_id){
                                            this.id = response.data.contact_note_id;
                                            this.performUpload(true);
                                          }
                                        } else {
                                          if(response.data.contact_note_id){
                                            this.id = response.data.contact_note_id;
                                            this.$message({
                                              showClose: true,
                                              duration: 0,
                                              type: 'success',
                                              message: "This contact note was automatically saved at " + moment(new Date()).format('hh:mma')
                                            });
                                          }
                                        }
                                      } else {
                                        //handle?
                                      }
                                    })
                                    .catch(error => {
                                      // eslint-disable-next-line
                                      console.log(error)
                                    });
                              }
                            }
                        } else if(!isAutoSave) {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: "One or more required fields are missing. Please correct any errors and try saving again."
                            });
                        }
                    });
                }
            },
            querySearch: function(queryString, cb) {
                 axios.get("/api/student/search/?query=" + queryString)
                    .then(response => {
                       cb(response.data.rows);
                    });
            },
            handleMainStudentSelect: function(student){
                this.validateFields.studentId = student.id;

                axios.get("/api/student/" + this.validateFields.studentId)
                    .then(response => {
                        this.student = response.data.title + " <" + response.data.uni + ">";
                        this.studentName = response.data.title;
                        this.studentUni = response.data.uni;

                        this.fyiSubject = "FYI regarding contact note for "+this.studentName;
                    });
            },
            handleStudentSelect: function(student){
                this.$message.closeAll();
                var existingStudent = this.copyStudents.find(function(existingStudentObj){
                    return existingStudentObj.id == student.id;
                });

                if(!existingStudent){
                    this.copyStudents.push(student);
                } else {
                    this.$message({
                        showClose: true,
                        duration: 0,
                        type: 'error',
                        message: "This student already exists in the list."
                    });
                }
                this.copyStudentsDisplay = null;
            },
            removeFromCopyStudents: function(student){
                this.copyStudents.splice(this.copyStudents.indexOf(student), 1);
            },
            handleSelectTemplate: function(template){
                this.text = template;
            },
            handleFyiSearch: function(queryString, cb) {
                axios.get('/api/contact-note/search-active-users/?query=' + queryString)
                    .then(response => {
                        cb(response.data.rows);
                    });
            },
            handleFyiSelect: function(user){
                this.$message.closeAll();
                var existingUser = this.fyiUsers.find(function(existingUserObj){
                    return existingUserObj.id == user.id;
                });

                if(!existingUser){
                    this.fyiUsers.push(user);
                } else {
                    this.$message({
                        showClose: true,
                        duration: 0,
                        type: 'error',
                        message: "This user already exists in the list."
                    });
                }
                this.recipients = null;
            },
            sendFyi: function() {
                if(!this.id){
                    this.onSubmit("send_fyi", false);
                } else {
                    this.$message.closeAll();

                    var fyiUserIds = [];
                    this.fyiUsers.forEach(function(user){
                        fyiUserIds.push(user.id);
                    });

                    axios.post('/api/contact-note/send-fyi/', {id: parseInt(this.id), fyi_user_ids: fyiUserIds, fyi_subject: this.fyiSubject, fyi_message: this.fyiMessage})
                        .then(response => {
                            if(response.data.success){
                                this.showFyiSection = false;
                                this.fyiUsers = [];
                                this.fyiSubject = null;
                                this.fyiMessage = null;
                                this.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'success',
                                    message: 'FYI message was sent to ' + response.data.recipients + ' recipient(s).'
                                });
                            }
                        })
                }
            },
            removeUserFromFyiUsers: function(user){
                this.fyiUsers.splice(this.fyiUsers.indexOf(user), 1);
            },
            beforeRemove: function(file, fileList){
                if(file.columnName) {
                    return new Promise((resolve, reject) => {
                        this.$confirm('Are you sure you want to delete this file?')
                            .then(() => resolve(true))
                            .catch(() => reject)
                        });
                }
            },
            onRemove: function(file, fileList){
                if(file.columnName){
                    axios.delete('/api/contact-note/delete-file/', {params: {id: parseInt(this.id), column_name: file.columnName}})
                        .then(response => {
                            if(response.data.success){
                                this.fileList = fileList;
                            }
                        });
                }
            },
            onChange: function(file, fileList){
                this.fileList = fileList;
            },
            performUpload: function(isCreate) {
                var data = new FormData();
                data.append('id', parseInt(this.id));
                data.append('is_create', isCreate);

                for(var i = 0; i < this.fileList.length; i++){
                    if(this.fileList[i].raw){
                        data.append('file', this.fileList[i].raw);
                    }
                }

                axios.post('/api/contact-note/upload-files/', data)
                    .then(response => {
                        if(response.data.success){
                            if(response.data.changes){
                                this.updateChanges(response.data.changes);
                            }

                            if(this.groupId){
                                this.showGroupChanges = true;
                                clearInterval(this.autoSaveInterval);
                            } else {
                              this.$router.push({ path: '/contact-notes/show/' + this.id });
                            }
                        } else {
                            if(isCreate){
                                this.$alert("Error: "+ response.data.message);
                            } else {
                                this.$alert("Autosave error: "+ response.data.message);
                            }
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error);
                    });
            },
            updateChanges: function(newChanges){
                for(var key in newChanges){
                    this.$set(this.changes, key, newChanges[key]);
                }
            },
            getCheckboxLabel: function(key){
                if(key === 'date'){
                    return 'Date';
                } else if(key === 'contact_types'){
                    return 'Type(s) of Contact';
                } else if(key === 'reasons'){
                    return 'Reason(s) for Contact';
                } else if(key === 'accommodation_types'){
                    return 'Accommodation Types';
                } else if(key === 'unique_accommodations'){
                    return 'Unique Accommodations';
                } else if(key === 'text'){
                    return 'Notes & Follow-up Needed';
                } else if(key === 'subject'){
                    return 'Subject/Headline';
                } else if(key === 'asset1_file_name'){
                    return 'File';
                } else if(key === 'asset2_file_name'){
                    return 'File2';
                } else if(key === 'asset3_file_name'){
                    return 'File3';
                } else if(key === 'asset4_file_name'){
                    return 'File4';
                } else if(key === 'asset5_file_name'){
                    return 'File5';
                } else {
                    return key;
                }
            },
            getCheckboxValue: function(key, value){
                if(key === 'date'){
                    return moment(value).format("MM/DD/YYYY");
                } else if(key === 'contact_types' || key === 'reasons' || key === 'accommodation_types' || key === 'unique_accommodations'){
                    if(value){
                        return value.join(", ")
                    }
                } else {
                    return value;
                }
            },
            selectAllChanges: function(){
                for(var key in this.changes){
                    if(this.changesToSubmit.indexOf(key) == -1){
                        this.changesToSubmit.push(key);
                    }
                }
            },
            selectNoChanges: function(){
                this.changesToSubmit = [];
            },
            checkAllNotes: function(){
                for(var i = 0; i < this.relatedContactNotes.length; i++){
                    this.$set(this.relatedContactNotes[i], 'isChecked', true);
                }
            },
            clearAllNotes: function(){
                for(var i = 0; i < this.relatedContactNotes.length; i++){
                    this.$set(this.relatedContactNotes[i], 'isChecked', false);
                }
            },
            selectNotesByStatus: function(selectByStatus){
                for(var i = 0; i < this.relatedContactNotes.length; i++){
                    if(this.relatedContactNotes[i].status_display == selectByStatus){
                        this.$set(this.relatedContactNotes[i], 'isChecked', true);
                    }
                }
            },
            skipGroupEdits: function(){
                this.$router.push({ path: '/contact-notes/show/' + this.id});
            },
            submitGroupChanges: function(){
                var selectedNoteIds = [];
                for(var i = 0; i < this.relatedContactNotes.length; i++){
                    if(this.relatedContactNotes[i].isChecked){
                        selectedNoteIds.push(this.relatedContactNotes[i].id);
                    }
                }

                if(selectedNoteIds.length == 0){
                    this.$alert("Please select at least one contact note to apply these changes to.");
                } else {
                    axios.post("/api/contact-note/propagate-changes/", {id: parseInt(this.id), changes: this.changesToSubmit, contact_note_ids: selectedNoteIds})
                        .then(response => {
                            if(response.data.success){
                                this.$router.push({ path: '/contact-notes/show/' + this.id });
                            }
                        });
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 1000px;
    }
    .fyi-section {
        margin-left: 1em;
        padding: 0.25em;
        background-color:#EEF;
        margin-bottom: 1em;
    }
    .fyi-link {
        float: left;
        margin-right: 2em;
    }
    .checkbox-group-input {
        padding: 0px 0px 4px 4px;
        width:250px;
    }
    .checkbox-group-sub-group {
        padding-left:25px;
    }
    #content .el-icon-close-tip {
        display: none;
    }
    #content .el-form-item {
        margin-bottom: 2px;
    }
    .changes-table {
        border: none;
    }
    .form .el-checkbox__input {
        line-height: inherit;
    }
    .form .el-checkbox__label {
        line-height: 28px;
    }
    .form td {
        padding-right: 8px;
    }
    #content .el-form-item {
        margin-bottom: 15px;
    }
    #content .el-checkbox {
        margin-bottom: 10px;
    }
</style>
