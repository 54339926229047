<template>
  <div id="container">
    <div id="content">
      <h1>Group Email</h1>
      <p>
        This section is for sending a broadcast email to a group of CUODS students.
        You may select the student recipients from either:
      </p>
      <ul>
        <li>a custom list of students that you give a name and add build by adding one student and a time, or
        </li>
        <li>a predefined group based on status, coordinator, etc.</li>
      </ul>

      <p>
        <router-link to="/group-email/compose">Compose a broadcast email</router-link>
        <br/>
        <router-link to="/group-email/sent">View most recent sent emails</router-link>

        <br/><router-link to="/group-email/draft-emails">Your Draft Emails ({{numDrafts}})</router-link>

      </p>
      <br/>


      <h2>Custom email lists</h2>
      <p>
        Use this link to create customized lists of students that can receive group emails
        (for example, attendees of a regular workshop).
      </p>
      <p><router-link to="/group-email/email-lists">Manage custom email lists</router-link></p>
      <br/>

      <h2>Email Templates and Signatures</h2>
      <p>
        Use this link to create templates with reusable blocks of text for frequently sent messages.
      </p>
      <p><router-link to="/group-email/email-templates">Manage email templates</router-link></p>
      <p><router-link to="/group-email/email-signatures">Manage email signatures</router-link></p>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    metaInfo () {
      return {
        title: "Group Email"
      }
    },
    components: {
    },
    data () {
      return {
        numDrafts: ''
      }
    },
    methods: {
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      getNumDrafts: function() {
        // eslint-disable-next-line
        console.log("num drafts");
        axios.get("/api/group-email/num-drafts")
          .then(response => {
            if(response.data.total) {
              this.numDrafts = response.data.total;
            }
          });

      }
    },
    mounted() {
      this.getNumDrafts();
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
</style>
