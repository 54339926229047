<template>
  <div id="container">
    <div id="content">
      <h1>Enter a new Accommodation Letter for {{ studentName}}</h1>
      <el-form label-width="250px" label-position="left">
        <el-form-item label="Course">
          <span class="form-text">{{ courseName }}</span>
        </el-form-item>
        <el-form-item label="Semester">
          <span class="form-text">{{ semesterName }}</span>
        </el-form-item>
        <el-form-item label="Instructor">
          <span class="form-text">{{ instructorName }}</span>
        </el-form-item>
        <el-form-item label="Document Name">
          <el-input size="mini" v-model="letter.name" style="width:350px;"></el-input>
        </el-form-item>
        <el-form-item label="Date Letter was Requested or Created">
          <el-date-picker size="mini" v-model="letter.doc_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="Status">
          <span class="form-text">Requested</span>
        </el-form-item>
        <el-form-item label="Should be sent to advisor?">
          <el-checkbox v-model="letter.should_be_sent_to_advisor"></el-checkbox><span v-if="advisors.length" style="padding-left:8em;" class="form-text">Advisor: {{ advisors[0].first_name }} {{ advisors[0].last_name }} &lt;<a :href="'mailto:' + advisors[0].email">{{ advisors[0].email }}</a>&gt;</span>
        </el-form-item>
        <el-form-item label="Was sent to advisor?">
          <el-checkbox v-model="letter.has_been_sent_to_advisor"></el-checkbox>
        </el-form-item>
        <el-form-item label="Select file for upload">
          <el-upload drag action="/api/document/create-single-accommodation-letter/" ref="upload" :auto-upload="false" :data="letter" :file-list="fileList" :on-change="handleChange" :on-success="onUploadSuccess">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="onSave">Save Changes</el-button> or <a :href="'/student/accommodation-letters/' + letter.student_id">Cancel and return to student record</a>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'



  export default {
    metaInfo () {
      return {
        title: 'Enter a new Accommodation Letter for ' + this.studentName + ' - Columbia University ODS'
      }
    },

    components: {
    },
    data () {
      return {
        urlParams: {},
        studentName: null,
        courseName: null,
        semesterId: null,
        semesterName: null,
        instructorName: null,
        fileList: [],
        advisors: [],
        letter: {
          student_id: null,
          course_id: null,
          doc_date: null,
          name: null,
          should_be_sent_to_advisor: null,
          has_been_sent_to_advisor: null
        }
      }
    },
    methods: {
      load: function () {
        this.urlParams = this.$route.query;
        this.letter.student_id = parseInt(this.urlParams.student_id);
        this.letter.course_id = parseInt(this.urlParams.course_id);

        axios.get("/api/student/" + this.letter.student_id)
          .then(response => {
            this.studentName = response.data.title;
          });

        axios.get("/api/document/setup-accommodation-letter-for-course-and-student/", {params: {student_id: this.letter.student_id, course_id: this.letter.course_id}})
          .then(response => {
            this.courseName = response.data.course_name;
            this.letter.name = response.data.course_name;
            this.semesterId = response.data.semester_id;
            this.semesterName = response.data.semester_name;
            this.instructorName = response.data.instructor_name;
            this.advisors = response.data.advisors;
          });
      },
      onSave: function () {
        //TODO: Should we do validation here?
        if(this.fileList.length){
          this.$refs.upload.submit();
        } else {
          var data = new FormData();

          data.append("course_id", this.letter.course_id);
          data.append("doc_date", this.letter.doc_date);
          data.append("has_been_sent_to_advisor", this.letter.has_been_sent_to_advisor);
          data.append("should_be_sent_to_advisor", this.letter.should_be_sent_to_advisor);
          data.append("name", this.letter.name);
          data.append("student_id", this.letter.student_id);

          axios.post("/api/document/create-single-accommodation-letter/", data)
            .then(response => {
              if(response.data.success){
                this.onUploadSuccess();
              }
            });
        }
      },
      onUploadSuccess: function(response, file) {
        if(response.success){
          this.$router.push({ path: '/student/accommodation-letters/' + this.letter.student_id })
        } else {
          this.$alert("Error: "+response.message);
          this.fileList.splice(this.fileList.indexOf(file), 1);
        }
      },
      handleChange: function(files, fileList) {
        //only allow one file to be uploaded, and replace previous file with new upload
        this.fileList = fileList.slice(-1);
      },
    },
    created () {
      this.load();
    }
  }
</script>

<style scoped>
  #content .el-form {
    width:100%;
  }

  .el-form-item__content {
    font-size: small;
  }

  #content .el-form-item {
    margin-bottom: 5px;
  }

  #content .el-form-item__label {
    line-height: 28px;
  }

  #content .el-form-item__content {
    line-height: inherit;
  }
</style>
