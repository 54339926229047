<template>
    <div id="container">
<div id="content">
            <h1>{{ serviceProvider.role }} Service Provider: {{ serviceProvider.first_name }} {{ serviceProvider.last_name }}</h1>
            <a :href="'/service-provider/edit/' + this.serviceProvider.id">Edit</a>
            <table>
                <thead>
                    <tr>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ serviceProvider.first_name }}</td>
                        <td>{{ serviceProvider.last_name }}</td>
                        <td>{{ serviceProvider.phone }}</td>
                        <td><a :href="'mailto:' + serviceProvider.email">{{ serviceProvider.email }}</a></td>
                    </tr>
                </tbody>
            </table>
            <student-emails :emails="groupEmails" :title="'Group Emails'" :showCCField="false"></student-emails>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.serviceProvider.role + " " + "Service Provider: " + this.serviceProvider.first_name  + " " +  this.serviceProvider.last_name
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                serviceProvider: {},
                groupEmails: []
            }
        },
        methods: {
            load: function(){
                this.id = location.pathname.split('/service-provider/show/')[1];
                this.getServiceProvider();
                this.getGroupEmails();
            },
            getServiceProvider: function(){
                axios.get("/api/service/service-provider/", {params: {id: parseInt(this.id)}})
                    .then(response => {
                        this.serviceProvider = response.data.service_provider;
                    });
            },
            getGroupEmails: function(){
                axios.get("/api/group-email/group-emails-for-generic/", {params: {id: parseInt(this.id), type: "ServiceProvider"}})
                    .then(response => {
                        this.groupEmails = response.data.rows;
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
