<template>
    <div id="container">
<div id="content">
            <student-nav selected="profile" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <component :is="workflowComponent" :id="id" :expiredReason="expiredReason" :expiredComment="expiredComment" :inactiveReason="inactiveReason" :inactiveComment="inactiveComment" :isGuest="isGuest" :studentName="name" v-on:refreshstudent="getStudent"></component>
            <h2>ODS Status</h2>
            <em>{{ status }}</em><br>
            <p v-if="status !== 'Expired' && status !== 'Inactive' && wasOriginallyProspective">Student was originally entered as a prospective.</p>
            <table v-if="status == 'Expired'" width="40%">
                <tbody>
                    <tr>
                        <th>Expired Reason</th>
                        <td>{{ expiredReason }}</td>
                    </tr>
                    <tr>
                        <th>Expired Comment</th>
                        <td>{{ expiredComment }}</td>
                    </tr>
                </tbody>
            </table>
            <table v-if="status == 'Inactive'" width="40%">
                <tbody>
                    <tr>
                        <th>Inactive Reason</th>
                        <td>{{ inactiveReason }}</td>
                    </tr>
                    <tr>
                        <th>Inactive Comment</th>
                        <td>{{ inactiveComment }}</td>
                    </tr>
                </tbody>
            </table>
            <student-information :id="id" :showReferral="true" :student="student"></student-information>
            <div class="static-links">
                <a :href="'/student/edit/' + this.id">Edit student information</a><br>
                <a :href="'/group-email/compose?student_id=' + this.id">Send mail to student</a><br>
                <a :href="'/intake-notes/new?student_id=' + this.id">Create intake note</a><br>
                <a :href="'/intake-notes/new?housing=true&student_id=' + this.id">Create housing intake note</a><br>
                <a :href="'/case-management-notes/new?student_id=' + this.id">Create case note</a><br>
            </div>
            <div v-if="!hasHousingProfile" class="housing-link">
                <a @click="makeHaProspective">Make this student a prospective in the HA system</a>
            </div>
            <br clear="all">
            <student-forms-and-permissions :id="id"></student-forms-and-permissions>
            <student-contact-information :id="id"></student-contact-information>
            <student-last-sis-update-date :id="id"></student-last-sis-update-date>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li v-if="showDeleteLink"><a @click="deleteStudent">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
            <student-history :id="id" :isAcademic="true"></student-history>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    import applicant from '../../components/student/student-workflow/Applicant.vue'
    import coordinator_review from '../../components/student/student-workflow/CoordinatorReview.vue'
    import denied from '../../components/student/student-workflow/Denied.vue'
    import director_review from '../../components/student/student-workflow/DirectorReview.vue'
    import expired from '../../components/student/student-workflow/Expired.vue'
    import guest from '../../components/student/student-workflow/Guest.vue'
    import guest_denied from '../../components/student/student-workflow/GuestDenied.vue'
    import guest_expired from '../../components/student/student-workflow/GuestExpired.vue'
    import guest_inactive from '../../components/student/student-workflow/GuestInactive.vue'
    import guest_registered_temporary from '../../components/student/student-workflow/GuestRegisteredTemporary.vue'
    import ha_only from '../../components/student/student-workflow/HaOnly.vue'
    import inactive from '../../components/student/student-workflow/Inactive.vue'
    import new_student from '../../components/student/student-workflow/NewStudent.vue'
    import one_doc_submitted from '../../components/student/student-workflow/OneDocSubmitted.vue'
    import pending_assignment from '../../components/student/student-workflow/PendingAssignment.vue'
    import pending_orientation from '../../components/student/student-workflow/PendingOrientation.vue'
    import prospective from '../../components/student/student-workflow/Prospective.vue'
    import psychologist_review from '../../components/student/student-workflow/PsychologistReview.vue'
    import registered from '../../components/student/student-workflow/Registered.vue'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
            //workflow components
            applicant,
            coordinator_review,
            denied,
            director_review,
            expired,
            guest,
            guest_denied,
            guest_expired,
            guest_inactive,
            guest_registered_temporary,
            ha_only,
            inactive,
            new_student,
            one_doc_submitted,
            pending_assignment,
            pending_orientation,
            prospective,
            psychologist_review,
            registered
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                workflowComponent: "",
                hasHousingProfile: true,
                inactiveReason: null,
                inactiveComment: null,
                expiredReason: null,
                expiredComment: null,
                wasOriginallyProspective: false,
                intensiveCaseManagement: false,
                isGuest: false,
                showDeleteLink: false,
                student: {}
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/profile/')[1];
                axios.get("/api/student/" + this.id, { params: { id: this.id }})
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.workflowComponent = response.data.status;
                        this.status = response.data.statusDisplay;
                        this.hasHousingProfile = response.data.hasHousingProfile;
                        this.inactiveReason = response.data.inactiveReason;
                        this.inactiveComment = response.data.inactiveReasonComment;
                        this.expiredReason = response.data.expiredReason;
                        this.expiredComment = response.data.expiredReasonComment;
                        this.wasOriginallyProspective = response.data.wasOriginallyProspective;
                        this.intensiveCaseManagement = response.data.intensiveCaseManagement;
                        this.showDeleteLink = response.data.showDeleteLink;

                        if(this.workflowComponent == 'guest_coordinator_review'){
                            this.workflowComponent = 'coordinator_review';
                            this.isGuest = true;
                        } else if(this.workflowComponent == 'guest_director_review'){
                            this.workflowComponent = 'director_review';
                            this.isGuest = true;
                        } else if(this.workflowComponent == 'guest_pending_assignment'){
                            this.workflowComponent = 'pending_assignment';
                            this.isGuest = true;
                        }
                    });



                axios
                    .get('/api/student/information/' + this.id)
                    .then(response => {
                        this.student = response.data;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error);
                    })
            },
            makeHaProspective: function() {
                axios.post('/api/student/make-ha-prospective/', {id: parseInt(this.id)})
                    .then(response => {
                        if(response.data.success){
                          this.$router.push({ path: '/student/ha-profile/' + this.id});
                        }
                    });
            },
            deleteStudent: function() {
                this.$confirm('Are you sure you want to delete all records for student ' + this.name + '?  There is no undo.')
                    .then(() => {
                        axios.delete('/api/student/', {params: {id: this.id}})
                            .then(response => {
                                if(response.data.success){
                                  this.$router.push({ path: '/search'});
                                }
                            })
                            .catch(error => {
                                // eslint-disable-next-line
                                console.log(error);
                            });
                    }).catch(() => {
                    });
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style scoped>
    .static-links {
        width:200px;
        float:left;
        margin-bottom:20px;
    }
    .housing-link {
        width:350px;
        float:left;
    }
    .inactive-inputs-label {
        padding-right: 2px;
    }
</style>
