<template>
    <div id="container">
<div id="content">
            <student-groups :pathParams="pathParams" :urlParams="urlParams">
                <student-groups-coordinator v-if="showGroupsCoordinator" :coordinatorId="this.coordinator_id"></student-groups-coordinator>
            </student-groups>
        </div>
        </div>
</template>

<script>


    export default {
        components: {
        },
        data () {
            return {
                pathParams: {},
                urlParams: {},
                showGroupsCoordinator: false
            }
        },
        methods: {
            load: function () {
                //this.pathParams = this.getStatusDataParams('/student/groups/');

                this.urlParams = this.$route.query;
                if(location.search.indexOf("coordinator_id") != -1){
                    var coordinator_id = location.search.match(/.*?coordinator_id=((\w|\d)*).*/)[1];

                    if(coordinator_id && coordinator_id != "unassigned"){
                        this.showGroupsCoordinator = true;
                        this.coordinator_id = coordinator_id;
                    }
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
