<template>
    <div id="container">
<div id="content">
            <student-nav selected="ha-accommodations" :id="id" :name="name" :uni="uni" :haStatus="haStatus"></student-nav>
            <br clear="all">
            <student-ha-instructions :id="id"></student-ha-instructions>
            <div class="ha-menu" v-if="canEditHousingAccommodations">
                <a :href="'/student/ha-accommodations-edit/' + id">Edit this section</a>
            </div>
            <student-ha :id="id"></student-ha>
            <student-housing-committee-notes :id="id"></student-housing-committee-notes>

        </div>
        </div>
</template>

<script>
    import axios from 'axios'


    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                haStatus: "",
                canEditHousingAccommodations: false
            }
        },
        methods: {
            load: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/ha-accommodations/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.haStatus = response.data.haStatus;
                    });


                axios.get("/api/simple/check-permissions/", {params: {permissions: ['can_edit_housing_accommodations']}})
                    .then(response => {
                        this.canEditHousingAccommodations = response.data.permissions.can_edit_housing_accommodations;
                    }) ;
            }

        },
        created () {
            this.load();
        }
    }
</script>

<style lang="postcss" scoped>
    .ha-menu {
        margin-top: 20px;
        margin-bottom: 3px;
    }
</style>
