<template>
  <div id="container">
    <div id="content">
      <h1 v-if="!showEmailsRegarding">Sent Group Email </h1>
      <template v-if="!groupEmailId && !showEmailsRegarding">
      <el-form >
        <el-form-item >
          <span class="label">Search item 1 </span><el-select  style="width: 100px" v-model="searchField1" placeholder="Please Select" size="mini">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
          <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm1" style="width: auto" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <span class="label">Search item 2 </span><el-select  style="width: 100px" v-model="searchField2" placeholder="Please Select" size="mini">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
          <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm2" style="width: auto" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <span class="label">Search item 3 </span><el-select  style="width: 100px" v-model="searchField3" placeholder="Please Select" size="mini">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
          <span class="label">Enter keywords, student names, or UNIs </span><el-input v-model="searchTerm3" style="width: auto" size="mini"></el-input>
          <el-button type="primary"  size="mini" @click="resetAndHandleSearch">Search</el-button>
        </el-form-item>
      </el-form>
      <el-pagination layout="total, ->, prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="handleChangePage"></el-pagination>
      <el-table :data="sent" height="750" @sort-change="handleSort">
        <el-table-column prop="email_from" label="From" sortable="custom" :sort-orders="['ascending', 'descending']" width="120"></el-table-column>
        <el-table-column prop="count" label="#" sortable="custom" :sort-orders="['ascending', 'descending']" width="70" ></el-table-column>
        <el-table-column prop="created_at" label="Date" sortable="custom" :sort-orders="['ascending', 'descending']" width="95">
          <template slot-scope="scope">
            {{scope.row.created_at | formatDateMDY}}
          </template>
        </el-table-column>
        <el-table-column prop="subject" label="Subject" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column>
        <el-table-column label="" width="70">
          <template slot-scope="scope">
            <!--
            <el-button @click.native.prevent="handleShow(scope.$index, sent)" type="text" size="small" class="minpadding">Show</el-button>
            -->
            <router-link :to="'/group-email/show/' + scope.row.id">Show</router-link>
          </template>
        </el-table-column>
      </el-table>
      </template>


      <student-emails :emails="sent" :title="'Sent Emails Regarding ' + regardingTitle" :showCCField="true" v-if="showEmailsRegarding && !groupEmailId"></student-emails>

      <div v-if="groupEmailId" id="sent-email-panel">
        <p>
        <router-link to="/group-email/sent">&lt; All Sent</router-link>
        </p>
        <table>
          <tr><td class="left-td"><b>From: </b></td><td>{{from}}</td></tr>
          <tr><td><b>To: </b></td><td><div class="toList" v-html="to"></div></td></tr>
          <tr v-if="cc"><td><b>CC: </b></td><td><div class="toList" v-html="cc"></div></td></tr>
          <tr><td><b>Date: </b></td><td>{{createdAt | formatDateTimeMDYhma}}</td></tr>
          <tr><td><b>Subject:</b></td><td>{{subject}}</td></tr>
          <tr v-if="attachments.length"><td><b>Attachments: </b></td><td>
             <div v-for="attachment in attachments" :key="attachment.id">
               <a :href="'/api/group-email/download-attachment/' + groupEmailId + '/' + attachment.id + '/' + attachment.name" target="_blank">{{attachment.name}}</a><br>
             </div>
          </td></tr>
        </table>
        <div id="html-preview" class="sent-email-html" v-html="html"></div>
      </div>
      <br clear="all">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'


  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Sent Group Emails"
      }
    },

    components: {
    },
    data () {
      return {
        id: '',
        from: '',
        to: '',
        cc: '',
        createdAt: '',
        subject: '',
        html: '',
        sent: [],
        attachments: [],
        pageSize: 200,
        prop: 'created_at',
        order: 'descending',
        currentPage: 1,
        total: 1000,
        searchField1: 'subject',
        searchTerm1: '',
        searchField2: 'subject',
        searchTerm2: '',
        searchField3: 'subject',
        searchTerm3: '',
        options: [{
          'value': 'subject',
          'label': 'Subject'
        }, {
          'value': 'sender',
          'label': 'Sender'
        }, {
          'value': 'body',
          'label': 'Text'
        }],
        groupEmailId: '',
        showEmailsRegarding: false,
        regardingTitle: ''
      }
    },
    watch: {
     '$route.params.id': function(id) {
       this.groupEmailId = this.$route.params.id;
       if(this.groupEmailId) {
         this.handleShow();
       } else {
         this.load();
       }
     }
    },
    methods: {
      handleChangePage: function(val) {
        this.currentPage = val;
        if(this.searchTerm1 || this.searchTerm2 || this.searchTerm3){
          this.handleSearch();
        } else {
          this.load();
        }

      },
      handleShow: function() {
        if(this.groupEmailId) {
          axios
            .get('/api/group-email/sent-email-body/?id=' + this.groupEmailId)
            .then(response => {
              if (response.data.success) {
                this.subject = response.data.subject
                this.from = response.data.from
                this.createdAt = response.data.created_at
                this.html = converter.makeHtml(response.data.body)
                if(response.data.cc){
                  this.cc = response.data.cc.join("<br>");
                }
              }
              this.loadAttachments();
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });
          axios
            .get('/api/group-email/sent-email-recipients/?id=' + this.groupEmailId)
            .then(response => {
              if (response.data.success) {
                //this.to = response.data.rows.join("<br>").replace('<', '&lt;').replace('>', '&gt;');
                this.to = response.data.rows.join("<br>");

              }
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            });
        }
      },
      loadAttachments: function() {
        this.attachments = [];
        axios.get("/api/group-email/email-attachments/?id=" + this.groupEmailId)
          .then(response => {
            if(response.data.success) {
              for(var i=0; i<response.data.rows.length; i++) {
                this.attachments.push({
                  id: response.data.rows[i].id,
                  name: response.data.rows[i].asset_file_name,
                  fileSize: response.data.rows[i].asset_file_size,
                  contentType: response.data.rows[i].asset_content_type
                });
              }
            }
          });
      },
      load: function () {
        this.urlParams = this.$route.query;
        this.groupEmailId = this.$route.params.id;
        if(this.groupEmailId) {
          this.handleShow();
        } else {
          if(this.urlParams.regarding_id && this.urlParams.regarding_class){
            this.showEmailsRegarding = true

            this.regardingTitle = this.urlParams.regarding_class.split(/(?=[A-Z])/).join(" ");

            axios.get("/api/group-email/group-emails-regarding-generic/", {params: {id: this.urlParams.regarding_id, type: this.urlParams.regarding_class,
                sent_from_master_list: this.urlParams.sent_from_master_list}})
                    .then(response => {
                      this.sent = response.data.rows;
                    });
          } else {
            axios
                    .get('/api/group-email/sent-emails/?start=' + this.pageSize * (this.currentPage - 1) + '&limit=' + this.pageSize + '&prop=' + this.prop + '&order=' + this.order + '&emailId=' + this.groupEmailId)
                    .then(response => {
                      if (response.data.success) {
                        this.sent = response.data.rows;
                        this.total = response.data.total;

                        //if(this.currentPage == 1 && this.groupEmailId != ''){
                      }
                    })
                    .catch(error => {
                      // eslint-disable-next-line
                      console.log(error)
                    });
          }

        }
      },
      resetAndHandleSearch: function(){
        this.currentPage = 1;
        this.handleSearch();
      },
      handleSearch: function() {
        var url = '/api/group-email/sent-emails/?';
        if(this.searchTerm1) url += 'search_field_1=' + this.searchField1 + '&' + 'search_term_1=' + this.searchTerm1 + '&';
        if(this.searchTerm2) url += 'search_field_2=' + this.searchField2 + '&' + 'search_term_2=' + this.searchTerm2 + '&';
        if(this.searchTerm3) url += 'search_field_3=' + this.searchField3 + '&' + 'search_term_3=' + this.searchTerm3 + '&';
        url += 'start=' + this.pageSize * (this.currentPage-1) + '&limit=' + this.pageSize + '&prop=' + this.prop + '&order=' + this.order;
        // eslint-disable-next-line
        console.log(url);
        axios
          .get(url)
          .then(response => {
            if(response.data.success) {
              this.sent = response.data.rows;
              this.total = response.data.total;
            }
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error)
          });
      },
      handleSort: function({column, prop, order}) {
        this.prop = prop;
        this.order = order;

        this.load();
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  #html-preview h1, #html-preview h2, #html-preview h3 {
    color: black;
    border-bottom: none;
    padding: 0;
  }
  #html-preview {
    margin: 0;
    padding-left: 10px;
    width: 80ch;
    float: left;
    background: #EBEFE5;
  }
  .minpadding {
    padding: 0 3px;
  }
  .toList {
    margin: 0;
    padding: 0;
    max-height: 10.5em;
    overflow-y: scroll;
  }
  #right-panel, #sent-email-panel {
    margin: 0;
    padding: 0 0 0 10px;
    width: 80ch;
    float: left;
  }
  #right-panel table, #right-panel td, #sent-email-panel table, #sent-email-panel td {
    margin: 0;
    padding: 0;
    font-size: 1em;
    border: 0;
  }
  #right-panel table td, #sent-email-panel td {
    padding-bottom: 0.9em;
  }
  #right-panel table td.left-td, #sent-email-panel td.left-td {
    width: 150px;
  }
  .label {
    margin-left: 0.8em;
    font-size: 13px;
  }

</style>
