<template>
    <div id="container">
<div id="content">
            <h1>Master List - No Identifiers Report</h1>
            <a :href="'/api/report/master-list-no-identifiers.xlsx?start_date=' + this.startDate + '&end_date=' + this.endDate" target="_blank">Download as CSV</a>
            <p><b>Total Exams: {{ data.length }}; Total Night (finish after 5pm) Exams: {{ totalNightExams }}</b></p>
            <el-table :default-sort="{prop: 'start_time', order: 'ascending'}" :data="data" style="width: 100%" class="table-top-align">
                <el-table-column prop="start_time" label="Exam Date" sortable :sort-orders="['ascending', 'descending']" width="115">
                    <template slot-scope="scope">{{ scope.row.start_time | formatDateMDY }}</template>
                </el-table-column>
                <el-table-column prop="start_time" label="Exam Time" sortable :sort-orders="['ascending', 'descending']" width="145">
                    <template slot-scope="scope">
                        {{ scope.row.start_time | formatTimehhmmA }} - {{ scope.row.end_time | formatTimehhmmA }}
                    </template>
                </el-table-column>
                <el-table-column prop="class_name" label="Course" sortable :sort-orders="['ascending', 'descending']" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.department }} {{ scope.row.course_number }} SEC <b>{{ scope.row.section_code }}</b><br>{{ scope.row.class_name }}
                    </template>
                </el-table-column>
                <el-table-column prop="instructor_name" label="Instructor" sortable :sort-orders="['ascending', 'descending']" width="160">
                    <template slot-scope="scope">{{ scope.row.instructor_name }}<br>{{ scope.row.instructor_email }}</template>
                </el-table-column>
                <el-table-column prop="location" label="Location of Exam" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="exam_equipment" label="Equipment">
                    <template slot-scope="scope">
                        <template v-if="scope.row.exam_equipment && scope.row.exam_equipment.indexOf('Computer') !== -1">
                            1 Computer – Exam Admin ({{ scope.row.computer_type_preference ? scope.row.computer_type_preference : "Any" }})<br>
                        </template>
                        <template v-if="studentComputer(scope.row.enrollment_accommodations)">
                            1 Computer – Student Use ({{ scope.row.ultimate_computer_choice }})<br>
                        </template>
                        <template v-for="equipment in scope.row.exam_equipment">
                            <template v-if="equipment != 'Computer'">
                                {{ equipment }}
                            </template>
                        </template>
                        <template v-if="scope.row.exam_equipment.length === 0 && !studentComputer(scope.row.enrollment_accommodations)">None</template>
                    </template>
                </el-table-column>
                <el-table-column prop="enrollment_accommodations" label="Student Accommodations">
                    <template slot-scope="scope">
                        <template v-for="accommodation in scope.row.enrollment_accommodations">
                            <template v-if="accommodation.name != 'Rest Breaks'">
                                <template v-if="accommodation.accommodation_type_id == 8">
                                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ scope.row.ultimate_computer_choice }}</span><br :key="accommodation.id">
                                </template>
                                <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id">
                                </template>
                                <template v-else>
                                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}</span><br :key="accommodation.id">
                                </template>
                            </template>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="is_placeholder_exam" label="Is Placeholder?" sortable :sort-orders="['ascending', 'descending']" width="100">
                    <template slot-scope="scope">{{ scope.row.is_placeholder_exam ? 'Yes' : '' }}</template>
                </el-table-column>
                <el-table-column prop="is_cancelled" label="Is Cancelled?" sortable :sort-orders="['ascending', 'descending']" width="100">
                    <template slot-scope="scope">{{ scope.row.is_cancelled ? 'Yes' : '' }}</template>
                </el-table-column>
            </el-table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Master List - No Identifiers"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                startDate: null,
                endDate: null,
                totalNightExams: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.startDate = this.urlParams.start_date;
                this.endDate = this.urlParams.end_date;
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/master-list-no-identifiers/', {params: {start_date: this.startDate, end_date: this.endDate}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });

                axios.get('/api/testing-service/master-list-exam-info/', {params: {start_date: this.startDate, end_date: this.endDate}})
                    .then(response => {
                        if(response.data.success) {
                            this.totalNightExams = response.data.total_night_exams;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            studentComputer: function(accommodations){
                if(accommodations){
                    for(var i = 0; i < accommodations.length; i++){
                        if(accommodations[i].accommodation_type_id == 8){
                            return true;
                        }
                    }
                }
                return false;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
