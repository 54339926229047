<template>
    <div id="container">
<div id="content">
            <h1>Room Reservation Contacts</h1>
            <el-table :default-sort="{prop: 'first_name', order: 'ascending'}" :data="managers">
                <el-table-column prop="first_name" label="First Name" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="last_name" label="Last Name" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="room_manager_for" label="Room Manager For" sortable :sort-orders="['ascending', 'descending']" width="325">
                    <template slot-scope="scope">
                        <template v-for="(room, index) in scope.row.room_manager_for">
                            <br v-if="index !== 0" :key="index">{{ room }}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']" width="180"></el-table-column>
                <el-table-column prop="office_phone" label="Office Phone" sortable :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                <el-table-column prop="mobile_phone" label="Mobile" sortable :sort-orders="['ascending', 'descending']" width="120"></el-table-column>
                <el-table-column prop="office_location" label="Office Location" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column width="56"><template slot-scope="scope"><a :href="'/room-reservation-manager/edit/' + scope.row.id">edit</a></template></el-table-column>
                <el-table-column width="70"><template slot-scope="scope"><a @click="onDelete(scope.row.id)">delete</a></template></el-table-column>
            </el-table>
            <p><a :href="'/room-reservation-manager/edit'">Add new contact</a></p>
            <p v-if="total"><a :href="'/api/room-reservation-manager/managers.xlsx'" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Room Reservation Contacts"
            }
        },
        components: {
            },
        data () {
            return {
                managers: [],
                total: null
            }
        },
        methods: {
            load: function() {
                axios.get('/api/room-reservation-manager/managers/')
                    .then(response => {
                        this.managers = response.data.rows;
                        this.total = response.data.total;
                    });
            },
            onDelete: function(id){
                this.$confirm('This will delete the room reservation contact. Are you sure?')
                    .then(() => {
                        axios.delete('/api/room-reservation-manager/', {params: {id: id}})
                            .then(response => {
                                if(response.data.success){
                                    this.load();
                                }
                            });
                    }).catch(() => {});
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
