import { render, staticRenderFns } from "./BooksByStatus.vue?vue&type=template&id=6d0a6845&scoped=true&"
import script from "./BooksByStatus.vue?vue&type=script&lang=js&"
export * from "./BooksByStatus.vue?vue&type=script&lang=js&"
import style0 from "./BooksByStatus.vue?vue&type=style&index=0&id=6d0a6845&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d0a6845",
  null
  
)

export default component.exports