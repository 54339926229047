<template>
    <div id="container">
        <div id="content">
            <h1>Edit Name and Address</h1>
            <el-form label-width="200px" :model="user" :rules="rules" ref="userForm" label-position="right">
                <el-form-item label="Title">
                    <el-input size="mini" v-model="user.prefix" style="width:85px;"></el-input>
                </el-form-item>
                <el-form-item label="First Names">
                    <el-input size="mini" v-model="user.firstNames"></el-input>
                </el-form-item>
                <el-form-item label="Last Name">
                    <el-input size="mini" v-model="user.lastName"></el-input>
                </el-form-item>
                <el-form-item label="Suffix">
                    <el-input size="mini" v-model="user.suffix" style="width:150px;"></el-input>
                </el-form-item>
                <el-form-item label="Job Title">
                    <el-input size="mini" v-model="user.jobTitle"></el-input>
                </el-form-item>
                <el-form-item label="Email Address">
                    <el-input size="mini" v-model="user.email"></el-input>
                </el-form-item>
                 <el-form-item label="Password" prop="password">
                    <el-input size="mini" type="password" v-model="user.password" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Re-type Password" prop="passwordConfirmation">
                    <el-input size="mini" type="password" v-model="user.passwordConfirmation" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Cell Phone #">
                    <el-input size="mini" v-model="user.cellPhone" style="width:125px;"></el-input>
                </el-form-item>
                <el-form-item label="Home Phone #">
                    <el-input size="mini" v-model="user.homePhone" style="width:125px;"></el-input>
                </el-form-item>
                <el-form-item label="Work Phone #">
                    <el-input size="mini" v-model="user.workPhone" style="width:125px;"></el-input>
                </el-form-item>
                <el-form-item label="Primary Address">
                    <el-input size="mini" v-model="user.address1"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size="mini" v-model="user.address2"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input size="mini" v-model="user.address3"></el-input>
                </el-form-item>
                <el-form-item label="City/State/ZIP">
                    <el-input size="mini" v-model="user.city" style="width:190px; margin-bottom:2px"></el-input>
                    <el-select size="mini" v-model="user.state" style="width:162px">
                        <el-option v-for="state in states" :key="state.value" :value="state.value" :label="state.name"></el-option>
                    </el-select>
                    <el-input size="mini" v-model="user.zip" style="width:70px;"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="onSave('userForm')">Save Changes</el-button> or <a :href="'/user'">Cancel</a>
                </el-form-item>
            </el-form>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: 'Edit Name and Address'
            }
        },
        components: {
            },
        data () {
            let validatePassword = (rule, value, callback) => {
                // console.log('validating password');
                // console.log(value !== '');
                // console.log(this.user.passwordConfirmation !== '');
                if (value !== '') {
                    if (this.user.passwordConfirmation !== '') {
                        this.$refs.userForm.validateField('passwordConfirmation');
                        //callback();
                    } //else {
                    // eslint-disable-next-line
                        console.log('validating password inner');
                        axios.post('/api/admin/validate-pwd', {id: this.user.id, pwd: this.user.password})
                                .then(response => {
                                    if(response.data.success) {
                                        callback();
                                    } else {
                                        callback(new Error(response.data.message));
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                    callback();
                                });
                   // }
                } else {
                    callback();
                }
            };
            let validatePasswordConfirmation = (rule, value, callback) => {
                if (this.user.password !== '' && value === '') {// eslint-disable-next-line
                    console.log('validating password confirmation 1');
                    callback(new Error('Please input the password again'));
                } else if (value !== this.user.password) {// eslint-disable-next-line
                    console.log('validating password confirmation 2');
                    callback(new Error('Passwords don\'t match!'));
                } else {// eslint-disable-next-line
                    console.log('validating password confirmation 3');
                    callback();
                }
            };
            return {
                user: {
                    id: null,
                    prefix: "",
                    firstNames: "",
                    lastName: "",
                    suffix: "",
                    jobTitle: "",
                    email: "",
                    password: null,
                    passwordConfirmation: null,
                    cellPhone: "",
                    homePhone: "",
                    workPhone: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    city: "",
                    state: "",
                    zip: ""
                },
                states: [],
                rules: {
                    email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
                    password: [{ validator: validatePassword, trigger: 'change' }],
                    //password: [{ validator: validatePassword, trigger: 'blur' }],
                    passwordConfirmation: [{ validator: validatePasswordConfirmation, trigger: 'change' }]
                }
            }
        },
        methods: {
            getStates: function() {
                axios.get("/api/simple/states")
                    .then(response => {
                        this.states = response.data.rows

                });
            },
            getUser: function () {
                 axios.get("/api/admin/user")
                    .then(response => {
                        this.user.id = response.data.id;
                        this.user.prefix = response.data.prefix;
                        this.user.firstNames = response.data.first_names;
                        this.user.lastName = response.data.last_name;
                        this.user.suffix = response.data.suffix;
                        this.user.jobTitle = response.data.job_title;
                        this.user.email = response.data.email;
                        this.user.cellPhone = response.data.cell_phone;
                        this.user.homePhone = response.data.home_phone;
                        this.user.workPhone = response.data.work_phone;
                        this.user.address1 = response.data.address_1;
                        this.user.address2 = response.data.address_2;
                        this.user.address3 = response.data.address_3;
                        this.user.city = response.data.city;
                        this.user.state = response.data.state;
                        this.user.zip = response.data.zip;
                    });
            },
            onSave: function (formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        axios.put('/api/user', this.user)
                                .then(response => {
                                    if(response.data.success) {
                                        //console.log("success");
                                        this.$router.push({ path: '/user'});
                                    } else {
                                        // eslint-disable-next-line
                                        console.log("failed:" + response.data.message);
                                        this.displayError(response.data.message);
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                });
                    } else {
                        //return message
                        // eslint-disable-next-line
                        console.log("not valid");
                    }
                });
            }
        },
        created () {
            this.getUser();
            this.getStates();
        }
    }
</script>

<style scoped>
    #content .el-form {
        width:550px;
    }
/*    .el-form-item__content {
        font-size: small;
    }*/
/*    #content .el-form-item__content {
        line-height: inherit;
    }*/
</style>
