<template>
    <div id="container">
<div id="content">
            <h1>Contact Notes Statistics</h1>
            <template v-for="(row, rowIndex) in data">
                <h2 :key="rowIndex">{{ row.date | formatDateMonthAndYear }} Contact Notes</h2>
                <table width="33%" :key="rowIndex"><tbody>
                    <tr>
                        <th width="50%">Total</th>
                        <td>{{ row.count }}</td>
                    </tr>
                </tbody></table>
                <table v-if="row.statuses && row.statuses.length" width="33%" :key="rowIndex"><tbody>
                    <tr v-for="(status, statusIndex) in row.statuses" :key="statusIndex">
                        <th width="50%">{{ status.status_display }}</th>
                        <td>{{ status.count }}</td>
                    </tr>
                </tbody></table>
                <table v-if="row.authors && row.authors.length" width="33%" :key="rowIndex">
                        <tbody>
                        <tr v-for="(author, index) in row.authors" :key="index">
                            <th width="50%">{{ author.author_name }}</th>
                            <td>{{ author.count }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Contact Notes Statistics"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: null,
                period: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.period = this.urlParams.period;
                this.getData();
            },
            getData: function() {
                this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/contact-note-statistics/', {params: {period: this.period}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
