<template>
    <div id="container">
      <div id="content">
            <h1>Listing group of related contact notes</h1>
            <a @click="toggleShowDetails">{{ showDetails ? "hide details" : "show all details" }}</a> |
            <a @click="checkAllNotes">select all</a>
            <a @click="clearAllNotes">select none</a>
            <template v-if="uniqueStatuses.length">
                select by status: <template v-for="(status, index) in uniqueStatuses"><a @click="selectNotesByStatus(status)" :key="index">{{ status }}</a>&nbsp;</template>
            </template>

            <template v-if="showDetails">
                <template v-for="note in contactNotes">
                    <table width="100%" :key="note.id">
                        <thead>
                            <tr>
                                <th><img v-if="prop === 'student_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'student_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('student_name')">Student Name</a></th>
                                <th><img v-if="prop === 'subject' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'subject' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('subject')">Subject/Heading</a></th>
                                <th><img v-if="prop === 'contact_types' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'contact_types' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('contact_types')">Type of Contact</a></th>
                                <th><img v-if="prop === 'reasons' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'reasons' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('reasons')">Reason for Contact</a></th>
                                <th><img v-if="prop === 'author_name' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'author_name' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('author_name')">ODS Staff</a></th>
                                <th><img v-if="prop === 'status_display' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'status_display' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('status')">Status</a></th>
                                <th><img v-if="prop === 'created_at' && order ==='ascending'" alt="Sort-asc" :src="sortAsc"><img v-if="prop === 'created_at' && order ==='descending'" alt="Sort-desc" :src="sortDesc"><a @click="handleDetailSort('created_at')">Date of Contact</a></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a :href="'/student/profile/' + note.student_id">{{ note.student_name }}</a></td>
                                <td>{{ note.subject }}</td>
                                <td>{{ note.contact_types ? note.contact_types.join(", ") : null }}</td>
                                <td>{{ note.reasons ? note.reasons.join(", ") : null }}</td>
                                <td>{{ note.author_name }}</td>
                                <td>{{ note.status_display }}</td>
                                <td>{{ note.created_at | formatDateMDY }} {{ note.author_identifier }}</td>
                              <td><router-link :to="'/contact-notes/show/'+note.id">show</router-link></td>
                                <td><el-checkbox v-model="note.isChecked"></el-checkbox></td>
                            </tr>
                            <tr v-if="note.text"><td colspan="9"><span class="formatted-text">{{ note.text }}</span></td></tr>
                            <tr v-if="note.asset1_file_name || note.asset2_file_name || note.asset3_file_name || note.asset4_file_name || note.asset5_file_name">
                                <td colspan="9">File attachment(s): <span class="text-italic">
                                    <template v-if="note.asset1_file_name"><a :href="'/contact-notes/download/' + note.id + '?column_name=asset1_file_name'">{{ note.asset1_file_name }}</a></template>
                                    <template v-if="note.asset2_file_name">, <a :href="'/contact-notes/download/' + note.id + '?column_name=asset2_file_name'">{{ note.asset2_file_name }}</a></template>
                                    <template v-if="note.asset3_file_name">, <a :href="'/contact-notes/download/' + note.id + '?column_name=asset3_file_name'">{{ note.asset3_file_name }}</a></template>
                                    <template v-if="note.asset4_file_name">, <a :href="'/contact-notes/download/' + note.id + '?column_name=asset4_file_name'">{{ note.asset4_file_name }}</a></template>
                                    <template v-if="note.asset5_file_name">, <a :href="'/contact-notes/download/' + note.id + '?column_name=asset5_file_name'">{{ note.asset5_file_name }}</a></template></span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot><tr><td colspan="9"></td></tr></tfoot>
                    </table>
                </template>
            </template>
            <template v-else>
                <el-table :default-sort="{prop: 'created_at', order: 'descending'}" :data="contactNotes" @sort-change="handleSort">
                    <el-table-column prop="student_name" label="Student Name" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope"><a :href="'/student/profile/' + scope.row.student_id">{{ scope.row.student_name }}</a></template></el-table-column>
                    <el-table-column prop="subject" label="Subject/Heading" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column>
                    <el-table-column prop="author_name" label="ODS Staff" sortable="custom" :sort-orders="['ascending', 'descending']" width="130"></el-table-column>
                    <el-table-column prop="status_display" label="Status" sortable="custom" :sort-orders="['ascending', 'descending']" width="125"></el-table-column>
                    <el-table-column prop="created_at" label="Date of contact" sortable="custom" :sort-orders="['ascending', 'descending']" width="150"><template slot-scope="scope">{{ scope.row.created_at | formatDateMDY }} {{ scope.row.author_identifier }}</template></el-table-column>
                    <el-table-column prop="num_files" label="Files" sortable="custom" :sort-orders="['ascending', 'descending']" width="78"><template slot-scope="scope">{{ scope.row.num_files > 0 ? scope.row.num_files : "None" }}</template></el-table-column>
                  <el-table-column width="64"><template slot-scope="scope"><router-link :to="'/contact-notes/show/'+scope.row.id">show</router-link></template></el-table-column>
                    <el-table-column width="45"><template slot-scope="scope"><el-checkbox v-model="scope.row.isChecked"></el-checkbox></template></el-table-column>
                </el-table>
            </template>
            <br>
            <el-button v-if="showDeleteButton" size="mini" type="primary" @click="deleteSelectedNotes" :disabled="showSupervisorCommentsSection">Delete selected</el-button><el-button v-if="showSubmitButton" size="mini" type="primary" @click="performBatchAction('submit')" :disabled="showSupervisorCommentsSection">Submit selected</el-button><el-button v-if="showApproveButton" size="mini" type="primary" @click="performBatchAction('approve')" :disabled="showSupervisorCommentsSection">Approve selected</el-button><el-button v-if="showRequiresEditsButton" size="mini" type="primary" @click="showSupervisorCommentsSection = true" :disabled="showSupervisorCommentsSection">Require Edits for selected</el-button>
            <template v-if="showSupervisorCommentsSection">
                <h2>Supervisor Comments</h2>
                <el-input size="mini" type="textarea" :rows="10" v-model="comment" style="width:700px"></el-input><br>
                <el-button size="mini" type="primary" @click="performBatchAction('requires_edits')">Save comments and set status to Edits Required</el-button> or <a @click="getContactNotes();showSupervisorCommentsSection = false">Cancel</a>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    import sortAsc from '../../assets/img/sort-asc.gif'
    import sortDesc from '../../assets/img/sort-desc.gif'


    export default {
        metaInfo () {
            return {
                title: "Listing group of related contact notes"
            }
        },
        components: {
            },
        data () {
            return {
                prop: "created_at",
                order: "descending",
                groupId: 0,
                showDetails: false,
                uniqueStatuses: [],
                comment: null,
                contactNotes: [],
                showDeleteButton: false,
                showSubmitButton: false,
                showApproveButton: false,
                showRequiresEditsButton: false,
                showSupervisorCommentsSection: false,
                sortAsc: sortAsc,
                sortDesc: sortDesc
            }
        },
        methods: {
            load: function() {
                this.groupId = location.pathname.split('/contact-notes/show-group/')[1];
                this.getContactNotes();
            },
            toggleShowDetails: function(){
                this.showDetails = !this.showDetails;
                this.getContactNotes();
            },
            getContactNotes: function() {
                axios.get('/api/contact-note/list/', {params: {group_id: this.groupId, show_details: this.showDetails, prop: this.prop, order: this.order}})
                    .then(response => {
                        if(response.data.success) {
                            this.contactNotes = response.data.rows;
                            this.comment = response.data.comment;
                            this.uniqueStatuses = response.data.unique_statuses;
                            this.showDeleteButton = response.data.show_delete_selected_button;
                            this.showSubmitButton = response.data.show_submit_selected_button;
                            this.showApproveButton = response.data.show_approve_selected_button;
                            this.showRequiresEditsButton = response.data.show_requires_edits_from_selected_button;
                            this.clearAllNotes();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            handleSort: function(sortObj){
                this.prop = sortObj.prop;
                this.order = sortObj.order;
                this.getContactNotes();
            },
            checkAllNotes: function(){
                for(var i = 0; i < this.contactNotes.length; i++){
                    this.$set(this.contactNotes[i], 'isChecked', true);
                }
            },
            clearAllNotes: function(){
                for(var i = 0; i < this.contactNotes.length; i++){
                    this.$set(this.contactNotes[i], 'isChecked', false);
                }
            },
            selectNotesByStatus: function(selectByStatus){
                for(var i = 0; i < this.contactNotes.length; i++){
                    if(this.contactNotes[i].status_display == selectByStatus){
                        this.$set(this.contactNotes[i], 'isChecked', true);
                    }
                }
            },
            deleteSelectedNotes: function(){
                this.$message.closeAll();
                this.$confirm('Are you sure you want to delete these contact notes?')
                    .then(() => {
                        var notesToDeleteIds = [];
                        for(var i = 0; i < this.contactNotes.length; i++){
                            if(this.contactNotes[i].isChecked){
                                notesToDeleteIds.push(this.contactNotes[i].id);
                            }
                        }
                        if(notesToDeleteIds.length){
                            axios.post("/api/contact-note/batch-actions/", {ids: notesToDeleteIds, action: 'delete'})
                                .then(response => {
                                    if(response.data.success){
                                        this.$message({
                                            showClose: true,
                                            duration: 20000,
                                            type: 'success',
                                            message: "The contact notes have been deleted."
                                        });
                                        this.getContactNotes();
                                    }
                                });
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 20000,
                                type: 'error',
                                message: "No contact notes were selected."
                            });
                        }
                    }).catch(() => {
                        // do nothing
                    });
            },
            performBatchAction: function(action){
                this.$message.closeAll();
                var message;

                if(action == 'submit'){
                    message = "The contact notes have been submitted for review."
                } else if(action == 'approve'){
                    message = "The contact notes have been approved.";
                } else if(action == 'requires_edits'){
                    message = "The contact notes have been marked as requiring edits.";
                }
                var selectedNoteIds = [];
                for(var i = 0; i < this.contactNotes.length; i++){
                    if(this.contactNotes[i].isChecked){
                        selectedNoteIds.push(this.contactNotes[i].id);
                    }
                }
                if(selectedNoteIds.length){
                    axios.post("/api/contact-note/batch-actions/", {ids: selectedNoteIds, action: action, comment: this.comment})
                        .then(response => {
                            if(response.data.success){
                                this.$message({
                                    showClose: true,
                                    duration: 20000,
                                    type: 'success',
                                    message: message
                                });
                                this.getContactNotes();
                            }
                        });
                } else {
                    this.$message({
                        showClose: true,
                        duration: 20000,
                        type: 'error',
                        message: "No contact notes were selected."
                    });
                }
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
