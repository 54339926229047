<template>
    <div id="container">
<div id="content">
            <student-nav selected="emergency-evac" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div style="position:relative">
                <student-emergency-evac :id="id"></student-emergency-evac>
                <a :href="'/student/emergency-evacuation-edit/' + id">Add Emergency Evac</a>
            </div>            
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: ""
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/emergency-evacuation/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
