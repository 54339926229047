import { render, staticRenderFns } from "./CaseLoadByDate.vue?vue&type=template&id=71c38a86&scoped=true&"
import script from "./CaseLoadByDate.vue?vue&type=script&lang=js&"
export * from "./CaseLoadByDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/jenkins_node_modules/doris/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c38a86",
  null
  
)

export default component.exports