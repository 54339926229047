<template>
    <div id="container">
<div id="content">
            <h1>Instructions for preparing Accommodation Letters in Doris</h1>
            <p><a :href="'/student/accommodation-letters/' + this.studentId">Return to student screen</a></p>
            <ol>
                <li>Click either "Create accommodation letters for all current courses" or "Create a letter in response to a specific request" and select the requested course.</li>
                <li>Click "Download CSV for letter mail merge."</li>
                <li>Save the Excel file as the student's name under the appropriate Accommodation Letter folder on the ODS drive. When a dialog box pops up, click "Yes."</li>
                <li>Open Microsoft Word. Select File, New, Template, and click Accommodation Letter Template. It will later show under "Recently Used Templates" on the right hand side of the screen. Click "Yes" when a dialog box pops up.</li>
                <li>Select Tools, Letters and Mailings, Mail Merge.</li>
                <li>Ensure that the panel at the right hand side of the screen is at Step 1 of 6. Go back if necessary and select Letters.</li>
                <li>Click Next to use the current document. Click Next to Select Recipients. At Step 3 click "Browse" and select the Excel spreadsheet you saved under the student's name. Open a Read copy, and click OK on the dialog box.</li>
                <li>Click Next until step 6 and click Edit Individual Letters. The letters will be in one file, which you will now save under the appropriate Accommodation Letter folder on the ODS drive and print.</li>
                <li>Go back to DORIS and click "All Requested Letters have been Prepared." An ODS staff member will mark letters as picked up when the student comes in.</li>
                <li>When the signed letter is returned, scan the letter, click edit for the appropriate letter on the documents page, and save it as being returned with or without instructor concerns.</li>
                <li>Track your students on the Reports page under "Accommodation Letter Tracking."</li>
            </ol>
            <p><a :href="'/student/accommodation-letters/' + this.studentId">Return to student screen</a></p>
        </div>
        </div>
</template>

<script>


    export default {
        metaInfo () {
            return {
                title: "Instructions for preparing Accommodation Letters in Doris"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                studentId: null
            }
        },
        methods: {
            load: function(){
                this.urlParams = this.$route.query;
                this.studentId = this.urlParams.student_id;
            }
        },
        created () {
            this.load();
        }
    }
</script>

<style scoped>
    li {
        margin: .6em 2em;
    }
</style>
