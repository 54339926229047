<template>
    <div id="container">
      <div id="content">
            <h1>Contact Note Templates</h1>
            <el-table :data="templates" class="templates-table">
                <el-table-column prop="title" label="Name" sortable :sort-orders="['ascending', 'descending']" width="175"></el-table-column>
                <el-table-column prop="body" label="Body"><template slot-scope="scope"><span class="formatted-text">{{ scope.row.body }}</span></template></el-table-column>
                <el-table-column width="64"><template slot-scope="scope"><a :href="'/contact-notes/show-template/' + scope.row.id">Show</a></template></el-table-column>
                <el-table-column width="60"><template slot-scope="scope"><a :href="'/contact-notes/edit-template/' + scope.row.id">Edit</a></template></el-table-column>
                <el-table-column width="85"><template slot-scope="scope"><a @click="deleteTemplate(scope.row.id)">Delete this template</a></template></el-table-column>
            </el-table>
            <a :href="'/contact-notes/new-template'">Create a new contact note template</a>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Contact Note Templates"
            }
        },
        components: {
            },
        data () {
            return {
                templates: []
            }
        },
        methods: {
            getTemplates: function() {
                axios.get('/api/contact-note/contact-note-templates/')
                    .then(response => {
                        this.templates = response.data.rows;
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            deleteTemplate: function(id) {
                this.$confirm('By clicking on "OK" you will be deleting the contact note template you\'re editing. This will not affect contact notes already sent using this template, but this template will no longer be available for new contact notes.<br><br>If you\'re not sure, click Cancel', {dangerouslyUseHTMLString: true})
                    .then(() => {
                        axios.delete("/api/contact-note/contact-note-template/", {params: {id: parseInt(id)}})
                            .then(response => {
                                this.getTemplates();
                            });
                    }).catch(() => {
                        // do nothing
                    });
            }
        },
        created () {
            this.getTemplates();
        }
    }
</script>
<style scoped>
    .templates-table td {
        vertical-align: top;
    }
</style>
