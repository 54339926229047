<template>
    <div id="container">
<div id="content">
            <h1>Edit Room Reservation Contact</h1>
            <el-form :model="manager" :rules="rules" ref="managerForm" label-width="150px" label-position="left">
                <el-form-item label="First name" prop="first_name">
                    <el-input size="mini" v-model="manager.first_name" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="Last name" prop="last_name">
                    <el-input size="mini" v-model="manager.last_name" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="Title">
                    <el-input size="mini" v-model="manager.title" style="width:300px"></el-input>
                </el-form-item>
                <template v-for="(building, index) in manager.buildings">
                    <el-form-item label="Building" :key="index">
                        <el-select size="mini" style="width:600px;" v-model="building.name">
                            <template v-for="(buildingOption) in buildings">
                                <el-option v-if="!isDuplicate(buildingOption)" :key="buildingOption" :label="buildingOption" :value="buildingOption"></el-option>
                            </template>
                        </el-select>
                        <a style="font-size:small;" @click="removeBuilding(index)">remove</a>
                    </el-form-item>
                </template>
                <a @click="addBuilding">[+] Add another building</a>
                <el-form-item label="Email">
                    <el-input size="mini" v-model="manager.email" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="Office Phone">
                    <el-input size="mini" v-model="manager.office_phone" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="Mobile Phone">
                    <el-input size="mini" v-model="manager.mobile_phone" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="Office Location">
                    <el-input size="mini" v-model="manager.office_location"></el-input>
                </el-form-item>
                <el-form-item label="Comments">
                    <el-input size="mini" type="textarea" :rows="10" v-model="manager.comments"></el-input>
                </el-form-item>
            </el-form>
            <el-button size="mini" type="primary" @click="onSubmit">Save changes</el-button>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Edit Room Reservation Contact"
            }
        },
        components: {
            },
        data () {
            return {
                manager: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    office_phone: null,
                    mobile_phone: null,
                    office_location: null,
                    title: null,
                    comments: null,
                    buildings: [{name: ''}]
                },
                rules: {
                    first_name: [{ required: true, whitespace: true, message: 'First Name is required' }],
                    last_name: [{ required: true, whitespace: true, message: 'Last Name is required' }]
                },
                buildings: []
            }
        },
        methods: {
            load: function(){
                this.manager.id = location.pathname.split('/room-reservation-manager/edit/')[1];
                if(this.manager.id){
                    this.manager.id = parseInt(this.manager.id);
                    this.getManager();
                }

                axios.get("/api/room-reservation-manager/buildings/")
                    .then(response => {
                        this.buildings = response.data.rows;
                    });
            },
            getManager: function(){
                axios.get("/api/room-reservation-manager/", {params: {id: this.manager.id}})
                    .then(response => {
                        this.manager = response.data.room_manager;
                        if(!this.manager.buildings || !this.manager.buildings.length){
                            this.manager.buildings = [''];
                        }
                    });
            },
            onSubmit: function(){
                this.$refs["managerForm"].validate((valid) => {
                    if(valid){
                        if(this.manager.id){
                            axios.put('/api/room-reservation-manager/', this.manager)
                                .then(response => {
                                    if(response.data.success){
                                        this.$router.push({ path: '/room-reservation-manager'});
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                });
                        } else {
                            axios.post('/api/room-reservation-manager/', this.manager)
                                .then(response => {
                                    if(response.data.success){
                                      this.$router.push({ path: '/room-reservation-manager'});
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line
                                    console.log(error);
                                });
                        }
                    }
                });
            },
            addBuilding: function(){
                this.manager.buildings.push({name: ''});
            },
            removeBuilding: function(index){
                this.$confirm('Are you sure you want to remove this building?')
                    .then(() => {
                        this.manager.buildings.splice(index, 1);
                    }).catch(() => {});
            },
            isDuplicate: function(buildingName){
                if(buildingName !== ''){
                    for(var i = 0; i < this.manager.buildings.length; i++){
                        if(this.manager.buildings[i].name == buildingName){
                            return true;
                        }
                    }
                }
                return false;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-form {
        width: 800px;
    }
</style>
