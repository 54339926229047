<template>
    <div id="container">
<div id="content">
            <h1>Add a new record</h1>
            <el-form ref="form" :model="form" :rules="rules" label-width="150px">
                <el-form-item label="PID">
                    <el-input size="mini" v-model="form.pid"></el-input>
                </el-form-item>
                <el-form-item label="UNI">
                    <el-input size="mini" v-model="form.uni"></el-input>
                </el-form-item>
                <el-form-item label="First Name" prop="first_name">
                    <el-input size="mini" v-model="form.first_name"></el-input>
                </el-form-item>
                <el-form-item label="Last Name" prop="last_name">
                    <el-input size="mini" v-model="form.last_name"></el-input>
                </el-form-item>
                <el-form-item label="Phone">
                    <el-input size="mini" v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input size="mini" v-model="form.email"></el-input>
                </el-form-item>
                <template v-for="(referral_source, index) in form.referral_sources">
                    <el-form-item label="Referral Source" :key="index">
                        <el-select size="mini" value-key="value" v-model="referral_source.value" @change="handleReferralSourceSelect(referral_source.value, index)" style="width:300px">
                            <el-option v-for="referralSource in referralSources" :label="referralSource.name" :value="referralSource.value" :key="referralSource.value"></el-option>
                        </el-select>
                        <template v-if="referral_source.show_other">
                            <br><el-input size="mini" v-model="referral_source.source_other" style="width:300px;"></el-input>
                        </template>
                    </el-form-item>
                </template>

                <template v-if="form.referral_sources.length < 5">
                    <a @click="addStudentReferralSource">[+] Add another</a>
                </template>
                <el-form-item label="Student Type" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio label="prospective">Prospective (No DS Registration Form or Disability Documentation)</el-radio>
                        <el-radio label="applicant">Applicant (Either DS Registration Form or Disability Documentation submitted)</el-radio>
                        <el-radio label="ha_only">HA Only (Student requesting Housing Accommodations, but no other accommodations)</el-radio>
                        <el-radio label="guest">Guest</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button size="mini" type="primary" @click="onSubmit">Create</el-button>
                    <el-button size="mini" >Cancel</el-button>
                </el-form-item>
            </el-form>

            <!--<br><a href="/faculty/new" class="cancel">Create Faculty Record</a><br/>-->


        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Add a new record"
            }
        },

        components: {
            },
        data() {
            return {
                referralSources: [],
                form: {
                    pid: null,
                    uni: null,
                    first_name: null,
                    last_name: null,
                    phone: null,
                    email: null,
                    referral_sources: [{name: "", source_other: ""}],
                    type: null
                },
                rules: {
                    first_name: [{ required: true, whitespace: true, message: 'First Name is required' }],
                    last_name: [{ required: true, whitespace: true, message: 'Last Name is required' }],
                    type: [{ required: true, message: 'Student Type is required' }]
                }
            }
        },
        computed: {
        },
        methods: {
            load: function () {
                this.paramString = location.search;
                this.params = this.$route.query;
                this.form.first_name = this.params["first_name"];
                this.form.last_name = this.params["last_name"];
                this.form.pid = this.params["pid"];
                this.form.uni = this.params["uni"];

                axios.get("/api/student/referral-sources")
                        .then(response => {
                            if(response.data.success) {
                               this.referralSources = response.data.rows;
                            }
                    });
            },
            onSubmit: function(){
                this.$message.closeAll();
                this.$refs['form'].validate((valid) => {
                    if(valid){
                        axios.post("/api/student/create-student/", this.form)
                            .then(response => {
                                if(response.data.success && response.data.id){
                                  this.$router.push({ path: '/student/profile/' + response.data.id });
                                } else if(response.data.message) {
                                    this.$message({
                                        showClose: true,
                                        duration: 20000,
                                        type: 'error',
                                        message: response.data.message
                                    });
                                }
                            });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 20000,
                            type: 'error',
                            message: "One or more required fields are missing. Please correct any errors and try saving again."
                        });
                    }
                });
            },
            handleReferralSourceSelect: function(referralSourceValue, index){
                this.form.referral_sources[index].show_other = this.referralSources.find(function(source){return source.value == referralSourceValue}).show_other;
            },
            addStudentReferralSource: function() {
                this.form.referral_sources.push({name: "", source_other: ""})
            }
        },
        mounted() {
            this.load()
        }
    }
</script>

<style scoped>
    .el-radio {
        margin: 6px 0 6px 10px;
    }
    .el-radio + .el-radio {
        margin-left: 10px;
    }
    .el-radio__label {
        font-size: small;
    }
    .el-form-item__label {
        line-height:28px;
    }
    .el-form-item__content {
        line-height:inherit;
    }

    #content .el-form-item .el-form-item__label {
        text-align: left;
    }

    #content .el-form-item {
        width: 400px;
    }
</style>
