<template>
    <div id="container">
<div id="content">
            <h1>Students without Contact Notes or Individual Emails</h1>
            <h2>Current<template v-if="semester === 'previous'"> and Previous</template> Semester</h2>
            <report-student-report-grid :data="data" :loading="loading" :sortProp="first_name" :fullView="true" :showOdsRegDate="true"></report-student-report-grid>
            <p><a :href="'/api/report/students-without-contact-notes.xlsx?semester=' + this.semester" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ data.length }} student records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Students without Contact Notes or Individual Emails"
            }
        },

        components: {
        },
        data () {
            return {
                urlParams: {},
                data: [],
                loading: false,
                semester: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.semester = this.urlParams.semester;
                this.getData();
            },
            getData: function() {
                this.loading = true;
                axios.get('/api/report/students-without-contact-notes/', {params: {semester: this.semester}})
                    .then(response => {
                        if(response.data.success) {
                            this.data = response.data.rows;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            // eslint-disable-next-line
            this.load();
        }
    }
</script>
<style scoped>
</style>
