<template>
  <div id="container">
    <div id="content">
      <h1>Group Email</h1>
      <el-steps :active="active" finish-status="success">
        <el-step title="Step 1" description="Select recipients"></el-step>
        <el-step title="Step 2" description="Compose email"></el-step>
        <el-step title="Step 3" description="Preview and send"></el-step>
        <el-step title="Step 4" description="Finish"></el-step>
      </el-steps>
      <el-form v-if="emailParams.courseId" label-width="120px" class="editor-container" id="recipient-form"  >
        <p><b>Instructor: Professor {{emailParams.instructorFirstName}} {{emailParams.instructorLastName}} - {{emailParams.courseName}}</b></p>

        <p v-if="emailParams.regardingClass == 'Student'"><b>Student: {{emailParams.regardingFirstName}} {{emailParams.regardingLastName}} - {{emailParams.regardingUni}}</b></p>

        <p v-if="emailParams.ccTAs && emailParams.teachingAssistants.length">
          <template v-for="assistant in emailParams.teachingAssistants">

            <b :key="assistant.id">Teaching Assistant: {{assistant.first_name}} {{assistant.last_name}}</b><br :key="assistant.id"/>
          </template>
        </p>

        <p v-if="emailParams.regardingClass == 'NoteTakingService'"><b>Notetaking Service</b></p>


        <p v-if="emailParams.regardingClass == 'TestingService'"><b>Testing Service: Exam at {{emailParams.testingServiceTitle}}</b></p>

      </el-form>
      <el-form v-else-if="false && emailParams.studentId" label-width="120px" class="editor-container" id="recipient-form">
        <p><b>Student: {{student.title}} - {{student.uni}}</b></p>
      </el-form>
      <div id="compose-area">
        <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm" class="editor-container" label-position="top">
          <el-form-item label="Subject" prop="subject">
            <el-input v-model="editForm.subject" size="small" @blur="onFieldBlur"></el-input>
          </el-form-item>

          <el-form-item label="Body" prop="body">
            <el-input type="textarea" @blur="onFieldBlur" :autosize="{ minRows: 20, maxRows: 500}" resize="vertical" :disabled="lockBody"
                      v-model="editForm.body"></el-input>
          </el-form-item>

          <el-form-item label="Templates" v-if="!lockBody">
            <el-select v-model="category" @change="onCategorySelect" placeholder="Select a category" size="mini" style="width: 50%">
              <el-option
                v-for="item in categories"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="template" @change="onTemplateSelect" placeholder="Select a template" size="mini" style="width: 100%">
              <el-option-group
                v-for="group in templates"
                :key="group.label"
                :label="group.label">
                <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="Attachments" v-if="!lockBody">
            <el-upload drag action="/api/document/create-email-attachment/" :data="{draft: draft}" :multiple="true"
                       :file-list="attachments" :on-success="handleAttachmentUpload"  :on-preview="handleAttachmentPreview"
                       :on-remove="handleAttachmentRemove">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </el-form-item>


          <el-form-item :label="'Attach a document from ' + student.title" v-if="!lockBody && attachableStudentDocuments.length">
            <a @click="toggleExpandAttachableDocuments">{{ expandAttachableStudentDocuments ? "Hide attachable documents" : "View attachable documents" }}</a>

            <table id="attachable_documents" v-if="expandAttachableStudentDocuments">
              <tbody><tr>
                <th>Name</th>
                <th>Status</th>
                <th>File</th>
                <th>Attach to email?</th>
              </tr>

              <tr :key="docIndex" v-for="(doc, docIndex) in attachableStudentDocuments">
                <td :key="docIndex + 'td-1'">{{ doc.name }}</td>
                <td :key="docIndex + 'td-2'">{{ doc.status }}</td>
                <td :key="docIndex + 'td-3'">{{ doc.filename }}</td>
                <td :key="docIndex + 'td-4'"><el-checkbox v-model="doc.addAsEmailAttachment" size="mini"></el-checkbox></td>
              </tr>

              <tr>
                <td colspan="4"><el-button type="primary" size="mini" @click="onAttachStudentDocuments" style="float: right">Attach Selected Documents</el-button></td>
              </tr>
              </tbody></table>
          </el-form-item>
        </el-form>
        <!--<markdown-editor id="contentEditor" ref="contentEditor" v-model="content" :height="300" :zIndex="20"></markdown-editor>-->
      </div>
      <div id="preview">
        <p class="subject"><b>Subject:</b> {{editForm.subject}}</p>
        <div id="html-preview" class="group-email-preview" v-html="html"></div>
      </div>
      <br clear="all">
      <el-button @click="prev">&lt; Previous step</el-button>
      <el-button type="primary" @click="next('editForm')">Preview &gt;</el-button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'


  import showdown from 'showdown'
  const converter = new showdown.Converter()
  converter.setOption('simpleLineBreaks',true)

  export default {
    metaInfo () {
      return {
        title: "Compose Group Email"
      }
    },

    components: {
    },
    data () {
      return {
        draft: '',
        attachments: [],
        category: '',
        template: '',
        editForm: {
          subject: '',
          body: '',
        },
        active: 1,
        categories: [
          { value: '1', label: 'Student Templates' },
          { value: '2', label: 'Faculty Templates' },
          { value: '3', label: 'Note Taker/Proctor Templates' }
        ],
        templates: [],
        rules: {
          subject: [{ required: true, message: 'Please enter a subject', trigger: 'blur' }],
          body: [{required: true, message: 'Please enter a message body', trigger: 'blur' }]
        },
        emailParams: {},
        lockBody: false,
        noUserSignature: false,
        attachableStudentDocuments: [],
        expandAttachableStudentDocuments: false,
        student: {}
      }
    },
    computed: {
      html () {
        var preHtml = "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" class=\"body\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;\">\n" +
                "    <tr>\n" +
                "        <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">&nbsp;</td>\n" +
                "        <td class=\"container\" style=\"font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto; max-width:100; padding: 0px; width: 636px;\">\n" +
                "            <div class=\"content\" style=\"box-sizing: border-box; display: block; Margin: 0 auto; max-width: 636px; padding: 0px;\">\n" +
                "\n" +
                "                <!-- START CENTERED WHITE CONTAINER -->\n" +
                "<!--                <span class=\"preheader\" style=\"color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;\">This is preheader text. Some clients will show this text as a preview.</span>-->\n" +
                "                <table class=\"main\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; border-radius: 3px;\">\n" +
                "\n"
                "                    <!-- START MAIN CONTENT AREA -->\n" +
                "                    <tr>\n" +
                "                        <td class=\"wrapper\" style=\"font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;\">\n" +
                "                            <table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" style=\"border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;\">\n" +
                "                                <tr>\n" +
                "                                    <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">";

        var postHtml = "</td>\n" +
                "                                </tr>\n" +
                "                            </table>\n" +
                "                        </td>\n" +
                "                    </tr>\n" +
                "\n" +
                "                    <!-- END MAIN CONTENT AREA -->\n" +
                "                </table>\n" +
                "                <!-- END CENTERED WHITE CONTAINER -->\n" +
                "            </div>\n" +
                "        </td>\n" +
                "        <td style=\"font-family: sans-serif; font-size: 14px; vertical-align: top;\">&nbsp;</td>\n" +
                "    </tr>\n" +
                "</table>";

        return preHtml + converter.makeHtml(this.editForm.body) + postHtml
      }
    },
    methods: {
      toggleExpandAttachableDocuments: function(){
        this.expandAttachableStudentDocuments = !this.expandAttachableStudentDocuments;
      },
      onAttachStudentDocuments: function(){
        var selectedDocs = [];
        this.attachableStudentDocuments.forEach(function(row) {
          if(row.addAsEmailAttachment){
            selectedDocs.push(row.id);
          }

        });

        if(selectedDocs.length == 0){
          this.$alert("Please select at least one document to attach");
        } else {
          axios
            .post('/api/document/attach-student-documents/', {draftId: this.draft, documentIds: selectedDocs})
            .then(response => {
              this.loadDraft(this.draft);
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
            });
        }
      },
      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      prev: function() {
        let params = this.$route.query;
        if(this.draft){
          this.$router.push({ path: "/group-email/compose", query: { draft: this.draft, ...params }});
        } else {
          this.$router.push({ path: "/group-email/compose", query: params })
        }

      },
      next: function(formName) {
        this.$refs[formName].validate(valid => {
          if(valid) {
            let params = this.$route.query;
            this.$router.push({ path: "/group-email/compose/preview", query: params })
          }
        });
      },
      //onCategorySelect: function(templateId) {
      onCategorySelect: function() {
        // eslint-disable-next-line
        console.log('category ' + this.category);
        this.template = '';
        // this.editForm.subject = '';
        // this.editForm.body = '';
        this.templates = [];
        axios.get("/api/group-email/template-list-by-sub-category/?id=" + this.category)
          .then(response => {
            if(response.data.success) {
              this.templates = response.data.rows
              //if(templateId){
              //  this.template = parseInt(templateId);
              //}
            }
          }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });
      },
      onTemplateSelect: function(id) {
        var template = this.template;
        var populateDropdowns = false;
        if(id) {
          template = id;
          populateDropdowns = true;
        }
        // eslint-disable-next-line
        console.log('template ' + this.template);
        axios.get("/api/group-email/email-template/?id=" + template)
          .then(response => {
            this.editForm.body = response.data.body;
            if(!this.editForm.subject || this.editForm.subject.trim() == ""){
              this.editForm.subject = response.data.subject;
            }
            this.loadSignature();

            if(populateDropdowns){
              this.category = ""+response.data.email_template_category_id;
              //this.onCategorySelect(id);
              this.onCategorySelect();
            }

          }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });

      },
      onFieldBlur: function() {
        if(this.draft) {
          axios.put("/api/group-email/draft/", {
            id: this.draft,
            subject : this.editForm.subject,
            body : this.editForm.body,
            emailParams: this.emailParams
          }).then(response => {
            // eslint-disable-next-line
            console.log("draft saved");
          });
        }
      },
      loadDraft: function(id)  {
        axios.get("/api/group-email/draft/" + (id ? "?id=" + id : ''))
          .then(response => {
            if(response.data.success) {
              this.draft = response.data.id;
              this.editForm.subject = response.data.subject;
              this.editForm.body = response.data.body;
              this.emailParams = response.data.params ? response.data.params : {};

              if(this.emailParams.disableBody){
                this.lockBody = true;
              }
              if(this.emailParams.noUserSignature){
                this.noUserSignature = true;
              }

              if(this.emailParams.studentId){
                this.loadStudent();
                this.loadStudentAttachableDocuments();
              }

              if(this.emailParams.templateId){
                this.onTemplateSelect(this.emailParams.templateId);
              }


              this.loadDraftAttachments(id);

              if(!this.editForm.subject && !this.noUserSignature){
                this.loadSignature();
              }
            }
          });
      },

      loadStudent: function(){

        axios.get("/api/student/" + this.emailParams.studentId)
          .then(response => {
            this.student = response.data;
          });
      },
      loadStudentAttachableDocuments: function() {
        this.attachableStudentDocuments = [];
        this.expandAttachableStudentDocuments = false;
        axios.get('/api/document/non-accommodation-letter-documents-for-student/', {params:{student_id: this.emailParams.studentId}})
          .then(response => {
            if(response.data.success) {
              for(var i=0; i<response.data.rows.length; i++) {
                var row = response.data.rows[i];

                for(var j=0; j<row.documents.length; j++ ){
                  var doc = row.documents[j];


                  this.attachableStudentDocuments.push({
                    id: doc.id,
                    name: doc.name,
                    status: doc.status,
                    filename: doc.filename,
                    date: doc.date,
                    addAsEmailAttachment: false
                  });
                }
              }
            }
          });
      },
      loadDraftAttachments: function(id) {
        axios.get("/api/group-email/draft-email-attachments" + (id ? "?id=" + id : ''))
          .then(response => {
            if(response.data.success) {
              for(var i=0; i<response.data.rows.length; i++) {
                this.attachments.push({
                  id: response.data.rows[i].id,
                  name: response.data.rows[i].asset_file_name//,
                  //fileSize: response.data.rows[i].asset_file_size,
                  //contentType: response.data.rows[i].asset_content_type
                });
              }
            }
          });
      },
      handleAttachmentUpload: function(response, file) {
        if(response.success){
          file.id = response.documentId;
        } else {
          this.$alert("Error: "+response.message);
          this.attachments.splice(this.attachments.indexOf(file), 1);
          this.loadDraftAttachments(this.draft)
        }
      },
      handleAttachmentPreview: function(file) {
        // eslint-disable-next-line
        console.log("in preview");
        var url = '/api/group-email/download-draft-attachment/' + this.draft + '/' + file.id + '/' + file.name;
        window.open(url);
      },
      handleAttachmentRemove: function(file) {
        axios.delete("/api/group-email/draft-email-attachment?id=" + file.id + "&draft=" + this.draft + "&name=" + file.name)
          .then(response => {
            // eslint-disable-next-line
            console.log('attachment removed: ' + file.id);
          });
      },
      loadSignature: function() {
        axios.get("/api/group-email/email-signature-for-user")
          .then(response => {
            var regex = RegExp(response.data);
            if(!regex.test(this.editForm.body)){
              this.editForm.body += "\n\n\n\n\n";
              this.editForm.body +=  response.data;
            }
            this.onFieldBlur();
          });

      },
      load: function () {
        this.params = this.$route.query;
        if(this.params.draft) {
          this.draft = parseInt(this.params.draft);
          this.loadDraft(this.params.draft);
        } else {
          this.loadDraft();
        }
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style>
  /*#html-preview h1, #html-preview h2, #html-preview h3 {*/
  /*  color: black;*/
  /*  border-bottom: none;*/
  /*  padding: 0;*/
  /*}*/
  #html-preview {
    margin-left: 20px;
    padding: 0px 10px;
    margin-top: 0;
    margin-bottom: 10px;
    background: #EBEFE5;
  }
</style>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  .editor-container {
    float: left;
  }
  .editor-container .CodeMirror {
    height: 100%!important;
  }
  .content {
    position: relative;
  }
 .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form-item__content {
    line-height: 14px;
  }
 .subject {
    margin-left: 10px;
    padding-left: 10px;
  }
  #compose-area {
    width: calc(100% - 686px);
    /*width: 50%;*/
  }
  #compose-area form {
    width: 100%;
  }
  #preview {
    width: 686px;
    float: left;
    margin-top: 32px;
  }
  #recipient-form {
    margin-bottom: 20px;
  }
  #recipient-form p{
    line-height: 18px;
    margin: 5px 0 0 0;
  }
</style>
