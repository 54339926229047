<template>
    <div id="container">
<div id="content">
            <h1>Graduate Proctoring Session Requestors</h1>
            <p><a :href="'/proctoring-session-requestor/edit'">Add new requestor</a></p>
            <el-table :default-sort="{prop: 'name', order: 'ascending'}" :data="requestors">
                <el-table-column prop="name" label="Name" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="email" label="Email" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column width="90"><template slot-scope="scope"><a :href="'/proctoring-session-requestor/edit/' + scope.row.id">edit</a></template></el-table-column>
                <el-table-column width="90"><template slot-scope="scope"><a @click="deleteRequestor(scope.row.id)">delete</a></template></el-table-column>
            </el-table>
            <p><a :href="'/proctoring-session-requestor/edit'">Add new requestor</a></p>
            <p v-if="total">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Graduate Proctoring Session Requestors"
            }
        },
        components: {
            },
        data () {
            return {
                requestors: [],
                total: null
            }
        },
        methods: {
            load: function() {
                this.getRequestors();
            },
            getRequestors: function() {
                axios.get('/api/proctoring-session-requestor/requestors/')
                    .then(response => {
                        if(response.data.success) {
                            this.requestors = response.data.rows;
                            this.total = response.data.total;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            },
            deleteRequestor: function(id){
                this.$confirm('This will delete the requestor. Are you sure?')
                    .then(() => {
                        axios.delete("/api/proctoring-session-requestor/", {params: {id: id}})
                            .then(response => {
                                if(response.data.success){
                                    this.getRequestors();
                                } else {
                                    this.$message({
                                        showClose: true,
                                        duration: 20000,
                                        type: 'error',
                                        message: response.data.message
                                    });
                                }
                            });
                    }).catch(() => {
                        // do nothing
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
