<template>
    <div id="container">
<div id="content">
            <student-nav selected="ha-accommodations" :id="id" :name="name" :uni="uni" :haStatus="haStatus"></student-nav>
            <br clear="all">
            <student-ha-instructions :id="id"></student-ha-instructions>
            <template v-if="!canEditHousingAccommodations">
                <p style="color:red">You do not have permission to edit housing accomodations</p>
            </template>
            <student-ha-edit :id="id" :isStudentRequest="isStudentRequest" v-if="canEditHousingAccommodations"></student-ha-edit>
            <student-housing-committee-notes :id="id"></student-housing-committee-notes>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },

        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                haStatus: "",
                isStudentRequest: null,
                canEditHousingAccommodations: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                var urlParams = this.$route.query;

                this.id = location.pathname.split('/student/ha-accommodations-edit/')[1];
                this.isStudentRequest = urlParams["is_student_request"] === "true" ? true : false
                
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.haStatus = response.data.haStatus;
                    });


                axios.get("/api/simple/check-permissions/", {params: {permissions: ['can_edit_housing_accommodations']}})
                    .then(response => {
                        this.canEditHousingAccommodations = response.data.permissions.can_edit_housing_accommodations;
                    }) ;
            }
        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
</style>
