<template>
  <div id="container">
    <div id="content">
      <h1>Group Email</h1>
      <el-steps :active="active" finish-status="success">
        <el-step title="Step 1" description="Select recipients"></el-step>
        <el-step title="Step 2" description="Compose email"></el-step>
        <el-step title="Step 3" description="Preview and send"></el-step>
        <el-step title="Step 4" description="Finished"></el-step>
      </el-steps>
      <el-form v-if="courseId" label-width="120px" class="editor-container" id="recipient-form"  >
        <p><b>Instructor: Professor {{instructorFirstName}} {{instructorLastName}} - {{courseName}}</b></p>

        <p v-if="regardingClass == 'Student'"><b>Student: {{regardingFirstName}} {{regardingLastName}} - {{regardingUni}}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox @change="ccRegardingToEmail"><span style="font-size: 0.9em">CC student on email</span></el-checkbox>

        </p>

        <p v-if="ccTAs && teachingAssistants.length">
          <template v-for="assistant in teachingAssistants">

            <b :key="assistant.id">Teaching Assistant: {{assistant.first_name}} {{assistant.last_name}}</b><br :key="assistant.id"/>
          </template>
        </p>

        <p v-if="regardingClass == 'NoteTakingService'"><b>Notetaking Service</b></p>


        <p v-if="regardingClass == 'TestingService'"><b>Testing Service: Exam at {{testingServiceTitle}}</b></p>

      </el-form>
      <el-form v-else-if="noteTakerId" label-width="120px" class="editor-container" >
        <p><b>NoteTaker: {{serviceProviderFirstName}} {{serviceProviderLastName}}</b></p>

      </el-form>
      <el-form v-else-if="proctorId" label-width="120px" class="editor-container" >
        <p><b>Proctor: {{serviceProviderFirstName}} {{serviceProviderLastName}}</b></p>

      </el-form>
      <el-form v-else-if="serviceProviderId" label-width="120px" class="editor-container" >
        <p><b>Service Provider: {{serviceProviderFirstName}} {{serviceProviderLastName}}</b></p>

      </el-form>
      <el-form v-else-if="teachingAssistantId" label-width="120px" class="editor-container" >
        <p><b>Teaching Assistant: {{serviceProviderFirstName}} {{serviceProviderLastName}}</b></p>

        <p v-if="regardingClass == 'TestingService'"><b>Testing Service: Exam at {{testingServiceTitle}}</b></p>

      </el-form>
      <el-form v-else-if="examId" label-width="120px" class="editor-container" >
        <p>Send email to Students taking:<br/><b>{{testingServiceTitle}}</b></p>

      </el-form>
      <el-form v-else-if="proctorsForProctoringSession || onDutyProctorsForProctoringSession" label-width="120px" class="editor-container" >
        <p>Select the proctors you wish to send the email to, then compose the email</p>

        <p>{{proctoringSessionTitle}}</p>

      </el-form>

      <el-form v-else-if="emailLocationsFor || proctorsOnDutyNow || examProctorsForDates || missingDropoffs || emailCourseStudents">

      </el-form>


      <el-form v-else-if="proctorScheduledSessionsForDates" id="recipient-form" >
        <tr>
          <th>From:</th>
          <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="proctorScheduledSessionsForDatesStart" value-format="yyyy-MM-dd"></el-date-picker></td>
        </tr>
        <tr>
          <th>To:</th>
          <td><el-date-picker size="mini" type="date" placeholder="Pick a day" v-model="proctorScheduledSessionsForDatesEnd" value-format="yyyy-MM-dd"></el-date-picker></td>
        </tr>
        <tr>
          <td colspan="2"><el-button size="mini" type="primary" @click="getProctorScheduledSessionsForDates">Change Dates</el-button></td>
        </tr>

        <el-form-item label="Choose recipients and click 'add recipient'">
          <el-select v-model="proctorSelection"   size="mini" style="width: 100%">
            <el-option
              v-for="item in proctorScheduledSessionsForDatesOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button size="mini" type="primary" @click="addProctors">Add recipient</el-button>
      </el-form>

      <el-form v-else-if="emailExamRelatedInstructors" id="recipient-form" >
        <el-form-item label="1) Bulk Email to All Professors with at least one Exams Not Received, Exam not Cancelled and Not Opted-Out: Final Reminders for Today and Tomorrow (final reminder)">
          <el-select v-model="examProfessorSelect1" @change="onExamProfessorSelectOne"  size="mini" style="width: 100%">
            <el-option
              v-for="item in examProfessorOptionsOne"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="2) Bulk Email Reminders to All Professors with at least one Exams Not Received, Exam not Cancelled and Not Opted-Out: Already Requested at least once (2nd, 3rd, 4th etc Reminders - excludes today and tomorrow)">
          <el-select v-model="examProfessorSelect2" @change="onExamProfessorSelectTwo"  size="mini" style="width: 100%">
            <el-option
              v-for="item in examProfessorOptionsTwo"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="3) Bulk Email to All Professors with at least one Exams Not Received, Exam not Cancelled and Not Opted-Out: Not Yet Requested (1st Reminder)">
          <el-select v-model="examProfessorSelect3" @change="onExamProfessorSelectThree" size="mini" style="width: 100%">
            <el-option
              v-for="item in examProfessorOptionsThree"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form v-else-if="emailNotetakingRelatedInstructors" id="recipient-form" >
        <p><b>Please note: Teaching assistants, if available and not opted out, will be copied on the emails</b></p>
        <p>This page is for sending to groups of professors automated emails that include details about their
          courses and note-taking status. This is a feature for emailing all professors at once who have
          courses with note-taking services in a particular status. The email sent in this case will
          automatically include details about the relevant course, but cannot be edited by the user. The
          status of each note taking service will be updated whenever the emails are sent.</p>

        <p>The body of the email will vary slightly depending on the whether the professor has been emailed once or twice. </p>

        <el-form-item label="Bulk Email to:">
          <el-select v-model="notetakingProfessorSelect" @change="onNotetakingProfessorSelect"  size="mini" style="width: 100%">
            <el-option
              v-for="item in notetakingProfessorOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Professors with note taking service requested:">
          <el-select v-model="notetakingProfessorRequested" @change="onNotetakingProfessorRequestedSelect"  size="mini" style="width: 100%">
            <el-option
              v-for="item in notetakingProfessorRequestedOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Professors emailed once with note taking services:">
          <el-select v-model="notetakingProfessorEmailed" @change="onNotetakingProfessorEmailedSelect"  size="mini" style="width: 100%">
            <el-option
              v-for="item in notetakingProfessorEmailedOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Professors emailed once (more than one week ago) with note taking services:">
          <el-select v-model="notetakingProfessorEmailedOneWeek" @change="onNotetakingProfessorEmailedOneWeekSelect"  size="mini" style="width: 100%">
            <el-option
              v-for="item in notetakingProfessorEmailedOneWeekOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form v-else id="recipient-form" label-width="120px" class="editor-container" label-position="top" >
        <el-form-item label="Student group or email list">
          <el-select v-model="type" @change="onCategorySelect" placeholder="Select a category" size="mini" style="width: 100%">
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="list" placeholder="Select a list" size="mini" style="width: 100%">
            <el-option-group
              v-for="group in listOptions"
              :key="group.name"
              :label="group.name">
              <el-option
                v-for="item in group.options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
          <el-col :span="10">
            <span style="font-size: 0.9em">Optionally, limit students assigned to:</span>
          </el-col>
          <el-col :span="14">
            <el-select v-model="limit" placeholder="Select" size="mini" style="width: 100%">
              <el-option
                v-for="item in limitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10">&nbsp;</el-col>
          <el-col :span="14">
            <el-checkbox v-model="limitToStudentsEnrolledThisTerm"><span style="font-size: 0.9em">Limit to students enrolled this term</span></el-checkbox>
          </el-col>
        </el-form-item>

        <el-button type="primary" @click="addToRecipientList" size="mini" style="float: right">Add to recipient list</el-button>
        <br clear="all">

        <el-form-item label="Individual students">
          <el-autocomplete class="inline-input" v-model="student" :fetch-suggestions="querySearch"
                           placeholder="Please Input" :trigger-on-focus="false" @select="handleSelect"
                           size="small" style="width: 100%"></el-autocomplete>

        </el-form-item>
        <el-form-item label="Individual proctors">
          <el-select v-model="proctor" placeholder="Select..." size="mini" style="width: 100%" @change="handleProctorSelect">
            <el-option
              v-for="item in proctorOptions"
              :key="item.id"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <!--
        <el-autocomplete class="inline-input" v-model="proctor" :fetch-suggestions="queryProctorSearch"
                         placeholder="Please Input" :trigger-on-focus="false" @select="handleProctorSelect"
                         size="small" style="width: 100%"></el-autocomplete>

      </el-form-item>
      -->
        <el-form-item label="Individual notetakers">
          <el-select v-model="notetaker" placeholder="Select..." size="mini" style="width: 100%" @change="handleNoteTakerSelect">
            <el-option
              v-for="item in noteTakerOptions"
              :key="item.id"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>


      <div id="recipient-table" v-bind:class="{ 'full-size': showFullSize }">
        <label class="el-form-item__label" style="width: 100%; text-align:left">Recipients <span style="float:right">Total Recipients: {{recipients.length}}</span></label>
        <el-table max-height="450" ref="multipleTable" :data="recipients" @selection-change="handleRecipientSelect">
          <el-table-column v-if="!showExamColumns" property="firstName" label="First" width="120" :key="'firstName'"></el-table-column>
          <el-table-column v-if="!showExamColumns" property="lastName" label="Last" width="120" :key="'lastName'"></el-table-column>
          <el-table-column v-if="showUniColumn && !showExamColumns" property="uni" label="UNI" width="120" :key="'uni'"></el-table-column>
          <el-table-column v-if="!showExamColumns" property="email" label="E-mail" show-overflow-tooltip :key="'email'"></el-table-column>
          <el-table-column v-if="showProctorScheduledTimeColumn && !showExamColumns" property="scheduled_time" label="Scheduled Time" width="120" :key="'scheduled_time'"></el-table-column>

          <el-table-column v-if="showExamColumns" prop="student_name" width="100" label="Student Name" :key="'student_name'">
            <template slot-scope="scope">
              <a :href="'/testing/show/' + scope.row.service_id">{{ scope.row.student_name }} &lt;{{ scope.row.uni }}&gt;</a>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns" prop="start_time" width="75" label="Time" :key="'start_time'">
            <template slot-scope="scope">
              <b>{{ scope.row.start_time | formatDateAbbrMonth }}</b><br>{{ scope.row.start_time | formatTimehhmmA }} - {{ scope.row.end_time | formatTimehhmmA }}
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns" prop="department" width="125" label="Class" :key="'department'">
            <template slot-scope="scope">
              {{ scope.row.department }} {{ scope.row.course_number }} SEC <b>{{ scope.row.section_code }}</b><br>{{ scope.row.class_name }}
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns"  prop="instructor_name" label="Professor" width="155" :key="'professor'">
            <template slot-scope="scope">
              {{ scope.row.instructor_name }}<br>{{ scope.row.instructor_email }}
              <template v-if="scope.row.alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.alternate_instructor_email }}</span></template>
              <template v-if="scope.row.secondary_alternate_instructor_email"><br><span style="color:red;" class="text-italic">{{ scope.row.secondary_alternate_instructor_email }}</span></template>
              <template v-if="scope.row.teaching_assistants && scope.row.teaching_assistants.length > 2">
                <br><span style="color:#212488;">({{ scope.row.teaching_assistants.length }}) TAs listed</span>
              </template>
              <template v-else>
                <template v-for="ta in scope.row.teaching_assistants">
                  <br :key="ta.id"><span :key="ta.id" style="color:#212488;">TA - {{ ta.first_name }} {{ ta.last_name }}</span>
                </template>
              </template>
            </template>
          </el-table-column>

          <el-table-column v-if="showExamColumns"  prop="location" label="Location" width="85" :key="'location'">
            <template slot-scope="scope">
              {{ scope.row.location ? scope.row.location : "N/A" }}
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns"  prop="exam_received" label="Exam Received" :key="'exam_received'" width="60">
            <template slot-scope="scope">
              <a :href="'/testing/show/' + scope.row.service_id">{{ scope.row.exam_received ? "Yes" : "No" }}</a>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns"  label="Exam Requested" width="140" :key="'exam_requested'">
            <template slot-scope="scope">
              <a :href="'/testing/show/' + scope.row.service_id">
                <template v-if="scope.row.exam_requested_from_professor">{{ scope.row.exam_requested_from_professor }}<br></template>
              </a>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns"  label="Exam Comments" :key="'exam_comments'">
            <template slot-scope="scope">
              <template v-for="comment in scope.row.exam_comments">
                {{ comment.content }}<br :key="comment.id"/><br :key="comment.id"/>
              </template>
              <template v-if="scope.row.legacy_exam_comments && scope.row.legacy_exam_comments != ''">
                <br><b>Archived comments</b><br>
                {{ scope.row.legacy_exam_comments }}
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns"  prop="exam_emails" label="Student Reminders and Comments" width="145" :key="'exam_emails'">
            <template slot-scope="scope">
              <template v-for="email in scope.row.exam_emails">
                <template v-if="!email.is_exam_location_email">R - {{ email.created_at | formatDateTimeMDYhma }}<br :key="email.id"></template>
                <span :key="email.id" v-if="email.is_exam_location_email" class="color-red">L - {{ email.created_at | formatDateTimeMDYhma }}<br></span>
              </template>
              <span class="formatted-text" :class="{ 'formatted-text': !scope.row.show_comments_more, 'hidden-text': scope.row.show_comments_more }"><a :href="'/testing/show/' + scope.row.service_id" style="color:red;">{{ scope.row.comments }}</a></span>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns" prop="exam_equipment" label="Equipment" width="100" :key="'exam_equipment'">
            <template slot-scope="scope">
              <template v-if="scope.row.exam_equipment && scope.row.exam_equipment.indexOf('Computer') !== -1">
                1 Computer – Exam Admin ({{ scope.row.computer_type_preference ? scope.row.computer_type_preference : "Any" }})<br>
              </template>
              <template v-if="studentComputer(scope.row.enrollment_accommodations)">
                1 Computer – Student Use ({{ scope.row.ultimate_computer_choice }})<br>
              </template>
              <template v-for="equipment in scope.row.exam_equipment">
                <template v-if="equipment != 'Computer'">
                  {{ equipment }}
                </template>
              </template>
              <template v-if="scope.row.exam_equipment.length === 0 && !studentComputer(scope.row.enrollment_accommodations)">None</template>
            </template>
          </el-table-column>
          <el-table-column v-if="showExamColumns" label="Student Accommodations" :key="'student_accomodations'">
            <template slot-scope="scope">
              <template v-for="accommodation in scope.row.enrollment_accommodations">
                <template v-if="accommodation.name != 'Rest Breaks'">
                  <template v-if="accommodation.accommodation_type_id == 8">
                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value }}: {{ scope.row.ultimate_computer_choice }}</span><br :key="accommodation.id">
                  </template>
                  <template v-else-if="accommodation.name == 'Other Testing Accommodation'">
                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.description }}</span><br :key="accommodation.id">
                  </template>
                  <template v-else>
                    <span :key="accommodation.id" :class="{ 'color-red': accommodation.highlight_accommodation }">- {{ accommodation.display_value ? accommodation.display_value : accommodation.name }}</span><br :key="accommodation.id">
                  </template>
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column v-if="emailExamRelatedInstructors" width="160">
            <template slot-scope="scope">
              <template v-if="scope.row.type == 'ServiceProvider'">TA</template>
              <template v-if="scope.row.type == 'Course'">
                Professor <template v-if="scope.row.cc.length">(CC: {{scope.row.cc.length}} TAs)</template>
              </template>
            </template>
          </el-table-column>
          <el-table-column type="selection" width="55"></el-table-column>
        </el-table>
        <el-button @click="handleRemoveSelect" size="mini" type="primary">Remove selected</el-button>
        <el-button @click="handleRemoveOthers" size="mini" type="primary">Remove others</el-button>
        <el-button @click="handleRemoveAll" size="mini" type="primary">Remove all</el-button>
        <p style="font-weight: bold">All recipients above will receive the email. Use selection and buttons only to edit the list.</p>
      </div>

      <div style="clear: both" ></div>
      <el-form >

        <el-form-item label="Reply to" prop="replyTo" style="margin-top:15px;" v-if="!hideReplyTo">
          <el-select v-model="replyTo" placeholder="Select" size="mini" style="width: 250px" @change="saveDraft">
            <el-option
                    v-for="item in replyToOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <br clear="all"><br>
      <el-button class="float-left" type="primary" @click="next" :disabled="recipients.length == 0">Compose email &gt;</el-button>
      <p class="color-red float-left padding-lft"  v-if="recipients.length == 0">Please add recipients before continuing</p>
    </div>

  </div>
</template>

<script>
  import moment from 'moment'
  import axios from 'axios'
  import {mapActions} from "vuex";
  import { mapGetters } from 'vuex'

  export default {
    metaInfo () {
      return {
        title: "Select Group Email Recipients"
      }
    },
    components: {
    },
    data () {
      return {
        typeOptions: [],
        listOptions: [],
        limitOptions: [],
        noteTakerOptions: [],
        proctorOptions: [],
        replyToOptions: [{
          value: 'disability@columbia.edu',
          label: 'disability@columbia.edu'
        }, {
          value: 'dsexams@columbia.edu',
          label: 'dsexams@columbia.edu'
        }, {
          value: 'odsnotes@columbia.edu',
          label: 'odsnotes@columbia.edu'
        }],
        replyTo: 'disability@columbia.edu',
        type: '',
        isStudentList: false,
        list: '',
        limit: '',
        limitToStudentsEnrolledThisTerm: false,
        student: '',
        proctor: '',
        notetaker: '',
        active: 0,
        templateId: '',
        draftId: null,
        studentId: '',
        courseId: '',
        courseName: '',
        examId: '',
        instructorFirstName: '',
        instructorLastName: '',
        instructorEmail: '',
        noteTakerId: '',
        proctorId: '',
        serviceProviderId: '',
        teachingAssistantId: '',
        regardingId: '',
        regardingClass: '',
        regardingFirstName: '',
        regardingLastName: '',
        regardingSendPromoEmail: '',
        regardingUni: '',
        testingServiceTitle: '',
        serviceProviderFirstName: '',
        serviceProviderLastName: '',
        subject: '',
        body: '',
        post_action: '',
        recipients: [],
        selectedRecipients: [],
        cc: [],
        ccTAs: false,
        bcc: [],
        noAutoBcc: false,
        teachingAssistants: [],
        showUniColumn: true,
        showProctorScheduledTimeColumn: false,
        proctorsForProctoringSession: '',
        onDutyProctorsForProctoringSession: '',
        proctoringSessionTitle: '',
        emailLocationsFor: '',
        disableBody: false,
        showExamColumns: false,
        isLocationEmail: false,
        showFullSize: false,
        autoEmailIdentifier: '',
        logIndividualEmails: false,
        timePeriod: '',
        emailExamRelatedInstructors: false,
        emailNotetakingRelatedInstructors: false,
        emailCourseStudents: false,
        examProfessorSelect1: '',
        examProfessorSelect2: '',
        examProfessorSelect3: '',
        examProfessorOptionsOne: [],
        examProfessorOptionsTwo: [],
        examProfessorOptionsThree: [],
        examRelatedInstructorData: {},
        notetakingProfessorSelect: '',
        notetakingProfessorRequested: '',
        notetakingProfessorEmailed: '',
        notetakingProfessorEmailedOneWeek: '',
        notetakingProfessorOptions: [],
        notetakingProfessorRequestedOptions: [],
        notetakingProfessorEmailedOptions: [],
        notetakingProfessorEmailedOneWeekOptions: [],
        notetakingRelatedInstructorData: {},
        noUserSignature: false,
        proctorsOnDutyNow: false,
        proctorScheduledSessionsForDates: false,
        proctorScheduledSessionsForDatesStart: moment(new Date()).format('YYYY-MM-DD'),
        proctorScheduledSessionsForDatesEnd: moment(new Date()).format('YYYY-MM-DD'),
        proctorScheduledSessionsForDatesOptions: [],
        proctorSelection: 'all',
        examProctorsForDates: false,
        examProctorsForDatesStart: moment(new Date()).format('YYYY-MM-DD'),
        examProctorsForDatesEnd: moment(new Date()).format('YYYY-MM-DD'),
        examProctorsForDatesSelection: 'all',
        enrollmentId: '',
        missingDropoffs: false,
        weekNum: 1,
        semesterId: '',
        sentFromMasterList: true,
        sendAsSingleEmail: false,
        loadSelfReplyToAddress: false,
        hideReplyTo: false,
        includeCancelled: false
      }
    },
    computed: {
      ...mapGetters('auth', ['userEmail'])
    },
    methods: {
      ...mapActions('auth', ['getUser']),

      displayError: function(msg) {
        this.$message({
          showClose: true,
          duration: 10000,
          type: 'error',
          message: msg,
          customClass: 'error-msg'
        });
      },
      next: function() {
        //set recipients and replyto on back end
        //this.replyTo
        //this.recipients
        let params = this.$route.query;
        // eslint-disable-next-line
        //console.dir(params);
        if(this.draftId) {
          this.$router.push({ path: "/group-email/compose/edit", query: { draft: this.draftId, ...params }});
        } else {
          this.$router.push({ path: "/group-email/compose/edit", query: params});
        }

      },
      querySearch: function(queryString, cb) {
        axios.get("/api/student/search/?query=" + queryString)
          .then(response => {
            cb(response.data.rows);
          });
        // call callback function to return suggestions
      },
      queryProctorSearch: function(queryString, cb) {
        axios.get("/api/group-email/search-proctors/?query=" + queryString)
          .then(response => {
            cb(response.data.rows);
          });
        // call callback function to return suggestions
      },
      handleSelect: function(item) {
        axios.get("/api/student/" + item.id)
          .then(response => {
            this.pushToRecipientsList(response, 'Student');
            this.student = "";
          });
      },
      handleProctorSelect: function(item) {
        axios.get("/api/group-email/proctor/" + item.id)
          .then(response => {
            this.pushToRecipientsList(response, 'ServiceProvider');
            this.proctor = "";
          });
      },
      handleNoteTakerSelect: function(item) {
        let response = {
          data: {
            id: item.id,
            firstName: item.first_name,
            lastName: item.last_name,
            uni: item.uni,
            preferredEmail: item.email,
            sendPromoEmail: item.sendPromoEmail
          }
        };
        this.pushToRecipientsList(response, 'ServiceProvider');
        this.notetaker = "";
      },
      onCategorySelect: function() {
        //console.log('category ' + this.type);
        if(this.type == 1) {
          this.isStudentList = true;
        } else {
          this.isStudentList = false;
        }
        axios.get("/api/group-email/app-email-lists-by-category/" + this.type + "?is_student_list=" + this.isStudentList)
          .then(response => {
            if(response.data.success) {
              this.listOptions = response.data.rows
            }
          }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
        });
      },
      onExamProfessorSelectOne: function(){
        this.examProfessorSelect2 = '';
        this.examProfessorSelect3 = '';


        var items = eval("this.examRelatedInstructorData."+this.examProfessorSelect1);

        if(this.examProfessorSelect1 == "today_rows"){
          this.autoEmailIdentifier = "exam_today_not_received";
        } else {
          this.autoEmailIdentifier = "exam_tomorrow_not_received";
        }

        this.addExamProfessorRecipients(items);
      },
      onExamProfessorSelectTwo: function(){
        this.examProfessorSelect1 = '';
        this.examProfessorSelect3 = '';


        this.autoEmailIdentifier = "exam_not_received";

        var items = eval("this.examRelatedInstructorData."+this.examProfessorSelect2);

        this.addExamProfessorRecipients(items);
      },
      onExamProfessorSelectThree: function(){
        this.examProfessorSelect1 = '';
        this.examProfessorSelect2 = '';

        this.autoEmailIdentifier = "exam_not_requested_not_received";

        var items = eval("this.examRelatedInstructorData."+this.examProfessorSelect3);

        this.addExamProfessorRecipients(items);
      },
      addExamProfessorRecipients: function(items){
        this.recipients = [];
        this.selectedRecipients = [];
        var j = null;

        for(var i = 0; i<items.length; i++){
          var item = items[i];

          var firstExam = item.exams[0];

          if(!firstExam.opt_professor_out){
            var cc = [];
            if(!firstExam.opt_teaching_assistants_out_of_group_emails && firstExam.teaching_assistants){

              for(j = 0; j<firstExam.teaching_assistants.length; j++){
                cc.push(firstExam.teaching_assistants[j].email);
              }
            }
            this.recipients.push({
              id: ""+item.id,
              type: "Course",
              firstName: item.instructor_first_name,
              lastName: item.instructor_last_name,
              regarding_id: firstExam.id,
              regarding_class: 'TestingService',
              email: item.instructor_email,
              cc: cc,
              exams: item.exams
            });
          } else {
            if(!firstExam.opt_teaching_assistants_out_of_group_emails && firstExam.teaching_assistants) {
              // console.log(item);
              for (j = 0; j < firstExam.teaching_assistants.length; j++) {
                var ta = firstExam.teaching_assistants[j];

                this.recipients.push({
                  id: "" + ta.id,
                  type: "ServiceProvider",
                  firstName: ta.first_name,
                  lastName: ta.last_name,
                  regarding_id: firstExam.id,
                  regarding_class: 'TestingService',
                  email: ta.email,
                  course_id: item.id,
                  exams: item.exams
                });
              }
            }
          }
        }

        axios.get("/api/group-email/get-auto-email/?identifier=" + this.autoEmailIdentifier)
          .then(emailResponse => {
            var autoEmail = emailResponse.data;

            this.subject = autoEmail.subject;
            this.body = autoEmail.body;
            this.cc = autoEmail.cc;
            this.bcc = autoEmail.bcc != null ? autoEmail.bcc : [];
            if(autoEmail.bcc_sending_user){
              this.bcc.push(this.userEmail)
            }
            this.sendAsSingleEmail = autoEmail.send_as_single_email;
            this.noAutoBcc = autoEmail.no_auto_bcc;

            //
            this.saveDraft();
          });

      },
      onNotetakingProfessorSelect: function(){
        this.notetakingProfessorRequested = '';
        this.notetakingProfessorEmailed = '';
        this.notetakingProfessorEmailedOneWeek = '';

        var items = eval("this.notetakingRelatedInstructorData."+this.notetakingProfessorSelect);

        if(this.notetakingProfessorSelect == "requested_rows"){
          this.autoEmailIdentifier = "notetaking_initial_request";
        } else {
          this.autoEmailIdentifier = "notetaking_subsequent_request";
        }

        this.addNotetakingProfessorRecipients(items);
      },
      onNotetakingProfessorRequestedSelect: function(){
        this.notetakingProfessorSelect = '';
        this.notetakingProfessorEmailed = '';
        this.notetakingProfessorEmailedOneWeek = '';

        var items = this.notetakingRelatedInstructorData.requested_rows;

        var item;

        for(var i = 0; i<items.length; i++){
          if(items[i].id == this.notetakingProfessorRequested){
            item = items[i];
          }
        }

        this.autoEmailIdentifier = "notetaking_initial_request";

        this.addNotetakingProfessorRecipients([item]);
      },
      onNotetakingProfessorEmailedSelect: function(){
        this.notetakingProfessorSelect = '';
        this.notetakingProfessorRequested = '';
        this.notetakingProfessorEmailedOneWeek = '';

        var items = this.notetakingRelatedInstructorData.emailed_rows;

        var item;

        for(var i = 0; i<items.length; i++){
          if(items[i].id == this.notetakingProfessorEmailed){
            item = items[i];
          }
        }

        this.autoEmailIdentifier = "notetaking_subsequent_request";

        this.addNotetakingProfessorRecipients([item]);
      },
      onNotetakingProfessorEmailedOneWeekSelect: function(){
        this.notetakingProfessorSelect = '';
        this.notetakingProfessorRequested = '';
        this.notetakingProfessorEmailed = '';

        var items = this.notetakingRelatedInstructorData.emailed_one_week_rows;

        var item;

        for(var i = 0; i<items.length; i++){
          if(items[i].id == this.notetakingProfessorEmailedOneWeek){
            item = items[i];
          }
        }

        this.autoEmailIdentifier = "notetaking_subsequent_request";

        this.addNotetakingProfessorRecipients([item]);
      },

      addNotetakingProfessorRecipients: function(items){

        this.recipients = [];
        this.selectedRecipients = [];

        for(var i = 0; i<items.length; i++){
          var item = items[i];

          var cc = [];
          if(!item.opt_teaching_assistants_out_of_group_emails && item.teaching_assistants){

            for(var j = 0; j<item.teaching_assistants.length; j++){
              cc.push(item.teaching_assistants[j].email);
            }
          }
          this.recipients.push({
            id: ""+item.id,
            type: "Course",
            firstName: item.instructor_first_name,
            lastName: item.instructor_last_name,
            regarding_id: item.service_id,
            regarding_class: 'NoteTakingService',
            email: item.instructor_email,
            cc: cc
          });

        }

        axios.get("/api/group-email/get-auto-email/?identifier=" + this.autoEmailIdentifier)
          .then(emailResponse => {
            var autoEmail = emailResponse.data;

            this.subject = autoEmail.subject;
            this.body = autoEmail.body;
            this.post_action = autoEmail.post_action;
            this.cc = autoEmail.cc;
            this.bcc = autoEmail.bcc != null ? autoEmail.bcc : [];
            if(autoEmail.bcc_sending_user){
              this.bcc.push(this.userEmail)
            }
            this.sendAsSingleEmail = autoEmail.send_as_single_email;
            this.noAutoBcc = autoEmail.no_auto_bcc;

            this.saveDraft();
          });

      },
      pushToRecipientsList: function(response, className) {
        //this.recipients.push({
        this.recipients.unshift({ //push to beginning
          id: response.data.id,
          email: response.data.preferredEmail ? response.data.preferredEmail : "email not found",
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          uni: response.data.uni,
          sendPromoEmail: response.data.sendPromoEmail,
          type: className
        });
        this.saveDraft();
      },
      handleRecipientSelect: function(val) {
        this.selectedRecipients = [];
        for(var i=0; i<val.length; i++) {
          this.selectedRecipients.push(val[i]);
        }

      },
      handleRemoveSelect: function() {
        var toRemove = this.selectedRecipients;
        this.recipients = this.recipients.filter( function( el ) {
          return toRemove.indexOf(el) < 0;
        });
        this.selectedRecipients = [];
        this.saveDraft();
      },
      handleRemoveOthers: function() {
        this.recipients = this.selectedRecipients.slice(0); //copy all
        this.selectedRecipients = [];
        this.saveDraft();
      },
      handleRemoveAll: function() {
        this.recipients = [];
        this.selectedRecipients = [];
        this.saveDraft();
      },
      loadDraft: function(id)  {
        axios.get("/api/group-email/draft/" + (id ? "?id=" + id : ''))
          .then(response => {
            if(response.data.success) {
              // this.cc = response.data.params.cc;
              // this.recipients = response.data.params.recipients;
              // this.regardingClass = response.data.params.regardingClass;
              // this.regardingId = response.data.params.regardingId;
              // this.replyTo = response.data.params.replyTo;
              // this.courseId = response.data.params.courseId;
              //

              this.subject = response.data.subject;
              this.body = response.data.body;
              // eslint-disable-next-line
              // console.log(response.data);

              //iterate over param keys, assign local variables accordingly
              if(response.data.params){
                const keys = Object.keys(response.data.params);

                // eslint-disable-next-line
                var me = this;
                for(var i=0; i<keys.length; i++){
                  var key = keys[i];
                    if(typeof eval("me."+key) !== 'undefined'){
                      // eslint-disable-next-line
                      console.log("me."+key + " = response.data.params."+key);
                      eval("me."+key + " = response.data.params."+key);
                    }
                }
              }

              this.loadCourse();
              this.loadRegarding();

            }
          });
      },
      saveDraft: function() {
        // eslint-disable-next-line
        console.log("in saveDraft");
        if(this.draftId){
          axios.put("/api/group-email/draft/",{
            id: this.draftId,
            replyTo: this.replyTo,
            recipients: this.recipients,
            regardingId: this.regardingId,
            regardingClass: this.regardingClass,
            cc: this.cc,
            bcc: this.bcc,
            noAutoBcc: this.noAutoBcc,
            courseId: this.courseId,
            studentId: this.studentId,
            subject: this.subject,
            body: this.body,
            post_action: this.post_action,
            disableBody: this.disableBody,
            isLocationEmail: this.isLocationEmail,
            autoEmailIdentifier: this.autoEmailIdentifier,
            logIndividualEmails: this.logIndividualEmails,
            timePeriod: this.timePeriod,
            noUserSignature: this.noUserSignature,
            proctorStartDate: this.proctorScheduledSessionsForDatesStart,
            proctorEndDate: this.proctorScheduledSessionsForDatesEnd,
            sentFromMasterList: this.sentFromMasterList,
            templateId: this.templateId,
            sendAsSingleEmail: this.sendAsSingleEmail,
            regardingFirstName: this.regardingFirstName,
            regardingLastName: this.regardingLastName,
            instructorFirstName: this.instructorFirstName,
            instructorLastName: this.instructorLastName,
            regardingUni: this.regardingUni,
            ccTAs: this.ccTAs,
            teachingAssistants: this.teachingAssistants,
            testingServiceTitle: this.testingServiceTitle,
            courseName: this.courseName,
            regardingEmail: this.regardingEmail
          }).then(response => {
            // eslint-disable-next-line
            console.log("recipients saved")});
        } else {

          axios.post("/api/group-email/draft/",{
            replyTo: this.replyTo,
            recipients: this.recipients,
            regardingId: this.regardingId,
            regardingClass: this.regardingClass,
            cc: this.cc,
            bcc: this.bcc,
            noAutoBcc: this.noAutoBcc,
            courseId: this.courseId,
            studentId: this.studentId,
            subject: this.subject,
            body: this.body,
            post_action: this.post_action,
            disableBody: this.disableBody,
            isLocationEmail: this.isLocationEmail,
            autoEmailIdentifier: this.autoEmailIdentifier,
            logIndividualEmails: this.logIndividualEmails,
            timePeriod: this.timePeriod,
            noUserSignature: this.noUserSignature,
            proctorStartDate: this.proctorScheduledSessionsForDatesStart,
            proctorEndDate: this.proctorScheduledSessionsForDatesEnd,
            sentFromMasterList: this.sentFromMasterList,
            templateId: this.templateId,
            sendAsSingleEmail: this.sendAsSingleEmail,
            regardingFirstName: this.regardingFirstName,
            regardingLastName: this.regardingLastName,
            instructorFirstName: this.instructorFirstName,
            instructorLastName: this.instructorLastName,
            regardingUni: this.regardingUni,
            ccTAs: this.ccTAs,
            teachingAssistants: this.teachingAssistants,
            testingServiceTitle: this.testingServiceTitle,
            courseName: this.courseName,
            regardingEmail: this.regardingEmail
          }).then(response => {

            this.draftId = response.data.id;

            // eslint-disable-next-line
            console.log("recipients and draft saved");

            if(this.studentId){
              this.$router.push("/group-email/compose/edit/?draft="+this.draftId);
            }
          });
        }
      },
      addToRecipientList: function() {
        var queryString = "id=" + this.list + "&limit_to_this_term=" + this.limitToStudentsEnrolledThisTerm;
        if(this.limit) {
          queryString += "&limit_to_coordinator_id=" + this.limit;
        }
        axios.get("/api/group-email/app-email-list/?" + queryString )
          .then(response => {
            if(response.data.success) {
              for(var i=response.data.rows.length-1; i>=0; i--) {
                var row = response.data.rows[i];
                this.recipients.unshift({
                  id: row.id,
                  email: row.uni ? row.uni + '@columbia.edu' : row.email,
                  firstName: row.first_name,
                  middleName: row.middle_name,
                  lastName: row.last_name,
                  uni: row.uni,
                  sendPromoEmail: row.send_promotional_email,
                  type: row.type

                });
              }
              this.saveDraft();
            }
          });
      },
      load: function () {
        this.params = this.$route.query;
        this.queryString = window.location.search.slice(1);

        this.templateId = this.params.id;
        this.draftId = this.params.draft ? parseInt(this.params.draft) : null;
        this.studentId = this.params.student_id;
        this.courseId = this.params.course_id;
        this.serviceProviderId = this.params.service_provider_id;
        this.teachingAssistantId = this.params.teaching_assistant_id;
        this.noteTakerId = this.params.note_taker_id;
        this.regardingId = this.params.regarding_id;
        this.examId = this.params.exam_id;
        this.includeCancelled = this.params.includeCancelled;
        this.regardingClass = this.params.regarding_class;
        this.ccTAs = this.params.ccTAs == "true";
        this.proctorId = this.params.proctor_id;
        this.proctorsForProctoringSession = this.params.proctors_for_proctoring_session_id;
        this.onDutyProctorsForProctoringSession = this.params.on_duty_proctors_for_proctoring_session_id;
        this.proctorsOnDutyNow = this.params.proctors_on_duty_now;
        this.proctorScheduledSessionsForDates = this.params.proctor_scheduled_sessions;
        this.examProctorsForDates = this.params.exam_proctors_for_date_range;
        this.examProctorsForDatesStart = this.params.start_date;
        this.examProctorsForDatesEnd = this.params.end_date;

        this.missingDropoffs = this.params.missing_dropoffs;
        this.weekNum = this.params.week_num;
        this.semesterId = this.params.semester_id;

        if(this.params.email_locations_for_today){
          this.emailLocationsFor = "today";
        } else if(this.params.email_locations_for_tomorrow){
          this.emailLocationsFor = "tomorrow";
        } else if(this.params.email_locations_for_monday){
          this.emailLocationsFor = "monday";
        } else if(this.params.email_locations_for_enrollment){
          this.emailLocationsFor = "enrollment";
          this.enrollmentId = this.params.enrollment_id;
        }

        if(this.params.email_exam_related_instructors){
          this.emailExamRelatedInstructors = true;
          this.replyTo = "dsexams@columbia.edu";
        }

        if(this.params.email_note_taking_related_instructors){
          this.emailNotetakingRelatedInstructors = true;
        }

        if(this.params.email_course_students){
          this.emailCourseStudents = this.params.email_course_students;
          this.replyTo = "dsexams@columbia.edu";
          this.cc = ["dsexams@columbia.edu"];
          this.noAutoBcc = true;
          this.hideReplyTo = true;
        }



        if(this.draftId){
          this.loadDraft(this.draftId);
          // } else if(this.regardingId && this.regardingClass && this.courseId) {
          //     this.loadCourse();
          // } else {
        }

        this.loadRecipients();
        this.loadCategories();
        this.loadCoordinators();
        this.loadNotetakers();
        this.loadProctors();

      },
      loadSelfReplyTo: function() {
        axios.get("/api/group-email/self-reply-to/")
                .then(response => {
                  if(response.data.success){
                      this.replyToOptions.push({
                        value: response.data.replyTo,
                        label: response.data.replyTo
                      })
                  }
                });
      },
      loadCourse: function() {
        if(this.courseId) {
          axios.get("/api/group-email/course/" + this.courseId)
            .then(response => {
              this.instructorFirstName = response.data.course.instructor_first_name;
              this.instructorLastName = response.data.course.instructor_last_name;

              if(response.data.course.instructor_email != null && response.data.course.instructor_email.trim() != ""){
                this.instructorEmail = response.data.course.instructor_email;
              } else if(response.data.course.alternate_instructor_email != null && response.data.course.alternate_instructor_email.trim() != ""){
                this.instructorEmail = response.data.course.alternate_instructor_email;
              } else if(response.data.course.secondary_alternate_instructor_email != null && response.data.course.secondary_alternate_instructor_email.trim() != ""){
                this.instructorEmail = response.data.course.secondary_alternate_instructor_email;
              }
              this.courseName = response.data.course.class_name;

              //this.recipients = [];

              var course = {
                id: this.courseId,
                email: this.instructorEmail,
                firstName: this.instructorFirstName,
                lastName: this.instructorLastName,
                sendPromoEmail: true,
                type: 'Course'
              };

              var found = false;
              for(var i = 0; i<this.recipients.length; i++){
                var obj = this.recipients[i];
                if(obj.type == "Course" && obj.id == this.courseId){
                  found = true;
                  break;
                }
              }
              if(!found){
                this.recipients.push(course);
              }
              this.saveDraft();

            });

        }

      },
      loadRegarding: function() {
        if(this.regardingId && this.regardingClass == "Student") {
          axios.get("/api/student/" + this.regardingId)
            .then(response => {
              //set student vars
              this.regardingEmail = response.data.preferredEmail;
              this.regardingFirstName = response.data.firstName;
              this.regardingLastName = response.data.lastName;
              this.regardingUni = response.data.uni;
              this.regardingSendPromoEmail = response.data.sendPromoEmail


              this.saveDraft();
            });
        } else if(this.regardingId && this.regardingClass == "TestingService") {
          if(!this.courseId){
            this.cc = ["dsexams@columbia.edu"];
          }
          this.replyTo = "dsexams@columbia.edu";
          // this.noAutoBcc = true;
          axios.get("/api/testing-service/?id=" + this.regardingId)
            .then(response => {
              this.testingServiceTitle = moment(response.data.testing_service.start_time).format("M/D/YYYY h:mma");

              if(this.ccTAs && response.data.testing_service.teaching_assistants){
                for(var i=0; i<response.data.testing_service.teaching_assistants.length; i++){
                  var teachingAssistant = response.data.testing_service.teaching_assistants[i];
                  this.cc.push(teachingAssistant.email);
                  this.teachingAssistants.push(teachingAssistant);
                }
              }
              //set title
              //TestingService: Exam at 08/02/18 12:30 PM

              this.saveDraft();

            });
        }
      },
      ccRegardingToEmail: function(val){
        var index = this.cc.indexOf(this.regardingEmail);

        if(val == true){
          if(index == -1){
            this.cc.push(this.regardingEmail);
          }
        } else {
          this.cc.splice(index, 1);
        }
        this.saveDraft();
      },
      addRegardingToRecipientList: function() {
        var found = false;
        for(var i = 0; i<this.recipients.length; i++){
          var obj = this.recipients[i];
          if(obj.type == this.regardingClass && obj.id == this.regardingId){
            found = true;
            break;
          }
        }
        if(!found){

          this.recipients.push({
            id: this.regardingId,
            email: this.regardingEmail,
            firstName: this.regardingFirstName,
            lastName: this.regardingLastName,
            sendPromoEmail: this.regardingSendPromoEmail,
            type: this.regardingClass
          });
          this.saveDraft();
        }
      },
      loadRecipients: function() {
        this.recipients = [];
        if(this.studentId) {
          this.loadSelfReplyToAddress = true;

          axios.get("/api/student/" + this.studentId)
            .then(response => {
              this.pushToRecipientsList(response, 'Student');

              this.saveDraft();


            });
        } else if(this.courseId){
          this.loadCourse();
        } else if(this.noteTakerId) {
          axios.get("/api/service/service-provider/?id=" + this.noteTakerId)
            .then(response => {
              this.serviceProviderFirstName = response.data.service_provider.first_name;
              this.serviceProviderLastName = response.data.service_provider.last_name;



              this.recipients.unshift({ //push to beginning

                id: ""+response.data.service_provider.id,
                email: response.data.service_provider.email,
                firstName: response.data.service_provider.first_name,
                lastName: response.data.service_provider.last_name,
                uni: response.data.service_provider.uni,
                sendPromoEmail: true,
                type: 'ServiceProvider'
              });
              this.saveDraft();

            });
        } else if(this.serviceProviderId) {
          axios.get("/api/service/service-provider/?id=" + this.serviceProviderId)
            .then(response => {
              this.serviceProviderFirstName = response.data.service_provider.first_name;
              this.serviceProviderLastName = response.data.service_provider.last_name;



              this.recipients.unshift({ //push to beginning

                id: ""+response.data.service_provider.id,
                email: response.data.service_provider.email,
                firstName: response.data.service_provider.first_name,
                lastName: response.data.service_provider.last_name,
                uni: response.data.service_provider.uni,
                sendPromoEmail: true,
                type: 'ServiceProvider'
              });
              this.saveDraft();

            });
        } else if(this.teachingAssistantId) {
          axios.get("/api/service/service-provider/?id=" + this.teachingAssistantId)
            .then(response => {
              this.serviceProviderFirstName = response.data.service_provider.first_name;
              this.serviceProviderLastName = response.data.service_provider.last_name;



              this.recipients.unshift({ //push to beginning

                id: ""+response.data.service_provider.id,
                email: response.data.service_provider.email,
                firstName: response.data.service_provider.first_name,
                lastName: response.data.service_provider.last_name,
                uni: response.data.service_provider.uni,
                sendPromoEmail: true,
                type: 'ServiceProvider'
              });
              this.saveDraft();

            });
        } else if(this.proctorId) {
          axios.get("/api/service/service-provider/?id=" + this.proctorId)
            .then(response => {
              this.serviceProviderFirstName = response.data.service_provider.first_name;
              this.serviceProviderLastName = response.data.service_provider.last_name;



              this.recipients.unshift({ //push to beginning

                id: ""+response.data.service_provider.id,
                email: response.data.service_provider.email,
                firstName: response.data.service_provider.first_name,
                lastName: response.data.service_provider.last_name,
                uni: response.data.service_provider.uni,
                sendPromoEmail: true,
                type: 'ServiceProvider'
              });
              this.saveDraft();

            });
        } else if(this.examId) {
          this.hideReplyTo = true;
          this.replyTo = "dsexams@columbia.edu";
          this.cc = ["dsexams@columbia.edu"];
          this.noAutoBcc = true;
          axios.get("/api/testing-service/", {params: {id: this.examId}})
            .then(response => {

              this.regardingId = this.examId;
              this.regardingClass = 'TestingService';

              this.testingServiceTitle = response.data.testing_service.class_name + " " +response.data.course_number_and_section;

              this.testingServiceTitle += " ("+response.data.testing_service.instructor_first_name + " " +response.data.testing_service.instructor_last_name + ") ";

              this.testingServiceTitle += " on " + moment(response.data.testing_service.start_time).format("M/D/YYYY h:mma");


              //todo get students for service
              //where
              //opt_student_out_of_emails is not true and status = 'active'
              axios.get("/api/student/emailable-students-for-exam/", {params: {exam_id: this.examId, includeCancelled: this.includeCancelled}})
                .then(response => {
                  for(var i=0; i<response.data.rows.length; i++){
                    var row = response.data.rows[i];

                    if(!this.draftId){
                      this.recipients.unshift({ //push to beginning
                        id: ""+row.id,
                        email: row.preferredEmail ? row.preferredEmail : "email not found",
                        firstName: row.firstName,
                        lastName: row.lastName,
                        uni: row.uni,
                        sendPromoEmail: row.sendPromoEmail,
                        type: 'Student'
                      });
                    }
                  }
                  this.saveDraft();

                });

            });
        } else if(this.proctorsForProctoringSession || this.onDutyProctorsForProctoringSession){
          var proctoringSessionId = this.proctorsForProctoringSession ? this.proctorsForProctoringSession : this.onDutyProctorsForProctoringSession;

          this.hideReplyTo = true;
          this.noAutoBcc = true;
          this.cc = ["dsexams@columbia.edu"];
          this.sendAsSingleEmail = true;

          axios.get("/api/proctoring-session/?id=" + proctoringSessionId)
            .then(response => {

              this.regardingClass='ProctoringSession';
              this.regardingId = proctoringSessionId;


              var proctoringSession = response.data.proctoring_session;

              var proctoringSessionStartDate = moment(proctoringSession.start_time).format("MMMM Do, YYYY");
              var proctoringSessionStartTime = moment(proctoringSession.start_time).format("h:mma");
              var proctoringSessionEndTime = moment(proctoringSession.end_time).format("h:mma");



              this.proctoringSessionTitle = "Proctoring session in " +proctoringSession.building_location ;
              this.proctoringSessionTitle += " on "+ proctoringSessionStartDate;
              this.proctoringSessionTitle += " from "+ proctoringSessionStartTime;
              this.proctoringSessionTitle += " to "+ proctoringSessionEndTime;
              this.sentFromMasterList = true;


              var serviceSchedules = response.data.service_schedules;

              if(serviceSchedules){
                for(var i = 0; i<serviceSchedules.length; i++){
                  var serviceSchedule = serviceSchedules[i];

                  if(this.proctorsForProctoringSession || serviceSchedule.on_duty_now){

                    var startTime = moment(serviceSchedule.start_time).format("h:mma");
                    var endTime = moment(serviceSchedule.end_time).format("h:mma");

                    if(!this.draftId) {
                      this.recipients.push({

                        id: "" + serviceSchedule.service_provider_id,
                        email: serviceSchedule.email,
                        firstName: serviceSchedule.first_name,
                        lastName: serviceSchedule.last_name,
                        scheduled_time: startTime + " - " + endTime,
                        sendPromoEmail: true,
                        type: 'ServiceProvider'
                      });
                    }
                  }
                }

              }

              this.showUniColumn = false;
              this.showProctorScheduledTimeColumn = true;

              this.saveDraft();

              // this.serviceProviderFirstName = response.data.service_provider.first_name;
              // this.serviceProviderLastName = response.data.service_provider.last_name;
              //
              //
              //
              // this.recipients.unshift({ //push to beginning
              //
              //     id: ""+response.data.service_provider.id,
              //     email: response.data.service_provider.email,
              //     firstName: response.data.service_provider.first_name,
              //     lastName: response.data.service_provider.last_name,
              //     uni: response.data.service_provider.uni,
              //     sendPromoEmail: true,
              //     type: 'ServiceProvider'
              // });
              // this.saveDraft();

            });
        } else if(this.emailLocationsFor){

            this.hideReplyTo=true;
          this.disableBody = true;
          this.isLocationEmail = true;
          this.showExamColumns = true;
          this.showFullSize = true;
          this.autoEmailIdentifier = "student_exam_location_reminder";
          this.logIndividualEmails = true;
          this.timePeriod = this.emailLocationsFor;

          this.noUserSignature = true;

          let url = "/api/testing-service/exam-location-recipients/?for=" + this.emailLocationsFor;
          if(this.emailLocationsFor == "enrollment"){
            url += "&enrollmentId="+this.enrollmentId;
          }

          axios.get(url)
            .then(response => {


              axios.get("/api/group-email/get-auto-email/?identifier=" + this.autoEmailIdentifier)
                .then(emailResponse => {
                  var autoEmail = emailResponse.data;

                  this.subject = autoEmail.subject;
                  this.body = autoEmail.body;
                  this.cc = autoEmail.cc;
                  this.bcc = autoEmail.bcc != null ? autoEmail.bcc : [];
                  if(autoEmail.bcc_sending_user){
                    this.bcc.push(this.userEmail)
                  }
                  this.sendAsSingleEmail = autoEmail.send_as_single_email;
                  this.noAutoBcc = autoEmail.no_auto_bcc;

                  var rows = response.data.rows;
                  if(!this.draftId) {
                    if (rows) {
                      for (var i = 0; i < rows.length; i++) {
                        var row = rows[i];

                        row.id = "" + row.student_id;
                        row.type = "Student";
                        row.firstName = row.first_name;
                        row.lastName = row.last_name;
                        row.regarding_id = row.service_id;
                        row.regarding_class = "TestingService";

                        this.recipients.push(row);
                      }
                    }
                  }

                  this.saveDraft();
                });
            });
        } else if(this.emailExamRelatedInstructors) {

          this.disableBody = true;
          this.showUniColumn = false;
          this.noUserSignature = true;
          this.logIndividualEmails = true;


          axios.get("/api/testing-service/exam-related-instructors-for-email/")
            .then(response => {
              var data = response.data;

              this.examRelatedInstructorData = data;

              this.examProfessorOptionsOne.push({id: "today_rows", name: "All professors with Exam Not Received and Exam is Today ("+data.today_rows.length+")"})
              this.examProfessorOptionsOne.push({id: "tomorrow_rows",name: "All professors with Exam Not Received and Exam is Tomorow ("+data.tomorrow_rows.length+")"})


              this.examProfessorOptionsTwo.push({id: "three_days_rows", name: "All professors with Exam Not Received within 3 days "+data.three_days_label+" ("+data.three_days_rows.length+")"});
              this.examProfessorOptionsTwo.push({id: "five_days_rows", name: "All professors with Exam Not Received within 5 days "+data.five_days_label+" ("+data.five_days_rows.length+")"});
              this.examProfessorOptionsTwo.push({id: "seven_days_rows", name: "All professors with Exam Not Received within 7 days "+data.seven_days_label+" ("+data.seven_days_rows.length+")"});
              this.examProfessorOptionsTwo.push({id: "nine_days_rows", name: "All professors with Exam Not Received within 9 days "+data.nine_days_label+" ("+data.nine_days_rows.length+")"});
              //

              this.examProfessorOptionsThree.push({id: "ten_days_rows", name: "All professors with Exam Not Received, Not Requested, Within 10 days "+data.ten_days_label+" ("+data.ten_days_rows.length+")"});

              // examProfessorOptionsOne
              // examProfessorOptionsTwo
              // examProfessorOptionsThree
            });
        } else if(this.emailNotetakingRelatedInstructors) {

          this.disableBody = true;
          this.showUniColumn = false;
          // this.noUserSignature = true;
          this.logIndividualEmails = true;


          axios.get("/api/notetaking-service/notetaking-related-instructors-for-email/")
            .then(response => {
              var data = response.data;

              this.notetakingRelatedInstructorData = data;

              this.notetakingProfessorOptions.push({id: "requested_rows", name: "Professors with note taking service requested ("+data.requested_rows.length+")"})
              this.notetakingProfessorOptions.push({id: "emailed_rows",name: "Professors emailed once with note taking services ("+data.emailed_rows.length+")"})

              this.notetakingProfessorOptions.push({id: "emailed_one_week_rows",name: "Professors emailed once with note taking services - more than 1 week ago ("+data.emailed_one_week_rows.length+")"})

              this.notetakingProfessorRequestedOptions = data.requested_rows;
              this.notetakingProfessorEmailedOptions = data.emailed_rows;
              this.notetakingProfessorEmailedOneWeekOptions = data.emailed_one_week_rows;

            });
        } else if(this.emailCourseStudents){
          axios.get("/api/student/students-for-course/?course_id="+this.emailCourseStudents)
            .then(response => {
              var rows = response.data.rows;

              for(var i=0; i < rows.length; i ++){
                var row = rows[i];
                if(!this.draftId) {
                  this.recipients.push({

                    id: "" + row.id,
                    type: "Student",
                    email: row.preferredEmail,
                    firstName: row.firstName,
                    lastName: row.lastName,
                    uni: row.uni,
                    regarding_id: this.emailCourseStudents,
                    regarding_class: 'Course'
                  });
                }
              }

              this.saveDraft();

            });
        } else if(this.proctorsOnDutyNow) {
            this.hideReplyTo = true;
            this.cc = ["dsexams@columbia.edu"];
            this.noAutoBcc = true;
            this.sendAsSingleEmail = true;
          axios.get("/api/proctoring-session/proctors-on-duty-now/")
            .then(response => {

              var serviceSchedules = response.data.rows;

              if(serviceSchedules){
                for(var i = 0; i<serviceSchedules.length; i++){
                  var serviceSchedule = serviceSchedules[i];

                  var startTime = moment(serviceSchedule.start_time).format("h:mma");
                  var endTime = moment(serviceSchedule.end_time).format("h:mma");
                  if(!this.draftId) {
                    this.recipients.push({

                      id: "" + serviceSchedule.id,
                      email: serviceSchedule.email,
                      firstName: serviceSchedule.first_name,
                      lastName: serviceSchedule.last_name,
                      scheduled_time: startTime + " - " + endTime,
                      sendPromoEmail: true,
                      type: 'ServiceProvider',
                      regarding_id: serviceSchedule.proctoring_session_id,
                      regarding_class: 'ProctoringSession'
                    });
                  }
                }

              }

              this.showUniColumn = false;
              this.showProctorScheduledTimeColumn = true;

              this.saveDraft();
            });

        } else if(this.examProctorsForDates) {
          this.hideReplyTo = true;
          this.cc = ["dsexams@columbia.edu"];
          this.noAutoBcc = true;
          this.sendAsSingleEmail = true;

          axios.get("/api/proctoring-session/exam-proctors-for-dates/?start_date="+this.examProctorsForDatesStart+"&end_date="+this.examProctorsForDatesEnd)
            .then(response => {

              var proctors = response.data.rows;
              if(!this.draftId) {
                if (proctors) {
                  for (var i = 0; i < proctors.length; i++) {
                    var proctor = proctors[i];

                    this.recipients.push({

                      id: "" + proctor.id,
                      email: proctor.email,
                      firstName: proctor.first_name,
                      lastName: proctor.last_name,
                      sendPromoEmail: true,
                      type: 'ServiceProvider'
                    });

                  }

                }
              }

              this.showUniColumn = false;
              this.showProctorScheduledTimeColumn = false;

              this.saveDraft();
            });

        } else if(this.missingDropoffs){
          axios.get("/api/notetaking-service/missing-dropoffs-for-email/?week_num="+this.weekNum+"&semester_id="+this.semesterId)
            .then(response => {
              var noteTakers = response.data.rows;

              if(!this.draftId) {
                if (noteTakers) {
                  for (var i = 0; i < noteTakers.length; i++) {
                    var noteTaker = noteTakers[i];

                    this.recipients.push({

                      id: "" + noteTaker.id,
                      email: noteTaker.email,
                      firstName: noteTaker.first_name,
                      lastName: noteTaker.last_name,
                      sendPromoEmail: true,
                      type: 'ServiceProvider'
                    });

                  }
                }
              }

              this.showUniColumn = false;
              this.showProctorScheduledTimeColumn = false;

              this.saveDraft();
            });
        } else if(this.proctorScheduledSessionsForDates) {
          this.noUserSignature = true;
          this.hideReplyTo = true;
          this.getProctorScheduledSessionsForDates();


        } else {
          this.loadSelfReplyToAddress = true;
        }

        if(this.regardingId && this.regardingClass){

          this.loadRegarding();
        }


        if(this.loadSelfReplyToAddress){
          this.loadSelfReplyTo();
        }



      },
      loadProctors: function() {
        axios.get("/api/proctor/active-proctors")
          .then(response => {
            if(response.data.success) {
              for(var i = 0; i<response.data.rows.length; i++) {
                response.data.rows[i].name = response.data.rows[i].first_name + " " + response.data.rows[i].last_name;
                this.proctorOptions.push(response.data.rows[i]);
              }
            }
          });
      },
      loadNotetakers: function() {
        axios.get("/api/notetaking-service/active-note-takers")
          .then(response => {
            if(response.data.success) {
              for(var i = 0; i<response.data.rows.length; i++) {
                response.data.rows[i].name = response.data.rows[i].first_name + " " + response.data.rows[i].last_name;
                this.noteTakerOptions.push(response.data.rows[i]);
              }
            }
          });
      },
      loadCategories: function() {
        axios.get("/api/group-email/top-level-email-list-categories")
          .then(response => {
            if(response.data.success) {
              this.typeOptions = response.data.rows;
            }
          });
      },
      loadCoordinators: function() {
        axios.get("/api/group-email/coordinators")
          .then(response => {
            if(response.data.success) {
              this.limitOptions = response.data.rows;
            }
          });
      },
      studentComputer: function(accommodations){
        if(accommodations){
          for(var i = 0; i < accommodations.length; i++){
            if(accommodations[i].accommodation_type_id == 8){
              return true;
            }
          }
        }
        return false;
      },
      getProctorScheduledSessionsForDates: function(){
        this.recipients = [];
        this.selectRecipients = [];
        axios.get("/api/proctoring-session/proctors-for-dates/?start_date="+this.proctorScheduledSessionsForDatesStart+"&end_date="+this.proctorScheduledSessionsForDatesEnd)
          .then(response => {

            var serviceSchedules = response.data.rows;

            this.proctorScheduledSessionsForDatesOptions = [];
            this.proctorScheduledSessionsForDatesOptions.push({
              id: 'all',
              name: 'All'
            });
            if(serviceSchedules){
              for(var i = 0; i<serviceSchedules.length; i++){
                var serviceSchedule = serviceSchedules[i];

                var found = false;
                for (var j = 0; j < this.proctorScheduledSessionsForDatesOptions.length; j++) {
                  var obj = this.proctorScheduledSessionsForDatesOptions[j];
                  if (obj.type == "ServiceProvider" && obj.id == serviceSchedule.id) {
                    found = true;
                    break;
                  }
                }
                if (!found) {

                  this.proctorScheduledSessionsForDatesOptions.push({

                    id: "" + serviceSchedule.id,
                    name: serviceSchedule.first_name + " " + serviceSchedule.last_name,
                    email: serviceSchedule.email,
                    firstName: serviceSchedule.first_name,
                    lastName: serviceSchedule.last_name,
                    sendPromoEmail: true,
                    type: 'ServiceProvider',
                    regarding_id: serviceSchedule.proctoring_session_id,
                    regarding_class: 'ProctoringSession'
                  });
                }

              }

            }

            this.showUniColumn = false;
            this.logIndividualEmails = true;
            this.disableBody = true;
            this.autoEmailIdentifier = "proctor_scheduled_sessions";


            axios.get("/api/group-email/get-auto-email/?identifier=" + this.autoEmailIdentifier)
              .then(emailResponse => {
                var autoEmail = emailResponse.data;

                this.subject = autoEmail.subject;
                this.body = autoEmail.body;
                this.cc = autoEmail.cc;
                this.bcc = autoEmail.bcc != null ? autoEmail.bcc : [];
                if(autoEmail.bcc_sending_user){
                  this.bcc.push(this.userEmail)
                }
                this.sendAsSingleEmail = autoEmail.send_as_single_email;
                this.noAutoBcc = autoEmail.no_auto_bcc;

                this.saveDraft();
              });
          });
      },
      addProctors: function(){
        var obj = null;
        var i = null;
        var j = null;
        var proctor = null;
        var found = false;
        if(this.proctorSelection  == 'all') {

          for(i = 0; i<this.proctorScheduledSessionsForDatesOptions.length; i++) {
            proctor = this.proctorScheduledSessionsForDatesOptions[i];
            if(proctor.id != 'all'){
              found = false;
              for (j = 0; j < this.recipients.length; j++) {
                obj = this.recipients[j];
                if (obj.type == "ServiceProvider" && obj.id == proctor.id) {
                  found = true;
                  break;
                }
              }
              if (!found) {

                this.recipients.push({
                  id: proctor.id,
                  email: proctor.email,
                  firstName: proctor.firstName,
                  lastName: proctor.lastName,
                  sendPromoEmail: true,
                  type: 'ServiceProvider'
                });
              }
            }

          }
        } else {
          for(i = 0; i<this.proctorScheduledSessionsForDatesOptions.length; i++) {
            proctor = this.proctorScheduledSessionsForDatesOptions[i];

            if(proctor.id == this.proctorSelection) {
              found = false;
              for (j = 0; j < this.recipients.length; j++) {
                obj = this.recipients[j];
                if (obj.type == "ServiceProvider" && obj.id == proctor.id) {
                  found = true;
                  break;
                }
              }
              if (!found) {

                this.recipients.push({
                  id: proctor.id,
                  email: proctor.email,
                  firstName: proctor.firstName,
                  lastName: proctor.lastName,
                  sendPromoEmail: true,
                  type: 'ServiceProvider'
                });
              }
              break;
            }
          }
        }


        this.saveDraft();
      }
    },
    mounted() {
      this.load();
    }
  }
</script>
<style>
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
</style>

<style lang="postcss" scoped>
  .error-msg p {
    color: red;
  }
  #recipient-form {
    float: left;
    width: 600px;
  }
  #recipient-form p{
    line-height: 18px;
    margin: 5px 0 0 0;
  }

  #recipient-table {
    float: left;
    margin-left: 20px;
    width: 700px;
  }
  #recipient-table.full-size {
    width: 100%;
  }
  .float-left {
    float: left;
  }
  .padding-lft {
    padding-left: 1em;
  }
</style>
