<template>
    <div id="container">
<div id="content">
            <student-nav selected="accommodations" :id="id" :name="name" :uni="uni" :status="status"></student-nav>
            <br clear="all">
            <div class="menu">
                <b>Review accommodations</b>: determine eligibility of an accommodation, change a recommendation, or set an accommodation to expired. Make sure to save the changes when you are finished.
            </div>
            <student-accommodations-review :id="id" :availableAccommodations="availableAccommodations" :canEditAccommodationDate="canEditAccommodationDate"></student-accommodations-review>
            <h2>Other options</h2>
            <ul>
                <li><a :href="'/search'">Search again</a></li>
                <li><a :href="'/tasks/edit?message=true&student_id=' + this.id">Send a message regarding this student</a></li>
                <li><a :href="'/student/delete/' + this.id" @click.prevent="$alert('Placeholder for delete student')">Delete this student</a> (only Director is authorized to do this)</li>
            </ul>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.name + " - " + this.uni + " | " + this.status
            }
        },
        components: {
        },
        data () {
            return {
                id: 0,
                name: "",
                uni: "",
                status: "",
                availableAccommodations: [],
                canEditAccommodationDate: false
            }
        },
        methods: {
            getStudent: function () {
                //get the student id from the url
                this.id = location.pathname.split('/student/accommodations-review/')[1];
                axios.get("/api/student/" + this.id)
                    .then(response => {
                        this.name = response.data.title;
                        this.uni = response.data.uni;
                        this.status = response.data.statusDisplay;
                    });

                axios.get("/api/accommodation/available-student-accommodations/", {params:{id: this.id}})
                    .then(response => {
                        this.availableAccommodations = response.data.rows;
                    });


              axios.get("/api/simple/check-permissions/", {params: {permissions: ['can_change_accommodation_date']}})
                  .then(response => {
                    this.canEditAccommodationDate = response.data.permissions.can_change_accommodation_date;
                  }) ;
            }

        },
        created () {
            this.getStudent();
        }
    }
</script>

<style lang="postcss" scoped>
    .menu {
        margin-top: 20px;
        margin-bottom: 3px;
    }
</style>
