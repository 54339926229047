<template>
    <div id="container">
<div id="content">
            <h1>{{ title }}</h1>
            <a v-if="activeStatus != 'active'" class="trailing-space" @click="changeActiveStatus('active')">Show active locations only</a>
            <a v-if="activeStatus != 'inactive'" class="trailing-space" @click="changeActiveStatus('inactive')">Show inactive locations only</a>
            <a v-if="activeStatus != 'active_graduate'" class="trailing-space" @click="changeActiveStatus('active_graduate')">Show active graduate locations</a>
            <a v-if="activeStatus != 'all'" class="trailing-space" @click="changeActiveStatus('all')">Show all locations</a>
            <a :href="'/room-reservation-manager'">View room reservation contacts</a><br/><br/>
            <el-table :default-sort="{prop: 'building', order: 'ascending'}" :data="proctoringLocations" v-loading="loading" element-loading-text="Loading...">
                <el-table-column prop="building" label="Building" sortable :sort-orders="['ascending', 'descending']"></el-table-column>
                <el-table-column prop="room" label="Room" sortable :sort-orders="['ascending', 'descending']" width="150"></el-table-column>
                <el-table-column prop="room_capacity" label="Room Capacity" sortable :sort-orders="['ascending', 'descending']" width="105"></el-table-column>
                <el-table-column prop="seating_style" label="Seating Style" sortable :sort-orders="['ascending', 'descending']" width="165">
                    <template slot-scope="scope">
                        {{ scope.row.other_seating_style ? scope.row.other_seating_style : scope.row.seating_style }}
                    </template>
                </el-table-column>
                <el-table-column prop="room_diagrams" label="Room Diagrams" sortable :sort-orders="['ascending', 'descending']" width="345">
                    <template slot-scope="scope">
                        <template v-for="diagram in scope.row.room_diagrams">
                            <br v-if="scope.row.room_diagrams.indexOf(diagram) !== 0" :key="diagram.id">
                            <a :href="'/api/document/download/' + diagram.id" target="_blank" :key="diagram.id">{{ diagram.asset_file_name }}</a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="wheelchair_accessible" label="Wheelchair accessible?" sortable :sort-orders="['ascending', 'descending']" width="125"><template slot-scope="scope">{{ scope.row.wheelchair_accessible ? "Yes" : "No" }}</template></el-table-column>
                <el-table-column width="56">
                    <template slot-scope="scope">
                        <a :href="'/proctoring-location/edit/' + scope.row.id">edit</a><br>
                    </template>
                </el-table-column>
            </el-table>
            <p><a :href="'/proctoring-location/edit'">Add new proctoring location</a></p>
            <p v-if="total"><a :href="'/api/proctoring-location/proctoring-locations.xlsx?active_status=' + activeStatus" target="_blank">Download as CSV</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({{ total }} records)</p>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: this.title
            }
        },
        components: {
            },
        data () {
            return {
                activeStatus: 'active',
                proctoringLocations: [],
                title: '',
                total: null,
                loading: true
            }
        },
        methods: {
            load: function() {
                this.getProctoringLocations();
            },
            changeActiveStatus: function(status){
                this.activeStatus = status;
                this.getProctoringLocations();
            },
            getProctoringLocations: function() {
                this.proctoringLocations = [];
                this.loading = true;
                axios.get('/api/proctoring-location/proctoring-locations/', {params: {active_status: this.activeStatus}})
                    .then(response => {
                        this.proctoringLocations = response.data.rows;
                        this.total = response.data.total;
                        this.setTitle();
                        this.loading = false;
                    });
            },
            setTitle: function(){
                var title = "";
                if(this.activeStatus == "active_graduate"){
                    title += "Active Graduate";
                } else {
                    title += this.activeStatus.replace(/^\w/, c => c.toUpperCase());
                }

                title += " Proctoring Locations";

                this.title = title;
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
    .el-table th {
        vertical-align: top;
    }
</style>
