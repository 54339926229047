<template>
    <div id="container">
        <div id="content">
            <h1>Tech Exams Breakdown</h1>
            <a :href="'/api/report/tech-exams-breakdown.xlsx'" target="_blank">Download as CSV</a><br><br>
            <table v-loading="loading">
                <tbody>
                <tr>
                    <th>Academic Year</th>
                    <th>Computer Exams</th>
                    <th>CD Player Exams</th>
                    <th>AHAR Exams</th>
                    <th>ARHB Exams</th>
                    <th>MUSI Exams</th>
                    <th>MUSB Exams</th>
                </tr>

                <template v-for="(row, index) in data">
                    <tr :key="index">
                        <td>{{ row.name }}</td>
                        <td>{{ row.total_computers }}</td>
                        <td>{{ row.total_cd_player }}</td>
                        <td>{{ row.total_ahar }}</td>
                        <td>{{ row.total_arhb }}</td>
                        <td>{{ row.total_musi }}</td>
                        <td>{{ row.total_musb }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo() {
            return {
                title: "Tech Exams Breakdown"
            }
        },
        components: {},
        data() {
            return {
                data: [],
                loading: false
            }
        },
        methods: {
            load: function () {
                this.loading = true;
                this.getData();

            },
            getData: function () {
                this.loading = true;
                axios.get('/api/report/tech-exams-breakdown/')
                    .then(response => {
                        if (response.data.success) {
                            this.data = response.data.rows;

                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created() {
            this.load();
        }
    }
</script>
<style scoped>
</style>
