<template>
    <div id="container">
<div id="content">
            <h1>Contact Notes By Month and User</h1>
            <h2>Contact Notes Written for {{ startDate | formatDateMDY }} to {{ endDate | formatDateMDY }} by Staff and Status</h2>
            <table><tbody>
                <tr>
                    <th>Name</th>
                    <th>Draft</th>
                    <th>Submitted</th>
                    <th>Edits Required</th>
                    <th>Edits Completed</th>
                    <th>Approved</th>
                    <th>Total without Drafts</th>
                </tr>
                <tr v-for="(row, index) in writtenContactNotes" :key="index">
                    <td>{{ row.name }}</td>
                    <td>{{ row.draft ? row.draft  : 0}}</td>
                    <td>{{ row.submitted ? row.submitted  : 0}}</td>
                    <td>{{ row.edits_required ? row.edits_required  : 0}}</td>
                    <td>{{ row.edits_completed ? row.edits_completed  : 0 }}</td>
                    <td>{{ row.approved ? row.approved  : 0 }}</td>
                    <td>{{ row.total_without_draft }}</td>
                </tr>
            </tbody></table>

            <h2>Contact Notes Supervised for {{ startDate | formatDateMDY }} to {{ endDate | formatDateMDY }} by Staff and Status</h2>
            <table><tbody>
                <tr>
                    <th>Name</th>
                    <th>Edits Required</th>
                    <th>Edits Completed</th>
                    <th>Approved</th>
                    <th>Total</th>
                </tr>
                <tr v-for="(row, index) in supervisedContactNotes" :key="index">
                    <td>{{ row.name }}</td>
                    <td>{{ row.edits_required ? row.edits_required  : 0}}</td>
                    <td>{{ row.edits_completed ? row.edits_completed  : 0 }}</td>
                    <td>{{ row.approved ? row.approved  : 0 }}</td>
                    <td>{{ row.total }}</td>
                </tr>
            </tbody></table>
        </div>
        </div>
</template>

<script>
    import axios from 'axios'



    export default {
        metaInfo () {
            return {
                title: "Contact Notes By Month and User"
            }
        },

        components: {
            },
        data () {
            return {
                urlParams: {},
                writtenContactNotes: [],
                supervisedContactNotes: [],
                loading: null,
                startDate: null,
                endDate: null
            }
        },
        methods: {
            load: function() {
                this.urlParams = this.$route.query;
                this.startDate = this.urlParams.start_date;
                this.endDate = this.urlParams.end_date;
                this.getData();
            },
            getData: function() {
                //TODO: uncomment this when page is ready
                //this.loading = this.$loading({lock: true, text: 'Loading...'});
                axios.get('/api/report/contact-notes-by-month-and-user/', {params: {start_date: this.startDate, end_date: this.endDate}})
                    .then(response => {
                        if(response.data.success) {
                            this.writtenContactNotes = response.data.written_rows;
                            this.supervisedContactNotes = response.data.supervised_rows;
                            this.loading.close();
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
