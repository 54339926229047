<template>
    <div id="container">
      <div id="content">
            <h1>Contact Note Template: {{ title }}</h1>
            <table><tbody>
                <tr><th>Body:</th><td><span class="formatted-text">{{ body }}</span></td></tr>
            </tbody></table>
            <p><a :href="'/contact-notes/list-templates'">Back to list</a></p>
            <p><a :href="'/contact-notes/edit-template/' + this.id">Edit this template</a></p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        metaInfo () {
            return {
                title: "Contact Note Template"
            }
        },
        components: {
            },
        data () {
            return {
                id: 0,
                title: '',
                body: ''
            }
        },
        methods: {
            load: function() {
                this.id = location.pathname.split('/contact-notes/show-template/')[1];
                axios.get('/api/contact-note/contact-note-template/', {params: {id: this.id}})
                    .then(response => {
                        if(response.data.success) {
                            this.title = response.data.template.title;
                            this.body = response.data.template.body;
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        console.log(error)
                    });
            }
        },
        created () {
            this.load();
        }
    }
</script>
<style scoped>
</style>
